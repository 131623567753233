<template>
  <CustomCard
    class="text-capitalize service-card radius-10"
    :title="serviceData.job"
    theme="ebony">
    <template #headerRight>
      <button
        v-if="serviceData.payed || confirPayment"
        :disabled="true"
        class="btn outline small radius-50 font-size-07">
        <span class="d-flex ac-center">
          <i class="mdi mdi-check mr-10 text-light-green"></i>
          Pagamento Confirmado
        </span>
      </button>
      <button
        v-if="isAgency && !(serviceData.payed || confirPayment)"
        @click="confirmPayment(serviceData.job)"
        class="btn secondary small radius-50 font-size-07">
        <span class="d-flex ac-center">Confirmar Pagamento</span>
      </button>
    </template>
    <div class="content pb-10">
      <div class="left-side">
        <div class="left-item">
          <span>
            <small>Vagas</small>
          </span>
          <span>{{ serviceData.totalVacancy }}</span>
        </div>
        <div class="left-item">
          <span>
            <small>Jornada</small>
          </span>
          <span>{{ serviceData.totalResponsabilities }}</span>
        </div>
        <div class="left-item">
          <span>
            <small>Check lists</small>
          </span>
          <span>{{ serviceData.totalCheckLists }}</span>
        </div>
      </div>

      <div class="right-side ml-20 d-flex f-100 wrapped">
        <div class="d-flex right-item as-start pt-15 w-100 direction-column">
          <span class="jc-start ai-center font-size">Valor por Vaga:</span>
          <span class="d-flex jc-start pt-20 f-100 payment">
            R$ {{ serviceData.payment }},00
          </span>
        </div>
      </div>
    </div>
    <div class="progress-out ml-15 mb-10 d-flex ac-center">
      <div class="progress-in"></div>
    </div>
    <article class="flex-100 d-flex jc-space-between pt-10 direction-column">
      <div class="content-members w-100"></div>
      <MultipleAvatar
        :showText="true"
        @row-click="$emit('members-click', serviceData.job)"
        :membersData="avatarImages" />
    </article>
  </CustomCard>
</template>

<script>
import MultipleAvatar from "@/components/events/EventInfo/MultipleAvatar/MultipleAvatar.vue";
import {
  postConfirmEventPayment,
  getMembersAvatar,
} from "@/services/events/event.http";
import { mapGetters } from "vuex";

export default {
  components: { MultipleAvatar },
  data: () => ({
    avatarImages: [],
    confirPayment: false,
  }),
  props: {
    serviceData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({ isAgency: "User/isAgency" }),

    eventId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getAvatar();
  },
  methods: {
    confirmPayment(job) {
      const body = {
        payed: true,
      };
      postConfirmEventPayment(this.eventId, job, body)
        .then(() => {
          this.confirPayment = true;
          this.$eventhub.$emit("feedback:show", {
            type: "success",
            msg: "Pagamento Confirmado!",
          });
        })
        .catch((error) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${error}`,
          });
        });
    },
    getAvatar() {
      getMembersAvatar(this.eventId, this.serviceData.job).then((success) => {
        const images = success.map((x) => x.image);
        this.avatarImages = images;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.text-capitalize {
  text-transform: capitalize;
}

.service-card {
  min-width: 330px;
  flex-grow: 1;
  max-width: 400px;

  .content {
    display: flex;
    flex: 50%;

    .left-side {
      flex: 0 1 50%;
      .left-item {
        display: flex;
        justify-content: space-between;
        margin: 10px 0 0 10px;
        padding: 5px;
      }
    }
    .right-side {
      flex: 0 1 50%;

      .right-item {
        display: flex;
        img {
          margin: 0 auto;
          border-radius: 50%;
          height: 60px;
          width: 60px;
        }
      }
      .font-size {
        font-size: 0.8rem;
      }
    }
    .opacity {
      opacity: 0.5;
    }
  }
  .payment {
    font-size: 1.3rem;
    color: $Malibu;
  }
  .content-members {
    border-top: 2px solid $SteelGray;
    display: flex;
  }
}
.progress-out {
  height: 0.3rem;
  width: 92%;
  border-radius: 20px;
  background-color: $Martinique;
  .progress-in {
    height: 0.3rem;
    border-radius: 20px;
    width: 40%;
    background-color: $LightGreen;
  }
}
</style>
