<template>
  <figure class="cover-image w-100 m-0">
    <img :src="image" :alt="alt" />
    <figcaption>
      <slot></slot>
    </figcaption>
  </figure>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
  },
  computed: {
    image() {
      return this.src || require("@/assets/icon_event.svg");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.cover-image {
  display: flex;
  position: relative;

  img {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  figcaption {
    background: linear-gradient(
      0deg,
      transparentize($Ebony, 0.1),
      transparentize($Ebony, 0.5) 50%,
      transparentize($Ebony, 0.9) 100%
    );
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    z-index: 1;
    height: calc(100% + 0.2px);
  }
}
</style>
