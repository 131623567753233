import moment from "moment";

const _months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
const _week = [
  "Domingo",
  "Segunda",
  "Terça",
  "Quarta",
  "Quinta",
  "Sexta",
  "Sábado",
];

/* Lógica stackOverflow */
// https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site/33487313#33487313
const epochs = [
  ["ano", 31536000],
  ["mes", 2592000],
  ["dia", 86400],
  ["h", 3600],
  ["min", 60],
  ["seg", 1],
];

// Get duration
const getDuration = (timeAgoInSeconds) => {
  for (let [name, seconds] of epochs) {
    const interval = Math.floor(timeAgoInSeconds / seconds);

    if (interval >= 1) {
      return {
        interval: interval,
        epoch: name,
      };
    }
  }
  return {
    interval: "",
    epoch: "agora",
  };
};

// Calculate
const timeAgo = (date) => {
  const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
  const { interval, epoch } = getDuration(timeAgoInSeconds);
  const suffix = "";

  return `${interval} ${epoch}${suffix}`;
};

/* FIM Lógica stackOverflow */

const _formats = {
  ago: (date) => timeAgo(date),
  ii: (date) => `${date.getMinutes()}`.padStart(2, 0),
  HH: (date) => `${date.getHours()}`.padStart(2, 0),
  ddd: (date) => _week[date.getDay()].substr(0, 3),
  dddd: (date) => _week[date.getDay()],
  D: (date) => `${date.getDate()}`.padStart(2, 0),
  M: (date) => `${date.getMonth() + 1}`.padStart(2, 0),
  MMM: (date) => _months[date.getMonth()].substr(0, 3),
  MMMM: (date) => _months[date.getMonth()],
  YY: (date) => date.getYear(),
  YYYY: (date) => date.getFullYear(),
};

export const isValidDate = (date) =>
  date instanceof Date && date.toString() !== "Invalid Date";
export const stringToDate = (date = "") =>
  date.length === 10 && new Date(`${date.split("/").reverse().join("-")} `);

export const dateToString = (date) =>
  isValidDate(date) &&
  `${formatDate(date, "D")}/${formatDate(date, "M")}/${formatDate(
    date,
    "YYYY"
  )}`;

export const datetimeFormatter = (date, format) => moment(date).format(format);

// TODO: implementar regex para parsear data de forma complexa (e.g. "D MMM YY" ou "dd-MMMM-YYYY")
export const formatDate = (date, type) =>
  _formats[type] ? _formats[type](date) : "Invalid Format";

export default {
  formatDate,
  stringToDate,
  isValidDate,
  dateToString,
};
