<template>
  <div class="p-30">
    <div class="d-flex cursor-pointer">
      <i class="mdi mdi-chevron-left font-size-1-9 text-malibu"></i>
      <span @click="goBack()" class="font-size-08 text-malibu">
        Voltar para faturas
      </span>
    </div>
    <h2 class="text-Periwinkle">Detalhes da fatura</h2>

    <section class="mt-30">
      <h3 class="text-Periwinkle">Informações basicas</h3>

      <div class="bg-steelGray p-15 pt-25 pb-25 d-flex">
        <div class="d-flex direction-column w-50">
          <span class="mb-10">
            <small>Contratante</small>
            <br />
            {{ detail.hirerName }}
          </span>

          <span>
            <small>Data vencimento</small>
            <br />
            {{ detail.expireDate | formDate }}
          </span>
        </div>

        <div class="d-flex direction-column w-50">
          <span class="mb-10">
            <small>Cnpj</small>
            <br />
            {{ detail.document | maskCnpj }}
          </span>

          <span>
            <small>Status</small>
            <br />
            {{ statusName[detail.status] }}
          </span>
        </div>
      </div>
    </section>

    <section>
      <h3 class="text-Periwinkle">Demandas dessa fatura</h3>
      <CustomTable :head="header">
        <template #table-body>
          <tr v-for="(item, index) in detail.serviceDetails" :key="index">
            <td scope="row" data-label="Nome evento">{{ item.eventName }}</td>
            <td data-label="Serviço">{{ item.serviceName }}</td>
            <td data-label="date">
              {{ item.date | formDate }}
            </td>
            <td data-label="Qtd. Profissionais">
              {{ item.totalProfessionals }}
            </td>
            <td data-label="Valor adicional">
              R$ {{ item.totalAdditional | toCurrency }}
            </td>
            <td data-label="Valor total da demanda">
              R$ {{ item.totalValue | toCurrency }}
            </td>
          </tr>
        </template>
      </CustomTable>
    </section>
  </div>
</template>

<script>
import CustomTable from "../shared/ui/CustomTable.vue";
import { getDetailInvoice } from "@/services/payment/payment.http.js";

export default {
  components: { CustomTable },
  data: () => ({
    header: [
      "Nome do evento",
      "Serviço",
      "Data",
      "Qtd. Profissionais",
      "Valor adicional",
      "Valor total da demanda",
    ],
    detail: {},
    statusName: {
      0: "Aguardando",
      1: "Cancelado",
      2: "Cancelado",
      3: "Pago",
      4: "Marcado como pago",
      5: "Atrasado",
    },
  }),
  mounted() {
    this.detailInvoice();
  },
  filters: {
    maskCnpj(val) {
      if (val) {
        return val
          .replace(/\D/g, "")
          .replace(
            /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
            "$1.$2.$3/$4-$5"
          );
      }
    },
  },
  methods: {
    detailInvoice() {
      getDetailInvoice(this.$route.params.id)
        .then((data) => data)
        .then((res) => {
          this.detail = res;
        });
    },
    goBack() {
      this.$router.push({
        name: "invoices",
      });
    },
  },
};
</script>

<style scoped></style>
