<template>
  <div
    id="add-client"
    class="select-hirer p-20 radius-10"
    @click="openHirerModal">
    <div class="d-flex ai-center">
      <div class="hirer-avatar">
        <Avatar />
        <i class="mdi mdi-plus" :class="{ 'has-hirer': hirer.cnpj }" />
      </div>
      <div class="d-flex direction-column ai-start jc-space-between">
        <span
          class="d-flex jc-center ai-center ml-15 text-Manatee"
          :class="{ 'grow-text': hirer.name }">
          {{ hirer.name || "Adicionar cliente" }}
        </span>
        <small
          v-if="hirer.cnpj"
          class="d-flex jc-center ai-center ml-15 text-white">
          CNPJ: {{ hirer.cnpj }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: mapGetters({
    hirer: "NewEvent/hirer",
  }),
  methods: {
    ...mapActions({
      setHirer: "NewEvent/setHirer",
    }),
    openHirerModal() {
      this.$eventhub.$emit("modal:open:hirer", {
        onSelectHirer: this.setHirer,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.select-hirer {
  background: $Ebony;
  cursor: pointer;

  .hirer-avatar {
    height: 45px;
    position: relative;
    width: 45px;

    i {
      background: $Mirage;
      color: transparentize(white, 0.1);
      border-radius: 50px;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: all 0.3s ease;
      transform: scale(1.1);

      &.has-hirer {
        background: $LilacBush;
        color: white;
        transform: scale(0.3) translate(45px, 45px);
      }
    }
  }

  span {
    font-size: 1rem;

    &.grow-text {
      font-size: 1.15rem;
    }
  }

  small {
    font-size: 0.8rem;
  }

  &:hover {
    background: lighten($Ebony, 1.5);

    .hirer-avatar i {
      background: lighten($Mirage, 2.5);
    }

    span {
      color: lighten($FuchsiaBlue, 2.5);
    }
  }
  &:active {
    background: lighten($Ebony, 3);

    .hirer-avatar i {
      background: lighten($Mirage, 5);
    }

    span {
      color: lighten($FuchsiaBlue, 5);
    }
  }
}
</style>
