<template>
  <Modal
    class="service-list-modal"
    :name="name"
    title="Porquê isso?"
    ref="modal"
    size="medium">
    <section class="p-20 mt-20">
      <div class="d-flex ai-start mb-20">
        <img class="pr-10" :src="require('@/assets/ic-dolar.svg')" />
        <div>
          <h3 class="text-malibu font-weight-300 mb-1 mt-0">
            1. Crie um fundo
          </h3>
          <p class="mt-0 font-size-08">
            O fundo de pagamento é o valor total suficiente para pagar os
            profissionais que você selecionou antes de agendar um pagamento.
          </p>
        </div>
      </div>

      <div class="d-flex ai-start mb-20">
        <img class="pr-10" :src="require('@/assets/ic-check.svg')" />
        <div>
          <h3 class="text-malibu font-weight-300 mb-1 mt-0">
            2. Confirmação de pagamento
          </h3>
          <p class="mt-0 font-size-08">
            A confirmação do pagamento pode levar até 3 dias úteis. Após o
            pagamento é só relaxar!
          </p>
        </div>
      </div>

      <div class="d-flex ai-start">
        <img class="pr-10" :src="require('@/assets/ic-user.svg')" />
        <div>
          <h3 class="text-malibu font-weight-300 mb-1 mt-0">
            3. Distribuição do pagamento
          </h3>
          <p class="mt-0 font-size-08">
            A Lanup fará toda a distribuição para os profissionais.
          </p>
        </div>
      </div>
    </section>
    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button class="btn primary" @click="close">Fechar</button>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  mounted() {},
  data: () => ({
    name: "modal-faq",
  }),
  methods: {
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
