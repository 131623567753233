<template>
  <div class="cover-date d-flex direction-column ai-center radius-10 p-3">
    <small>{{ weekDay }}</small>
    <span class="font-size-1-3">{{ monthDay }}</span>
    <small>{{ month }}</small>
  </div>
</template>

<script>
import { formatDate, isValidDate } from "@/helpers/dateFormat";

export default {
  props: {
    date: {
      type: String,
      default: "",
    },
  },

  computed: {
    displayDate() {
      const [day] = this.date.split("T");
      const date = new Date(`${day} `);

      return isValidDate(date) ? date : new Date();
    },
    monthDay() {
      return formatDate(this.displayDate, "D");
    },
    month() {
      return formatDate(this.displayDate, "MMM");
    },
    weekDay() {
      return formatDate(this.displayDate, "ddd");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.cover-date {
  background: rgba(white, 0.6);
  color: $SteelGray;
  font-weight: bold;
  line-height: 0.96rem;
  min-width: 44px;
  text-transform: uppercase;

  small {
    font-size: 0.6rem;
  }
}
</style>
