const T_TYPES = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info",
};

const defaultStyle = {
  display: "flex",
};

/**
 * payload: {
 *   type: success | error | warning | info (default),
 *   msg: '',
 * }
 * usage: this.$eventhub.$emit('feedback:show', { msg: 'Seja Bem vindo manolo!' })
 */
const show =
  ($toastr) =>
  ({ type: t = "info", msg = "", timeout = 3000 }) =>
    $toastr.Add({
      type: T_TYPES[t] || "info",
      msg,
      classNames: ["animated", "zoomInUp", "lanup-toast"],
      timeout,
    });

export const register = function ($vue) {
  $vue.$toastr.defaultStyle = defaultStyle;

  $vue.$on("feedback:show", show($vue.$toastr));
};

export default {
  register,
};
