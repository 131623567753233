<template>
  <div class="chart">
    <template v-if="chartHasData">
      <DonutChart
        :key="hashKey"
        :chartData="chartData"
        :height="190"
        :width="190"
        class="doughnut-chart"></DonutChart>
    </template>
  </div>
</template>

<script>
import DonutChart from "./DonutChart.vue";
import { props, isEmpty } from "ramda";

const dataSets = [
  {
    key: "ONTHEWAY",
    color: "#86d7ca",
    label: "A caminho",
  },
  {
    key: "ARRIVAL",
    color: "#008aff",
    label: "Chegada",
  },
  {
    key: "CHECKIN",
    color: "#46C5F3",
    label: "Entrada",
  },
  {
    key: "CHECKOUT",
    color: "#865FC0",
    label: "Saída",
  },
  {
    key: "BREAK",
    color: "#F28703",
    label: "Pausas",
  },
];

export default {
  components: {
    DonutChart,
  },
  data: () => ({
    chartData: {
      labels: [],
      datasets: [
        {
          label: "Data One",
          backgroundColor: [],
          hoverBackgroundColor: [],
          borderColor: "#24203b",
          hoverBorderColor: [],
          hoverBorderWidth: "4",
          weight: "2",
          data: [],
        },
      ],
    },
    chartHasData: false,
  }),
  props: {
    activitiesTotal: {
      type: Object,
      default: () => ({}),
    },
    operationId: {
      type: String,
      default: "",
    },
  },
  computed: {
    hashKey() {
      return `${this.operationId + JSON.stringify(this.activitiesTotal)}`;
    },

    dataSets() {
      return dataSets.reduce(
        (acc, { label, color, key }) => {
          const activity = this.activitiesTotal;
          const total = {
            ONTHEWAY:
              activity.ONTHEWAY > activity.ARRIVAL
                ? activity.ONTHEWAY - activity.ARRIVAL
                : 0,
            ARRIVAL:
              activity.ARRIVAL > activity.CHECKIN
                ? activity.ARRIVAL - activity.CHECKIN
                : 0,
            CHECKIN:
              activity.CHECKIN > activity.CHECKOUT
                ? activity.CHECKIN - activity.CHECKOUT
                : 0,
            CHECKOUT: activity.CHECKOUT,
            BREAK:
              activity.BREAK > activity.RETURNBREAK
                ? activity.BREAK - activity.RETURNBREAK
                : 0,
          };

          acc.labels = [...acc.labels, `${label} ${total[key]}`];
          acc.colors = [...acc.colors, color];
          acc.keys = [...acc.keys, key];

          return acc;
        },
        { labels: [], colors: [], keys: [] }
      );
    },
  },
  created() {
    this.chartHasData = true;
  },

  watch: {
    activitiesTotal: {
      deeep: true,
      handler() {
        this.generateDate();
      },
    },
  },
  methods: {
    generateDate() {
      if (!isEmpty(this.activitiesTotal)) {
        this.chartData.labels = this.dataSets.labels;
        this.chartData.datasets[0].backgroundColor = this.dataSets.colors;
        this.chartData.datasets[0].hoverBackgroundColor = this.dataSets.colors;
        this.chartData.datasets[0].hoverBorderColor = this.dataSets.colors;
        this.chartData.datasets[0].data = props(
          this.dataSets.keys,
          this.activitiesTotal
        );
      } else {
        this.chartData.labels = [];
        this.chartData.datasets[0].data = [];
      }
    },
  },
  mounted() {
    setInterval(() => this.generateDate(), 3000);
  },
};
</script>

<style lang="scss">
.doughnut-chart {
  width: 80%;
}
</style>
