<template>
  <form
    action="?"
    @submit.prevent="onSubmit"
    class="p-30 h-100 d-flex direction-column jc-space-between">
    <ServiceList
      :selectedServices="selectedServices"
      :exclusiveServices="selectedServices"
      @onSelected="selectedServices = $event" />
    <footer class="d-flex jc-end pt-10">
      <SpinnerButton
        class="btn primary medium mr-5"
        :disabled="!someChange"
        :loading="loading">
        Salvar Alterações
      </SpinnerButton>
    </footer>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ServiceList from "@/components/shared/ServiceList";

export default {
  components: {
    ServiceList,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      someChange: "Profile/someChange",
      services: "Profile/services",
    }),

    selectedServices: {
      get() {
        return this.services;
      },

      set(services) {
        this.setServices(services);
      },
    },
  },

  methods: {
    ...mapActions({
      setServices: "Profile/setServices",
      save: "Profile/save",
    }),
    onSubmit() {
      this.loading = true;
      const onSuccess = () =>
        this.notify("Suas informações foram salvas!", "success");
      const onError = ({
        response: {
          data: { errorMessage },
        },
      }) => this.notify(errorMessage);

      this.save()
        .then(onSuccess)
        .catch(onError)
        .finally(() => (this.loading = false));
    },
    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", {
        type,
        msg,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
</style>
