<template>
  <div
    v-if="showMember"
    class="member-card d-flex direction-column p-15 ai-center">
    <ContextMenu
      class="options"
      :options="contextMenuOptions"
      @itemClick="onContextMenuItemClick">
      <button class="button-menu-context-card" md-menu-trigger>
        <i class="mdi mdi-dots-horizontal text-malibu"></i>
      </button>
    </ContextMenu>
    <Avatar size="larger" :src="member.imageUrl" :alt="member.name" />
    <div class="info p-2 d-flex direction-column w-100 text-center">
      <div class="d-flex ai-center jc-center">
        <span class="name overflow-ellipsis" :title="member.name">
          {{ member.name }}
        </span>

        <button
          v-if="member.active"
          class="btn-favorite btn outline icon"
          :class="{ 'is-favorite': favorite }"
          @click="onFavoriteClick(member.id)">
          <i class="mdi mdi-star-circle"></i>
        </button>

        <button v-else class="btn-favorite block btn outline icon">
          <i class="mdi mdi-block-helper text-red"></i>
        </button>
      </div>

      <small>
        {{ member.avgRating }}
        <i class="mdi mdi-star"></i>
      </small>

      <div class="d-flex jc-center">
        <button
          class="btn outline no-border total-groups ph-10 mt-5"
          @click="onGroupsClick">
          <i class="mdi mdi-account-multiple"></i>
          {{
            `${member.totalRelatedGroup || 0} grupo${
              member.totalRelatedGroup === 1 ? "" : "s"
            }`
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { favoriteMember, blockMember } from "@/services/agency/agency.http";
import { mapActions } from "vuex";

export default {
  props: {
    member: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.viewMember = this.member;
  },
  data: () => ({
    memberFavorite: undefined,
    showMember: true,
  }),
  computed: {
    favorite() {
      return this.memberFavorite != undefined
        ? this.memberFavorite
        : this.member.favorite;
    },
    contextMenuOptions() {
      const blockMenuItem = this.member.active
        ? {
            label: "Bloquear",
            icon: "mdi-block-helper",
            action: "block",
          }
        : {
            label: "Desbloquear",
            icon: "mdi-check-circle-outline",
            action: "block",
          };

      return [
        {
          label: "Ver Perfil",
          icon: "mdi-account-circle",
          action: "showProfile",
        },
        // { label: 'Escalar para', icon: 'mdi-account-details', action:"scale" },
        blockMenuItem,
      ];
    },
  },
  methods: {
    ...mapActions({
      getFreelaInformations: "Freela/getFreelaInformations",
    }),
    onGroupsClick() {
      // this.$eventhub.$emit("modal:open:userGroups", {
      //     member: this.member,
      // })
    },
    onFavoriteClick(memberId) {
      favoriteMember(memberId)
        .then(() => (this.memberFavorite = !this.favorite))
        .then(() => this.notify("Membro atualizado!", "success"))
        .catch((err) => this.notify(err.response.data.errorMessage));
    },
    onContextMenuItemClick({ action }) {
      const actions = {
        showProfile: this.showProfileAction,
        scale: this.scaleAction,
        block: this.blockMemberAction,
      };

      actions[action]();
    },
    blockMemberAction() {
      blockMember(this.member.id)
        .then(() => (this.showMember = false))
        .then(() => this.notify("Membro atualizado!", "success"))
        .catch((err) => this.$emit(err.response.data.errorMessage));
    },
    scaleAction() {
      // console.log('scale action')
    },
    showProfileAction() {
      this.getFreelaInformations(this.member.freelaId);
      this.$eventhub.$emit("modal:open:freela-profile-modal");
    },
    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", { msg, type });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.button-menu-context-card {
  color: white;
  padding: 6px 8px;
  border-radius: 35px;
  margin-top: 4px;
  &:hover {
    background-color: $Ebony;
  }
}

.member-card {
  border-radius: 10px;
  opacity: 0.9;
  position: relative;
  transition: all 0.3s ease;
  width: 152px;
  will-change: transition;

  .options {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 69%;
    z-index: 1;
  }

  .info {
    .name {
      font-size: 0.8rem;
    }

    .total-groups {
      background: lighten($SteelGray, 5%);
      border-radius: 5px;
      font-size: 0.7rem;
      height: 30px;
      opacity: 0;

      &:hover {
        background: lighten($SteelGray, 10%);
      }

      &:active {
        background: lighten($SteelGray, 15%);
      }
    }

    .btn-favorite {
      color: white;
      font-size: 1.2rem;
      margin-right: -10px;
      opacity: 0;

      &.is-favorite {
        color: $Orange;
      }

      &.block {
        cursor: initial;
      }
    }
  }

  &:hover,
  &:active {
    background: $SteelGray;
    box-shadow: 0 3px 6px rgba(black, 0.3);
    transform: translateY(-2px);
    opacity: 1;

    .options,
    .total-groups,
    .btn-favorite {
      opacity: 1;
    }
  }
}
</style>
