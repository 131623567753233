<template>
  <div class="d-flex wrapped" :class="size">
    <div
      class="font-size-06 text-white chip d-flex"
      :class="[theme, size]"
      v-for="(item, key) in date"
      :key="key">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: "Lilac-bush",
    },
    size: {
      type: String,
      default: "normal",
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.chip {
  font-weight: 600;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  margin-right: 6px;
  margin-bottom: 5px;

  &.Lilac-bush {
    background-color: $LilacBush;
  }
  &.Picton-blue {
    background-color: $PictonBlue;
    color: $Ebony;
  }

  &.small {
    padding: 0px 7px;
    border-radius: 5px;
  }

  &.normal {
    padding: 5px 10px;
    border-radius: 10px;
  }
}
</style>
