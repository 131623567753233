<template>
  <PaymentFields @hash="$emit('hash', $event)" />
</template>

<script>
import PaymentFields from "./PaymentFields";

export default {
  components: {
    PaymentFields,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.content {
  flex: 0 1 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.md-snackbar-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.md-snackbar {
  background-color: $Ebony !important;
  border-radius: 10px 10px 0px 0px;
  max-height: inherit;
  small {
    color: white;
  }
}
</style>
