import axios from "axios";
import { ifElse } from "ramda";

import { connect, channels } from "@/services/SignalR/";

const URL_OPERATIONS = "/operations";
const URL_ACTIVITIES = "/operations/:id/activities";
const URL_INCIDENTS = "/operations/:id/incidents";

const performError = (e) => e;

const isSuccess = ({ data: { isSuccess } }) => isSuccess;
const returnResult = ({
  data: {
    result: { value },
  },
}) => value;

const validateReturn = ifElse(isSuccess, returnResult, performError);

export const getOperations = () =>
  axios.get(URL_OPERATIONS).then(validateReturn);

export const getActivities = (id) =>
  axios.get(URL_ACTIVITIES.replace(":id", id)).then(validateReturn);

export const getActivitiesReport = ({ eventId, operationDate, operationId }) =>
  axios
    .get(
      `${URL_OPERATIONS}/${operationId}/activities/${eventId}/export/${operationDate}`,
      {
        responseType: "blob",
      }
    )
    .catch(performError);

export const getOccurrences = (id) =>
  axios
    .get(URL_INCIDENTS.replace(":id", id))
    .then(validateReturn)
    .then((value) => (value === null ? [] : value));

export const getConnection = ({ referenceDate, eventId }) =>
  axios
    .get(
      `${URL_OPERATIONS}/dates/${referenceDate}/events/${eventId}/connections`
    )
    .then(validateReturn);

export const changeStatus = (operationId, { id, status }) =>
  axios
    .put(URL_INCIDENTS.replace(":id", operationId), { id, status })
    .then(validateReturn);

export const onNewActivity = (callback) =>
  connect().then((conn) => conn.on(channels.ACTIVITIES, callback));

export const onNewCheckpoint = (callback) =>
  connect().then((conn) => conn.on(channels.LOCALIZATIONS, callback));

export const onNewIncidente = (callback) =>
  connect().then((conn) => conn.on(channels.INCIDENTS, callback));

export const setGroupName = (operationGroupName) =>
  connect().then((conn) => conn.invoke("addToGroup", operationGroupName));

export const getServices = (id) =>
  axios.get(`${URL_OPERATIONS}/${id}/services`).then(validateReturn);

export const checkin = (data) =>
  axios
    .post(`${URL_OPERATIONS}/${data.id}/checkins`, data)
    .then(validateReturn);

export const statusOperation = (data) =>
  axios
    .get(
      `${URL_OPERATIONS}/${data.id}/freelas/${data.freelaId}/status/${data.isHomeOffice}`
    )
    .then(validateReturn);

export const checkout = (data) =>
  axios
    .post(`${URL_OPERATIONS}/${data.id}/checkouts`, data)
    .then(validateReturn);

export const checkinAndCheckoutByCode = (data) =>
  axios
    .post(`${URL_OPERATIONS}/checkins/${data.id}/checkout/code`, data)
    .then(validateReturn);

export const scheduleManagement = (operationId, body) => {
  return axios
    .post(`${URL_OPERATIONS}/${operationId}/scheduleManagement`, body)
    .then(validateReturn);
};

export default {
  getOperations,
  getActivities,
  getOccurrences,
  getConnection,
  onNewActivity,
  onNewIncidente,
  onNewCheckpoint,
  setGroupName,
  getServices,
  getActivitiesReport,
  checkin,
  scheduleManagement,
  statusOperation,
};
