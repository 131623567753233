import { getEvent } from "@/services/events/event.http";

const initialState = () => ({
  filtersTab: {
    eventType: null,
    startDate: "",
    endDate: "",
    hirerCnpj: "",
    payed: null,
    eventRef: "",
  },
  menuColumns: {
    local: { checked: true, label: "Local" },
    hirer: { checked: true, label: "Cliente" },
    eventType: { checked: true, label: "Tipo" },
    startDate: { checked: true, label: "Início" },
    endDate: { checked: true, label: "Fim" },
    daysQuantity: { checked: true, label: "Qtd Dias" },
    functionsQuantity: { checked: true, label: "Qtd de funções" },
    planning: { checked: true, label: "Planejado" },
    accepted: { checked: true, label: "Aceito" },
    pending: { checked: true, label: "Pendente" },
    waiting: { checked: true, label: "Realizado" },
    invited: { checked: true, label: "Convidado" },
    paid: { checked: true, label: "Pago" },
    candidated: { checked: true, label: "Candidato" },
  },
  events: [],
  items: [],
});

const state = initialState();

const getters = {
  filtersTab: (state) => state.filtersTab,
  menuColumns: (state) => state.menuColumns,
  events: (state) => state.events,
  items: (state) => state.items,
};

const mutations = {
  SET_FILTERS_TAB: (state, filters) => {
    state.filtersTab = Object.assign({}, state.filtersTab, filters);
  },
  CLEAR_FILTER_TAB: (state) =>
    (state.filterTab = {
      eventType: null,
      startDate: null,
      endDate: null,
      hirerCnpj: "",
      payed: null,
      eventRef: "",
    }),

  SET_LIST_EVENT: (state, content) => {
    state.events = content.reduce((acc, item) => (acc[item.id] = item) && acc, {
      ...state.events,
    });
  },
  SET_LIST_ITEMS: (state, items) => {
    state.items = items;
  },
  SET_MENU_COLUMNS: (state, { node, checked }) => (node.checked = checked),
  RESET_ITEMS(state) {
    state.events = [];
  },
};

const actions = {
  setFiltersTab({ commit }, filters) {
    return commit("SET_FILTERS_TAB", filters);
  },
  setMenuColumns({ commit }, { node, checked }) {
    return commit("SET_MENU_COLUMNS", { node, checked });
  },
  getEvent({ commit }, filters) {
    getEvent("", filters).then((res) => commit("SET_LIST_EVENT", res.content));
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
