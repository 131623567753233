import axios from "axios";
import { normalizeToSave } from "@/helpers/image";

const URL_AGENCY = "/agencies";
const URL_WORKGROUP = "/agencies/workgroups";
const URL_MEMBERS = "/agencies/members";
const URL_FAVORITE_MEMBER = "/agencies/members/:memberId/favorites";
const URL_BLOCK_MEMBER = "/agencies/members/:memberId/blocks";
const URL_GROUP_MEMBERS = "/agencies/workgroups/:workgroupId/members";
const URL_HIRERS = "/agencies/:agencyId/hirers";

export const saveAgencyData = (data, isActiveAccount) =>
  isActiveAccount ? updateAgencyData(data) : activeAgency(data);

export const updateAgencyData = (data) =>
  axios.put(URL_AGENCY, data).then(({ data }) => data.result);

export const activeAgency = (data) =>
  axios.post(URL_AGENCY, data).then(({ data }) => data.result);

export const getAgencyData = () =>
  axios.get(URL_AGENCY).then(({ data }) => data.result);

export const searchAgency = (term) =>
  axios.get(`${URL_AGENCY}/${term}`).then(({ data }) => data.result);

export const getRootMembers = ({
  size,
  date: lastCreateDate,
  actives,
  page,
  term,
}) =>
  axios
    .get(`${URL_MEMBERS}`, {
      params: { size, lastCreateDate, actives, page, term },
    })
    .then(({ data }) => data.result);

export const searchMembers = (term) =>
  axios
    .get(`${URL_MEMBERS}?size=6&term=${term}`)
    .then(({ data }) => data.result);

export const getGroupMembers = ({
  size,
  // date: lastCreateDate,
  groupId,
  actives,
  page,
  term,
}) =>
  axios
    .get(`${URL_GROUP_MEMBERS.replace(":workgroupId", groupId)}`, {
      params: {
        size,
        // lastCreateDate,
        actives,
        page,
        term,
      },
    })
    .then(({ data }) => data.result);

export const getMembers = ({
  size = 10,
  date,
  groupId = undefined,
  actives,
  page = 1,
  term,
}) =>
  groupId
    ? getGroupMembers({ size, date, groupId, actives, page, term })
    : getRootMembers({ size, date, actives, page, term });

export const getTotalMembers = () =>
  axios.get(`${URL_MEMBERS}/total`).then(({ data }) => data.result);

export const favoriteMember = (memberId) =>
  axios
    .post(URL_FAVORITE_MEMBER.replace(":memberId", memberId), { memberId })
    .then(({ data }) => data.result);

export const blockMember = (memberId) =>
  axios
    .post(URL_BLOCK_MEMBER.replace(":memberId", memberId), { memberId })
    .then(({ data }) => data.result);

export const getWorkgroupById = (groupId) =>
  axios.get(`${URL_WORKGROUP}/${groupId}`).then(({ data }) => data.result);

export const getWorkgroups = ({
  size = 10,
  // date: lastCreateDate,
  page,
  term,
}) =>
  axios
    .get(`${URL_WORKGROUP}`, { params: { size, page, term } })
    .then(({ data }) => data.result);

export const saveWorkgroup = (workgroup) =>
  workgroup.id ? updateWorkgroup(workgroup) : createWorkgroup(workgroup);

export const createWorkgroup = ({ name, freelaIds, image }) =>
  axios
    .post(URL_WORKGROUP, {
      name,
      freelaIds,
      image: normalizeToSave(image),
    })
    .then(({ data }) => data.result);

export const updateWorkgroup = ({ id, name, freelaIds, image }) =>
  axios
    .put(`${URL_WORKGROUP}/${id}`, {
      name,
      freelaIds,
      image: normalizeToSave(image),
    })
    .then(({ data }) => data.result);

const normalizeHirers = (hirers) =>
  hirers.map(({ name, cnpj, email: { value }, services }) => ({
    name,
    cnpj,
    email: value,
    services,
  }));

export const searchHirer = (agencyId, term) =>
  axios
    .get(`${URL_HIRERS.replace(":agencyId", agencyId)}?term=${term}`)
    .then(({ data }) => data.result)
    .then(normalizeHirers);

const transformHirerToSave = ({ name, cnpj, email, phone, services }) => ({
  name,
  cnpj,
  email,
  telefone: phone.replace(/\D/g, ""),
  services,
});

export const saveHirer = (agencyId, hirer) =>
  axios
    .post(
      URL_HIRERS.replace(":agencyId", agencyId),
      transformHirerToSave(hirer)
    )
    .then(({ data }) => data.result);

export default {
  saveAgencyData,
  getAgencyData,
  searchAgency,

  getWorkgroups,
  getWorkgroupById,
  saveWorkgroup,

  getMembers,
  getTotalMembers,
  searchMembers,
  favoriteMember,
  blockMember,

  searchHirer,
  saveHirer,
};
