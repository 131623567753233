<template>
  <StateHandler class="group-list d-flex h-100" :state="currentState">
    <template #default>
      <div class="d-flex direction-column jc-space-between w-100">
        <div
          class="d-flex direction-row jc-start wrapped ai-start ac-start w-100">
          <GroupCard
            class="ml-10 mr-10 mb-10"
            v-for="(group, key) in groups"
            :key="key"
            :group="group"
            @click="onCardClick" />
        </div>
        <div class="d-flex jc-center ai-center" v-if="!isLastPage">
          <SpinnerButton
            class="btn primary load-more"
            @click="onLoadMoreClick"
            :loading="loading">
            Carregar Mais
          </SpinnerButton>
        </div>
      </div>
    </template>

    <template #empty>
      <div class="d-flex jc-center ai-center w-100">
        <span>Você ainda não tem nenhum grupo cadastrado =(</span>
      </div>
    </template>

    <template #error>
      <div class="d-flex jc-center ai-center direction-column w-100">
        <span class="mb-10">
          Ops, alguma coisa aconteceu quando tentamos carregar seus grupos...
        </span>
      </div>
    </template>

    <template #loading>
      <div class="d-flex jc-center ai-center w-100">
        <Loading label="Carregando seus Grupos" />
      </div>
    </template>
  </StateHandler>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import GroupCard from "./GroupCard";

export default {
  components: { GroupCard },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      groupList: "MyFreelas/groupList",
      isLastPage: "MyFreelas/isLastPage",
      currentState: "MyFreelas/currentState",
      filterMatch: "MyFreelas/filterMatch",
      dataParameters: "MyFreelas/dataParameters",
    }),
    groups() {
      return Object.values(this.groupList);
    },
  },
  methods: {
    ...mapActions({
      fetch: "MyFreelas/fetch",
    }),
    onLoadMoreClick() {
      this.loading = true;
      const setLoadingFalsy = () => (this.loading = false);

      this.fetch({ page: this.dataParameters.page + 1 }).finally(
        setLoadingFalsy
      );
    },
    onCardClick(group) {
      this.$emit("cardGroupClick", group);
    },
  },
};
</script>
