<template>
  <FormCard class="select-user-type pt-15 pr-25 pl-25">
    <template v-slot:title>
      <span>Criar uma conta</span>
      <br />
      <small class="subtitle">Seja bem vindo ao LanUp!</small>
    </template>
    <template v-slot:form>
      <div class="d-flex direction-column jc-space-between h-100 pt-10">
        <button
          class="user-type-option p-20"
          :class="{ active: currentType === USER_TYPE.HIRER }"
          @click="currentType = USER_TYPE.HIRER">
          <div
            :class="currentType === USER_TYPE.HIRER ? 'check' : 'uncheck'"
            class="d-flex jc-center ai-center pr-10 check">
            <i class="mdi mdi-check-bold"></i>
          </div>
          <div class="text-start">
            <h4 class="m-0">Sou Contratante</h4>
            <p class="m-0 mt-6">
              Contrate até 5 profissionais de um jeito simples e ágil. E
              acompanhe todo o processo em um só local.
            </p>
          </div>
        </button>

        <div class="divider mb-10 mt-10">
          <hr />
          <span>Ou</span>
          <hr />
        </div>

        <button
          class="user-type-option p-20"
          id="btn-company"
          :class="{ active: currentType === USER_TYPE.AGENCY }"
          @click="currentType = USER_TYPE.AGENCY">
          <div
            :class="currentType === USER_TYPE.AGENCY ? 'check' : 'uncheck'"
            class="d-flex jc-center ai-center pr-10 check">
            <i class="mdi mdi-check-bold"></i>
          </div>
          <div class="text-start">
            <h4 class="m-0">Sou Empresa</h4>
            <p class="m-0 mt-6">
              Planeje e crie todas as rotinas do seu time de forma simples e
              rápida, automatizando seu próprio banco de talentos.
            </p>
          </div>
        </button>

        <button
          class="btn primary mt-30"
          id="btn-continue"
          :disabled="!currentType"
          @click="$emit('input', currentType)">
          Continuar
        </button>
      </div>
    </template>
  </FormCard>
</template>

<script>
import FormCard from "./FormCard";

const USER_TYPE = {
  HIRER: "hirer",
  AGENCY: "agency",
};

export default {
  components: { FormCard },
  props: {
    userType: {
      type: String,
      default: "",
    },
  },
  watch: {
    userType(val) {
      this.currentType = val;
    },
  },
  data: () => ({
    currentType: "",
  }),
  computed: {
    USER_TYPE() {
      return USER_TYPE;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.select-user-type {
  max-width: 50%;
  .subtitle {
    color: $Malibu;
    font-weight: 300;
    letter-spacing: -0.15rem;
  }

  .user-type-option {
    border: 1.5px solid $SilverChalice;
    color: $SilverChalice;
    cursor: pointer;
    font-weight: 300;

    & .check {
      color: white;
      font-size: 1.3rem;
      width: 50px;
    }

    & .uncheck {
      display: none;
    }

    &:not(.active):hover {
      background: rgba(white, 0.05);
    }

    &.active {
      background: $FuchsiaBlue;
      border: 1.5px solid $FuchsiaBlue;
      color: white;
    }

    h4 {
      color: white;
      font-weight: 300;
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
      letter-spacing: -0.1rem;
      word-break: break-word;
    }
  }
}
</style>
