<template>
  <section>
    <h3 class="title">
      <span>Contato</span>
      <small class="text-fuchsia-blue font-italic ml-10">
        Contato do responsável por essa demanda.
      </small>
    </h3>

    <div class="md-layout md-gutter md-alignment-center-center">
      <div class="md-layout-item md-xsmall-size-100">
        <CustomInput
          :isInvalid="$v.fullName.$error"
          v-model.trim="$v.fullName.$model"
          placeholder="Nome completo"
          id="completeName" />
        <div class="error d-flex wrapped f-100">
          <span
            class="d-flex f-100"
            v-if="$v.fullName.$dirty && !$v.fullName.required">
            Campo Obrigatório
          </span>
          <span
            class="d-flex f-100"
            v-if="$v.fullName.$dirty && !$v.fullName.minLength">
            Nome necessita de ao menos 2 caracteres
          </span>
        </div>
      </div>
      <div class="md-layout-item md-xsmall-size-100">
        <CustomInput
          :isInvalid="$v.email.$error"
          v-model.trim="$v.email.$model"
          placeholder="E-mail"
          id="email" />
        <div class="error d-flex wrapped f-100">
          <span
            class="d-flex f-100"
            v-if="$v.email.$dirty && !$v.email.required">
            Campo Obrigatório
          </span>
          <span
            class="d-flex f-100"
            v-if="$v.email.$dirty && !$v.email.required">
            Favor entra com um e-mail válido
          </span>
        </div>
      </div>
      <div class="md-layout-item md-xsmall-size-100">
        <CustomInput
          :isInvalid="$v.tel.$error"
          v-model.trim="$v.tel.$model"
          :outputMask="false"
          :mask="['(##)#####-####', '(##)####-####']"
          placeholder="Telefone"
          id="phone" />
        <div class="error d-flex wrapped f-100">
          <span class="d-flex f-100" v-if="$v.tel.$dirty && !$v.tel.required">
            Entre com um telefone válido. Ex.: (99) 99999-9999
          </span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  validations: {
    fullName: {
      required,
      minLength: minLength(2),
    },
    email: {
      required,
      email,
    },
    tel: {
      required,
    },
  },

  computed: {
    ...mapGetters({
      editContact: "EditEvent/contact",
      newContact: "NewEvent/contact",
      isEdit: "EditEvent/isEdit",
    }),

    contact() {
      return this.isEdit ? this.editContact : this.newContact;
    },

    fullName: {
      get() {
        return this.contact.fullName;
      },
      set(fullName) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              contact: { ...this.contact, fullName },
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              contact: { ...this.contact, fullName },
            });
      },
    },
    email: {
      get() {
        return this.contact.email;
      },
      set(email) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              contact: { ...this.contact, email },
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              contact: { ...this.contact, email },
            });
      },
    },
    tel: {
      get() {
        return this.contact.tel;
      },
      set(tel) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              contact: { ...this.contact, tel },
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              contact: { ...this.contact, tel },
            });
      },
    },
  },
};
</script>
