<template>
  <section
    class="vacancy-slot-list d-flex direction-column h-100 overflow-hidden pt-10">
    <header class="bg-ebony slot-list_item w-100 radius-5 p-10">
      <div class="d-flex">
        <button
          class="d-flex"
          @click="sorting(header.name)"
          v-for="header in headers"
          :key="header.id">
          <small class="text-white">{{ header.label }}</small>
          <i
            v-if="header.name"
            :class="[header.isAsc ? sortIconAsc : sortIconDesc, sortIcon]">
            {{ header.content }}
          </i>
        </button>
      </div>
      <button class="d-flex jc-start" @click="sorting(date.name)">
        <small class="text-white">{{ date.label }}</small>
        <i :class="[date.isAsc ? sortIconAsc : sortIconDesc, sortIcon]"></i>
      </button>
      <small class="js-end">Entrada</small>
      <small class="js-end">Saída</small>
      <small class="js-end">Valor</small>
      <div v-if="isAgency" class="d-flex ai-center">
        <small>Ações</small>
      </div>
    </header>
    <section class="d-flex direction-column h-100 overflow-hidden">
      <md-content class="md-scrollbar list-wrapper">
        <div v-for="(vacancy, key) in sortedSlots" :key="key">
          <Slot
            class="slot-list_item p-10 ai-center"
            :vacancy="vacancy"
            :index="key" />
          <Divider theme="ebony" />
        </div>
      </md-content>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Slot from "./Slot";

export default {
  components: { Slot },
  data() {
    return {
      headers: [
        {
          id: 1,
          name: "description",
          label: "Membros",
          isAsc: true,
          content: "/",
        },
        {
          id: 2,
          name: "statusLabel",
          label: "Status",
          isAsc: true,
          content: "/",
        },
        { id: 3, name: "", label: "Tipo", isAsc: true, content: "" },
      ],
      date: { id: 4, name: "day", label: "Data", isAsc: true },

      sortIcon: "d-flex ai-center text-white icon mdi d-flex font-size-1",
      sortIconAsc: "mdi-chevron-down",
      sortIconDesc: "mdi-chevron-up",
    };
  },
  computed: {
    ...mapGetters({
      slots: "EventDetails/currentSlots",
      currentFilters: "EventDetails/currentFilters",
      sortedSlots: "EventDetails/sortedSlots",
      isAgency: "User/isAgency",
    }),
  },
  methods: {
    ...mapActions({
      updateEventServiceData: "EventDetails/updateEventServiceData",
      setSort: "EventDetails/setSort",
      updateVacancies: "EventDetails/updateVacancies",
    }),

    sorting(field) {
      if (field) {
        let direction = "";

        if (field === "day") {
          this.date.isAsc = !this.date.isAsc;
          direction = this.date.isAsc ? "asc" : "desc";
        } else {
          this.headers.forEach((header) => {
            if (header.name == field) {
              header.isAsc = !header.isAsc;
              direction = header.isAsc ? "asc" : "desc";
            }
          });
        }

        this.setSort({ field, direction });
        this.updateVacancies();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.vacancy-slot-list {
  height: 50%;
  .bg-ebony {
    background-color: $Ebony;
  }

  .md-scrollbar {
    background: none;
  }

  .bg-ebony.slot-list_item.w-100 {
    place-items: baseline;
  }

  .slot-list_item {
    display: grid;
    grid-template-columns: 3fr 1.5fr 2.5fr 2fr 1fr 2fr;
    column-gap: 15px;
    grid-template-rows: 1fr;
  }

  .list-wrapper
    > :is(div:nth-last-child(1), div:nth-last-child(2), div:nth-last-child(3))
    .slot-list_item.p-10.ai-center
    .context-menu
    .dropdownMenu {
    top: -276px;
  }
}

.icon {
  transition: transform 0.3s ease;
  will-change: transform;
  margin-right: -3px;

  &.rotate {
    transform: rotate(180deg);
  }
}
.menuDropDown {
  position: absolute;
  z-index: 10;
  height: 80px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: $SteelGray;
  box-shadow: 0px 3px 6px rgba(black, 0.2);
}
</style>
