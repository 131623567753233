import jwtDecode from "jwt-decode";
import { tryCatch, always } from "ramda";

const TOKEN_NAME = process.env.VUE_APP_TOKEN_NAME || "LANUP-TOKEN";

const hasToken = () => !!localStorage.getItem(TOKEN_NAME);

const _tryParseToken = (token) => jwtDecode(token);
const decodeToken = tryCatch(_tryParseToken, always(false));

const getToken = () => decodeToken(localStorage.getItem(TOKEN_NAME));

const saveToken = (token) => {
  const user = decodeToken(token);
  if (user) localStorage.setItem(TOKEN_NAME, token);
  return user;
};

const getUser = () => {
  const { id, Username, avatarUrl, Email, userType, activeAccount } =
    getToken();

  return {
    id,
    username: Username,
    email: Email,
    avatarUrl,
    userType,
    activeAccount,
  };
};

const isAuthenticated = () => hasToken();

const USER_TYPES = {
  AGENCY: "2",
  HIRER: "4",
  FREELA: "1",
};

const isAgency = () => getUser().userType == USER_TYPES.AGENCY;
const isHirer = () => getUser().userType == USER_TYPES.HIRER;
const isFreela = () => getUser().userType == USER_TYPES.FREELA;

export {
  isHirer,
  isAgency,
  isFreela,
  hasToken,
  getToken,
  saveToken,
  getUser,
  isAuthenticated,
};
