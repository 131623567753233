<template>
  <aside role="dialog" class="DrawerModal" :class="show && 'DrawerModalOpen'">
    <div @click="close" class="ModalOverlay"></div>
    <div class="MenuContainer md-scrollbar md-theme-default">
      <header id="modalTitle" class="d-flex jc-end">
        <button
          class="outline d-flex jc-center ai-center js-end close-button-modal"
          @click="close">
          <i class="mdi mdi-close"></i>
        </button>
      </header>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: "medium",
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "color-white",
    },
  },
  data: () => ({
    show: false,
  }),
  created() {
    this.register();
  },
  methods: {
    register() {
      const MODAL_OPEN_EVENT = `modal:open:${this.name}`;
      const MODAL_CLOSE_EVENT = `modal:close:${this.name}`;

      this.$eventhub.$on(MODAL_OPEN_EVENT, this.open);
      this.$eventhub.$on(MODAL_CLOSE_EVENT, this.close);
    },
    open($event) {
      this.$emit("open", $event);
      this.show = true;
    },
    close($event) {
      this.$emit("close", $event);
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/colors.scss";

.DrawerModal {
  background-color: rgba(255, 255, 255, 0.2);
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  display: -ms-grid;
  display: grid;
  grid-template-areas: "MENU OVERLAY";
}

.content {
  padding: 5px;
}

@media (min-width: 30em) {
  .DrawerModal {
    -ms-grid-columns: 10fr 5fr;
    grid-template-columns: 10fr 5fr;
  }
}

@media only screen and (max-width: 768px) {
  .close-button-modal {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .MenuContainer {
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  }
  .DrawerModal {
    -ms-grid-columns: 10fr 5fr;
    grid-template-columns: 10fr 5fr;
  }
  .content {
    padding: 20px;
  }
  .close-button-modal {
    border-radius: 50px;
    color: #ffffff;
    font-size: 1.2rem;
    height: 30px;
    width: 30px;
    background: rgba(white, 0.1);

    &:hover {
      background: rgba(white, 0.13);
    }

    &:active {
      background: rgba(white, 0.16);
    }
  }
}

.DrawerModalOpen {
  -webkit-transform: none;
  transform: none;
}

.MenuContainer {
  grid-area: "MENU";
  background-color: $Mirage;
  box-sizing: border-box;
  padding: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-rows: 20px 200px;
  -ms-grid-rows: 20px 200px;
}

.ModalOverlay {
  grid-area: "OVERLAY";
}
</style>
