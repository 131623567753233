<template>
  <section>
    <NeededFreelasFields :class="{ 'disabled-container': isEdit }" />
    <Divider class="mv-30" />
    <VerbaFields
      :class="{ 'disabled-container': isEdit }"
      v-if="upToFiveFreelas" />
    <Divider class="mv-30" v-if="upToFiveFreelas" />
    <div class="d-flex jc-space-between ai-end m-5">
      <div class="d-flex direction-column">
        <h3 class="title">Serviços</h3>

        <button
          class="btn primary d-flex wrapped"
          id="add-functions"
          @click.prevent="openModal">
          Adicionar Funções
        </button>
      </div>

      <TotalFreelas v-if="upToFiveFreelas" class="w-35" />
    </div>
    <div class="content d-flex">
      <EventServiceCard
        v-for="(s, k) in servicesAdded"
        :key="k"
        :title="s.job"
        :responsabilitiyQuantity="s.responsabilities.length"
        :checkListquantity="
          s.checkListAtCheckin.length + s.checkListAtCheckout.length
        "
        :vacancyQuantity="vacancyQuantity(s)"
        :selectedAgency="s.agency ? true : false"
        :agencies="s.agency"
        :payment="s.payment"
        @showEdit="openEditServiceModal"></EventServiceCard>
    </div>
    <div v-if="exceededAmountFreela">
      <small class="error-message">
        O limite de profissionais é 5. Favor rever a quantidade selecionada.
      </small>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { pluck } from "ramda";

import VerbaFields from "./VerbaFields.vue";
import NeededFreelasFields from "./NeededFreelasFields.vue";
import EventServiceCard from "./EventServiceCard.vue";
import TotalFreelas from "@/components/events/StepperApp/Shared/TotalFreelas";

import { getAvailableServices } from "@/services/events/event.http";

export default {
  components: {
    NeededFreelasFields,
    VerbaFields,
    EventServiceCard,
    TotalFreelas,
  },

  mounted() {
    if (this.isEdit) {
      this.setPreviouslySelected(pluck("job", this.servicesAdded));
    }
  },

  computed: {
    ...mapGetters({
      totalFreelasInServices: "NewEvent/totalFreelasInServices",
      hirer: "NewEvent/hirer",
      upToFiveFreelas: "NewEvent/upToFiveFreelas",
      exceededAmountFreela: "NewEvent/exceededAmountFreela",
      isEdit: "EditEvent/isEdit",
      form: "EditEvent/form",
    }),
    freelaTotalIsBiggerThanFive() {
      return this.totalFreelasInServices > 5;
    },
    servicesAdded() {
      return this.isEdit
        ? [...this.$store.getters["EditEvent/servicesAdded"]]
        : [...this.$store.getters["NewEvent/servicesAdded"]];
    },
  },

  methods: {
    ...mapActions({ setPreviouslySelected: "EditEvent/setPreviouslySelected" }),

    openModal() {
      const exclusiveServices = () => {
        if (this.isEdit) return this.form.agencyData.services;
        else return this.hirer.services || [];
      };

      const openModal = (services) => {
        this.$eventhub.$emit("modal:open:services", {
          services: services,
          servicesAdded: this.servicesAdded.map(({ job }) => job),
          exclusiveServices: exclusiveServices(),
          onAdd: this.onToggleService,
        });
      };

      getAvailableServices().then(openModal);
    },
    onToggleService(services) {
      if (this.isEdit)
        this.$store.dispatch("EditEvent/addNewServices", services);
      else this.$store.dispatch("NewEvent/addNewServices", services);
    },
    openEditServiceModal($event) {
      this.$eventhub.$emit("modal:open:EditEventServiceModal", {
        show: true,
        title: $event,
        currentService: this.servicesAdded.find((s) => s.job == $event),
      });
    },
    vacancyQuantity(s) {
      return s.workshifts.reduce(
        (acc, { quantity }) => acc + new Number(quantity),
        0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.content {
  flex: 0 1 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.error-message {
  color: $Red;
}
</style>
