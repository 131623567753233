<template>
  <Modal
    class="service-list-modal"
    :name="name"
    @open="onModalOpen"
    title="Confirmação da fatura"
    ref="modal"
    size="mini">
    <section class="p-40">
      <span class="mb-35 font-size-1">
        Você está criando uma fatura, essas informações não poderão ser
        <br />
        alteradas, deseja continuar?
      </span>
    </section>
    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button class="btn outline" @click="close">Revisar</button>
        <SpinnerButton
          :loading="loading"
          class="btn primary"
          @click="_createInvoice">
          Sim, gerar fatura
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import { createInvoice } from "@/services/payment/payment.http.js";

export default {
  data: () => ({
    name: "confirmation-creation-invoice",
    loading: false,
    invoice: {},
  }),
  methods: {
    ...mapActions({
      cancelOrMarkAsPaidInvoice: "Payment/cancelOrMarkAsPaidInvoice",
    }),
    close() {
      this.$refs.modal.close();
    },

    onModalOpen(invoice) {
      this.invoice = invoice;
    },

    _createInvoice() {
      this.loading = true;
      createInvoice(this.invoice)
        .then((res) => {
          this.$store.commit("EventDetails/CLEAR_FILTER_SERVICE_EVENT");
          this.$store.commit("EventDetails/RESET_EVENT_SERVICE_LIST");
          this.$eventhub.$emit("feedback:show", {
            type: "success",
            msg: "Fatura enviada para o contratante",
          });
          this.$router.push({
            name: "InvoiceDetails",
            params: { id: res.value },
          });
        })
        .catch(({ message }) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${message}`,
          });
          this.$refs.modal.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";
</style>
