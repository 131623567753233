<template>
  <Modal
    class="service-list-modal"
    :name="name"
    @open="onModalOpen"
    :title="status === 1 ? 'Confirmar cancelamento' : 'Confirmar pagamento'"
    ref="modal"
    size="mini">
    <section class="p-40">
      <span class="mb-35 font-size-1">
        {{
          status === 1
            ? "Você tem certeza que deseja cancelar essa fatura?"
            : "Você tem certeza que deseja marcar essa fatura como paga?"
        }}
      </span>
    </section>
    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button class="btn outline" @click="close">Cancelar</button>
        <SpinnerButton
          :loading="loading"
          class="btn primary"
          @click="_cancelOrMarkAsPaidInvoice">
          Confirmar
        </SpinnerButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    name: "invoice-cancel-markAsPaid-confirmation",
    loading: false,
    id: "",
    status: 0,
  }),
  methods: {
    ...mapActions({
      cancelOrMarkAsPaidInvoice: "Payment/cancelOrMarkAsPaidInvoice",
    }),
    close() {
      this.$refs.modal.close();
    },

    onModalOpen({ id, status }) {
      this.id = id;
      this.status = status;
    },

    _cancelOrMarkAsPaidInvoice() {
      this.loading = true;
      this.cancelOrMarkAsPaidInvoice({
        id: this.id,
        status: this.status,
      }).finally(() => {
        this.loading = false;
        this.$refs.modal.close();
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";
</style>
