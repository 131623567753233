import { equals, not } from "ramda";
import { getAgencyData, saveAgencyData } from "@/services/agency/agency.http";
import { getInfoBasicHirer } from "@/services/hirer/hirer.http";

const initialState = () => ({
  isActiveAccount: false,
  currentState: "loading",
  agencyProfile: {
    initialState: {
      identification: false,
      contact: false,
      bankAccount: false,
    },
    identification: {
      companyName: "",
      fantasyName: "",
      code: "",
      doc: "",
      email: "",
    },
    contact: {
      email: "",
      phone: "",
      address: "",
    },
    bankAccount: {
      code: "",
      branch: "",
      account: "",
      doc: "",
      name: "",
    },
    services: [],
  },
});

const state = initialState();

const getters = {
  isActiveAccount: (state) => state.isActiveAccount,
  currentState: (state) => state.currentState,
  agencyCode: (state) => state.agencyProfile.identification.code,
  cnpjAccount: (state) => state.agencyProfile.identification.doc,
  identification: (state) => state.agencyProfile.identification,
  contact: (state) => state.agencyProfile.contact,
  bankAccount: (state) => state.agencyProfile.bankAccount,
  services: (state) => state.agencyProfile.services,
  tabChanged: (state) => ({
    identification: not(
      equals(
        state.agencyProfile.initialState.identification,
        state.agencyProfile.identification
      )
    ),
    contact: not(
      equals(
        state.agencyProfile.initialState.contact,
        state.agencyProfile.contact
      )
    ),
    bankAccount: not(
      equals(
        state.agencyProfile.initialState.bankAccount,
        state.agencyProfile.bankAccount
      )
    ),
    services: not(
      equals(
        state.agencyProfile.initialState.services,
        state.agencyProfile.services
      )
    ),
  }),
  someChange: (state) =>
    Object.values(getters.tabChanged(state)).some((changed) => changed),
};

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, initialState());
  },
  SET_STATE_READY: (state) => {
    state.currentState = "ready";
  },
  SET_ACTIVE_ACCOUNT: (state, isActiveAccount) => {
    state.isActiveAccount = isActiveAccount;
  },
  SET_IDENTIFICATION: (state, identification) => {
    Object.assign(state.agencyProfile.identification, { ...identification });
  },
  SET_CONTACT: (state, contact) => {
    Object.assign(state.agencyProfile.contact, { ...contact });
  },
  SET_BANK_ACCOUNT: (state, bankAccount) => {
    Object.assign(state.agencyProfile.bankAccount, { ...bankAccount });
  },
  SET_SERVICES: (state, services) => {
    state.agencyProfile.services = services;
  },
  SET_NO_CHANGES: (state) => {
    state.agencyProfile.initialState.identification = {
      ...state.agencyProfile.identification,
    };
    state.agencyProfile.initialState.contact = {
      ...state.agencyProfile.contact,
    };
    state.agencyProfile.initialState.bankAccount = {
      ...state.agencyProfile.bankAccount,
    };
    state.agencyProfile.initialState.services = state.agencyProfile.services;
  },
};

const agencyInfo = ({ identification, contact, bankAccount, services }) => ({
  identityNumber: identification.doc,
  companyName: identification.companyName,
  fantasyName: identification.fantasyName,
  code: identification?.code.replace("@", ""),
  contact: {
    email: contact.email,
    phone: contact?.phone.replace(/\D/g, ""),
    address: contact.address,
  },
  bankInformation: {
    bankCode: bankAccount.code,
    bankBranch: bankAccount.branch,
    bankAccount: bankAccount.account,
    cpfCnpj: bankAccount.doc,
    name: bankAccount.name,
  },
  services,
});

const extratcData = ({
  code,
  companyName,
  fantasyName,
  cnpj,
  contact,
  bankInformation,
  activeAccount,
  services,
}) => ({
  contact,
  services,
  isActiveAccount: activeAccount,

  identification: {
    code: code?.replace("@", ""),
    companyName,
    fantasyName,
    doc: cnpj,
    email: contact.email,
    services,
  },

  bankAccount: {
    code: bankInformation?.code,
    branch: bankInformation?.branch,
    account: bankInformation?.account,
    doc: bankInformation?.document,
    name: bankInformation?.ownerName,
  },
});

const actions = {
  init({ commit }) {
    commit("RESET_STATE");
    return actions.loadAgencyData({ commit });
  },

  loadAgencyData({ commit }) {
    return getAgencyData()
      .then(extratcData)
      .then(
        ({
          identification,
          contact,
          bankAccount,
          isActiveAccount,
          services = [],
        }) => {
          commit("SET_ACTIVE_ACCOUNT", isActiveAccount);
          commit("SET_IDENTIFICATION", identification);
          commit("SET_CONTACT", contact);
          commit("SET_BANK_ACCOUNT", bankAccount);
          commit("SET_SERVICES", services);
        }
      )
      .then(() => commit("SET_STATE_READY"))
      .finally(() => commit("SET_NO_CHANGES"));
  },

  getInfoBasicHirer({ commit }) {
    getInfoBasicHirer()
      .then(({ value: { name, document, email } }) => {
        commit("SET_IDENTIFICATION", {
          fantasyName: name,
          doc: document,
          email,
        });
      })
      .then(() => commit("SET_STATE_READY"))
      .finally(() => commit("SET_NO_CHANGES"));
  },

  setIdentification({ commit }, identification) {
    commit("SET_IDENTIFICATION", identification);
  },

  setContact({ commit }, contact) {
    commit("SET_CONTACT", contact);
  },

  setBankAccount({ commit }, bankAccount) {
    commit("SET_BANK_ACCOUNT", bankAccount);
  },

  setServices({ commit }, services) {
    commit("SET_SERVICES", services);
  },

  save({ commit, state }) {
    const data = agencyInfo(state.agencyProfile);

    const onSave = () => actions.loadAgencyData({ commit });

    return saveAgencyData(data, state.isActiveAccount).then(onSave);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
