<template>
  <section class="event">
    <h3 class="title">Demanda</h3>

    <div class="d-flex direction-column-sm">
      <div class="d-flex jc-center-sm">
        <ImageField @onChange="onImageChange" :preview="preview" />
      </div>
      <div class="ml-30 ml-0-sm w-100">
        <div class="w-100">
          <div class="w-100 pl-10 pr-10">
            <CustomInput
              :isInvalid="$v.title.$error"
              placeholder="Título"
              v-model.trim="$v.title.$model"
              id="title"
              tip="O título que os profissionais vão receber sobre a demanda." />
            <div class="error d-flex wrapped f-100">
              <span class="d-flex f-100" v-if="!$v.title.minLength">
                Titulo deve conter ao menos 5 caracteres
              </span>
              <span
                class="d-flex f-100"
                v-if="$v.title.$dirty && !$v.title.required">
                Campo Obrigatório
              </span>
            </div>
          </div>
          <div class="d-flex w-100 pl-10 pr-10">
            <CustomSelect
              class="w-100"
              placeholder="Tipo"
              id="type"
              v-model="eventTypeNew">
              <option v-if="eventTypeNew === ''">-</option>
              <option
                v-for="({ value, name }, key) in options.availableEventTypes"
                :value="value"
                :key="key"
                :selected="value == eventTypeNew">
                {{ name }}
              </option>
            </CustomSelect>
            <CustomSelect
              class="w-100"
              placeholder="Tópico"
              id="topic"
              v-model="eventTopicNew">
              <option v-if="eventTopicNew === ''">-</option>
              <option
                v-for="({ value, name }, key) in options.eventTopics"
                :value="value"
                :key="key"
                :selected="value === eventTopicNew">
                {{ name }}
              </option>
            </CustomSelect>
            <div v-if="!isEdit" class="w-100">
              <CustomInput
                :isInvalid="$v.OS.$error"
                placeholder="Código da OS"
                v-model.trim="$v.OS.$model"
                id="OS" />
              <div class="error d-flex wrapped f-100">
                <span
                  class="d-flex f-100"
                  v-if="$v.OS.$dirty && !$v.OS.maxLength">
                  A OS deve conter no máximo 6 dígitos
                </span>
              </div>
            </div>
          </div>
          <div class="pl-10">
            <md-checkbox v-model="requiresProfile" class="ml-5">
              Exige do candidato o preenchimento completo do perfil.
            </md-checkbox>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

import {
  getAvailableEventTypes,
  getEventTopics,
} from "@/services/events/event.http";

export default {
  data() {
    return {
      options: {
        availableEventTypes: [],
        eventTopics: [],
      },
    };
  },
  validations: {
    OS: {
      maxLength: maxLength(6),
    },
    title: {
      required,
      minLength: minLength(5),
    },
  },

  computed: {
    ...mapGetters({
      imageFile: "NewEvent/imageFile",
      currentImage: "EditEvent/currentImage",
      editEvent: "EditEvent/event",
      newEvent: "NewEvent/event",
      isEdit: "EditEvent/isEdit",
      requiresFullProfile: "NewEvent/requiresFullProfile",
      editRequiresFullProfile: "EditEvent/requiresFullProfile",
    }),
    event() {
      return this.$store.getters["EditEvent/isEdit"]
        ? this.editEvent
        : this.newEvent;
    },
    preview() {
      return this.isEdit ? this.currentImage : this.imageFile;
    },
    _requiresFullProfile() {
      return this.isEdit
        ? !this.editRequiresFullProfile
        : !this.requiresFullProfile;
    },
    requiresProfile: {
      get() {
        return !this._requiresFullProfile;
      },
      set(requiresFullProfile) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              requiresFullProfile,
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              requiresFullProfile,
            });
      },
    },
    eventTypeNew: {
      get() {
        return this.event.type;
      },
      set(eventTypeNew) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              event: { ...this.event, type: eventTypeNew },
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              event: { ...this.event, type: eventTypeNew },
            });
      },
    },
    eventTopicNew: {
      get() {
        return this.event.topic;
      },
      set(eventTopicNew) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              event: { ...this.event, topic: eventTopicNew },
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              event: { ...this.event, topic: eventTopicNew },
            });
      },
    },
    OS: {
      get() {
        return this.event.OS;
      },
      set(os) {
        this.$store.dispatch("NewEvent/setFormData", {
          event: { ...this.event, OS: os },
        });
      },
    },

    title: {
      get() {
        return this.event.title;
      },
      set(title) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              event: { ...this.event, title: title },
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              event: { ...this.event, title: title },
            });
      },
    },
  },

  mounted() {
    const populateOptions = ([availableEventTypes, eventTopics]) =>
      Object.assign(this.options, { availableEventTypes, eventTopics });

    Promise.all([getAvailableEventTypes(), getEventTopics()]).then(
      populateOptions
    );
  },

  methods: {
    onImageChange({ imageBase64 }) {
      this.isEdit
        ? this.$store.dispatch("EditEvent/setFormData", {
            imageFile: imageBase64,
          })
        : this.$store.dispatch("NewEvent/setFormData", {
            imageFile: imageBase64,
          });
    },
  },
};
</script>
<style lang="scss">
.event {
  .no-margin {
    margin: 0 !important;
  }

  .fix-margin-30 {
    margin-left: 30px;
  }

  @media (max-width: "995px") {
    .fix-margin-30 {
      margin-left: 0px;
    }
  }
}
</style>
