import {
  getOperations,
  setGroupName,
  getActivities,
  getOccurrences,
  changeStatus,
  onNewActivity,
  onNewIncidente,
  getServices,
} from "@/services/operation/operation.http";
import {
  postEmergencialVacancy,
  getEventDetails,
} from "../services/events/event.http";
import { getUser, isAgency } from "@/helpers/auth/";

const initialState = () => ({
  screen: {
    currentOperation: "",
  },
  isHomeOffice: false,
  qrCodeCheckinCheckout: {
    hasCheckinQrCode: false,
    hasCheckoutQrCode: false,
  },
  operations: [],
  occurrences: [],
  activities: [],
  operationService: {
    eventId: "",
    Day: "",
    EventName: "",
    OS: "",
    services: [],
  },
  userUnauthorized: false,
});

const state = initialState();

const getTotalActivities = (activities) => {
  const defaultValue = {
    ONTHEWAY: 0,
    ARRIVAL: 0,
    CHECKIN: 0,
    CHECKOUT: 0,
    BREAK: 0,
    RETURNBREAK: 0,
  };

  if (activities.length) {
    const result = activities.reduce((totalizador, item) => {
      totalizador[item.type]++;
      return totalizador;
    }, defaultValue);
    return result;
  }
};

const getters = {
  operations: (state) => state.operations,
  activities: (state) => state.activities,
  occurrences: (state) => state.occurrences,
  currentOperation: (state) => state.screen.currentOperation,
  homeOfficeOperation: (state) => state.isHomeOffice,
  qrCodeCheckinCheckout: (state) => state.qrCodeCheckinCheckout,
  chartDataSet: (state) => getTotalActivities(state.activities),
  operationService: (state) => state.operationService,
  userUnauthorized: (state) => state.userUnauthorized,
};

const mutations = {
  SET_OPERATIONS: (state, operations) => (state.operations = operations),
  SET_CURRENT_OPERATION: (state, operationId) =>
    (state.screen.currentOperation = operationId),

  SET_HOMEOFFICE_OPERATION: (state, isHomeOffice) =>
    (state.isHomeOffice = isHomeOffice),

  SET_QRCODE_CHECKIN_CHECKOUT: (state, qrCodeCheckinCheckout) =>
    (state.qrCodeCheckinCheckout = qrCodeCheckinCheckout),

  SET_OCCURRENCES: (state, occurrences) => (state.occurrences = occurrences),
  ADD_OCCURRENCE: (state, occurrence) => {
    if (
      !state.occurrences.find(
        ({ agencyId, message }) =>
          agencyId === occurrence.agencyId && message === occurrence.message
      )
    ) {
      state.occurrences.unshift(occurrence);
    }
  },

  SET_ACTIVITIES: (state, activities) => (state.activities = activities),
  ADD_ACTIVITIE: (state, activity) => {
    if (
      !state.activities.find(
        ({ agencyId, occurredAt, description }) =>
          agencyId === activity.agencyId &&
          occurredAt === activity.occurredAt &&
          description === activity.description
      )
    ) {
      state.activities.unshift(activity);
    }
  },

  SET_OPERATION_SERVICES: (state, operationService) =>
    (state.operationService = operationService),
  SET_USER_UNAUTHORIZED: (state, userUnauthorized) =>
    (state.userUnauthorized = userUnauthorized),
};

const actions = {
  init({ commit }) {
    actions.loadOperations({ commit });
    actions.subscribeChannels({ commit });
  },

  subscribeChannels({ commit }) {
    // eslind-disabled-line
    const addNewActivity = (activity) => {
      if (isAgency() && activity.agencyId == getUser().id) {
        commit("ADD_ACTIVITIE", activity);
        return;
      }

      if (!isAgency()) {
        commit("ADD_ACTIVITIE", activity);
      }
    };
    const addOccurrence = (occurrence) => {
      if (isAgency() && occurrence.agencyId == getUser().id) {
        commit("ADD_OCCURRENCE", occurrence);
        return;
      }

      if (!isAgency()) {
        commit("ADD_OCCURRENCE", occurrence);
      }
    };

    onNewActivity(addNewActivity);
    onNewIncidente(addOccurrence);
  },

  loadOperations({ commit }) {
    return getOperations().then((operations) =>
      commit("SET_OPERATIONS", operations)
    );
  },

  loadActivities({ commit }, operationId) {
    return getActivities(operationId).then((activities = []) =>
      commit("SET_ACTIVITIES", activities.reverse())
    );
  },

  loadOccurrences({ commit }, operationId) {
    return getOccurrences(operationId).then((occurrences = []) =>
      commit("SET_OCCURRENCES", occurrences.reverse())
    );
  },

  async loadOperation({ commit }, { id: operationId, groupName, eventId }) {
    commit("SET_CURRENT_OPERATION", operationId);

    commit("SET_OCCURRENCES", []);
    commit("SET_ACTIVITIES", []);

    if (operationId) {
      setGroupName(groupName);

      await getServices(operationId).then((operationService) => {
        commit("SET_OPERATION_SERVICES", operationService);
      });

      actions.loadActivities({ commit }, operationId);
      actions.loadOccurrences({ commit }, operationId);
      getEventDetails(eventId).then(
        ({ isHomeOffice, hasCheckinQrCode, hasCheckoutQrCode }) => {
          commit("SET_QRCODE_CHECKIN_CHECKOUT", {
            hasCheckinQrCode,
            hasCheckoutQrCode,
          });
          commit("SET_HOMEOFFICE_OPERATION", isHomeOffice);
        }
      );
    }
  },

  changeOccurrenceStatus({ commit, state }, occurrenceStatus) {
    const operationId = state.screen.currentOperation;

    changeStatus(operationId, occurrenceStatus).then(() => {
      actions.loadOccurrences({ commit }, operationId);
    });
  },

  getOperationServices({ commit }, operationId) {
    getServices(operationId)
      .then((operationService) => {
        commit("SET_OPERATION_SERVICES", operationService);
      })
      .catch(() => {
        commit("SET_USER_UNAUTHORIZED", true);
      });
  },

  // eslint-disable-next-line no-unused-vars
  createEmergencialVacancy({ commit }, body) {
    return postEmergencialVacancy(body);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
