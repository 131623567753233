<template>
  <div class="vacancy-slot border-status" :class="getStatus.borderColor">
    <div class="d-flex ai-center">
      <div class="mr-7">
        <Avatar :src="vacancy.image" />
      </div>
      <div
        class="d-flex direction-column cursor-pointer jc-space-between"
        @click="openMemberModal">
        <small
          v-if="!vacancy.freelaId && isAgency && canAgencyManager"
          :class="{ 'text-PictonBlue': isEmpty }">
          Adicionar profissional
        </small>
        <small v-else :class="{ 'text-dim-gray': isEmpty }">
          {{ vacancy.description }}
        </small>
        <small class="text-BrightSun">Titular</small>
        <small :class="getStatus.TextColor">{{ getStatus.name }}</small>
      </div>
    </div>
    <div class="d-flex direction-column">
      <small class="text-white-opacity">{{ vacancy.day.dayOfWeek }}</small>
      <small>{{ vacancy.day.fullDate }}</small>
      <small class="text-white-opacity">{{ vacancy.day.year }}</small>
    </div>
    <div class="d-flex direction-column border-left pl-15 mr-30">
      <div class="d-flex w-100 jc-space-between">
        <small class="text-white-opacity">Planejado</small>
        <small>{{ checkin.planned | formatData }}</small>
      </div>
      <div class="d-flex w-100 jc-space-between">
        <small class="text-white-opacity">Realizado</small>
        <small class="p-relative">
          {{ checkin.done | formatData }}
          <span v-if="vacancy.checkinOrigin === 1" class="check-edited-tooltip">
            <img src="@/assets/ic-info.svg" alt="info" />
            <md-tooltip
              md-direction="top"
              style="background: #865fc0; color: white">
              Editado pelo gestor
            </md-tooltip>
          </span>
        </small>
      </div>
      <div class="d-flex w-100 jc-space-between">
        <small class="text-white-opacity">Diferença</small>
        <small>{{ checkin.diff }}</small>
      </div>
    </div>
    <div class="d-flex direction-column js-end ai-end border-right pr-30">
      <small>{{ checkout.planned | formatData }}</small>
      <small class="p-relative">
        {{ checkout.done | formatData }}
        <span v-if="vacancy.checkoutOrigin === 1" class="check-edited-tooltip">
          <img src="@/assets/ic-info.svg" alt="info" />
          <md-tooltip
            md-direction="top"
            style="background: #865fc0; color: white">
            Editado pelo gestor
          </md-tooltip>
        </span>
      </small>
      <small>{{ checkout.diff }}</small>
    </div>
    <div
      class="js-end emptyPayment"
      :class="
        !vacancy.freelaId &&
        !vacancy.isPublished &&
        isAgency &&
        canAgencyManager &&
        'cursor-pointer'
      "
      v-click-outside="hiddenPaymentEdition"
      @click="showPaymentEdition">
      <EmbededInput
        v-model="payment"
        type="currency"
        v-if="canEditPaymentValue && !vacancy.isPublished"
        ref="payment"
        @keypress.enter="hiddenPaymentEdition" />
      <small
        v-else-if="vacancy.payment === 0 && isAgency && canAgencyManager"
        class="text-dim-gray">
        Digite o valor
      </small>
      <small v-else>R$ {{ vacancy.payment | toCurrency }}</small>
    </div>

    <div class="d-flex jc-space-between">
      <small class="d-flex ai-center">
        <SpinnerButton
          v-if="isAgency && canAgencyManager && !vacancy.inOperation"
          :loading="loading"
          :class="currentActionButton.style"
          :disabled="vacancy.payment === 0 || !vacancy.freelaId"
          class="btn radius-50 font-size-07 small"
          @click="onVacancyAction(currentActionButton.id)">
          {{ currentActionButton.label }}
        </SpinnerButton>
      </small>
    </div>

    <ContextMenu
      class="context-menu p-absolute"
      :options="contextMenuOptions"
      @itemClick="onContextMenuItemClick">
      <button class="context-menu-button btn outline icon" md-menu-trigger>
        <i class="mdi mdi-dots-horizontal text-malibu font-size-1-3"></i>
      </button>
    </ContextMenu>
  </div>
</template>

<script>
import { stringToDate } from "@/helpers/dateFormat";
import { mapActions, mapGetters } from "vuex";
import EmbededInput from "@/components/shared/form/EmbededInput.vue";
import { strCurrencyToDecimal } from "@/helpers/form/currencyConvert";
import { vacancyStatus } from "./index";
import { cond } from "ramda";

export default {
  components: {
    EmbededInput,
  },
  props: {
    vacancy: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      required: true,
    },
  },
  filters: {
    formatData(data) {
      if (data !== "") {
        return new Date(data).toLocaleDateString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return "--";
    },
  },
  data: () => ({
    loading: false,
    enabledEditPayment: false,
    selectedMember: {},
    cssStyle: false,
    slotActionDefinition: [
      {
        id: vacancyStatus.AVAILABLE,
        label: "Enviar convite",
        style: "yellow",
        isPublished: false,
        status: vacancyStatus,
      },
      {
        id: vacancyStatus.WAIT_AGENCY_OR_HIRER_APPROVAL,
        label: "Confirma profissional",
        style: "purple",
        isPublished: false,
        status: vacancyStatus,
      },
      {
        id: vacancyStatus.CONFIRMED,
        label: "Cancelar convite",
        style: "red",
        isPublished: false,
        status: vacancyStatus,
      },
      {
        id: vacancyStatus.WAIT_FREELA_ACCEPT_INVITE,
        label: "Cancelar convite",
        style: "red",
        isPublished: false,
        status: vacancyStatus,
      },
      {
        id: vacancyStatus.AVAILABLE,
        label: "Vaga publicada",
        style: "red",
        isPublished: true,
        status: vacancyStatus,
      },
      {
        id: vacancyStatus.WORKED,
        label: "Serviço realizado",
        style: "outline",
        isPublished: false,
        status: vacancyStatus,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      operations: "EventDetails/operations",
      isAgency: "User/isAgency",
      negotiations: "EventDetails/negotiations",
      userId: "User/userId",
      isManagementByAgency: "EventDetails/isManagementByAgency",
      currentService: "EventDetails/currentService",
    }),

    canAgencyManager() {
      if (this.isManagementByAgency) return true;
      else {
        return this.negotiations.some(
          (n) =>
            n.agencyId === this.userId &&
            n.serviceId === this.vacancy.serviceId &&
            n.budgets &&
            n.budgets.some((b) => b.status === 3)
        );
      }
    },

    eventId() {
      return this.$route.params.id;
    },
    isEmpty() {
      return !this.vacancy.freelaId;
    },
    currentActionButton() {
      const filter = ({ id }) => id === this.vacancy.status;

      return this.slotActionDefinition.find(filter);
    },
    checkin() {
      const [hour, min] = this.vacancy.checkInDiff.split(":");
      return {
        planned: this.vacancy.start,
        done:
          this.vacancy.checkIn === "0001-01-01T00:00:00Z"
            ? ""
            : this.vacancy.checkIn,
        diff:
          this.vacancy.checkIn === "0001-01-01T00:00:00Z"
            ? "--"
            : `${hour}h${min}min`,
      };
    },
    checkout() {
      const [hour, min] = this.vacancy.checkOutDiff.split(":");
      return {
        planned: this.vacancy.end,
        done:
          this.vacancy.checkOut === "0001-01-01T00:00:00Z"
            ? ""
            : this.vacancy.checkOut,
        diff:
          this.vacancy.checkOut === "0001-01-01T00:00:00Z"
            ? "--"
            : `${hour}h${min}min`,
      };
    },
    contextMenuOptions() {
      return [
        {
          label: `Publicar vaga`,
          icon: "mdi-bullhorn",
          action: "publish",
          disabled:
            !this.isEmpty ||
            this.vacancy.isPublished ||
            !this.isAgency ||
            !this.canAgencyManager,
        },
        {
          label: `Ver perfil`,
          icon: "mdi-account-circle",
          action: "profile",
          disabled: this.isEmpty,
        },
        {
          label: "Ver Localização",
          icon: "mdi-map-marker",
          action: "localize",
          disabled: this.isEmpty,
        },
        {
          label: "Avaliar o profissional",
          icon: "mdi-star",
          action: "avaliate",
          disabled: this.isEmpty,
        },
        {
          label: "Corrigir horários",
          icon: "mdi-pencil",
          action: "correctSchedules",
          disabled: this.isEmpty,
        },
      ];
    },
    getStatus() {
      const { status, isPublished } = this.vacancy;
      const statusTypes = isPublished ? 1 : status;
      return {
        0: { name: "---", TextColor: "text-dim-gray" },
        1: {
          name: "Publicado",
          TextColor: "text-LilacBush",
          borderColor: "border-color-LilacBush",
        },
        2: {
          name: "Aceito",
          TextColor: "text-light-green",
          borderColor: "border-color-light-green",
        },
        3: {
          name: "Candidatado",
          TextColor: "text-BrightSun",
          borderColor: "border-color-BrightSun",
        },
        4: { name: "Realizado" },
        5: { name: "Pago" },
        8: {
          name: "Aguardando",
          TextColor: "text-Tangerine",
          borderColor: "border-color-Tangerine",
        },
      }[statusTypes];
    },
    payment: {
      get() {
        return this.vacancy.payment;
      },
      set(currency) {
        const value = strCurrencyToDecimal(currency);
        this.updateVacancySlot({
          vacancySlot: { ...this.vacancy, payment: value },
          index: this.index,
        });
      },
    },
    referenceDate() {
      return stringToDate(this.vacancy.day.id);
    },

    operationId() {
      return this.operations.find((o) => o.day == this.vacancy.day.raw).id;
    },

    canEditPaymentValue() {
      return (
        this.enabledEditPayment &&
        this.isAgency &&
        this.vacancy.status == vacancyStatus.AVAILABLE
      );
    },
    canOpenMemberModal() {
      return (
        this.vacancy.status == vacancyStatus.AVAILABLE &&
        this.isAgency &&
        this.canAgencyManager
      );
    },
  },
  methods: {
    ...mapActions({
      getOperationConnection: "EventDetails/getOperationConnection",
      getFreelaInformations: "Freela/getFreelaInformations",
      updateVacancySlot: "EventDetails/updateVacancySlot",
      sendInvite: "EventDetails/invite",
      cancelInvite: "EventDetails/cancelInvite",
      confirmFreelancer: "EventDetails/confirmFreelancer",
    }),
    onContextMenuItemClick({ action }) {
      const actions = {
        localize: this.localize,
        publish: this.publish,
        avaliate: this.avaliation,
        profile: this.profile,
        correctSchedules: this.correctSchedules,
      };

      actions[action]();
    },
    localize() {
      this.getOperationConnection({
        eventId: this.eventId,
        referenceDate: this.referenceDate.toISOString(),
      });

      this.$eventhub.$emit("modal:open:freela-geolocation", {
        slots: [this.vacancy],
        eventId: this.eventId,
        referenceDate: this.referenceDate,
      });
    },

    publish() {
      const title = `Publicar  vagas de "${this.vacancy.job}"`;
      this.$eventhub.$emit("modal:open:vacancy-value-modal", {
        title,
        slots: [this.vacancy],
        eventId: this.eventId,
      });
    },

    async profile() {
      this.getFreelaInformations(this.vacancy.freelaId);
      this.$eventhub.$emit("modal:open:freela-profile-modal");
    },

    avaliation() {
      this.$eventhub.$emit("modal:open:memberAvaliation", {
        members: this.vacancy,
        operationId: this.operationId,
        ratingAll: false,
      });
    },

    correctSchedules() {
      this.$eventhub.$emit("modal:open:correctSchedules", {
        operationId: this.operationId,
        job: this.vacancy.job,
        vacancyId: this.vacancy.vacancyId,
        freelaId: this.vacancy.freelaId,
        checkin: this.checkin,
        checkout: this.checkout,
      });
    },

    onVacancyAction() {
      const canSendInvite = ({ status }) => status === vacancyStatus.AVAILABLE;
      const canCancelInvite = ({ status }) =>
        status === vacancyStatus.CONFIRMED ||
        status === vacancyStatus.WAIT_FREELA_ACCEPT_INVITE;
      const canConfirmFreelancer = ({ status }) =>
        status === vacancyStatus.WAIT_AGENCY_OR_HIRER_APPROVAL;

      cond([
        [canSendInvite, this.trySendEnvite],
        [canCancelInvite, this.tryCancelInvite],
        [canConfirmFreelancer, this.tryConfirm],
      ])(this.vacancy);
    },

    trySendEnvite(vacancy) {
      this.loading = true;
      this.sendInvite(vacancy)
        .then(() => this.feedbackSuccess("Convite enviado!"))
        .catch(this.feedbackError)
        .finally(() => (this.loading = false));
    },

    tryCancelInvite(vacancy) {
      this.loading = true;
      this.cancelInvite(vacancy)
        .then(() => this.feedbackSuccess("Convite cancelado"))
        .catch(this.feedbackError)
        .finally(() => (this.loading = false));
    },

    tryConfirm(vacancy) {
      this.loading = true;
      this.confirmFreelancer(vacancy)
        .then(() =>
          this.feedbackSuccess(
            `O profissional ${vacancy.description} está escalado para a demanda!`
          )
        )
        .catch(this.feedbackError)
        .finally(() => (this.loading = false));
    },

    feedbackError({ message }) {
      this.$eventhub.$emit("feedback:show", {
        type: "error",
        msg: `${message}`,
      });
    },

    feedbackSuccess(message) {
      this.$eventhub.$emit("feedback:show", {
        type: "success",
        msg: `${message}`,
      });
    },

    showPaymentEdition() {
      this.enabledEditPayment = true;
    },

    hiddenPaymentEdition() {
      this.enabledEditPayment = false;
    },

    onSelectedMember({ name: description, imageUrl: image, freelaId }) {
      this.updateVacancySlot({
        vacancySlot: {
          ...this.vacancy,
          description,
          image,
          freelaId,
        },
        index: this.index,
      });
    },

    openMemberModal() {
      if (this.canOpenMemberModal) {
        this.$eventhub.$emit("modal:open:members", {
          onSelectedMember: this.onSelectedMember,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.vacancy-slot {
  font-size: 12px;

  &.border-status {
    border-left: solid 5px $Ebony;
  }
  &.border-color-LilacBush {
    border-color: $LilacBush;
  }
  &.border-color-light-green {
    border-color: $LightGreen;
  }
  &.border-color-BrightSun {
    border-color: $BrightSun;
  }
  &.border-color-Tangerine {
    border-color: $Tangerine;
  }
  .border-left {
    border-left: solid 1px $Ebony;
  }

  .border-right {
    border-right: solid 1px $Ebony;
  }
}

.emptyPayment {
  width: 80px;
  text-align: end;
  line-height: normal;
}

.context-menu {
  right: 10px;
  z-index: 100;
}

.check-edited-tooltip {
  filter: brightness(0) saturate(100%) invert(28%) sepia(15%) saturate(2550%)
    hue-rotate(220deg) brightness(96%) contrast(82%);

  position: absolute;
  right: -24px;

  .tooltip-message {
    color: white;
    background: $LilacBush;
  }

  img {
    width: 1rem;
    height: 1rem;
  }
}
</style>
