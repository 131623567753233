<template>
  <table
    :class="{ fixed_header: !fixedHeader }"
    cellspacing="0"
    cellpadding="0">
    <thead>
      <tr>
        <th scope="col" v-for="item in head" :key="item">{{ item }}</th>
      </tr>
    </thead>
    <tbody>
      <slot name="table-body" />
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["head", "fixedHeader"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
table {
  margin: 0;
  padding: 0;
  // table-layout: fixed;
  width: 100%;
  th {
    background-color: $SteelGray;
    border: 0;
  }

  td {
    border-bottom: 1px solid $SteelGray;
    white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
  }

  th,
  td {
    padding: 1em 1.6em;
    text-align: left;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 768px) {
    border: 0;
    margin-top: 2em;

    .col {
      width: 0;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      display: block;
      margin-bottom: 2em;
    }

    td {
      border-bottom: 2px solid $SteelGray;
      display: block;
      font-size: 0.8em;
      text-align: right;

      &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.fixed_header {
  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  th[scope="row"] {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
  }
}
</style>
