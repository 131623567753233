<template>
  <section class="custom-autocomplete">
    <div class="__input">
      <CustomInput
        :beforeIcon="icon"
        :id="id"
        :tip="tip"
        :label="label"
        :example="example"
        :placeholder="placeholder"
        :error="error"
        :value="value"
        :disabled="disabled"
        @input="onType"
        @keydown.prevent.esc="setStateClose"
        @keydown.prevent.up="onKeyUp"
        @keydown.prevent.down="onKeyDown"
        @keydown.prevent.enter="onKeyEnter"
        @iconClick="$emit('iconClearClick')" />
      <div v-if="state === STATES.LOADING" class="__loading">
        <Loading size="small" />
      </div>
    </div>
    <article class="d-flex f-col __result-list">
      <StateHandler :state="state" class="d-flex jc-center overflow-hidden">
        <template #loading>
          <div class="w-100 d-flex jc-center p-10">
            <slot name="loading">
              <Loading size="small" label="Buscando" />
            </slot>
          </div>
        </template>

        <template #empty>
          <div class="w-100 d-flex jc-center p-10">
            <slot name="empty">Nenhum resultado para "{{ input }}"</slot>
          </div>
        </template>

        <div class="__list w-100">
          <ul v-if="state === STATES.DEFAULT">
            <li
              @click="onClick(key)"
              v-for="(item, key) in items"
              :key="key"
              class="__item d-flex ai-center cursor-pointer"
              :class="{ hover: key === indexHover }">
              <slot name="option" :item="item">
                <div class="w-100 p-5">{{ getLabel(item) }}</div>
              </slot>
            </li>
          </ul>
        </div>
      </StateHandler>
    </article>
  </section>
</template>

<script>
import { debounce } from "lodash";

const STATES = {
  DEFAULT: "default",
  LOADING: "loading",
  EMPTY: "empty",
  CLOSED: "closed",
};

export default {
  props: {
    id: { default: "" },
    tip: { default: "" },
    icon: { default: "" },
    value: { default: "" },
    label: { default: "" },
    example: { default: "" },
    placeholder: { default: "" },
    error: { default: "" },
    displayField: { default: "label" },
    limit: { default: 6 },
    search: {
      type: Function,
      required: true, // should return promise
    },
    disabled: { default: false },
  },
  data: () => ({
    STATES,
    state: STATES.CLOSED,
    selected: undefined,
    items: [],
    indexHover: -1,
  }),
  created() {
    window.addEventListener("click", this.close);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    debouncedSearch() {
      return debounce((term) => {
        const setStateCurrent = () => {
          const currentState =
            this.items.length === 0 ? STATES.EMPTY : STATES.DEFAULT;
          this.setState(currentState);
        };

        const applyLimit = (items) => items.slice(0, this.limit);

        this.search(term)
          .then(applyLimit)
          .then(this.addResults)
          .finally(setStateCurrent);
      }, 500);
    },
  },
  methods: {
    doSearch(term) {
      this.setState(STATES.LOADING);
      this.debouncedSearch(term);
    },
    addResults(results) {
      this.items = results;
      return results;
    },
    select(item) {
      this.input = this.getLabel(item);
      this.selected = item;
      this.$emit("selected", item);
      this.setStateClose();
    },
    setState(state) {
      this.state = state;
    },
    setStateClose() {
      this.items = [];
      this.setState(STATES.CLOSED);
    },
    getLabel(item) {
      return item[this.displayField] || item.toString();
    },
    onType(value) {
      if (this.value === value) return;

      this.input = value;

      if (value.length === 0) {
        this.setStateClose();
        return;
      }

      this.indexHover = -1;
      this.setState(STATES.DEFAULT);
      this.doSearch(value);
    },
    onClick(key) {
      this.select(this.items[key]);
    },

    onKeyDown() {
      const lastIndex = this.items.length - 1;
      const newIndex = this.indexHover + 1;
      this.indexHover = newIndex > lastIndex ? lastIndex : newIndex;
    },
    onKeyUp() {
      this.indexHover = this.indexHover > 0 ? this.indexHover - 1 : 0;
    },
    onKeyEnter() {
      const item = this.items[this.indexHover] || this.items[0];

      if (item) {
        this.select(item);
        return;
      }

      this.$emit(STATES.EMPTY);
      this.setStateClose();
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.state = STATES.CLOSED;
        // if (this.$refs.inputSearch) {
        //   this.$refs['inputSearch'].value = '';
        // }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.custom-autocomplete {
  position: relative;

  .__input {
    position: relative;

    .__loading {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }

  .__result-list {
    background: $Ebony;
    box-shadow: 0 0 3px $Ebony;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 2;

    .__list {
      .__item {
        min-height: 40px;

        &:hover,
        &.hover {
          background: transparentize(white, 0.3);
        }
      }
    }
  }
}
</style>
