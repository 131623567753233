<template>
  <div
    class="container w-100 pt-20 d-flex direction-column h-100 overflow-hidden">
    <header class="d-flex pl-25 pr-25 ai-center jc-space-between w-100 mb-20">
      <div class="d-flex ai-center w-50">
        <h2 class="mr-10 font-size-1-3 text-Periwinkle">Faturas</h2>
      </div>
      <div class="w-50 d-flex jc-end">
        <FilterInvoice class="mr-20" :agencyId="agencyId" />
        <button class="btn primary p-10" @click="goNewInvoice">
          Nova Fatura
        </button>
      </div>
    </header>

    <md-content class="md-scrollbar h-60 pb-30">
      <CustomTable :head="head" :filterHeader="true">
        <template #table-body v-if="!loader && invoice.list.length">
          <tr v-for="(item, index) in invoice.list" :key="index">
            <td scope="row" data-label="Contratante">{{ item.hirerName }}</td>
            <td data-label="Data criação">{{ item.createdAt | formDate }}</td>
            <td data-label="Data de vencimento">
              {{ item.expireDate | formDate }}
            </td>
            <td data-label="Valor">R$ {{ item.totalValue | toCurrency }}</td>
            <td data-label="Qtd. Profissionais">
              {{ item.totalProfessionals }}
            </td>
            <td data-label="Status">
              <span class="bg-status" :class="status[item.status]">
                {{ statusName[item.status] }}
              </span>
            </td>
            <td data-label="Ações">
              <ContextMenu
                :value="item"
                class="context-menu"
                :options="[
                  ...contextMenuOptions,
                  {
                    label: `Marcar como pago`,
                    icon: 'mdi-check',
                    action: 'markAsPaid',
                    disabled: ![0, 5].includes(item.status),
                  },
                  {
                    label: `Cancelar`,
                    icon: 'mdi-cancel text-red',
                    action: 'cancelInvoice',
                    disabled: [1, 2].includes(item.status),
                  },
                ]"
                @itemClick="onContextMenuItemClick">
                <button
                  class="context-menu-button btn outline icon"
                  md-menu-trigger>
                  <i
                    class="mdi mdi-dots-horizontal text-malibu font-size-1-3"></i>
                </button>
              </ContextMenu>
            </td>
          </tr>
        </template>
      </CustomTable>
      <div
        v-if="!(!loader && invoice.list.length)"
        class="d-flex direction-column ai-center mt-50">
        <template v-if="!loader">
          <img
            class="img-empty"
            :src="require('@/assets/empty_search.png')"
            alt="empty" />
          <h4>Não há nenhuma fatura a serem exibidas.</h4>
        </template>
        <Loading v-else class="p-50" label="Carregando" />
      </div>
    </md-content>
    <div
      v-if="!(invoice.totalPages === page) && invoice.list.length"
      class="d-flex jc-center m-25">
      <SpinnerButton
        :loading="loaderButton"
        class="btn outline outline-perfume"
        @click="loaderMorePage">
        Carregar mais
      </SpinnerButton>
    </div>
    <CancelOrMarkAsPaidConfirmationModal />
  </div>
</template>

<script>
import FilterInvoice from "@/components/Invoices/Filter/filterInvoice.vue";
import { mapActions, mapGetters } from "vuex";
import CancelOrMarkAsPaidConfirmationModal from "../../components/Invoices/Modal/CancelOrMarkAsPaidConfirmationModal.vue";

export default {
  components: {
    FilterInvoice,
    CancelOrMarkAsPaidConfirmationModal,
  },
  computed: {
    contextMenuOptions() {
      return [
        {
          label: `Ver detalhes`,
          icon: "mdi-eye",
          action: "detailsInvoices",
        },
      ];
    },
    ...mapGetters({
      invoice: "Payment/listInvoices",
      loader: "Payment/loading",
      agencyId: "User/userId",
    }),
  },
  mounted() {
    this.init();
  },
  data: () => ({
    head: [
      "Contratante",
      "Data de criação",
      "Data de vencimento",
      "Valor",
      "Qtd. Profissionais",
      "Status",
      "Ações",
    ],
    status: {
      0: "waiting",
      1: "canceled",
      2: "canceled",
      3: "paid",
      4: "markedAsPaid",
      5: "expired",
      6: "ProblemOnTransferOperation",
    },
    statusName: {
      0: "Aguardando",
      1: "Cancelado",
      2: "Cancelado por problema na fatura",
      3: "Pago",
      4: "Marcado como pago",
      5: "Atrasado",
      6: "Problema na transferência",
    },
    loaderButton: false,
    page: 1,
  }),
  methods: {
    onContextMenuItemClick({ action, value }) {
      const actions = {
        detailsInvoices: this.detailsInvoices,
        markAsPaid: this.markAsPaid,
        cancelInvoice: this.cancelInvoice,
      };

      actions[action](value);
    },

    ...mapActions({
      getListInvoices: "Payment/getListInvoices",
      resetListInvoices: "Payment/resetListInvoices",
    }),
    init() {
      this.resetListInvoices();
      this.listInvoices(true);
    },
    listInvoices(loader) {
      this.getListInvoices({
        id: this.agencyId,
        page: this.page,
        loader,
      })
        .catch((error) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${error}`,
          });
        })
        .finally(() => {
          this.loaderButton = false;
        });
    },
    loaderMorePage() {
      this.loaderButton = true;
      this.page = this.page + 1;
      this.listInvoices(false);
    },

    detailsInvoices({ id }) {
      this.$router.push({
        name: "InvoiceDetails",
        params: { id },
      });
    },
    markAsPaid({ id }) {
      this.$eventhub.$emit(
        "modal:open:invoice-cancel-markAsPaid-confirmation",
        { id, status: 4 }
      );
    },
    cancelInvoice({ id }) {
      this.$eventhub.$emit(
        "modal:open:invoice-cancel-markAsPaid-confirmation",
        { id, status: 1 }
      );
    },
    goNewInvoice() {
      this.$router.push({
        name: "NewInvoice",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.img-empty {
  width: 20%;
  height: 20%;
  object-fit: contain;
}

.context-menu {
  width: 20px;
  height: 20px;
}

td {
  @media screen and (max-width: 768px) {
    &:last-child {
      display: flex;
      justify-content: space-between;
      margin: 0;
      width: 99%;
    }
  }
}
</style>
