<template>
  <div class="event">
    <h3 class="title">Localização</h3>

    <div class="d-flex direction-column-sm">
      <div class="md-size-33 md-small-hide">
        <GoogleMapsPreview :location="mapStatic" />
      </div>
      <div class="ml-30 ml-0-sm w-100">
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-size-100">
            <div class="w-100 d-flex ai-center mb-10">
              <toggle-button
                class="pr-10"
                id="toggle"
                v-model="isHomeOffice"
                :height="25"
                :width="60"
                :color="colorSwitch"
                :sync="true"
                :disabled="hasCheckinQrCode || hasCheckoutQrCode || isEdit" />
              <small>Local e endereço</small>
            </div>

            <CustomInput
              :isInvalid="$v.name.$error && isHomeOffice"
              placeholder="Nome do Local"
              id="localName"
              v-model.trim="$v.name.$model"
              :disabled="!isHomeOffice" />
            <div class="error d-flex wrapped f-100">
              <span
                class="d-flex f-100"
                v-if="$v.name.$dirty && !$v.name.required && isHomeOffice">
                Campo Obrigatório
              </span>
            </div>
          </div>
          <div class="md-layout-item md-size-100">
            <AddressAutocomplete
              :disabled="!isHomeOffice"
              placeholder="Endereço"
              id="local-address"
              tip="Ex: Av. Paulista, 967"
              v-model.trim="$v.address.$model"
              @selected="selectedHandler" />
            <div class="error d-flex wrapped f-100">
              <span
                class="d-flex f-100"
                v-if="
                  $v.address.$dirty && !$v.address.required && isHomeOffice
                ">
                Campo Obrigatório
              </span>
            </div>
            <div class="d-flex ai-center">
              <md-checkbox
                v-model="hasCheckinQrCode"
                id="checking-qr-code"
                class="ml-5"
                :disabled="!isHomeOffice">
                QR CODE entrada
              </md-checkbox>
              <md-checkbox
                v-model="hasCheckoutQrCode"
                id="checkout-qr-code"
                class="ml-5"
                :disabled="!isHomeOffice">
                QR CODE saída
              </md-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

import GoogleMapsPreview from "@/components/events/Google/GoogleMapsPreview.vue";

import { getDistanceBetween } from "@/services/events/event.http.js";

export default {
  components: { GoogleMapsPreview },
  data: () => ({
    colorSwitch: { checked: "#86d7ca", unchecked: "#403A60" },
  }),
  validations: {
    name: {
      required,
    },
    address: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      addresses: "NewEvent/addressesFound",
      mapStatic: "NewEvent/mapLocation",
      editLocation: "EditEvent/location",
      newLocation: "NewEvent/location",
      isEdit: "EditEvent/isEdit",
      homeOffice: "NewEvent/isHomeOffice",
      editHomeOffice: "EditEvent/isHomeOffice",
      newQrCodeCheckinCheckout: "NewEvent/qrCodeCheckinCheckout",
      editQrCodeCheckinCheckout: "EditEvent/qrCodeCheckinCheckout",
      isDuplicated: "NewEvent/isDuplicated",
    }),

    location() {
      this.$store.dispatch(
        "NewEvent/getMapLocation",
        this.isEdit || this.isDuplicated
          ? this.editLocation.address
          : this.newLocation.address
      );
      return this.isEdit ? this.editLocation : this.newLocation;
    },
    _isHomeOffice() {
      return this.isEdit ? !this.editHomeOffice : !this.homeOffice;
    },
    _hasCheckinQrCode() {
      return this.isEdit
        ? this.editQrCodeCheckinCheckout.hasCheckinQrCode
        : this.newQrCodeCheckinCheckout.hasCheckinQrCode;
    },
    _hasCheckoutQrCode() {
      return this.isEdit
        ? this.editQrCodeCheckinCheckout.hasCheckoutQrCode
        : this.newQrCodeCheckinCheckout.hasCheckoutQrCode;
    },
    address: {
      get() {
        return this.location.address;
      },
      set(value) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              location: { ...this.location },
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              location: { ...this.location, address: value },
            });
      },
    },
    name: {
      get() {
        return this.location.name;
      },
      set(value) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              location: { ...this.location, name: value },
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              location: { ...this.location, name: value },
            });
      },
    },
    isHomeOffice: {
      get() {
        return this._isHomeOffice;
      },
      set(value) {
        const location = { place_id: "", address: "", name: "" };
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              location,
              isHomeOffice: !value,
              hasCheckinQrCode: false,
              hasCheckoutQrCode: false,
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              location,
              isHomeOffice: !value,
              hasCheckinQrCode: false,
              hasCheckoutQrCode: false,
            });
      },
    },
    hasCheckinQrCode: {
      get() {
        return this._hasCheckinQrCode;
      },
      set(hasCheckinQrCode) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              hasCheckinQrCode,
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              hasCheckinQrCode,
            });
      },
    },
    hasCheckoutQrCode: {
      get() {
        return this._hasCheckoutQrCode;
      },
      set(hasCheckoutQrCode) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              hasCheckoutQrCode,
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              hasCheckoutQrCode,
            });
      },
    },
  },

  methods: {
    selectedHandler($event) {
      this.isEdit ? this.allowEditLocation($event) : this._newLocation($event);
    },

    _newLocation($event) {
      this.$store.dispatch("NewEvent/setFormData", {
        location: {
          ...this.location,
          place_id: $event.place_id,
          address: $event.description,
        },
      });

      this.$store.dispatch("NewEvent/getMapLocation", $event.description);
    },

    allowEditLocation($event) {
      let oldLocation = this.location;
      getDistanceBetween({
        placeId_1: $event.place_id,
        placeId_2: this.location.place_id,
      })
        .then((distance) => {
          if (Number(distance) > 2) {
            this.$store.dispatch("EditEvent/setFormData", {
              location: {
                ...this.location,
                place_id: $event.place_id,
                address: $event.description,
              },
            });

            this.$eventhub.$emit("feedback:show", {
              type: "error",
              msg: "O novo endereço não pode ter mais de 2km de distância do endereço atual",
            });

            setTimeout(() => {
              this.$store.dispatch("EditEvent/setFormData", {
                location: {
                  ...oldLocation,
                },
              });
              this.$store.dispatch(
                "NewEvent/getMapLocation",
                this.location.address
              );
            }, 500);
          } else {
            this.$store.dispatch("EditEvent/setFormData", {
              location: {
                ...this.location,
                place_id: $event.place_id,
                address: $event.description,
              },
            });
            this.$store.dispatch("NewEvent/getMapLocation", $event.description);
          }
        })
        .catch((error) =>
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${error.Message}`,
          })
        );
    },
  },
};
</script>
