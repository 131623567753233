var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container w-100 pt-20 d-flex direction-column h-100 overflow-hidden"},[_c('header',{staticClass:"d-flex pl-25 pr-25 ai-center jc-space-between w-100 mb-20"},[_vm._m(0),_c('div',{staticClass:"w-50 d-flex jc-end"},[_c('FilterInvoice',{staticClass:"mr-20",attrs:{"agencyId":_vm.agencyId}}),_c('button',{staticClass:"btn primary p-10",on:{"click":_vm.goNewInvoice}},[_vm._v(" Nova Fatura ")])],1)]),_c('md-content',{staticClass:"md-scrollbar h-60 pb-30"},[_c('CustomTable',{attrs:{"head":_vm.head,"filterHeader":true},scopedSlots:_vm._u([(!_vm.loader && _vm.invoice.list.length)?{key:"table-body",fn:function(){return _vm._l((_vm.invoice.list),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"scope":"row","data-label":"Contratante"}},[_vm._v(_vm._s(item.hirerName))]),_c('td',{attrs:{"data-label":"Data criação"}},[_vm._v(_vm._s(_vm._f("formDate")(item.createdAt)))]),_c('td',{attrs:{"data-label":"Data de vencimento"}},[_vm._v(" "+_vm._s(_vm._f("formDate")(item.expireDate))+" ")]),_c('td',{attrs:{"data-label":"Valor"}},[_vm._v("R$ "+_vm._s(_vm._f("toCurrency")(item.totalValue)))]),_c('td',{attrs:{"data-label":"Qtd. Profissionais"}},[_vm._v(" "+_vm._s(item.totalProfessionals)+" ")]),_c('td',{attrs:{"data-label":"Status"}},[_c('span',{staticClass:"bg-status",class:_vm.status[item.status]},[_vm._v(" "+_vm._s(_vm.statusName[item.status])+" ")])]),_c('td',{attrs:{"data-label":"Ações"}},[_c('ContextMenu',{staticClass:"context-menu",attrs:{"value":item,"options":[
                ..._vm.contextMenuOptions,
                {
                  label: `Marcar como pago`,
                  icon: 'mdi-check',
                  action: 'markAsPaid',
                  disabled: ![0, 5].includes(item.status),
                },
                {
                  label: `Cancelar`,
                  icon: 'mdi-cancel text-red',
                  action: 'cancelInvoice',
                  disabled: [1, 2].includes(item.status),
                },
              ]},on:{"itemClick":_vm.onContextMenuItemClick}},[_c('button',{staticClass:"context-menu-button btn outline icon",attrs:{"md-menu-trigger":""}},[_c('i',{staticClass:"mdi mdi-dots-horizontal text-malibu font-size-1-3"})])])],1)])})},proxy:true}:null],null,true)}),(!(!_vm.loader && _vm.invoice.list.length))?_c('div',{staticClass:"d-flex direction-column ai-center mt-50"},[(!_vm.loader)?[_c('img',{staticClass:"img-empty",attrs:{"src":require('@/assets/empty_search.png'),"alt":"empty"}}),_c('h4',[_vm._v("Não há nenhuma fatura a serem exibidas.")])]:_c('Loading',{staticClass:"p-50",attrs:{"label":"Carregando"}})],2):_vm._e()],1),(!(_vm.invoice.totalPages === _vm.page) && _vm.invoice.list.length)?_c('div',{staticClass:"d-flex jc-center m-25"},[_c('SpinnerButton',{staticClass:"btn outline outline-perfume",attrs:{"loading":_vm.loaderButton},on:{"click":_vm.loaderMorePage}},[_vm._v(" Carregar mais ")])],1):_vm._e(),_c('CancelOrMarkAsPaidConfirmationModal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex ai-center w-50"},[_c('h2',{staticClass:"mr-10 font-size-1-3 text-Periwinkle"},[_vm._v("Faturas")])])
}]

export { render, staticRenderFns }