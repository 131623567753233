<template>
  <section>
    <ExpectedAudienceFields />
    <Divider class="mv-30" />

    <Protocols />
    <Divider class="mv-30" />

    <GeneralDescriptionFields />
  </section>
</template>

<script>
import Protocols from "./Protocols.vue";
import ExpectedAudienceFields from "./ExpectedAudienceFields.vue";
import GeneralDescriptionFields from "./GeneralDescriptionFields.vue";

export default {
  components: {
    Protocols,
    ExpectedAudienceFields,
    GeneralDescriptionFields,
  },
};
</script>
