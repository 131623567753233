var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-field",class:{ 'input-field--error': !!_vm.error, 'input-field--focus': _vm.focus }},[(_vm.placeholder || _vm.tip)?_c('label',{attrs:{"for":_vm.localId}},[_c('span',{style:(`opacity: ${_vm.placeholderOpacity}`)},[_vm._v(_vm._s(_vm.placeholder))]),_c('span',{staticClass:"tip"},[_vm._v(_vm._s(_vm.tip))])]):_vm._e(),_c('md-field',[_c('div',{staticClass:"wrap-input w-100"},[(_vm.beforeIcon)?_c('button',{staticClass:"before-icon",class:{ 'no-readonly': !_vm.readonly },on:{"click":function($event){return _vm.$emit('iconClick')}}},[_c('i',{staticClass:"mdi",class:_vm.beforeIcon})]):_vm._e(),(_vm.type === 'currency')?[_c('md-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.currencyConfig),expression:"currencyConfig"}],class:{
            invalid: _vm.isInvalid,
            'has-before-icon': _vm.beforeIcon,
          },attrs:{"id":_vm.localId,"type":'text',"value":_vm.value,"placeholder":_vm.label},on:{"input":function($event){return _vm.$emit('input', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)},"focus":_vm.onFocus,"blur":_vm.onBlur}})]:(_vm.hasMask)?[_c('md-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],class:{
            invalid: _vm.isInvalid,
            'has-before-icon': _vm.beforeIcon,
          },attrs:{"id":_vm.localId,"type":_vm.type,"value":_vm.value,"placeholder":_vm.label},on:{"input":function($event){return _vm.$emit('input', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)},"focus":_vm.onFocus,"blur":_vm.onBlur}})]:[_c('md-input',{staticClass:"w-100",class:{
            invalid: _vm.isInvalid,
            'has-before-icon': _vm.beforeIcon,
            'cursor-pointer': _vm.pointer,
          },attrs:{"disabled":_vm.disabled,"id":_vm.localId,"type":_vm.type,"value":_vm.value,"placeholder":_vm.label,"readonly":_vm.readonly},on:{"input":function($event){return _vm.$emit('input', $event)},"click":function($event){return _vm.$emit('click', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)},"focus":_vm.onFocus,"blur":_vm.onBlur}})]],2),(_vm.icon)?_c('button',{staticClass:"icon-button",class:{ 'no-readonly': !_vm.readonly },on:{"click":function($event){return _vm.$emit('iconClick')}}},[_c('i',{staticClass:"mdi",class:_vm.icon})]):_vm._e()]),_c('label',{staticClass:"pt-2",attrs:{"for":_vm.localId}},[_c('span',{staticClass:"tip example"},[_vm._v(_vm._s(_vm.example))])]),(_vm.error)?_c('div',{staticClass:"error-box"},[_c('div',{staticClass:"error-box__wrap"},[_c('small',{staticClass:"error-box__message"},[_vm._v(_vm._s(_vm.error))])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }