<template>
  <CustomCard class="radius-10" :title="title" theme="ebony-malibu">
    <div class="d-flex p-5">
      <div class="left-side">
        <div
          v-for="(times, k) in eventTimeToDisplay"
          :key="k"
          class="d-flex jc-space-between direction-column p-3 ml-10 mt-10 mb-0 mr-0">
          <span>
            <small>
              Início: {{ times.start | formatData }} - Término:
              {{ times.end | formatData }}
            </small>
          </span>
        </div>
      </div>
    </div>
  </CustomCard>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Horários das Demandas",
    },
    eventTime: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    formatData(data) {
      return new Date(data).toLocaleDateString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  computed: {
    eventTimeToDisplay() {
      return Array.from(new Set(this.eventTime)); // removendo duplicádos
    },
  },
};
</script>
<style lang="scss" scoped>
.left-side {
  flex: 0 1 100%;
}
</style>
