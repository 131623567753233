<template>
  <form
    action="?"
    @submit.prevent="onSubmit"
    class="p-30 h-100 d-flex direction-column jc-space-between">
    <section>
      <div class="form-group">
        <CustomInput
          beforeIcon="mdi-at"
          placeholder="Código da empresa"
          :tip="`@${form.code || '<sua-agencia>'}`"
          label="@minhagencia"
          v-model.trim="$v.form.code.$model"
          :error="errors.code" />
      </div>
      <div class="form-group">
        <CustomInput
          placeholder="Nome Fantasia"
          label="Minha Super Empresa"
          v-model.trim="$v.form.fantasyName.$model"
          :error="errors.fantasyName" />
      </div>
      <div class="form-group">
        <CustomInput
          placeholder="CNPJ"
          label="11.222.333/1000-44"
          :mask="'##.###.###/####-##'"
          v-model.trim="$v.form.doc.$model"
          :error="errors.doc" />
      </div>
      <div class="form-group">
        <CustomInput
          placeholder="Razão Social"
          label="Minha Empresa LTDA"
          v-model.trim="$v.form.companyName.$model"
          :error="errors.companyName" />
      </div>
    </section>
    <footer class="d-flex jc-end">
      <SpinnerButton
        class="btn primary medium mr-5"
        :disabled="someChange === false || $v.$invalid"
        :loading="loading">
        Salvar Alterações
      </SpinnerButton>
    </footer>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, alphaNum, minLength } from "vuelidate/lib/validators";
import { whenDirty } from "@/helpers/form/validations";
import { validateCNPJ } from "@/helpers/form/validateCNPJ";

const requiredLabel = "Campo obrigatório";

const errorMessages = {
  code: {
    required: requiredLabel,
    alphaNum: "Apenas letras e números",
    minLength: "Digite no pelo menos 2 letras",
  },
  companyName: {
    required: requiredLabel,
  },
  fantasyName: {
    required: requiredLabel,
  },
  doc: {
    required: requiredLabel,
    validDOC: "Digite um CNPJ válido",
  },
};

const makeGetErrors = (fieldValidationMeta) => (field) =>
  whenDirty(fieldValidationMeta[field], errorMessages[field])[0];

export default {
  mounted() {
    this.form = { ...this.identification };
  },
  computed: {
    ...mapGetters({
      someChange: "Profile/someChange",
      identification: "Profile/identification",
      currentState: "Profile/currentState",
    }),
    errors() {
      const getErrors = makeGetErrors(this.$v.form);

      return {
        code: getErrors("code"),
        companyName: getErrors("companyName"),
        fantasyName: getErrors("fantasyName"),
        doc: getErrors("doc"),
      };
    },
  },
  data: () => ({
    loading: false,
    form: {
      code: "",
      companyName: "",
      fantasyName: "",
      doc: "",
    },
  }),
  watch: {
    identification: {
      deep: true,
      handler(val) {
        this.form = { ...val };
      },
    },
    form: {
      deep: true,
      immediate: false,
      handler(val) {
        this.setIdentification(val);
      },
    },
    currentState(state) {
      if (state === "ready") {
        this.$v.$reset();
      }
    },
  },
  validations: {
    form: {
      code: {
        required,
        alphaNum,
        minLength: minLength(2),
      },
      companyName: {
        required,
      },
      fantasyName: {
        required,
      },
      doc: {
        required,
        validDOC: validateCNPJ,
      },
    },
  },
  methods: {
    ...mapActions({
      setIdentification: "Profile/setIdentification",
      save: "Profile/save",
    }),
    onSubmit() {
      this.loading = true;
      const onSuccess = () =>
        this.notify("Suas informações foram salvas!", "success");
      const onError = ({
        response: {
          data: { errorMessage },
        },
      }) => this.notify(errorMessage);

      this.save()
        .then(onSuccess)
        .catch(onError)
        .finally(() => (this.loading = false));
    },
    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", {
        type,
        msg,
      });
    },
  },
};
</script>
