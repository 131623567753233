<template>
  <div class="loading d-flex ai-center jc-center">
    <div class="wrapper">
      <Spinner :size="size" />
    </div>
    <span class="ml-10" v-if="label">
      {{ label }}
      <b class="elipses">...</b>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss">
.loading {
  animation: 0.6s ease-in 0.6s infinite alternate pulse;

  .wrapper {
    max-height: 40px;
    position: relative;
    max-width: 40px;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}
</style>
