<template>
  <div class="tab-item" :tabid="tabId">
    <md-content class="md-scrollbar h-100">
      <slot></slot>
    </md-content>
  </div>
</template>

<script>
export default {
  props: {
    tabId: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.tab-item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  opacity: 0;

  .md-content {
    overflow: auto;
    background: inherit;
  }

  &.active {
    z-index: 1;
    opacity: 1;
  }
}
</style>
