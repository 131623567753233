<template>
  <div
    ref="tableWrapper"
    @mousedown="mousedown"
    @mouseup="mouseup"
    @mouseleave="mouseleave"
    @mousemove="mousemove"
    class="table-wrapper md-scrollbar">
    <table class="mt-25">
      <thead>
        <tr>
          <th class="fixed-column"></th>
          <th class="fixed-column">
            <small>OS</small>
          </th>
          <th class="fixed-column"><small>Nome do evento</small></th>
          <th v-if="columns.local.checked">
            <small>Local</small>
          </th>
          <th v-if="columns.hirer.checked">
            <small>Cliente</small>
          </th>
          <th v-if="columns.eventType.checked"><small>Tipo</small></th>
          <th v-if="columns.startDate.checked"><small>Início</small></th>
          <th v-if="columns.endDate.checked"><small>Fim</small></th>
          <th v-if="columns.daysQuantity.checked"><small>Qtd. Dias</small></th>
          <th v-if="columns.functionsQuantity.checked">
            <small>Qtd. Funções</small>
          </th>
          <th v-if="columns.planning.checked"><small>Planejados</small></th>
          <th v-if="columns.accepted.checked"><small>Aceitos</small></th>
          <th v-if="columns.pending.checked"><small>Pendentes</small></th>
          <th v-if="columns.waiting.checked"><small>Realizados</small></th>
          <th v-if="columns.invited.checked"><small>Convidados</small></th>
          <th v-if="columns.paid.checked"><small>Pagos</small></th>
          <th v-if="columns.candidated.checked"><small>Candidatados</small></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(event, index) in events" :key="index">
          <td class="fixed-column menu">
            <ContextMenu
              class="context-menu p-absolute align-menu"
              :options="contextMenuOptions"
              :right="true"
              :top="true"
              @itemClick="onContextMenuItemClick">
              <button
                class="context-menu-button btn outline icon"
                md-menu-trigger>
                <i
                  class="mdi mdi-dots-horizontal text-malibu font-size-1-3"></i>
              </button>
            </ContextMenu>
          </td>
          <td class="fixed-column">
            {{ event.serviceOrderNumber }}
          </td>
          <td
            class="large-column fixed-column cursor-pointer"
            @click="onEventClick($event, event)">
            {{ eventName(event) }}
          </td>
          <td class="large-column" v-if="columns.local.checked">
            {{ eventAddress(event) }}
          </td>
          <td v-if="columns.hirer.checked">{{ clientName(event) }}</td>
          <td v-if="columns.eventType.checked">{{ eventType(event) }}</td>
          <td v-if="columns.startDate.checked">
            {{ event.startEventDay | date }}
          </td>
          <td v-if="columns.endDate.checked">{{ event.endEventDay | date }}</td>
          <td v-if="columns.daysQuantity.checked">
            {{ event.totalEventDays }}
          </td>
          <td v-if="columns.functionsQuantity.checked">
            {{ event.totalEventServices }}
          </td>
          <td v-if="columns.planning.checked">{{ event.totalPlanned }}</td>
          <td v-if="columns.accepted.checked">{{ event.totalReadyToWork }}</td>
          <td v-if="columns.pending.checked">{{ totalPending(event) }}</td>
          <td v-if="columns.waiting.checked">{{ event.totalWorked }}</td>
          <td v-if="columns.invited.checked">
            {{ event.totalWaitFreelaAcceptInvite }}
          </td>
          <td v-if="columns.paid.checked">{{ event.totalPaid }}</td>
          <td v-if="columns.candidated.checked">
            {{ event.totalWaitAgencyHirerApprove }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAvailableEventTypes } from "@/services/events/event.http";

export default {
  props: {
    states: {
      type: Object,
      default: () => {},
    },
    currentState: {
      type: String,
      default: () => "",
    },
  },

  filters: {
    date(val) {
      return new Date(val.replace("Z", "")).toLocaleDateString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },

  data() {
    return {
      eventTypes: [],
      isDown: false,
      startX: null,
      scrollLeft: null,
    };
  },

  mounted() {
    getAvailableEventTypes().then((types) => {
      this.eventTypes = types;
    });
  },

  methods: {
    ...mapActions({
      getEventDetail: "EditEvent/getEventDetail",
      setMenuColumns: "ListEvent/setMenuColumns",
    }),

    mousemove(e) {
      const slider = this.$refs.tableWrapper;
      if (!this.isDown) return;
      if (e.pageX > 600) {
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - this.startX) * 3;
        slider.scrollLeft = this.scrollLeft - walk;
      }
    },

    mousedown(e) {
      const slider = this.$refs.tableWrapper;
      this.isDown = true;
      this.startX = e.pageX - slider.offsetLeft;
      this.scrollLeft = slider.scrollLeft;
    },

    mouseleave() {
      this.isDown = false;
    },

    mouseup() {
      this.isDown = false;
    },

    clientName(event) {
      const { hirerName, agencyName } = event;
      return hirerName ? hirerName : agencyName;
    },

    eventType(event) {
      const { eventTypeNew } = event;
      let name;

      if (this.eventTypes) {
        name = this.eventTypes.find((type) => type.value == eventTypeNew)?.name;
      }
      return name ? name : "Sem tipo";
    },

    totalPending(event) {
      const { totalPlanned, totalReadyToWork, totalWorked, totalPaid } = event;
      return totalPlanned - (totalReadyToWork + totalWorked + totalPaid);
    },

    eventName({ name }) {
      return name.length > 20 ? `${name.substring(0, 20)}...` : name;
    },

    eventAddress({ address }) {
      if (!address) {
        return "Sem endereço";
      }
      return address.length > 20 ? `${address.substring(0, 20)}...` : address;
    },

    onContextMenuItemClick({ action }) {
      const actions = {
        edit: this.edit,
        duplicate: this.duplicate,
      };

      actions[action]();
    },

    edit() {
      this.$router.push({
        name: "events.edit",
        params: { id: this.event.id, step: 1 },
      });
    },

    duplicate() {
      try {
        this.getEventDetail(this.event.id);
      } catch (err) {
        // console.error(err);
      } finally {
        this.$eventhub.$emit("modal:open:replication", { event: this.event });
      }
    },

    onChange(node, checked) {
      this.setMenuColumns({ node, checked });
    },

    onEventClick($event, event) {
      this.$router.push({ name: "events.info", params: { id: event.id } });
    },
  },

  computed: {
    ...mapGetters({
      events: "ListEvent/events",
      columns: "ListEvent/menuColumns",
    }),
    contextMenuOptions() {
      return [
        {
          label: `Editar`,
          icon: "mdi-pencil",
          action: "edit",
          disabled: false,
        },
        {
          label: `Duplicar`,
          icon: "mdi-content-copy",
          action: "duplicate",
          disabled: false,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.table-wrapper {
  overflow: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar-thumb {
    background: $EastBay;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
}

table {
  background-color: $SteelGray;
  width: 100%;
  border-collapse: collapse;
  & .large-column {
    min-width: 300px;
  }

  tr {
    border-bottom: 1px solid #24203b;
  }

  th {
    position: sticky;
    text-align: start;
    background-color: #24203b;
    z-index: 2;
    min-width: 150px;
    padding: 20px 2rem;

    &.fixed-column {
      position: sticky;
      left: 0;
      z-index: 3;

      &:nth-child(2) {
        left: 150px;
      }

      &:nth-child(3) {
        left: 300px;
      }
    }
  }

  td {
    background-color: #1e1a35;
    padding: 20px 2rem;
    white-space: nowrap;
    z-index: 2;

    &:not(.fixed-column) {
      cursor: grabbing;
    }

    &.fixed-column {
      position: sticky;
      left: 0;
      z-index: 3;

      &:nth-child(2) {
        left: 150px;
      }

      &:nth-child(3) {
        left: 300px;
      }
    }
  }
}

.menu {
  z-index: 15 !important;
}

.align-menu {
  left: 0;
}

.dropdownMenu {
  right: 0 !important;
}
</style>
