<template>
  <div
    class="input-field"
    :class="{ 'input-field--error': !!error, 'input-field--focus': focus }">
    <label :for="localId" v-if="placeholder || tip">
      <span :style="`opacity: ${placeholderOpacity}`">{{ placeholder }}</span>
      <span class="tip">{{ tip }}</span>
    </label>

    <md-field>
      <div class="wrap-input w-100">
        <button
          class="before-icon"
          :class="{ 'no-readonly': !readonly }"
          v-if="beforeIcon"
          @click="$emit('iconClick')">
          <i class="mdi" :class="beforeIcon"></i>
        </button>

        <template v-if="type === 'currency'">
          <md-input
            :class="{
              invalid: isInvalid,
              'has-before-icon': beforeIcon,
            }"
            :id="localId"
            :type="'text'"
            :value="value"
            @input="$emit('input', $event)"
            @keypress="$emit('keypress', $event)"
            @keydown="$emit('keydown', $event)"
            @focus="onFocus"
            @blur="onBlur"
            v-money="currencyConfig"
            :placeholder="label"></md-input>
        </template>

        <template v-else-if="hasMask">
          <md-input
            :class="{
              invalid: isInvalid,
              'has-before-icon': beforeIcon,
            }"
            v-mask="mask"
            :id="localId"
            :type="type"
            :value="value"
            @input="$emit('input', $event)"
            @keypress="$emit('keypress', $event)"
            @keydown="$emit('keydown', $event)"
            @focus="onFocus"
            @blur="onBlur"
            :placeholder="label"></md-input>
        </template>

        <template v-else>
          <md-input
            :disabled="disabled"
            class="w-100"
            :class="{
              invalid: isInvalid,
              'has-before-icon': beforeIcon,
              'cursor-pointer': pointer,
            }"
            :id="localId"
            :type="type"
            :value="value"
            @input="$emit('input', $event)"
            @click="$emit('click', $event)"
            @keypress="$emit('keypress', $event)"
            @keydown="$emit('keydown', $event)"
            @focus="onFocus"
            @blur="onBlur"
            :placeholder="label"
            :readonly="readonly" />
        </template>
      </div>
      <button
        class="icon-button"
        :class="{ 'no-readonly': !readonly }"
        v-if="icon"
        @click="$emit('iconClick')">
        <i class="mdi" :class="icon"></i>
      </button>
    </md-field>
    <label :for="localId" class="pt-2">
      <span class="tip example">{{ example }}</span>
    </label>
    <div v-if="error" class="error-box">
      <div class="error-box__wrap">
        <small class="error-box__message">{{ error }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import randomId from "@/helpers/randomId";

export default {
  props: {
    beforeIcon: { default: "" },
    tip: { default: "" },
    error: { default: "" },
    isInvalid: { default: false },
    value: { default: "" },
    example: { default: "" },
    placeholder: { default: "" },
    placeholderOpacity: { default: 1 },
    label: { default: "" },
    icon: { default: "" },
    type: { default: "text" },
    mask: { default: false },
    disabled: { default: false },
    readonly: { default: false },
    pointer: { default: false },
    id: { default: null },
  },
  data: () => ({
    rid: randomId(),
    focus: false,
    currencyConfig: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
  }),
  computed: {
    hasMask() {
      return this.mask;
    },
    localId() {
      return this.id ? this.id : this.rid;
    },
  },
  methods: {
    onFocus($event) {
      this.focus = true;
      this.$emit("focus", $event);
    },
    onBlur($event) {
      this.focus = false;
      this.$emit("blur", $event);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.input-field {
  .md-input {
    background: transparent;
    border-radius: 4px;
    border: 2px groove white;
    height: 50px;
    padding: 5px 10px;
    padding-right: 8%;
    transition: border 0.25s;
    &:disabled {
      background-color: rgba($DimGray, 0.1);
      border-color: rgba(white, 0.5);
      cursor: not-allowed;
    }
  }
  .md-field.md-theme-default:after {
    background-color: transparent;
  }
  .md-field.md-theme-default:before {
    background-color: transparent;
  }
  .md-field .md-input-action {
    top: 0px;
  }
}

.md-field:after {
  position: relative;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin: 0px 5px 0px;
  position: relative;

  .error-box {
    bottom: 100%;
    margin-bottom: -5px;
    position: absolute;
    transition: all 0.3s ease;
    right: 0;
    opacity: 0.3;

    .error-box__wrap {
      transition: all 0.3s ease;
      border-radius: 5px;
      position: relative;
      text-align: right;

      &::after {
        transition: all 0.3s ease;
        content: "";
        background: $Red;
        border-radius: 50%;
        height: 10px;
        position: absolute;
        right: 10px;
        top: 100%;
        width: 10px;
      }

      .error-box__message {
        transition: all 0.3s ease;
        height: 1px;
        opacity: 0;
        overflow: hidden;
        padding: 10px;
        width: 1px;
      }
    }
  }

  label {
    margin: 0px 0px 2px;
    font-size: 12px;
    font-weight: 300;

    .tip {
      color: $Manatee;
      font-style: italic;
      font-weight: 200;
      margin-left: 15px;

      &.example {
        margin: 0px;
      }
    }
  }
  .wrap-input {
    position: relative;

    .before-icon {
      cursor: inherit;
      position: absolute;
      color: white;
      top: 2px;
      left: 3px;
      height: 45px;
      width: 45px;
      font-size: 1.6rem;

      &:hover {
        background: rgba(white, 0.1);
      }

      &:active {
        background: rgba(white, 0.1);
      }
    }

    input.has-before-icon {
      padding-left: 48px;
    }
  }

  &.input-field--error {
    input {
      border-color: $Red;
    }

    &.input-field--focus,
    &:hover {
      .error-box {
        opacity: 0.5;

        .error-box__wrap {
          background: $Red;

          &::after {
            content: "";
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid $Red;
            background: initial;
            border-radius: 0;
            height: 0;
            position: absolute;
            width: 0;
          }

          .error-box__message {
            height: auto;
            opacity: 1;
            width: auto;
          }
        }
      }
    }
    &.input-field--focus {
      .error-box {
        opacity: 1;
      }
    }
  }

  .md-field.md-has-password .md-toggle-password {
    margin: 0px;
    position: absolute;
    right: 5px;
    bottom: -2px;
    top: 5px;
    width: 40px;
    height: 40px;
  }
  .md-field {
    margin: 0px 0 0px;
    padding-top: 0px;
  }
  .invalid {
    border-color: $Red !important;
  }

  .icon-button {
    align-items: center;
    bottom: 0px;
    color: white;
    display: flex;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;

    &.no-readonly {
      &:hover,
      &:active,
      &:focus {
        background: transparentize(white, 0.9);
        cursor: pointer;
      }
    }
  }
}
</style>
