<template>
  <div class="image-box d-flex jc-sm-center">
    <label class="image-area">
      <div class="image-wrap">
        <div class="icon-square">
          <i class="mdi mdi-image-outline"></i>
        </div>
        <span>Adicionar Imagem</span>
        <img v-if="currentPreview" :src="currentPreview" class="img-loaded" />
        <input type="file" class="file" accept="image/*" ref="inputFile" />
      </div>
    </label>
  </div>
</template>

<script>
import { toBase64 } from "@/helpers/image";

export default {
  props: {
    preview: String,
  },

  mounted() {
    this.$refs.inputFile.addEventListener("change", this.onChange);
  },

  data: () => ({
    localPreview: undefined,
  }),

  computed: {
    currentPreview() {
      return this.localPreview || this.preview;
    },
  },

  methods: {
    onChange(event) {
      const [file] = event.target.files;

      toBase64(file).then((imageBase64) => {
        this.$eventhub.$emit("modal:open:ModalCropper", {
          image: imageBase64,
          cropImage: this.cropImage,
          closeModal: this.closeModal,
        });
      });
    },

    cropImage(image) {
      this.localPreview = image;
      this.$emit("onChange", { imageBase64: image });
      this.$eventhub.$emit("modal:close:ModalCropper");
    },

    closeModal() {
      this.$refs.inputFile.value = "";
      this.$eventhub.$emit("modal:close:ModalCropper");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.image-box {
  .image-area {
    background: $Ebony;
    color: $FuchsiaBlue;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 250px;
    width: 250px;

    .image-wrap {
      align-items: center;
      border: dashed 1px $DimGray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 90%;
      margin: auto;
      overflow: hidden;
      position: relative;
      width: 90%;

      .img-loaded {
        position: absolute;
        width: 100%;
      }

      .icon-square {
        align-items: center;
        background: $Ebony;
        border-radius: 5px;
        color: white;
        display: flex;
        font-size: 30px;
        height: 40px;
        justify-content: center;
        text-align: center;
        width: 40px;
        position: relative;

        ::after {
          content: "+";
          background: $LilacBush;
          border-radius: 20px;
          width: 15px;
          height: 15px;
          font-size: 15px;
          position: absolute;
          top: 2px;
          right: 2px;
          display: flex;
          font-style: normal;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .file {
      bottom: 0;
      cursor: pointer;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}
</style>
