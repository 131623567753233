<template>
  <div class="d-flex ai-center p-relative">
    <span v-if="filtered !== 0" class="filtered">
      <i class="mdi mdi-circle-medium font-size-1-8 text-red"></i>
    </span>
    <div @click="openModal" class="filter d-flex ai-center">
      <i class="mdi mdi-filter-variant font-size-1-4 text-malibu"></i>
    </div>
    <Modal size="larger" name="filter-invoice" title="Filtrar" ref="modal">
      <template>
        <div class="d-flex m-15 mt-30 mb-30">
          <SearchHirer
            class="w-50"
            @selected="onHirerSelected"
            :value="filterInvoice.name" />
          <MultiSelect
            class="w-50"
            placeholder="Serviços"
            :options="options"
            v-model="status"></MultiSelect>
        </div>
        <div class="d-flex m-20">
          <custom-date-range
            label="Data de criação"
            class="mr-5 w-50"
            @input="setFiltersInvoice({ creationDate: $event })"
            :value="filterInvoice.creationDate" />
          <custom-date-range
            label="Data de vencimento"
            class="ml-5 w-50"
            @input="setFiltersInvoice({ expirationDate: $event })"
            :value="filterInvoice.expirationDate" />
        </div>
      </template>

      <template #footer>
        <div class="d-flex f-1 jc-end ai-end wrapped footer p-15">
          <button class="btn secondary mr-5" @click="clearFilter">
            Limpar filtro
          </button>
          <button class="btn outline" @click="close">Cancelar</button>
          <SpinnerButton
            :loading="loaderButton"
            class="btn primary ml-15"
            @click="onfilter">
            Aplicar filtro
          </SpinnerButton>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import SearchHirer from "./SearchHirer.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SearchHirer,
  },
  props: ["agencyId"],
  data: () => ({
    options: [
      { label: "Aguardando", value: "AwaitPayment" },
      { label: "Atrasado", value: "Expired" },
      { label: "Cancelado", value: "CanceledByUser" },
      {
        label: "Cancelado por problema na fatura",
        value: "CanceledByProblemOnInvoiceCreation",
      },
      { label: "Marcado como pago", value: "MarkedAsPaid" },
      { label: "Pago", value: "Paid" },
    ],
    loaderButton: false,
  }),
  computed: {
    ...mapGetters({
      filterInvoice: "Payment/filterInvoice",
    }),

    status: {
      get() {
        return this.filterInvoice.status;
      },
      set(value) {
        this.setFiltersInvoice({ status: value });
      },
    },

    filtered() {
      return Object.values(this.filterInvoice).filter(
        (key) =>
          key !== null &&
          key !== "" &&
          key !== this.filterInvoice.name &&
          key.length !== 0
      ).length;
    },
  },
  methods: {
    ...mapActions({
      getListInvoices: "Payment/getListInvoices",
      resetListInvoices: "Payment/resetListInvoices",
      setFiltersInvoice: "Payment/setFiltersInvoice",
      clearFilterInvoice: "Payment/clearFilterInvoice",
    }),
    openModal() {
      this.$eventhub.$emit("modal:open:filter-invoice");
    },
    close() {
      this.$refs.modal.close();
    },
    onHirerSelected({ cnpj, name }) {
      this.setFiltersInvoice({ cnpj, name });
    },
    onfilter() {
      this.resetListInvoices();
      this.loaderButton = true;
      this.getListInvoices({
        id: this.agencyId,
        page: 1,
        loader: true,
        filters: this.filterInvoice,
      })
        .catch((error) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${error}`,
          });
        })
        .finally(() => {
          this.loaderButton = false;
          this.$refs.modal.close();
        });
    },
    clearFilter() {
      this.clearFilterInvoice();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.filter {
  border: 2px solid $Malibu;
  border-radius: 10px;
  padding: 25%;
  height: 100%;
  cursor: pointer;
}

.filtered {
  position: absolute;
  top: -6px;
  left: 21px;
  color: $BrightSun;
}
</style>
