<template>
  <CustomCard class="radius-10" :title="title" theme="ebony-malibu">
    <div class="content d-flex ml-15">
      <!-- <div class="left-side">
        <div class="d-flex jc-space-between p-5 ml-10 mt-10 mb-0 mr-0">
          <div class="d-flex direction-column">
            <md-avatar class="md-avatar-icon md-large">
              <img src="@/assets/user.png" alt="Avatar" />
            </md-avatar>
          </div>
        </div>
      </div> -->
      <div class="right-side d-flex ai-start direction-column">
        <!-- <div class="d-flex direction-column">
          <span class="d-flex f-100">{{organizerName}}</span>
          <span class="d-flex f-100 jc-start">
            <i class="material-icons">star</i>
            <span class="ml-5">{{score}}</span>
          </span>
        </div> -->
        <div class="contato d-flex direction-column mb-5">
          <span class="mb-5">Contato:</span>
          <span>Nome: {{ contactInfo.contactName }}</span>
          <span>Telefone: {{ contactInfo.phone }}</span>
          <span>E-mail: {{ contactInfo.email }}</span>
        </div>
      </div>
    </div>
  </CustomCard>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Organizador",
    },
    contactInfo: {
      type: Object,
      default: () => ({}),
    },
    score: {
      type: String,
      default: "4.29",
    },
    organizerName: {
      type: String,
      default: "Claudia Maria de Cerqueira César",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.left-side {
  flex: 0 1 10%;
}

.right-side {
  flex: 0 1 100%;
  .material-icons {
    font-size: 18px;
    padding-top: 2px;
  }

  .contato {
    margin-top: 20px;
  }

  .right-item {
    margin: 10px 0 0 20px;
    display: flex;

    img {
      margin: 0 auto;
      border-radius: 50%;
      height: 60px;
      width: 60px;
    }
  }
}
</style>
