<template>
  <div class="status-details">
    <div class="d-flex jc-center wrapped">
      <span
        class="d-flex total-status direction-column pl-5 mr-15"
        v-for="status in vacanciesStatus"
        :key="status.id"
        :class="status.class">
        <small>{{ status.label }}</small>
        <hr class="strip mt-1 border" />
        <span>{{ status.quantities }}</span>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "StatusDetails",
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters({
      slots: "EventDetails/currentSlots",
      currentService: "EventDetails/currentService",
    }),
    vacanciesStatus() {
      const trimZeros = (str) => str?.toString().padStart(2, "0");

      const pending =
        this.currentService.totalVacancy -
        this.slots.filter(({ status }) => {
          return [2, 4, 5].includes(status);
        }).length;

      const planning = this.currentService.totalVacancy;

      const accepted = this.slots.filter(({ status }) => status === 2).length;

      const waiting = this.slots.filter(({ status }) => status === 8).length;

      const candidated = this.slots.filter(({ status }) => status === 3).length;

      const performed = this.slots.filter(({ status }) => status === 4).length;

      const paid = this.slots.filter(({ status }) => status === 5).length;

      return [
        {
          id: 1,
          label: "Planejado",
          quantities: trimZeros(planning),
          class: "planning",
        },
        {
          id: 2,
          label: "Aceito",
          quantities: trimZeros(accepted),
          class: "accepted",
        },
        {
          id: 3,
          label: "Pendente",
          quantities: trimZeros(pending),
          class: "pending",
        },
        {
          id: 4,
          label: "Aguardando",
          quantities: trimZeros(waiting),
          class: "waiting",
        },
        {
          id: 5,
          label: "Candidatado",
          quantities: trimZeros(candidated),
          class: "candidated",
        },
        {
          id: 6,
          label: "Realizado",
          quantities: trimZeros(performed),
          class: "performed",
        },
        {
          id: 7,
          label: "Pago",
          quantities: trimZeros(paid),
          class: "paid",
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.status-details {
  margin: auto;
  color: white;

  > div {
    max-width: 460px;
  }
}

.strip {
  min-width: 90px;
}

.total-status {
  flex-grow: 1;
  max-width: 100px;
  border-radius: 10px;

  small {
    font-size: 0.7rem;
    font-weight: 200;
  }

  span {
    font-size: 1.2rem;
    font-weight: 500;
  }

  @mixin incident-title-style($color) {
    color: $color;

    small {
      color: white;
      text-align: center;
      text-transform: uppercase;
    }

    hr {
      background: $color;
      border: 1px solid $color;
      height: 3px;
    }

    span {
      text-align: center;
      font-size: 1.6rem;
    }

    &.disabled {
      color: $Martinique;

      small {
        color: rgba(white, 0.5);
      }

      hr {
        background: $Martinique;
        border-color: $Martinique;
      }
    }
  }

  &.planning {
    @include incident-title-style(white);
  }
  &.accepted {
    @include incident-title-style($LightGreen);
  }
  &.pending {
    @include incident-title-style($Red);
  }
  &.waiting {
    @include incident-title-style($BrightSun);
  }
  &.candidated {
    @include incident-title-style($RoyalBlue);
  }
  &.performed {
    @include incident-title-style($AzureRadiance);
  }
  &.paid {
    @include incident-title-style($LilacBush);
  }
}
</style>
