import axios from "axios";
import { ifElse } from "ramda";
import qs from "qs";

const URL_SESSION = "/payments/sessions";
const URL_GET_TOKEN = "/payments/creditcards";

const performError = (e) => {
  const error = e.response ? { message: e.response.data.errorMessage } : e;
  throw error;
};

const isSuccess = ({ data: { isSuccess } }) => isSuccess;
const returnResult = ({ data: { result } }) => result;

const validateReturn = ifElse(isSuccess, returnResult, performError);

export const getSession = () => axios.get(URL_SESSION).then(validateReturn);

const _getCardToken = (data) =>
  axios.post(URL_GET_TOKEN, data).then(validateReturn).catch(performError);

export const getCardToken = (card) =>
  getSession()
    .then((sessionId) => _getCardToken({ ...card, sessionId }))
    .catch(performError);

export const paymentBatch = (data) =>
  axios.post("/payments/batch", data).then(validateReturn).catch(performError);

export const getPaymentInformation = (id) =>
  axios.get(`/payments/batch/${id}`).then(validateReturn).catch(performError);

export const validateCoupon = (name) => {
  return axios
    .get(`/payments/coupon/validate?name=${name}`)
    .then(validateReturn)
    .catch(performError);
};

export const getListBatch = ({ id, page }) =>
  axios
    .get(`/payments/event/${id}/batchs`, { params: { page, pageSize: 10 } })
    .then(validateReturn)
    .catch(performError);

export const getAllBatches = ({ page }) =>
  axios
    .get(`/payments/batches`, { params: { page, pageSize: 10 } })
    .then(validateReturn)
    .catch(performError);

export const createInvoice = (data) =>
  axios.post("/payments/events", data).then(validateReturn).catch(performError);

export const getDetailInvoice = (id) =>
  axios.get(`/payments/events/${id}`).then(validateReturn).catch(performError);

export const getListInvoice = ({
  id,
  page,
  document,
  status,
  creationDateStart,
  creationDateEnd,
  expireDateStart,
  expireDateEnd,
}) =>
  axios
    .get(`/payments/events/agency/${id}`, {
      params: {
        page,
        pageSize: 10,
        document,
        status,
        creationDateStart,
        creationDateEnd,
        expireDateStart,
        expireDateEnd,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
    })
    .then(validateReturn)
    .catch(performError);

export const getDetailsBatch = ({ id }) =>
  axios
    .get(`/payments/batch/${id}/detail`)
    .then(validateReturn)
    .catch(performError);

export const cancelBatch = (id) =>
  axios
    .delete(`/payments/batch/${id}`)
    .then(validateReturn)
    .catch(performError);

export const cancelInvoice = (id) =>
  axios
    .delete(`/payments/events/${id}`)
    .then(validateReturn)
    .catch(performError);

export const markAsPaidInvoice = (id) =>
  axios
    .put(`/payments/events/${id}/mark-as-paid`)
    .then(validateReturn)
    .catch(performError);

// export default {
//   getSession,
//   getCardToken,
//   paymentBatch,
// };
