<template>
  <section v-if="!isAgency">
    <h3 class="title">Quantidade de profissionais</h3>

    <CustomSelect
      class="md-layout-item max-w-350"
      placeholder="Preciso para minha demanda"
      id="type"
      v-model="totalFreelas"
      :disabled="hasAgency">
      <option
        v-for="({ value, label }, key) in options"
        :key="key"
        :selected="value == totalFreelas"
        :value="value">
        {{ label }}
      </option>
    </CustomSelect>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { isAgency } from "@/helpers/auth";

export default {
  data: () => ({
    hasAgency: false,
  }),
  computed: {
    ...mapGetters({
      isAgency: "User/isAgency",
    }),
    totalFreelas: {
      get() {
        return this.$store.getters["NewEvent/form"].totalFreelas;
      },
      set(totalFreelas) {
        this.$store.dispatch("NewEvent/setFormData", { totalFreelas });
      },
    },
    options() {
      const upToFiveFreelas = {
        value: "5",
        label: "Contrate até 5 rapidamente",
      };

      const moreThenfiveFreelas = {
        value: "+5",
        label: "Contratar prestadores de serviços",
      };

      return isAgency()
        ? [moreThenfiveFreelas]
        : [moreThenfiveFreelas, upToFiveFreelas];
    },
  },
  mounted() {
    this.totalFreelas = isAgency() ? "+5" : "5";
    this.hasAgency = isAgency();
  },
};
</script>
