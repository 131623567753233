<template>
  <div class="overflow-hidden h-100 f-1">
    <md-content class="md-scrollbar h-100 timeline">
      <TimelineItem
        :key="key"
        v-for="(activity, key) in activities"
        :activity="activity" />
    </md-content>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimelineItem from "@/components/shared/TimelineItem";

export default {
  components: { TimelineItem },
  computed: mapGetters({
    activities: "Dashboard/activities",
  }),
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.timeline {
  // height: 50%;

  hr {
    border-color: $Malibu;
    background: $Malibu;
  }

  &.md-scrollbar.md-theme-default {
    background: transparent;
    height: auto;
    overflow: auto;

    &::-webkit-scrollbar-track {
      background: $Ebony;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: $EastBay;
    }
  }
}
</style>
