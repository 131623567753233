/* istanbul ignore file */
/**
 * Automatically imports all the modules and exports as a single module object
 * ref: https://dev.to/localeai/architecting-vuex-store-for-large-scale-vue-js-applications-4f1f
 */

// pegando todos os arquivos .store
const requireModule = require.context(".", false, /\.store\.js$/);
const modules = {};

requireModule.keys().forEach((filename) => {
  // criando o padrão de modulos (e.g. 'user.store.js' => 'USER')
  const moduleName = filename
    .replace(/(\.\/|\.store\.js)/g, "")
    .replace(/^[a-zA-Z]/, (c) => c.toUpperCase())
    .replace(/-[a-zA-Z]/, (c) => c.toUpperCase())
    .replace(/-/, "");

  // adicionando no modulo global
  modules[moduleName] =
    requireModule(filename).default || requireModule(filename);
});

export default modules;
