<template>
  <div class="custom-card" :class="theme">
    <slot name="header">
      <template v-if="title">
        <header class="p-15 ai-center jc-space-between header">
          <slot name="headerLeft"></slot>
          <h6>{{ title }}</h6>
          <slot name="headerRight"></slot>
          <div class="d-flex">
            <div
              v-if="button"
              @click="$emit('buttonClick')"
              class="button-icon p-10 pl-25 pr-25 d-flex ai-center radius-5">
              <img
                class="icon"
                :src="require('@/assets/icon-qr-code-scan.svg')" />
              <span class="pl-10">{{ buttonTitle }}</span>
            </div>
            <div
              v-if="secondButton"
              @click="$emit('secondButtonClick')"
              class="button-icon p-10 pl-25 pr-25 d-flex ai-center radius-5 ml-10 mr-10">
              <img
                class="icon"
                :src="require('@/assets/file-document-outline.svg')" />
              <span class="pl-10">{{ secondButtonTitle }}</span>
            </div>
            <div v-if="thirdButton">
              <button
                class="btn outline w-100 ml-10 font-weight-300"
                @click="$emit('thirdButtonClick')">
                <img
                  class="icon-scale"
                  :src="require('@/assets/outline-list.png')" />
                <span class="pl-10">{{ thirdButtonTitle }}</span>
              </button>
            </div>
          </div>
        </header>
        <Divider v-if="divider" :theme="dividerTheme" />
      </template>
    </slot>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "steel-gray",
    },
    divider: {
      type: Boolean,
      default: true,
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    secondButton: {
      type: Boolean,
      default: false,
    },
    secondButtonTitle: {
      type: String,
      default: "",
    },
    thirdButton: {
      type: Boolean,
      default: false,
    },
    thirdButtonTitle: {
      type: String,
      default: "",
    },
  },
  computed: {
    dividerTheme() {
      const themeMap = {
        "steel-gray": "ebony",
        "ebony-malibu": "steel-gray",
        ebony: "steel-gray",
      };

      return themeMap[this.theme] || undefined;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

@media (max-width: 991px) {
  .button-icon {
    width: 18em;
    margin: 0.5em 0em 0em 0.5em;
  }
}

@media (min-width: 992px) {
  .header {
    display: flex;
  }
}

.custom-card {
  // border-radius: 10px;
  height: auto;
  min-width: 200px;

  > header {
    min-height: 55px;
    letter-spacing: -0.05rem;

    h6 {
      margin: 0;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  &.steel-gray {
    background: $SteelGray;
  }

  &.ebony {
    background: $Ebony;
  }

  &.ebony-malibu {
    background: $Ebony;

    > header {
      h6 {
        color: $Malibu;
      }
    }
  }
}

.icon {
  width: 1.5em;
  height: 1.5em;
}

.icon-scale {
  width: 1.8em;
  height: 1.8em;
}

.button-icon {
  background-color: $FuchsiaBlue;

  &.outline {
    background: none;
    border-color: white;
    color: white;
    border: 1px solid white;
    padding: 10px 60px 10px;

    &:hover {
      background-color: rgba(67, 66, 66, 0.153);
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba($color: $FuchsiaBlue, $alpha: 0.5);
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
