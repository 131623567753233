import { formatDate, dateToString } from "@/helpers/dateFormat";
import eventhub from "@/eventhub";
import { vm } from "@/main";
import { debounce, orderBy } from "lodash";
import {
  getEventDetails,
  getJobMembers,
  publishVacancy,
  getFreelasLocation,
  getEventLocation,
  sendInvite,
  cancelInvite,
  approveInvite,
  addNewBudget,
  refuseBudget,
  acceptBudget,
  getReport,
  getServiceReport,
  getEventServices,
} from "@/services/events/event.http";
import {
  setGroupName,
  onNewCheckpoint,
  getConnection,
  getActivitiesReport,
  scheduleManagement,
} from "@/services/operation/operation.http";
import { validateCoupon, paymentBatch } from "@/services/payment/payment.http";
import { flatten, pluck } from "ramda";

const feedback = ({ message }) =>
  eventhub.$emit("feedback:show", { type: "error", msg: message });

const initialState = () => ({
  connection: "",
  operations: [],
  basicInfo: {
    eventId: "",
    name: "",
    hirerId: "",
    type: "",
    topic: "",
    image: "",
    startDate: "",
    endDate: "",
    schedule: [],
    address: "",
    addressId: "",
    locationName: "",
    contactName: "",
    phone: "",
    email: "",
    destination: { latLng: { lat: 0, lng: 0 } },
    isManagementByAgency: false,
    hasServiceManagementByAgency: false,
  },
  detailsInfo: {
    expectedAudienceNumber: "",
    description: "",
    eventProfileTypes: [],
  },
  services: [],
  eventProfileTypes: [],
  ratings: {},
  currentSort: {
    field: "description",
    direction: "asc",
  },
  currentFilters: {
    day: "",
    status: "",
    type: "",
    editedByManager: false,
  },
  currentService: {
    id: "",
    serviceId: "",
    slots: [],
    actualTeamLocalization: [{ latLng: { lat: 0, lng: 0 } }],
    selectedMember: {},
  },
  eventServiceList: {
    empty: true,
    checked: false,
    children: [],
  },
  filterEventService: {
    cnpj: null,
    services: null,
    shift: null,
  },
  loading: false,
  coupon: "",
  responseIsValid: "",
  freelasToPay: [],
});

const state = initialState();

const getTotalVacancy = (services, id) => {
  return services.filter(
    ({ job }) => job.toLowerCase() === id.toLowerCase()
  )[0];
};

const normalizeDays = (schedule) =>
  Object.values(
    schedule.reduce((days, { day }) => {
      const date = day;
      days[date] = date;
      return days;
    }, {})
  );

const defaultFilter = { id: "", label: "Todos" };

const formatDay = (day) => {
  if (!day) return {};
  return {
    dayOfWeek: formatDate(day, "ddd"),
    fullDate: `${formatDate(day, "D")} de ${formatDate(day, "MMM")}`,
    year: formatDate(day, "YYYY"),
  };
};

const formatFilterDays = (days) => {
  const label = (date) => `${date.dayOfWeek}, ${date.fullDate} de ${date.year}`;
  return days.map((day) => ({
    id: day,
    label: label(formatDay(new Date(day.replace("Z", "")))),
  }));
};

const sortDateFilter = (dates, direction = "asc") => {
  if (direction === "asc") {
    return dates.sort((a, b) => new Date(a.id) - new Date(b.id));
  } else {
    return dates.sort((a, b) => new Date(b.id) - new Date(a.id));
  }
};

const sortCaseInsensitive = (array, field, direction) =>
  orderBy(array, [(item) => item[field].toLowerCase()], direction);

const statusFilter = () => {
  const status = [
    { id: 2, label: "Aceito" },
    { id: 8, label: "Aguardando" },
    { id: 3, label: "Candidatado" },
    { id: 5, label: "Pago" },
    { id: 1, label: "Publicado" },
    { id: 4, label: "Realizado" },
    { id: 0, label: "Pendente" },
  ];

  return status;
};

const coupons = {
  LANUP25: 25,
  LANUP50: 50,
  LANUPFREE: 100,
  "": 0,
};

const downloadFile = (response, fileName) => {
  const fileURL = URL.createObjectURL(
    new Blob([response.data], {
      type: "application/vnd.ms-excel",
    })
  );
  var fileLink = document.createElement("a");

  fileLink.href = fileURL;
  fileLink.setAttribute("download", `${fileName}.xlsx`);
  document.body.appendChild(fileLink);

  fileLink.click();
};

const time = {
  1: "Comunicador",
  2: "Excutador",
  3: "Analista",
  4: "Planejador",
};

const updateChildren = (node, checked) => {
  if (!node.children || (node.name != "" && node.hasBankData == false)) return;
  node.children.forEach((child) => {
    child.checked = checked;
    updateChildren(child, checked);
  });
};

const updateTree = (root) => {
  (function update(node) {
    if (!node.children) return;
    node.children.forEach(update);
    node.checked = node.children.every((child) => child.checked);
  })(root);
};

const getters = {
  loading: (state) => state.loading,
  basicInfo: (state) => state.basicInfo,
  eventServiceList: (state) => state.eventServiceList,
  rating: (state) => state.ratings,
  detailsInfo: (state) => state.detailsInfo,
  services: (state) => state.services,
  currentService: (state) => state.currentService,
  actualTeamLocalization: (state) =>
    state.currentService.actualTeamLocalization,
  currentSlots: (state) => state.currentService.slots,
  sortedSlots: (state, getters) => {
    return getters.filteredSlots;
  },

  filteredSlots: (state) => {
    const { day, status, editedByManager } = state.currentFilters;
    const filterDay = (slot) => !day || slot.day.raw === day;
    let filterStatus = state.currentService.slots
      .filter(filterDay)
      .filter(
        (x) => !status || (x.isPublished ? 1 : x.status) === Number(status)
      );

    // TODO: refactor
    filterStatus = editedByManager
      ? filterStatus.filter(
          (slot) => slot.checkinOrigin === 1 || slot.checkoutOrigin === 1
        )
      : filterStatus;

    return filterStatus;
  },
  eventDays: (state) => normalizeDays(state.basicInfo.schedule),
  currentSort: (state) => state.currentSort,
  currentFilters: (state) => state.currentFilters,
  status: () => (id) => statusFilter().find((x) => x.id === id).label,
  filters: (state) => ({
    day: sortDateFilter([
      defaultFilter,
      ...formatFilterDays(getters.eventDays(state)),
    ]),
    status: [defaultFilter, ...statusFilter()],
    // type: [defaultFilter],
  }),
  operations: (state) => state.operations,
  isManagementByAgency: (state) => state.basicInfo.isManagementByAgency,
  showNegotiation: (state) =>
    !state.basicInfo.isManagementByAgency &&
    state.basicInfo.hasServiceManagementByAgency,
  negotiations: (state) => flatten(pluck("negotiations", state.services)),
  filterEventService: (state) => state.filterEventService,
  coupon: (state) => state.coupon,
  couponDiscount: (state) => coupons[state.coupon],
  responseIsValid: (state) => state.responseIsValid,
  freelasToPay: (state) => state.freelasToPay,
};

const mutations = {
  RESET_STATE: (state) => Object.assign(state, initialState()),
  SET_LOADING: (state, loading) => (state.loading = loading),
  SET_BASIC_INFO: (state, basicInfo) => (state.basicInfo = basicInfo),
  SET_EVENT_SERVICE_LIST: (state, eventServiceList) =>
    (state.eventServiceList = eventServiceList),
  RESET_EVENT_SERVICE_LIST: (state) =>
    (state.eventServiceList = initialState().eventServiceList),
  UPDATE_EVENT_SERVICE_LIST: (state, { node, checked }) => {
    node.checked = checked;
    updateChildren(node, checked);
    updateTree(state.eventServiceList);
  },
  SET_DETAILS_INFO: (state, detailsInfo) => (state.detailsInfo = detailsInfo),
  SET_SERVICES: (state, services) => (state.services = services),
  SET_RATINGS: (state, ratings) => (state.ratings = ratings),
  SET_SORT: (state, sort) => (state.currentSort = sort),
  SET_FILTERS: (state, filters) =>
    (state.currentFilters = Object.assign({}, state.currentFilters, filters)),
  SET_FILTER_SERVICE_EVENT: (state, filters) => {
    state.filterEventService = Object.assign(
      {},
      state.filterEventService,
      filters
    );
  },
  CLEAR_FILTER_SERVICE_EVENT: (state) =>
    (state.filterEventService = {
      cnpj: null,
      services: null,
      shift: null,
    }),
  SET_CURRENT_SERVICE_DATA: (state, currentService) =>
    (state.currentService = currentService),
  SET_CURRENT_SERVICE_SLOTS: (state, slots) =>
    (state.currentService.slots = slots),
  SET_ACTUAL_SERVICE_TEAM_LOCALIZATION: (state, { freelas, value }) => {
    if (freelas && value) {
      state.currentService = {
        ...state.currentService,
        actualTeamLocalization: value
          .filter((localization) => freelas.includes(localization.freelaId))
          .map((l) => ({
            latLng: {
              lat: parseFloat(l.lat),
              lng: parseFloat(l.lng),
            },
            freelaId: l.freelaId,
            info: l.freelaName,
            icon: "http://maps.google.com/mapfiles/marker.png",
            occuredAt: l.occuredAt,
          })),
      };
    }
  },
  SET_EVENT_LOCALIZATION: (state, { lat, lng }) =>
    (state.basicInfo = {
      ...state.basicInfo,
      destination: {
        latLng: { lat: parseFloat(lat), lng: parseFloat(lng) },
        icon: "http://maps.google.com/mapfiles/kml/paddle/E.png",
        isDestination: true,
        name: state.basicInfo.locationName,
        address: state.basicInfo.address,
      },
    }),

  UPDATE_CHECKPOINT: (state, newLocalization) => {
    if (state.currentService.actualTeamLocalization.length) {
      const actualLocation = state.currentService.actualTeamLocalization.find(
        ({ freelaId }) => freelaId == newLocalization.freelaId
      );
      if (actualLocation) {
        actualLocation.latLng = {
          lat: parseFloat(newLocalization.lat),
          lng: parseFloat(newLocalization.long),
        };
      }
    }
  },
  SET_OPERATION_CONNECTION: (state, connection) =>
    (state.connection = connection),

  UPDATE_VACANCIES: (state, vacancies) => {
    state.currentService.slots = vacancies;
  },
  UPDATE_VACANCY_SLOT: (state, { vacancySlot, index }) => {
    state.currentService.slots[index] = {
      ...vacancySlot,
    };
    state.currentService.slots = [...state.currentService.slots];
  },

  SET_SELECTED_MEMBER: (state, member) =>
    (state.currentService = {
      ...state.currentService,
      selectedMember: member,
    }),

  SET_OPERATIONS: (state, operations) => (state.operations = operations),

  SET_COUPON: (state, payload) => {
    state.coupon = payload;
  },

  SET_COUPON_VALIDATION: (state, payload) => {
    state.responseIsValid = payload;
  },

  SET_FREELAS_TO_PAY: (state, payload) => {
    state.freelasToPay = payload;
  },

  SET_ADDITIONAL_VALUE: (state, { child, additionalValue }) => {
    child.checked = false;
    child.checked = true;
    child.paymentService.additionalValue = additionalValue;
  },

  SET_CHARGE_HIRER: (state, { child, chargeHirer }) => {
    child.paymentService.chargeHirer = chargeHirer;
  },
};

const extractData = ({
  expectedAudienceNumber,
  description,
  services,
  eventProfileTypes,
  operations,
  ...basicInfo
}) => ({
  basicInfo,
  services,
  eventProfileTypes,
  detailsInfo: {
    expectedAudienceNumber,
    eventProfileTypes: eventProfileTypes
      ? eventProfileTypes.map((e) => time[e])
      : ["Nenhum"],
    description,
  },
  operations,
});

const emptyId = "00000000-0000-0000-0000-000000000000";

const transformService = ({
  freelaId,
  vacancyId,
  start,
  end,
  checkIn,
  checkInDiff,
  checkinOrigin,
  checkOut,
  checkOutDiff,
  checkoutOrigin,
  inOperation,
  day,
  payment,
  agencyProfit,
  description,
  image,
  type,
  status,
  isPublished,
  serviceId,
  job,
}) => ({
  start,
  end,
  checkIn,
  checkInDiff,
  checkinOrigin,
  checkOut,
  checkOutDiff,
  checkoutOrigin,
  inOperation,
  payment,
  agencyProfit,
  type,
  status,
  isPublished,
  serviceId,
  job,
  description,
  image,
  freelaId: freelaId.replace(emptyId, ""),
  vacancyId: vacancyId.replace(emptyId, ""),
  day: {
    ...formatDay(new Date(day?.split("T")[0] + " ")),
    id: dateToString(new Date(day?.split("T")[0] + " ")),
    raw: day,
  },
});

const transformToInvitePayload = ({
  freelaId,
  eventId,
  start,
  end,
  day,
  job,
  payment,
}) => ({
  freelaId,
  eventId,
  job,
  payment,
  checkIn: start,
  checkOut: end,
  day: day.raw,
});

const actions = {
  init({ commit }, { eventId, serviceId }) {
    commit("RESET_STATE");

    const serviceValidation = (result) => {
      const { services } = result;
      const isValidService = (id) => services.some(({ job }) => job === id);

      if (serviceId && isValidService(serviceId)) {
        actions.loadEventServiceData(
          { commit },
          {
            eventId,
            serviceId,
            service: getTotalVacancy(services, serviceId),
          }
        );
        actions.subscribeChannels({ commit });
      }

      return result;
    };

    return actions.loadEventData({ commit }, eventId).then(serviceValidation);
  },

  subscribeChannels({ commit }) {
    const updateCheckpoint = (checkpoint) => {
      commit("UPDATE_CHECKPOINT", checkpoint);
    };

    onNewCheckpoint(updateCheckpoint);
  },

  setSort({ commit }, sort) {
    return commit("SET_SORT", sort);
  },

  setFilters({ commit }, filters) {
    return commit("SET_FILTERS", filters);
  },

  loadEventData({ commit }, eventId) {
    const populate = (result) => {
      const { basicInfo, detailsInfo, services, operations } =
        extractData(result);

      commit("SET_BASIC_INFO", { ...basicInfo, eventId: eventId });
      commit("SET_DETAILS_INFO", detailsInfo);
      commit("SET_SERVICES", services);
      commit("SET_RATINGS", {
        hirerId: basicInfo.hirerId,
        name: basicInfo.contactName,
      });

      commit("SET_OPERATIONS", operations);

      return result;
    };

    return getEventDetails(eventId).then(populate);
  },

  loadEventServiceData(
    { commit },
    { eventId, serviceId, service: { totalVacancy } }
  ) {
    return getJobMembers(eventId, serviceId).then((services) => {
      commit("SET_CURRENT_SERVICE_DATA", {
        id: serviceId,
        slots: services.map(transformService),
        totalVacancy,
      });
    });
  },
  /* eslint-disable no-unused-vars */
  saveSchedule({ commit, state }, { operationId, job, body }) {
    return scheduleManagement(operationId, body).then(() =>
      actions.loadEventServiceData(
        { commit },
        {
          serviceId: job,
          eventId: state.basicInfo.eventId,
          service: getTotalVacancy(state.services, job),
        }
      )
    );
  },
  /* eslint-disable no-unused-vars */
  coupon: ({ commit }, payload) => {
    commit("SET_COUPON", payload);
  },

  validateCoupon: async ({ commit, state }) => {
    await validateCoupon(state.coupon).then((result) => {
      commit("SET_COUPON_VALIDATION", result.value);
    });
  },

  paymentBatch: async ({ commit, state, rootGetters }) => {
    commit("SET_LOADING", true);
    paymentBatch({
      isHirerAgency: rootGetters["User/isAgency"],
      hirerId: state.basicInfo.hirerId,
      paymentBatch: state.freelasToPay,
      couponName: state.coupon,
    })
      .then((data) => data)
      .then((batchId) => {
        commit("RESET_MEMBERS_WITH_JOB_DONE");
        vm.$eventhub.$emit("clear:filter-payroll");
        vm.$router.push({
          name: "events.charge",
          params: {
            id: state.basicInfo.eventId,
            batchId,
            isListBatch: false,
          },
        });
      })
      .catch((error) => {
        feedback(error);
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },

  freelasToPay: (context, payload) => {
    context.commit("SET_FREELAS_TO_PAY", payload);
  },

  publishVacancies({ state }, { value, eventId, slots }) {
    const vacancy = ({ start: checkIn, end: checkOut, day: { raw } }) => ({
      day: raw,
      checkIn,
      checkOut,
      availableVacancyQuantity: 1,
      payment: value,
    });

    const payload = (eventId, slots) => ({
      eventId,
      vacancies: slots.map((slot) => vacancy(slot)),
    });

    return publishVacancy(state.currentService.id, payload(eventId, slots));
  },

  getServiceTeamLocalization({ commit }, { eventId, referenceDate, freelas }) {
    getFreelasLocation(eventId, referenceDate).then(({ value }) => {
      commit("SET_ACTUAL_SERVICE_TEAM_LOCALIZATION", { freelas, value });
    });
  },

  getEventLocalization({ commit }, addressId) {
    getEventLocation(addressId).then((result) =>
      commit("SET_EVENT_LOCALIZATION", result)
    );
  },

  getOperationConnection({ commit, state }, { referenceDate, eventId }) {
    getConnection({ referenceDate, eventId }).then((result) => {
      commit("SET_OPERATION_CONNECTION", result);
      actions.setGroupNameConnection({ state });
    });
  },

  getReport({ getters }, { day, status, eventId, job }) {
    getReport(eventId, job, day, status).then((response) => {
      const fileURL = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.ms-excel",
        })
      );
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Relatório - ${getters.basicInfo.name} - ${job}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  },

  getServiceReport({ getters }, { day, eventId }) {
    getServiceReport(eventId, day).then((response) => {
      downloadFile(response, `Relatório - ${getters.basicInfo.name}`);
    });
  },

  getActivitiesReport({ getters }, { eventId, operationDate, operationId }) {
    getActivitiesReport({ eventId, operationDate, operationId }).then(
      (response) => {
        const fileURL = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.ms-excel",
          })
        );
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Relatório Atividades - ${getters.basicInfo.name}-${operationDate}.xlsx`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      }
    );
  },
  setGroupNameConnection({ state }) {
    if (state.connection) setGroupName(state.connection);
  },

  invite({ commit, state }, data) {
    const payload = transformToInvitePayload({
      ...data,
      eventId: state.basicInfo.eventId,
    });
    const { job, eventId } = payload;
    return sendInvite(payload).then(() =>
      actions.loadEventServiceData(
        { commit },
        {
          serviceId: job,
          eventId,
          service: getTotalVacancy(state.services, job),
        }
      )
    );
  },

  cancelInvite({ commit, state }, data) {
    const { job, vacancyId, status } = data;
    return cancelInvite(vacancyId, status).then(() =>
      actions.loadEventServiceData(
        { commit },
        {
          serviceId: job,
          eventId: state.basicInfo.eventId,
          service: getTotalVacancy(state.services, job),
        }
      )
    );
  },
  confirmFreelancer({ commit, state }, data) {
    const { job, vacancyId } = data;
    return approveInvite(vacancyId).then(() =>
      actions.loadEventServiceData(
        { commit },
        {
          serviceId: job,
          eventId: state.basicInfo.eventId,
          service: getTotalVacancy(state.services, job),
        }
      )
    );
  },
  updateVacancies({ commit, getters }) {
    const { field, direction } = state.currentSort;
    let result = [];
    let sortedSlots = [];
    let emptySlots = [];

    getters.filteredSlots.forEach((slot) => {
      slot.statusLabel = getters.status(slot.status);
      slot.freelaId ? sortedSlots.push(slot) : emptySlots.push(slot);
    });

    if (getters.currentSort.field === "day") {
      result = [...sortDateFilter(sortedSlots, direction), ...emptySlots];
    } else if (getters.currentSort.field === "statusLabel") {
      result = sortCaseInsensitive(
        [...sortedSlots, ...emptySlots],
        field,
        direction
      );
    } else {
      result = [
        ...sortCaseInsensitive(sortedSlots, field, direction),
        ...emptySlots,
      ];
    }

    commit("UPDATE_VACANCIES", [...result]);
  },

  updateVacancySlot({ commit }, { vacancySlot, index }) {
    commit("UPDATE_VACANCY_SLOT", { vacancySlot: vacancySlot, index: index });
  },

  updateEventServiceData({ commit }, slots) {
    commit("SET_CURRENT_SERVICE_SLOTS", slots);
  },

  setSelectedMember({ commit }, member) {
    commit("SET_SELECTED_MEMBER", member);
  },

  updateBudget({ state, commit }, { serviceId, amount }) {
    const body = {
      eventId: state.basicInfo.eventId,
      serviceId,
      amount,
    };
    return addNewBudget(body).then(() =>
      actions.loadEventData({ commit }, body.eventId)
    );
  },

  refuseNewBudget({ state, commit }, { serviceId, agencyId }) {
    const body = {
      eventId: state.basicInfo.eventId,
      serviceId,
      agencyId,
    };
    return refuseBudget(body).then(() =>
      actions.loadEventData({ commit }, body.eventId)
    );
  },

  acceptNewBudget({ state, commit }, { serviceId, agencyId }) {
    const body = {
      eventId: state.basicInfo.eventId,
      serviceId,
      agencyId,
    };
    return acceptBudget(body).then(() =>
      actions.loadEventData({ commit }, body.eventId)
    );
  },

  getEventServices({ state, commit }, filters) {
    commit("SET_LOADING", true);
    commit("SET_FILTER_SERVICE_EVENT", filters);
    debounce(
      () =>
        getEventServices({
          cnpj: state.filterEventService.cnpj,
          service: state.filterEventService.services,
          startDate: state.filterEventService.shift?.start,
          endDate: state.filterEventService.shift?.end,
        })
          .then((data) => data)
          .then((result) => {
            const root = {
              checked: false,
              empty: !result.length,
              children: result.map((group) => ({
                eventId: group.id,
                hirerName: group.hirerName,
                eventName: group.eventName,
                startDate: group.startDate,
                endDate: group.endDate,
                checked: false,
                children: group.services.map(
                  ({
                    day,
                    id: serviceId,
                    serviceName,
                    status,
                    totalFreelas,
                    totalValue,
                    valueByFreela,
                    agencyProfit,
                  }) => ({
                    day,
                    serviceId,
                    serviceName,
                    status,
                    totalFreelas,
                    totalValue,
                    valueByFreela,
                    agencyProfit,
                    eventId: group.id,
                    checked: false,
                  })
                ),
              })),
            };
            commit("SET_EVENT_SERVICE_LIST", root);
          })
          .catch(({ message }) => {
            this._vm.$eventhub.$emit("feedback:show", {
              type: "error",
              msg: `${message}`,
            });
          })
          .finally(() => commit("SET_LOADING", false)),
      1000
    )();
  },
  udpateEventServiceList({ commit }, { node, checked }) {
    return commit("UPDATE_EVENT_SERVICE_LIST", {
      node,
      checked,
    });
  },
  updateAdditionalValue(
    { state, commit, getters },
    { additionalValue, chargeHirer, vacancyId }
  ) {
    let child = {};
    child = getters.child(vacancyId);

    commit("SET_ADDITIONAL_VALUE", { child, additionalValue });
    commit("SET_CHARGE_HIRER", { child, chargeHirer });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
