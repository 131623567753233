<template>
  <div>
    <div class="grid-table pr-15 pl-15 p-20">
      <small>{{ listBatch.externalId }}</small>
      <small>{{ listBatch.creationDate | formDate }}</small>
      <small>R$ {{ listBatch.totalValue | toCurrency }}</small>
      <small>
        <span class="bg-status" :class="objBatchStatusColor[listBatch.status]">
          {{ objBatchStatus[listBatch.status] }}
        </span>
      </small>
      <div class="">
        <ContextMenu
          class="context-menu"
          :options="contextMenuOptions"
          @itemClick="onContextMenuItemClick">
          <button class="context-menu-button btn outline icon" md-menu-trigger>
            <i class="mdi mdi-dots-horizontal text-malibu font-size-1-3"></i>
          </button>
        </ContextMenu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listBatch: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    objBatchStatusColor: {
      0: "waiting",
      1: "canceled",
      2: "canceled",
      3: "paid",
    },
    objBatchStatus: {
      0: "Pendente",
      1: "Cancelado",
      2: "Cancelado por problemas",
      3: "Pago",
    },
  }),
  computed: {
    contextMenuOptions() {
      return [
        {
          label: `Ver detalhes`,
          icon: "mdi-eye",
          action: "detailsBatch",
        },
        {
          label: `Pagamento`,
          icon: "mdi-currency-brl",
          action: "goToCharge",
        },
        {
          label: `Cancelar`,
          icon: "mdi-cancel text-red",
          action: "cancelBatch",
          disabled: !(this.listBatch.status === 0),
        },
      ];
    },
  },

  methods: {
    onContextMenuItemClick({ action }) {
      const actions = {
        detailsBatch: this.detailsBatch,
        goToCharge: this.goToCharge,
        cancelBatch: this.cancelBatch,
      };

      actions[action]();
    },
    detailsBatch() {
      this.$router.push({
        name: "events.details.batch",
        params: {
          id: this.$route.params.id,
          batchId: this.listBatch.id,
        },
      });
    },
    cancelBatch() {
      this.$eventhub.$emit("modal:open:batch-cancellation-confirmation", {
        batchId: this.listBatch.id,
        batchNumber: this.listBatch.externalId,
      });
    },
    goToCharge() {
      this.$router.push({
        name: "events.charge",
        params: {
          id: this.$route.params.id,
          batchId: this.listBatch.id,
          isListBatch: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.grid-table {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.2fr;
}

.context-menu {
  width: 20px;
  height: 20px;
}
</style>
