<template>
  <CustomCard
    class="tabs-component h-100 w-100 d-flex direction-column ai-stretch radius-10">
    <template #header>
      <div class="d-flex jc-space-between tab-header">
        <ul v-if="tabs" class="d-flex ai-start m-0" :class="headerClass">
          <li
            :class="{ active: currentTab.id === tab.id }"
            v-for="(tab, key) in tabs"
            :key="key"
            @click="setActiveTab(tab)">
            {{ tab.label }}
            <Badge v-if="tab.badge" color="orange" position="top right" />
          </li>
        </ul>
        <slot name="right_bar"></slot>
      </div>
      <Divider />
    </template>

    <div class="tab-content h-100" ref="tabContent">
      <slot></slot>
    </div>
  </CustomCard>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => [],
      validator: (tabs) => tabs.find(({ id, label }) => id && label), // payload: [{ id: 'aba1', label: 'Aba exemplo' }]
    },
    headerClass: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    currentTab: {},
  }),
  mounted() {
    const firstTab = this.tabs.find(({ active }) => active) || this.tabs[0];
    this.setActiveTab(firstTab);
  },
  methods: {
    setActiveTab(tab) {
      this.currentTab = tab;

      const setActiveClass = (el) => {
        const className = el.className?.replace(" active", "");

        el.className =
          el?.getAttribute("tabid") === tab.id
            ? `${className} active`
            : className;
      };

      this.$refs.tabContent.childNodes.forEach(setActiveClass);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.tabs-component {
  .tab-header {
    z-index: 2;

    li {
      background-position: bottom left;
      background-size: 0% 4px;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-bottom: -3px;
      padding: 1rem;
      position: relative;
      transition: background-size 0.3s, background-position 0.3s 0.3s;

      &:hover,
      &:active {
        background-color: transparentize($Malibu, 0.95);
      }

      &:first-child {
        border-radius: 10px 0 0 0;
      }

      &.active {
        background-image: linear-gradient($Malibu, $Malibu);
        background-position: bottom left;
        color: $Malibu;
        background-size: 100% 4px;
      }
    }
  }

  .tab-content {
    position: relative;
  }
}
</style>
