import { cond, T } from "ramda";
import canAccessWithoutPermission from "./canAccessWithoutPermission";
import validateLogout from "./validateLogout";
import canAccessAuthRoutes from "./canAccessAuthRoutes";

const callNext = [T, ({ next }) => next()];

const beforeEach = (to, from, next) =>
  cond([
    validateLogout,
    canAccessWithoutPermission,
    canAccessAuthRoutes,
    callNext,
  ])({ to, from, next });

export default beforeEach;
