<template>
  <div class="d-flex direction-column p-28 event-details">
    <CustomCard
      theme="ebony-malibu"
      title="Perfil desejado do time"
      class="w-100 radius-10">
      <div class="p-20 direction-column d-flex">
        <small
          class="m-5 font-size-08 text-uppercase"
          v-for="(item, index) in detailsInfo.eventProfileTypes"
          :key="index">
          - {{ item }}
        </small>
      </div>
    </CustomCard>

    <CustomCard
      theme="ebony-malibu"
      title="Detalhes Gerais"
      class="w-100 mt-28 radius-10">
      <div class="p-20">
        <small v-html="detailsInfo.description"></small>
      </div>
    </CustomCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: mapGetters({ detailsInfo: "EventDetails/detailsInfo" }),
};
</script>
