<template>
  <section>
    <article class="embeded-input">
      <md-field>
        <template v-if="type === 'currency'">
          <md-input
            type="text"
            @input="$emit('input', $event)"
            @keypress="$emit('keypress', $event)"
            @keydown="$emit('keydown', $event)"
            @focus="onFocus"
            @blur="onBlur"
            v-money="currencyConfig"
            ref="embededInput" />
        </template>
        <template v-else>
          <md-input
            type="text"
            v-money="currencyConfig"
            @input="$emit('input', $event)"
            @keypress="$emit('keypress', $event)"
            @keydown="$emit('keydown', $event)"
            @focus="onFocus"
            @blur="onBlur" />
        </template>
      </md-field>
    </article>
  </section>
</template>

<script>
export default {
  name: "EmbededInput",
  props: {
    type: {
      type: String,
      default: () => "text",
    },
  },
  data: () => ({
    currencyConfig: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false,
    },
  }),

  methods: {
    onFocus($event) {
      this.focus = true;
      this.$emit("focus", $event);
    },
    onBlur($event) {
      this.focus = false;
      this.$emit("blur", $event);
    },

    setFocus() {
      this.$refs.embededInput.$el.focus();
    },
  },
  mounted() {
    this.setFocus();
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";
.embeded-input {
  background: $Ebony;
  padding: 0 3px 1px 3px;
  border-radius: 3px;
  opacity: 0.5;

  .md-input {
    padding: 5px 10px;
    transition: border 0.25s;
    font-size: 12px !important;
  }
  .md-field {
    width: 100%;
    min-height: auto;
    margin: 4px 0 4px;
    /* padding-top: 16px; */
    display: flex;
    position: relative;
    /* font-family: inherit; */
    vertical-align: bottom;
  }
  .md-field.md-theme-default:after {
    background-color: white;
  }
  .md-field.md-theme-default:before {
    background-color: transparent;
  }
  .md-field.md-input-action {
    top: 0px;
  }
}
</style>
