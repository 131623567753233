<template>
  <div class="map-box d-flex">
    <label class="map-area">
      <div class="map-wrap">
        <div class="icon-square">
          <i class="mdi mdi-map-marker-outline"></i>
        </div>
        <span>Não há Mapa</span>
        <img v-if="location" :src="location" class="g-maps" />
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    location: String,
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.map-box {
  .map-area {
    align-items: center;
    background: $Ebony;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 225px;
    width: 250px;

    .map-wrap {
      align-items: center;
      border: dashed 1px #fff6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80%;
      margin: auto;
      overflow: hidden;
      position: relative;
      width: 80%;

      .g-maps {
        position: absolute;
      }

      .icon-square {
        align-items: center;
        background: $Ebony;
        border-radius: 5px;
        color: white;
        display: flex;
        font-size: 30px;
        height: 40px;
        justify-content: center;
        text-align: center;
        width: 40px;
        position: relative;
      }
    }
  }
}
</style>
