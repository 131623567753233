<template>
  <section class="bg-steelGray p-10 radius-5 d-flex">
    <SearchHirer
      :value="filterEventService.name"
      class="w-50 mr-5"
      @selected="onHirerSelected" />
    <custom-date-range
      label="Por turno"
      class="mr-5 w-50"
      :icon="true"
      @input="getEventServices({ shift: $event })"
      @iconClick="getEventServices({ shift: null })"
      :value="filterEventService.shift" />
    <MultiSelect
      class="w-50"
      placeholder="Serviços"
      :options="servicesOptions"
      @input="getServices"
      autoComplete
      :loader="loaderMultiSelect"
      placeholderSearch="Pesquisar serviços"
      v-model="services"></MultiSelect>
  </section>
</template>

<script>
import SearchHirer from "./SearchHirer.vue";
import { getAvailableServices } from "@/services/events/event.http";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { SearchHirer },
  data: () => ({
    filters: {
      cnpj: null,
      shift: null,
      services: null,
    },
    services: [],
    servicesOptions: [],
    loaderMultiSelect: false,
  }),
  created() {
    this.getServices();
  },
  watch: {
    services: {
      deep: true,
      immediate: false,
      handler: function (job) {
        this.getEventServices({ services: job });
      },
    },
  },
  computed: {
    ...mapGetters({
      filterEventService: "EventDetails/filterEventService",
      identification: "Profile/identification",
    }),
  },
  methods: {
    ...mapActions({
      getEventServices: "EventDetails/getEventServices",
    }),
    onHirerSelected({ cnpj, name }) {
      this.getEventServices({ cnpj, name });
    },
    getServices(val) {
      this.loaderMultiSelect = true;
      getAvailableServices(val)
        .then((services) => {
          this.servicesOptions = services.map((x) => ({
            label: x,
            value: x,
          }));
        })

        .finally(() => (this.loaderMultiSelect = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
