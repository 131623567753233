<template>
  <button
    class="spinner-button"
    :class="{ loading: loading }"
    :disabled="isDisabled"
    :id="id"
    @click="emitClick"
    @keypress.enter="emitClick">
    <span class="label">
      <slot></slot>
    </span>
    <Spinner v-if="loading" />
  </button>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabled() {
      return this.loading || this.disabled;
    },
  },
  methods: {
    emitClick() {
      if (!this.loading) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.spinner-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  min-width: 100px;

  &.loading {
    .label {
      display: none;
    }

    .spinner {
      display: block;
    }
  }
}
</style>
