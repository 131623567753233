<template>
  <div class="md-layout d-flex direction-column md-size-100 w-100">
    <h3>Informações do Cartão</h3>
    <div class="md-layout-item">
      <CustomInput
        :isInvalid="$v.card.cardNumber.$error"
        v-model.trim="$v.card.cardNumber.$model"
        mask="#### #### #### ####"
        placeholder="Número do Cartão" />
      <div class="error d-flex wrapped f-100">
        <span
          class="d-flex f-100"
          v-if="$v.card.cardNumber.$dirty && !$v.card.cardNumber.required">
          Campo Obrigatório
        </span>
      </div>
    </div>
    <div class="md-layout-item d-flex">
      <div class="w-50">
        <CustomInput
          :isInvalid="$v.card.cardExpirationDate.$error"
          v-model.trim="$v.card.cardExpirationDate.$model"
          mask="##/####"
          placeholder="Validade" />
        <div class="error d-flex wrapped f-100">
          <span
            class="d-flex f-100"
            v-if="
              $v.card.cardExpirationDate.$dirty &&
              !$v.card.cardExpirationDate.required
            ">
            Campo Obrigatório
          </span>
        </div>
      </div>
      <div class="w-50">
        <CustomInput
          :isInvalid="$v.card.cardSecurityNumber.$error"
          v-model.trim="$v.card.cardSecurityNumber.$model"
          mask="###"
          placeholder="CVV" />
        <div class="error d-flex wrapped f-100">
          <span
            class="d-flex f-100"
            v-if="
              $v.card.cardSecurityNumber.$dirty &&
              !$v.card.cardSecurityNumber.required
            ">
            Campo Obrigatório
          </span>
        </div>
      </div>
    </div>
    <div class="md-layout-item">
      <CustomInput
        :isInvalid="$v.card.cardOwnerName.$error"
        v-model.trim="$v.card.cardOwnerName.$model"
        placeholder="Nome no Cartão" />
      <div class="error d-flex wrapped f-100">
        <span
          class="d-flex f-100"
          v-if="
            $v.card.cardOwnerName.$dirty && !$v.card.cardOwnerName.required
          ">
          Campo Obrigatório
        </span>
      </div>
    </div>
    <div class="md-layout-item">
      <CustomInput
        :isInvalid="$v.card.cpf.$error"
        v-model.trim="$v.card.cpf.$model"
        placeholder="CPF"
        mask="###.###.###-##" />
      <div class="error d-flex wrapped f-100">
        <span
          class="d-flex f-100"
          v-if="$v.card.cpf.$dirty && !$v.card.cpf.required">
          Campo Obrigatório
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data: () => ({
    card: {
      cardNumber: "",
      cardExpirationDate: "",
      cardSecurityNumber: "",
      cardOwnerName: "",
      cpf: "",
    },
  }),
  watch: {
    card: {
      deep: true,
      handler(card) {
        this.$store.dispatch("Payment/updateCardData", card);
      },
    },
  },
  validations: {
    card: {
      cardNumber: {
        required,
      },
      cardExpirationDate: {
        required,
      },
      cardSecurityNumber: {
        required,
      },
      cardOwnerName: {
        required,
      },
      cpf: {
        required,
      },
    },
  },
};
</script>
