<template>
  <div class="input-field p-relative">
    <label :for="'input' + placeholder">
      <span>{{ placeholder }}</span>
      <span class="tip">{{ tip }}</span>
    </label>

    <div
      class="multiSelect w-100 cursor-pointer"
      id="profile-dropdown"
      @click="openDropdown = !openDropdown">
      <i class="arrow" :class="{ 'icon-rotate': openDropdown }" />
      <span v-if="proxyChecked.length" class="pl-15 font-size-07">
        Selecionados: {{ proxyChecked.length }}
      </span>
      <span v-else class="pl-15 font-size-07">Selecione</span>
    </div>
    <div class="dropDown" :class="{ openDropDown: openDropdown }">
      <div v-if="autoComplete" class="search-drop">
        <input
          type="text"
          @input="debounceInput"
          :placeholder="placeholderSearch"
          ref="inputSearch" />
      </div>
      <template v-if="!loader">
        <template v-if="options.length">
          <label
            class="label-drop cursor-pointer d-flex ai-center jc-space-between"
            :for="`${option.label}${index}`"
            v-for="(option, index) in options"
            :key="`${option.label}${index}`">
            <span class="pr-10">{{ option.label }}</span>
            <input
              class="custom-regular-checkbox multiSelect__checkbox"
              :id="`${option.label}${index}`"
              type="checkbox"
              :value="option.value"
              v-model="proxyChecked" />
          </label>
        </template>
        <span v-else class="font-size-07 d-flex jc-center">
          Nenhum grupo encontrado
        </span>
      </template>

      <Loading v-else size="small" />
    </div>
    <label :for="'input' + placeholder" class="pt-2">
      <span class="tip example">{{ example }}</span>
    </label>
  </div>
</template>

<script>
import { debounce } from "@/helpers/debounce.js";
export default {
  model: {
    prop: "checked",
    event: "change",
  },
  created() {
    window.addEventListener("click", this.close);
    this.debounceInput = debounce(this.debounceInput, 500);
  },

  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  data: () => ({
    openDropdown: false,
    search: "",
  }),
  props: {
    options: {
      type: Array,
      required: true,
    },
    tip: { default: "" },
    value: { default: "" },
    example: { default: "" },
    placeholder: { default: "" },
    placeholderSearch: { default: "" },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: [Array, Boolean],
      default: false,
    },
  },
  methods: {
    debounceInput(event) {
      this.$emit("input", event.target.value);
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.openDropdown = false;
        if (this.$refs.inputSearch) {
          this.$refs["inputSearch"].value = "";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.input-field {
  display: flex;
  flex-direction: column;
  margin: 0px 5px 0px;

  label {
    margin: 0px 0px 2px;
    font-size: 12px;
    font-weight: 300;

    .tip {
      color: $FuchsiaBlue;
      font-style: italic;
      font-weight: 200;
      margin-left: 15px;

      &.example {
        margin: 0px;
      }
    }
  }
}

.multiSelect {
  color: white;
  height: 50px;
  border: 2px groove white;
  outline: none;
  box-shadow: none;
  transition: border 0.25s;
  background: transparent;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
}

.arrow {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 5%;
  top: 40%;
  transition: transform 1s;

  &.icon-rotate {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
  }
}

.dropDown {
  overflow: auto;
  color: white;
  padding: 10px;
  width: 100%;
  max-height: 250px;
  border: 2px groove white;
  background: $SteelGray;
  position: absolute;
  top: 100%;
  z-index: 3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  &::-webkit-scrollbar-track {
    background: $Ebony;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $EastBay;
    border-radius: 10px;
  }

  &.openDropDown {
    transition: all 0.3s ease-in;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

.label-drop {
  word-break: break-word;
  padding-right: 5px;
  border-bottom: 2px solid $Ebony;
  padding: 5px;
  &:last-child {
    border: none;
  }
}

.search-drop {
  input {
    width: 100%;
    height: 30px;
    border: none;
    border-bottom: 2px solid white;
    font-size: 16px;
    font-family: "HelveticaNowMicro";
    background: transparent;
    border-radius: 0;
    &::placeholder {
      color: rgba($color: white, $alpha: 0.7);
      font-family: "HelveticaNowMicro";
      font-weight: bold;
      font-size: 0.8rem;
    }
  }
  margin-bottom: 20px;
  margin-top: 10px;
}

.multiSelect__checkbox {
  padding: 7px;
  height: 10px;
}
</style>
