<template>
  <div>
    <div class="divider" :class="[theme, size]"></div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "ebony",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.divider {
  width: 100%;

  &.ebony {
    background-color: $Ebony;
  }

  &.martinique {
    background-color: $Martinique;
  }

  &.steel-gray {
    background: $SteelGray;
  }

  &.small {
    height: 1.5px;
  }

  &.medium {
    height: 2px;
  }

  &.larger {
    height: 4px;
  }
}
</style>
