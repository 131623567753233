<template>
  <section>
    <h3>Endereço de cobrança</h3>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-layout md-size-100">
        <CustomInput
          class="md-layout-item"
          placeholder="Endereço"
          :value="billingAddress.street"
          @input="updateBillingData({ street: $event })" />
      </div>
      <div class="md-layout-item md-layout md-size-100">
        <CustomInput
          class="md-layout-item"
          placeholder="Número"
          :value="billingAddress.number"
          @input="updateBillingData({ number: $event })" />
        <CustomInput
          class="md-layout-item"
          placeholder="CEP"
          mask="#####-###"
          :value="billingAddress.postalCode"
          @input="updateBillingData({ postalCode: $event })" />
        <CustomInput
          class="md-layout-item"
          placeholder="Complemento"
          :value="billingAddress.complement"
          @input="updateBillingData({ complement: $event })" />
      </div>
      <div class="md-layout-item md-layout md-size-100">
        <CustomInput
          class="md-layout-item"
          placeholder="Bairro"
          :value="billingAddress.district"
          @input="updateBillingData({ district: $event })" />
        <CustomInput
          class="md-layout-item"
          placeholder="Cidade"
          :value="billingAddress.city"
          @input="updateBillingData({ city: $event })" />
        <CustomSelect
          class="md-layout-item"
          placeholder="Estado"
          :value="billingAddress.state"
          @input="updateBillingData({ state: $event })">
          <option
            v-for="({ id, name }, key) in options.availableStates"
            :value="id"
            :key="key"
            :selected="id == billingAddress.state">
            {{ name }}
          </option>
        </CustomSelect>
      </div>
    </div>
  </section>
</template>

<script>
import states from "./states.json";

export default {
  data: () => ({
    options: {
      availableStates: states,
    },
  }),
  computed: {
    billingAddress: {
      get() {
        return { ...this.$store.getters["Payment/billingAddress"] };
      },
    },
  },
  methods: {
    updateBillingData(data) {
      this.$store.dispatch("Payment/setBilling", { ...data });
    },
  },
};
</script>
