<template>
  <div class="pt-15">
    <div
      :class="{ 'd-flex': isListBatch }"
      class="pl-16 pr-16 jc-space-between ai-center">
      <button
        @click="isListBatch = !isListBatch"
        v-if="isListBatch"
        class="btn secondary d-flex ai-center jc-center">
        <i class="mdi mdi-plus-circle font-size-1-4 button-ic"></i>
        <span class="ml-5">Novo lote</span>
      </button>
      <div class="d-flex jc-end cursor-pointer">
        <span
          @click="isListBatch = !isListBatch"
          class="font-size-08 text-malibu">
          {{
            isListBatch
              ? "Ir para solicitação de pagamento"
              : "Todos pagamentos solicitados"
          }}
        </span>
        <i class="mdi mdi-chevron-right font-size-1-9 text-malibu"></i>
      </div>
    </div>
    <EventPayroll v-if="!isListBatch" :eventService="eventService" />
    <ListBatch v-else />
  </div>
</template>

<script>
import EventPayroll from "./EventPayroll.vue";
import ListBatch from "./Batch/ListBatch.vue";
export default {
  components: { EventPayroll, ListBatch },
  props: {
    eventService: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.isListBatch = this.$route.params.isListBatch;
  },
  data: () => ({
    isListBatch: false,
  }),
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.button-ic {
  color: rgba($color: $Ebony, $alpha: 0.2);
}
</style>
