import {
  pipe,
  map,
  chain,
  flatten,
  sum,
  last,
  pluck,
  groupWith,
  any,
  gt,
  flip,
} from "ramda";
import { storage } from "@/mixins/storage";
import {
  saveEvent,
  saveAgencyEvent,
  getStaticMap,
  saveEventWithFiveThanFreelas,
  saveEventWithPromotionalCode,
} from "@/services/events/event.http";
import { isAgency, getUser } from "@/helpers/auth";
import { normalizeToSave } from "@/helpers/image";

const initialState = () => ({
  currentStep: 1,

  form: {
    agencyData: {},
    draftUpdatedAt: undefined,
    promotionalCode: "",
    id: undefined,
    imageFile: undefined,
    totalFreelas: "",
    verba: "0",
    hasSecurityProtocol: false,
    isHomeOffice: false,
    hasCheckinQrCode: false,
    hasCheckoutQrCode: false,
    requiresFullProfile: false,
    expectedAudience: "030 a 100",
    eventProfileTypes: [],
    generalDescription: "",
    dates: [],
    event: {
      OS: "",
      title: "",
      type: "",
      topic: "",
    },
    location: {
      name: "",
      address: "",
      place_id: "",
    },
    contact: {
      fullName: "",
      email: "",
      tel: "",
    },
    services: new Map(),
    hirer: { services: [] },
    mapLocation: "",
  },
  addressesFound: [],
  isDuplicated: false,
});

const getInitialState = (draft = {}) => ({
  ...initialState(),
  draft,
  form: {
    ...initialState().form,
    ...draft,
    services: draft.services
      ? new Map(draft.services.map((i) => [i.job, i]))
      : [],
  },
});

const draft = storage.get("eventDraft") || {};

const state = getInitialState(draft);

const stringToDate = (str) => (typeof str === "string" ? new Date(str) : str);
const sortByDate = (a, b) => new Date(a) - new Date(b);
const mapToDate = (dates) => dates.map(stringToDate);

const mapToArray = pipe(Array.from, map(last));
const [getQtd, getWorkshift] = chain(pluck, ["quantity", "workshifts"]);
const totalFreelasComputed = pipe(getWorkshift, flatten, getQtd, sum);
const totalOrZero = (total) => (isNaN(total) ? 0 : total);
const getTotalVacancy = (services) =>
  pipe(totalFreelasComputed, totalOrZero)(services);

const greaterThenFive = flip(gt)(5);
const groupWithByDate = (a, b) => a.date == b.date;
const groupDate = (services) =>
  groupWith(groupWithByDate, flatten(getWorkshift(services)));
const exceededAmountFreela = (grouping) =>
  any(greaterThenFive)(map((item) => pipe(getQtd, sum)(item), grouping));

const getters = {
  form: (state) => state.form,
  dates: (state) => mapToDate(state.form.dates).sort(sortByDate),
  draft: (state) => state.draft,
  draftUpdatedAt: (state) => state.form.draftUpdatedAt,
  currentStep: (state) => state.currentStep,
  servicesAdded: (state) => mapToArray(state.form.services),
  totalFreelasInServices: (state) =>
    getTotalVacancy(mapToArray(state.form.services)),
  currentService: (state) => state.form.services,
  addressesFound: (state) => state.addressesFound,
  generalDescription: (state) => state.form.generalDescription,
  eventTypeNew: (state) => state.form.event.type,
  eventTopicNew: (state) => state.form.event.topic,
  totalFreelas: (state) => state.form.totalFreelas,
  upToFiveFreelas: (state) => state.form.totalFreelas === "5",
  totalSteps: (state) => (state.form.totalFreelas == "5" ? 4 : 3),
  mapLocation: (state) =>
    state.form.mapLocation
      ? `data:image/png;base64, ${state.form.mapLocation}`
      : undefined,
  imageFile: (state) => state.form.imageFile,
  event: (state) => state.form.event,
  location: (state) => state.form.location,
  hasSecurityProtocol: (state) => state.form.hasSecurityProtocol,
  isHomeOffice: (state) => state.form.isHomeOffice,
  qrCodeCheckinCheckout: (state) => {
    return {
      hasCheckinQrCode: state.form.hasCheckinQrCode,
      hasCheckoutQrCode: state.form.hasCheckoutQrCode,
    };
  },
  requiresFullProfile: (state) => state.form.requiresFullProfile,
  contact: (state) => state.form.contact,
  eventProfileTypes: (state) => state.form.eventProfileTypes,
  expectedAudience: (state) => state.form.expectedAudience,
  promotionalCode: (state) => state.form.promotionalCode,
  hasPromotionalCode: (state) => state.form.promotionalCode.length > 0,
  exceededAmountFreela: (state) =>
    state.form.totalFreelas == "5" &&
    exceededAmountFreela(groupDate(mapToArray(state.form.services))),
  hirer: (state) => state.form.hirer,
  agencyForServices: (state) => ({
    id: getUser().id,
    cnpj: state.form.agencyData.cnpj,
    name: state.form.agencyData.fantasyName,
    avatarUrl: state.form.agencyData.avatarUrl,
  }),
  isDuplicated: (state) => state.isDuplicated,
};

const mutations = {
  SET_FORM(state, data) {
    Object.assign(state.form, data);
  },

  SET_IS_DUPLICATED(state, value) {
    state.isDuplicated = value;
  },

  SET_AGENCY_DATA(state, agencyData) {
    Object.assign(state.form.agencyData, agencyData);
  },

  SET_DRAFT(state, draft) {
    Object.assign(state, getInitialState(draft));
  },

  RESET_STATE(state) {
    Object.assign(state, getInitialState(initialState));
  },

  SET_SERVICE(state, newMap) {
    state.form.services = newMap;
  },

  SET_ADDRESSES_FOUND(state, addresses) {
    state.addressesFound = addresses;
  },

  SET_HIRER(state, hirer) {
    state.form.hirer = hirer;
  },

  SET_MAP_LOCATION(state, mapLocation) {
    state.form.mapLocation = mapLocation;
  },
};

const strCurrencyToDecimal = (str) =>
  str?.split(" ").reverse()[0].replace(".", "").replace(",", ".");

const transformService = ([, service]) => ({
  ...service,
  totalVacancy: getTotalVacancy([service]),
  payment: strCurrencyToDecimal(service.payment),
  agencyProfit: strCurrencyToDecimal(service.agencyProfit),
  agencyId: service.agency.map((a) => a.id),
});

const transformToSave = ({
  event,
  agencyData: { cnpj },
  location,
  contact,
  generalDescription,
  imageFile = "",
  services,
  expectedAudience,
  eventProfileTypes,
  payment,
  agencyProfit,
  hirer,
  hasSecurityProtocol,
  isHomeOffice,
  hasCheckinQrCode,
  hasCheckoutQrCode,
  requiresFullProfile,
}) => ({
  serviceOrderNumber: event.OS,
  eventName: event.title,
  eventOwner: cnpj,
  description: generalDescription,
  expectedAudienceNumber: expectedAudience,
  eventProfileTypes: eventProfileTypes,
  eventTypeNew: event.type,
  eventTopicNew: event.topic,
  eventImage: normalizeToSave(imageFile),
  isManagerByAgency: false,
  hasSecurityProtocol: hasSecurityProtocol,
  isHomeOffice: isHomeOffice,
  hasCheckinQrCode,
  hasCheckoutQrCode,
  requiresFullProfile,
  payment,
  agencyProfit,
  services: [...services].map(transformService),
  contact: {
    name: contact.fullName,
    email: contact.email,
    phone: contact.tel.replace(/[()-]/g, ""),
  },
  eventLocation: location,
  hirer,
});

const transformToSaveWithPromotionalCode = ({
  id,
  event,
  location,
  contact,
  generalDescription,
  imageFile = "",
  services,
  expectedAudience,
  eventProfileTypes,
  promotionalCode,
  hasSecurityProtocol,
  isHomeOffice,
  hasCheckinQrCode,
  hasCheckoutQrCode,
  requiresFullProfile,
}) => ({
  id,
  promotionalCode,
  serviceOrderNumber: event.OS,
  eventName: event.title,
  description: generalDescription,
  expectedAudienceNumber: expectedAudience,
  eventProfileTypes: eventProfileTypes,
  eventTypeNew: event.type,
  eventTopicNew: event.topic,
  eventImage: normalizeToSave(imageFile),
  isManagerByAgency: false,
  hasSecurityProtocol: hasSecurityProtocol,
  isHomeOffice: isHomeOffice,
  hasCheckinQrCode,
  hasCheckoutQrCode,
  requiresFullProfile,
  services: [...services].map(transformService),
  contact: {
    name: contact.fullName,
    email: contact.email,
    phone: contact.tel.replace(/[()-]/g, ""),
  },
  eventLocation: location,
});

const actions = {
  duplicate: ({ state, rootState: { EditEvent }, commit }) => {
    EditEvent.form.services.forEach((service) => {
      service.payment = "";
      service.agencyProfit = "";
    });

    commit("SET_FORM", {
      ...EditEvent.form,
      event: { title: state.form.event.title, OS: state.form.event.OS },
    });
  },
  resetState: (state) => {
    state.commit("RESET_STATE") && localStorage.removeItem("eventDraft");
  },
  setEventDays: ({ state: { form }, commit }, dates) =>
    commit("SET_FORM", { ...form, dates }),
  setFormData: ({ commit }, data) => {
    commit("SET_FORM", { ...data });
  },
  setHirer: ({ commit }, hirer) => commit("SET_HIRER", hirer),
  save: ({ state: { form }, commit }, paymentData) => {
    form.isHomeOffice && delete form.location;
    const dataToSave = transformToSave({ ...form, payment: paymentData });
    return saveEvent(dataToSave)
      .then(() => actions.resetState({ commit }))
      .then(() => storage.clean("eventDraft"));
  },

  agencySave: ({ state: { form }, commit }) => {
    form.isHomeOffice && delete form.location;
    const dataToSave = transformToSave(form);
    return saveAgencyEvent(dataToSave)
      .then(() => actions.resetState({ commit }))
      .then(() => storage.clean("eventDraft"));
  },

  saveWithPromotionalCode: ({ state: { form }, commit }) => {
    form.isHomeOffice && delete form.location;
    const dataToSave = transformToSaveWithPromotionalCode(form);

    return saveEventWithPromotionalCode(dataToSave)
      .then(() => actions.resetState({ commit }))
      .then(() => storage.clean("eventDraft"));
  },

  saveEventWithThanFiveFreelas: ({ state: { form }, commit }) => {
    form.isHomeOffice && delete form.location;
    const dataToSave = transformToSave(form);

    return saveEventWithFiveThanFreelas(dataToSave)
      .then(() => actions.resetState({ commit }))
      .then(() => storage.clean("eventDraft"));
  },

  saveDraft: ({ state: { form }, commit, getters }) => {
    storage.set("eventDraft", {
      ...form,
      services: getters.servicesAdded,
      draftUpdatedAt: new Date(),
    });
    commit("SET_DRAFT", storage.get("eventDraft"));
  },

  addNewServices({ commit, state }, services) {
    const newMap = new Map(state.form.services);
    const exclusiveServices = getters.hirer(state).services;

    const getAgencyExclusivity = (service) =>
      isAgency() &&
      exclusiveServices
        .map((s) => s.toLowerCase())
        .includes(service.toLowerCase())
        ? [getters.agencyForServices(state)]
        : [];

    const newServices = services.map((service) => {
      return newMap.has(service)
        ? [service, newMap.get(service)]
        : [
            service,
            {
              totalVacancy: 0,
              job: service,
              payment: "",
              agencyProfit: "",
              responsabilities: [],
              checkListAtCheckin: [],
              checkListAtCheckout: [],
              workshifts: [],
              agency: getAgencyExclusivity(service),
            },
          ];
    });

    commit("SET_SERVICE", new Map(newServices));
  },

  updateService({ commit, state }, service) {
    const newMap = new Map(state.form.services);

    const serviceFounded = newMap.get(service.job);
    const updateedService = { ...serviceFounded, ...service };
    newMap.set(service.job, updateedService);

    commit("SET_SERVICE", newMap);
  },

  getMapLocation({ commit }, term) {
    getStaticMap(term)
      .then((data) => {
        commit("SET_MAP_LOCATION", data);
        //TODO: INCLUIR AQUI O TOASTR DE FEEDBACK
      })
      .catch(
        () => ({
          // console.log(response)
        })
        //TODO: INCLUIR AQUI O TOASTR DE FEEDBACK
      );
  },
  setAgencyData({ commit }, agencyData) {
    const { fantasyName: fullName, contact: agencyContactInfo } = agencyData;
    const { email, phone: tel } =
      agencyContactInfo !== null ? agencyContactInfo : {};

    commit("SET_FORM", { contact: { fullName, email, tel } });
    commit("SET_AGENCY_DATA", agencyData);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
