<template>
  <CustomAutocomplete
    placeholder="Cliente"
    label="Procurar"
    :limit="3"
    :search="search"
    v-model.trim="hirer"
    @selected="onHirerSelected">
    <template #option="{ item: { name, cnpj } }">
      <div class="d-flex direction-column pl-10">
        <small class="text-azure-radiance">
          <b>{{ name }}</b>
          - {{ cnpj }}
        </small>
      </div>
    </template>
    <template #empty>Nenhum cliente encontrado</template>
  </CustomAutocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import { searchHirer } from "@/services/agency/agency.http";
export default {
  props: ["value"],
  data: () => ({}),
  computed: {
    ...mapGetters({ agencyId: "User/userId" }),
    hirer: {
      get() {
        return this.value;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    search(term) {
      return searchHirer(this.agencyId, term);
    },
    clear() {
      this.hirer = "";
      this.$emit("selected", { name: null, cnpj: null });
    },
    onHirerSelected({ name, cnpj }) {
      this.hirer = name;
      this.$emit("selected", { name, cnpj });
    },
  },
};
</script>
