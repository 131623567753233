<template>
  <Collapsible v-if="AwaitingPayment">
    <template #header>
      <td>
        <custom-checkbox
          type="checkbox"
          @change="onChange"
          :item="item"
          :checked="item.checked" />
      </td>
      <td>{{ item.hirerName }}</td>
      <td>{{ item.eventName }}</td>
      <td>{{ item.startDate | formDate }} - {{ item.endDate | formDate }}</td>
      <td>{{ item.children | totalFreelas }}</td>
      <td>
        {{ item.children | totalValue }}
      </td>
    </template>

    <template #header-body>
      <th></th>
      <th></th>
      <th><small>Serviços</small></th>
      <th><small>Data de realização</small></th>
      <th><small>Qtd. Profissionais</small></th>
      <th><small>Valor por profissional</small></th>
      <th><small>Lucro da agência</small></th>
      <th>
        <small>Valor total por serviço</small>
      </th>
    </template>

    <template #body>
      <tr
        class="header-body"
        v-for="(service, index) in item.children"
        :key="index">
        <template v-if="service.status === 1">
          <td></td>
          <td>
            <CustomCheckbox
              type="checkbox"
              :item="service"
              :checked="service.checked"
              @change="onChange" />
          </td>
          <td>
            <small>{{ service.serviceName }}</small>
          </td>
          <td>
            <small>{{ service.day | formDate }}</small>
          </td>
          <td>
            <small>{{ service.totalFreelas }}</small>
          </td>
          <td>
            <small>R$ {{ service.valueByFreela | toCurrency }}</small>
          </td>
          <td>
            <small>R$ {{ service.agencyProfit | toCurrency }}</small>
          </td>
          <td>
            <small>R$ {{ service.totalValue | toCurrency }}</small>
          </td>
        </template>
      </tr>
    </template>
  </Collapsible>
</template>

<script>
import Collapsible from "@/components/shared/Collapsible.vue";
import { mapActions } from "vuex";

export default {
  components: { Collapsible },
  computed: {
    AwaitingPayment() {
      return !!this.item.children.filter((s) => s.status === 1).length;
    },
  },
  props: [
    "item",
    {
      service: Object,
    },
  ],
  filters: {
    totalValue(val) {
      const value = val.reduce(
        (acc, currentValue) => acc + currentValue.totalValue,
        0
      );
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    totalFreelas(val) {
      const value = val.reduce(
        (acc, currentValue) => acc + currentValue.totalFreelas,
        0
      );
      return value;
    },
  },

  methods: {
    ...mapActions({
      udpateEventServiceList: "EventDetails/udpateEventServiceList",
    }),
    onChange(node, checked) {
      this.udpateEventServiceList({ node, checked });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.header-body {
  border-bottom: 2px solid $Ebony;
  &:last-child {
    border-bottom: none;
  }
}
</style>
