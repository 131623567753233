<template>
  <Modal
    :name="name"
    @open="onModalOpen"
    @close="goDashboard"
    :title="title"
    ref="modal"
    size="mini">
    <section class="w-100 d-flex direction-column jc-center p-10">
      <span class="p-20">Você não tem permissão para acessar essa área.</span>
    </section>
    <template #footer>
      <div class="d-flex jc-end ai-end p-15">
        <button class="btn primary" @click="close">Ir para Indicadores</button>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  data: () => ({
    name: "forbidden-user",
    title: "Usuário sem acesso",
    slots: [],
    eventId: "",
    payment: 0,
    loading: false,
    callback: () => ({}),
  }),
  computed: {
    disabled() {
      return this.payment === 0;
    },
  },
  methods: {
    goDashboard() {
      this.$router.push("/");
    },
    close() {
      this.$refs.modal.close();
      this.goDashboard();
    },
    onModalOpen() {},
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.modal-content .section .modal-content.medium {
  height: 200px I !important;
  min-height: 200px i !important;
}
</style>
