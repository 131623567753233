import { getFreelaInfo, getAgencies } from "../services/events/event.http";

const initialState = () => ({
  freelaInfo: {
    avatar: require("@/assets/icon_avatar.svg"),
    name: "",
    email: "",
    phone: "",
    description: "",
    jobs: [],
    card: [
      { icon: "mdi mdi-human-male-height", title: "Altura", name: "--" },
      { icon: "mdi mdi-scale-bathroom", title: "Peso", name: "--" },
      { icon: "mdi mdi-tag", title: "Manequim", name: "--" },
      { icon: "mdi mdi-gender-male-female", title: "Gênero", name: "--" },
    ],
    cardAlternative: [
      { checked: false, title: "Transporte Próprio" },
      { checked: false, title: "Trabalhar Fixo" },
      { checked: false, title: "Material de Trabalho" },
      { checked: false, title: "Vestimenta Profissional" },
    ],
    skill: [],
    availability: [],
    daysOfWeek: {
      6: "Sabado",
      5: "Sexta",
      4: "Quinta",
      3: "Quarta",
      2: "Terça",
      1: "Segunda",
      0: "Domingo",
    },
    gallery: [],
  },
  agencies: [],
  cpf: "",
  cnpj: "",
  owner: "",
  bankCode: "",
  bankBranch: "",
  bankAccount: "",
  bankAccountType: "",
});

const state = initialState();

const gender = (type) => {
  return {
    0: "---",
    1: "Masculino",
    2: "Feminino",
    3: "Transgênero homem",
    4: "Transgênero mulher",
    5: "Queer +",
  }[type];
};

const sortByDaysOfWeek = (days) => {
  const daysOfWeek = days.sort(({ dayOfWeek: a }, { dayOfWeek: b }) =>
    a > b ? 1 : a < b ? -1 : 0
  );
  return daysOfWeek;
};

const getters = {
  freelaInfo: (state) => state.freelaInfo,
  agencies: (state) => state.agencies,
};

const mutations = {
  SET_AGENCIES: (state, agencies) => (state.agencies = agencies),
  SET_FREELAINFO: (state, { value: freelaInfo }) => {
    state.freelaInfo = {
      ...freelaInfo,
      avatar: freelaInfo.image,
      cardAlternative: [
        { checked: freelaInfo.ownTransport, title: "Transporte Próprio" },
        { checked: freelaInfo.healthProblem, title: "Trabalhar Fixo" },
        { checked: freelaInfo.smoke, title: "Material de Trabalho" },
        {
          checked: freelaInfo.professionalClothing,
          title: "Vestimenta Profissional",
        },
      ],
      card: [
        {
          icon: "mdi mdi-human-male-height",
          title: "Altura",
          name: `${freelaInfo.height / 100}M`,
        },
        {
          icon: "mdi mdi-scale-bathroom",
          title: "Peso",
          name: `${freelaInfo.weight}KG`,
        },
        {
          icon: "mdi mdi-tag",
          title: "Manequim",
          name: freelaInfo.clothingsSizes || "--",
        },
        {
          icon: "mdi mdi-gender-male-female",
          title: "Gênero",
          name: gender(freelaInfo.gender) || "--",
        },
      ],
      daysOfWeek: state.freelaInfo.daysOfWeek,
      availability: sortByDaysOfWeek(freelaInfo.availability.days),
    };
  },
};

const actions = {
  getFreelaInformations({ commit }, freelaId) {
    getFreelaInfo(freelaId).then((result) => commit("SET_FREELAINFO", result));
    getAgencies(freelaId).then((result) =>
      commit("SET_AGENCIES", result.value)
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
