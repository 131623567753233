<template>
  <div class="event-occurrences d-flex direction-column ph-10 pv-2">
    <header>
      <div class="header-occurrences d-flex w-100 jc-space-between ai-center">
        <div
          class="total d-flex direction-column jc-space-between cursor-pointer"
          @click="view.statusToShow = [...Object.values(INCIDENT_STATUS)]">
          <span class="text-red font-size-1-2">{{ occurrences.length }}</span>
          <small>Ocorrências</small>
        </div>
        <i class="separator mdi mdi-chevron-right"></i>
        <div
          class="total-status pv-5 opened d-flex direction-column ai-center"
          :class="{
            disabled: !view.statusToShow.includes(INCIDENT_STATUS.OPENED),
          }"
          @click="toggleStatus(INCIDENT_STATUS.OPENED)">
          <small>Em aberto</small>
          <hr class="mt-1 border" />
          <span>{{ totalOpened }}</span>
        </div>
        <i class="separator mdi mdi-chevron-right"></i>
        <div
          class="total-status pv-5 in-progress d-flex direction-column ai-center"
          :class="{
            disabled: !view.statusToShow.includes(INCIDENT_STATUS.IN_PROCCESS),
          }"
          @click="toggleStatus(INCIDENT_STATUS.IN_PROCCESS)">
          <small>Em progresso</small>
          <hr class="mt-1 border" />
          <span>{{ totalInProgress }}</span>
        </div>
        <i class="separator mdi mdi-chevron-right"></i>
        <div
          class="total-status pv-5 resolved d-flex direction-column ai-center"
          :class="{
            disabled: !view.statusToShow.includes(INCIDENT_STATUS.RESOLVED),
          }"
          @click="toggleStatus(INCIDENT_STATUS.RESOLVED)">
          <small>Resolvido</small>
          <hr class="mt-1 border" />
          <span>{{ totalResolved }}</span>
        </div>
      </div>
      <hr />
    </header>

    <md-content class="occurrences-list md-scrollbar">
      <OccurrenceItem
        :key="key"
        v-for="(occurrence, key) in occurrencesToDisplay"
        :occurrence="occurrence"
        @statusButtonClick="onStatusButtonClick" />
    </md-content>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OccurrenceItem from "./OccurrenceItem";
import { INCIDENT_STATUS } from "./occurrenceStatus";

const totalByStatus = (occurrences, STATUS) =>
  occurrences
    .reduce((total, { status }) => total + Number(status === STATUS), 0)
    .toString()
    .padStart(2, "0");

export default {
  components: { OccurrenceItem },
  data: () => ({
    view: {
      statusToShow: [...Object.values(INCIDENT_STATUS)],
    },
    INCIDENT_STATUS,
  }),
  computed: {
    ...mapGetters({
      occurrences: "Dashboard/occurrences",
    }),
    totalOpened() {
      return totalByStatus(this.occurrences, INCIDENT_STATUS.OPENED);
    },
    totalInProgress() {
      return totalByStatus(this.occurrences, INCIDENT_STATUS.IN_PROCCESS);
    },
    totalResolved() {
      return totalByStatus(this.occurrences, INCIDENT_STATUS.RESOLVED);
    },
    occurrencesToDisplay() {
      const enabledStatus = ({ status }) =>
        this.view.statusToShow.includes(status);

      return this.occurrences.filter(enabledStatus);
    },
  },
  methods: {
    ...mapActions({
      changeOccurrenceStatus: "Dashboard/changeOccurrenceStatus",
    }),
    onStatusButtonClick(occurrenceStatus) {
      this.changeOccurrenceStatus(occurrenceStatus);
    },
    toggleStatus(status) {
      this.view.statusToShow = this.view.statusToShow.includes(status)
        ? this.view.statusToShow.filter(
            (availableStatus) => availableStatus !== status
          )
        : this.view.statusToShow.concat([status]);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

@media (max-width: 991px) {
  .separator {
    display: none;
  }
  .header-occurrences {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.border {
  width: 80%;
  @media (min-width: 992px) {
    width: 100%;
  }
}

.event-occurrences {
  background: $Ebony;
  border-radius: 5px;
  height: 100%;

  hr {
    border-color: $Martinique;
    background: $Martinique;
    height: 2px;
  }

  .total {
    text-align: center;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;

    &:hover {
      background: rgba(white, 0.1);
    }

    :last-child {
      font-size: 0.6rem;
    }
  }

  i.separator {
    font-size: 1.5rem;
    line-height: normal;
  }

  .total-status {
    flex-grow: 1;
    cursor: pointer;
    // padding: 5px 10px;
    border-radius: 10px;

    &:hover {
      background: rgba(white, 0.1);
    }

    small {
      font-size: 0.7rem;
      font-weight: 200;
    }

    span {
      font-size: 1.2rem;
      font-weight: 500;
    }

    @mixin incident-title-style($color) {
      color: $color;

      small {
        color: white;
      }

      hr {
        background: $color;
        border: 1px solid $color;
        height: 3px;
      }

      &.disabled {
        color: $Martinique;

        small {
          color: rgba(white, 0.5);
        }

        hr {
          background: $Martinique;
          border-color: $Martinique;
        }
      }
    }

    &.opened {
      @include incident-title-style($Red);
    }
    &.in-progress {
      @include incident-title-style($Orange);
    }
    &.resolved {
      @include incident-title-style($LightGreen);
    }
  }

  .occurrences-list {
    height: 100%;
    min-height: 280px;

    &.md-scrollbar.md-theme-default {
      background: transparent;
      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar-track {
        background: $Martinique;
        border-radius: 10px;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
}
</style>
