<template>
  <section class="date-hours">
    <h3 class="title">Datas</h3>
    <div class="d-flex direction-column-sm">
      <div class="d-flex jc-center-sm">
        <v-calendar
          class="date-picker"
          is-dark
          is-inline
          :firstDayOfWeek="1"
          :min-date="new Date()"
          @dayclick="onDayClick"
          :attributes="attributes" />
      </div>

      <div class="ml-30 ml-0-sm w-100">
        <div class="overview">
          <h4 class="title mt-0">Resumo</h4>

          <div class="card mt-5">
            <div class="card-info">
              <span>{{ datesAmount }}</span>
              <span class="days-label">{{ datesLabel }}</span>
            </div>

            <div class="card-content">
              <div
                class="card-content-item"
                v-for="(date, index) in datesSelected"
                :key="`${index}`">
                {{ date }}
              </div>
            </div>
          </div>
          <br />
          <p class="text-Manatee font-italic">
            Adicione todas as datas, informando os dias de acordo as demandas
            dos profissionais.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setupCalendar } from "v-calendar";
setupCalendar({ firstDayOfWeek: 1 });

import { formatDate } from "@/helpers/dateFormat";

export default {
  components: {},
  computed: {
    dates: {
      get() {
        return this.$store.getters["EditEvent/isEdit"]
          ? this.$store.getters["EditEvent/dates"]
          : this.$store.getters["NewEvent/dates"];
      },
      set(value) {
        this.$store.dispatch("NewEvent/setEventDays", [value]);
      },
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
    datesLabel() {
      return this.dates.length === 1 ? "DIA" : "DIAS";
    },
    datesAmount() {
      return this.dates.length.toString().padStart(2, "0");
    },
    datesSelected() {
      const format = (day) =>
        `${formatDate(day, "dddd")}, ${formatDate(day, "D")} ${formatDate(
          day,
          "MMMM"
        )} de ${formatDate(day, "YYYY")}`;
      return Array.from(new Set(this.dates.map(format))); // removendo duplicádos
    },
  },
  methods: {
    onDayClick(day) {
      const datesValidation = this.dates.map((x) =>
        new Date(x)
          .toLocaleDateString("pt-BR")
          .includes(new Date(day.date).toLocaleDateString("pt-BR"))
      );
      if (!datesValidation.includes(true)) {
        this.$store.dispatch("NewEvent/setEventDays", [
          ...this.dates,
          day.date,
        ]);
      } else {
        this.$store.dispatch(
          "NewEvent/setEventDays",
          this.dates.filter(
            (x) =>
              ![new Date(x).toLocaleDateString("Pt-BR")].includes(
                new Date(day.date).toLocaleDateString("pt-BR")
              )
          )
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.min-width-270 {
  min-width: 270px !important;
}

.date-hours {
  .date-picker {
    height: fit-content;
  }

  .overview {
    width: 100%;

    .title {
      font-size: 14px;
    }

    .card {
      display: flex;
      padding: 15px 20px;
      border-radius: 10px;
      background-color: $Ebony;

      .card-info {
        width: 60px;
        color: $Ebony;
        height: 60px;
        display: flex;
        font-size: 18px;
        font-weight: 600;
        align-items: center;
        border-radius: 8px;
        flex-direction: column;
        justify-content: center;
        background-color: $Malibu;

        .days-label {
          font-size: 10px;
          line-height: 1;
        }
      }

      .card-content {
        margin: 0px 10px;

        .card-content-item {
          margin: 0px 0px 5px;
        }
      }
    }
  }
}
</style>
