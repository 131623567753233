<template>
  <div class="container-landing">
    <div class="container-bg-img">
      <!-- Header Landing -->
      <header class="header-landing">
        <div class="container-logo">
          <img alt="Logo da LanUp" src="@/assets/logo.svg" />
        </div>
        <nav id="menuToggle" class="container-menu-landing">
          <input type="checkbox" />
          <!-- hamburger menu -->
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu" class="menu-landing">
            <li class="menu-item"><a href="#alanup">A LanUp</a></li>
            <li class="menu-item"><a href="#souagencia">Sou Empresa</a></li>
            <li class="menu-item"><a href="#soutalento">Sou Talento</a></li>
          </ul>
        </nav>
        <div class="button-login">
          <a class="btn primary submit-button" href="/acesso/login">Entrar</a>
        </div>
      </header>

      <!-- Section Headlines -->
      <section class="section-headlines">
        <h1 class="title-headline">Contrate, Planeje e Acompanhe</h1>
        <ul class="subheadline">
          <li class="create">
            <span>CRIE</span>
            a demanda;
          </li>
          <li class="write">
            <span>ESCREVA</span>
            o que precisa;
          </li>
          <li class="getit">
            <span>CONTRATE</span>
            seu staff;
          </li>
        </ul>
        <div class="container-options">
          <h3 class="title-options">Lanup conecta todos os lados:</h3>
          <ul class="card-options">
            <li class="card hirer">
              <h5 class="card-title">Contratante</h5>
              <p class="card-description">
                Planeje o sua demanda de um jeito mais simples e ágil. E
                acompanhe sua demanda do início ao fim
              </p>
              <a href="" class="card-action">Sou contratante</a>
            </li>
            <li class="card agency">
              <h5 class="card-title">Empresa</h5>
              <p class="card-description">
                Receba propostas de demandas, planeje seu serviço e, encontre e
                gerencie os seus talentos em um só aplicativo
              </p>
              <a href="" class="card-action">Sou empresa</a>
            </li>
            <li class="card freelancer">
              <h5 class="card-title">Freelancer</h5>
              <p class="card-description">
                Encontre mais vagas de Jobs de jeito simples e ágil de acordo
                com o seu perfil
              </p>
              <a href="" class="card-action">Sou freenlacer</a>
            </li>
          </ul>
        </div>
      </section>
    </div>

    <!-- Section Slide -->
    <section class="section-slide" id="alanup">
      <div class="main-circle">
        <div class="second-circle">
          <VueSlickCarousel :arrows="true" :dots="false">
            <div>
              <h5 class="slide-title">Quem Somos?</h5>
              <div class="slide-img">
                <img
                  src="../../assets/landing/quemsomos.jpg"
                  alt="Quem somos" />
              </div>

              <p class="slide-description">
                A LANUP, é um software de automação que nasceu da compreensão e
                dores da seleção, contratação e gestão operacional de dois
                grandes players do segmento de bares, restaurantes e eventos. Em
                2019 juntos, contrataram 27 mil colaboradores, faturando mais de
                5 milhões de reais através de nossa solução.
              </p>
            </div>
            <div>
              <h5 class="slide-title">Por que a LanUp?</h5>
              <div class="slide-img">
                <img src="../../assets/landing/phones.png" alt="Quem somos" />
              </div>
              <h5 class="subtitle">Simples e Ágil</h5>
              <p class="slide-description">
                Com o LanUp você consegue encontrar de forma simples e ágil
                profissionais que você precisa contratar para seu evento.
                Ajudando negócios a qualificar sua mão de obra, reduzindo custos
                e aumentando produtividade
              </p>
            </div>
            <div>
              <h5 class="slide-title">Como resolvemos?</h5>
              <div class="slide-img">
                <img
                  src="../../assets/landing/contratante.jpg"
                  alt="Quem somos" />
              </div>
              <h5 class="subtitle">Contratante</h5>
              <p class="slide-description">
                - Criação do evento com vagas planejadas e urgentes;
                <br />
                - Contratação direta até 5 profissionais por evento;
                <br />
                - Recebimento de orçamentos das empresas;
                <br />
                - Gestão, avaliação e acompanhamento do evento;
                <br />
                - Geolocalização dos profissionais até o evento.
                <br />
              </p>
            </div>
            <div>
              <h5 class="slide-title">Como resolvemos?</h5>
              <div class="slide-img">
                <img src="../../assets/landing/freelas.jpg" alt="Quem somos" />
              </div>
              <h5 class="subtitle">Empresa</h5>
              <p class="slide-description">
                - Criação do banco de talentos;
                <br />
                - Criação do evento com vagas planejadas e urgentes;
                <br />
                - Seleção, escalação de times e contratação de profissionais;
                <br />
                - Recebimento de orçamentos dos contratantes;
                <br />
                - Geolocalização, acompanhamento, gestão operacional e avaliação
                dos times antes e durante os eventos.
              </p>
            </div>
            <div>
              <h5 class="slide-title">Como resolvemos?</h5>
              <div class="slide-img">
                <img src="../../assets/landing/freelas.jpg" alt="Quem somos" />
              </div>
              <h5 class="subtitle">Time</h5>
              <p class="slide-description">
                - Cadastro nas empresas contratantes pelo AppLanUP;
                <br />
                - Recebimento de convite e vagas planejadas ou urgentes conforme
                o cadastro e a geolocalização;
                <br />
                - Agenda dos jobs;
                <br />
                - Avaliação do job e contratante;
                <br />
                - Controle dos pagamentos;
                <br />
                - Capacitação e certificação.
                <br />
              </p>
            </div>
            <template #prevArrow>
              <div class="custom-arrow prev"></div>
            </template>
            <template #nextArrow>
              <div class="custom-arrow next"></div>
            </template>
          </VueSlickCarousel>
        </div>
      </div>
    </section>

    <!-- Section For Agencies -->
    <section class="section-border for-agencies" id="souagencia">
      <img class="stripe" src="../../assets/landing/stripes_01.png" />
      <div class="container-center">
        <div class="container-left">
          <h3 class="title-section">Para Empresa e Contratante</h3>
          <p class="subtitle-section">
            Melhore a gestão e performance e aumente os resultados do seu
            negócio
          </p>
          <div class="action-section">
            <a class="learn-more" href="">Comece agora</a>
          </div>
        </div>
        <div class="container-right container-notebook">
          <img
            width="770"
            class="image-highlight"
            src="../../assets/landing/notebook.png"
            alt="Phones Image" />
        </div>
      </div>
    </section>

    <!-- Section For Talents -->

    <div class="container-section-reverse" id="soutalento">
      <section class="section-border for-talents">
        <img class="stripe" src="../../assets/landing/stripes_02.png" />

        <div class="container-center">
          <div class="container-left">
            <h3 class="title-section">Para Talentos</h3>
            <p class="subtitle-section">
              Com seus talentos e habilidades, receba vagas e aumente sua renda.
              <br />
              <br />
              Você e a vaga perfeita = MATCH PERFEITO
              <svg
                fill="#18142F"
                width="20px"
                viewBox="0 -28 512.00002 512"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m471.382812 44.578125c-26.503906-28.746094-62.871093-44.578125-102.410156-44.578125-29.554687 0-56.621094 9.34375-80.449218 27.769531-12.023438 9.300781-22.917969 20.679688-32.523438 33.960938-9.601562-13.277344-20.5-24.660157-32.527344-33.960938-23.824218-18.425781-50.890625-27.769531-80.445312-27.769531-39.539063 0-75.910156 15.832031-102.414063 44.578125-26.1875 28.410156-40.613281 67.222656-40.613281 109.292969 0 43.300781 16.136719 82.9375 50.78125 124.742187 30.992188 37.394531 75.535156 75.355469 127.117188 119.3125 17.613281 15.011719 37.578124 32.027344 58.308593 50.152344 5.476563 4.796875 12.503907 7.4375 19.792969 7.4375 7.285156 0 14.316406-2.640625 19.785156-7.429687 20.730469-18.128907 40.707032-35.152344 58.328125-50.171876 51.574219-43.949218 96.117188-81.90625 127.109375-119.304687 34.644532-41.800781 50.777344-81.4375 50.777344-124.742187 0-42.066407-14.425781-80.878907-40.617188-109.289063zm0 0" />
              </svg>
            </p>
            <div class="action-section">
              <!-- <a href="">
                  <img class="image-store" src="../../assets/landing/applestore.png" alt="Phones Image">
                </a> -->
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=br.com.lanup.app&hl=pt_BR">
                <img
                  class="image-store"
                  src="../../assets/landing/playstore.png"
                  alt="Phones Image" />
              </a>
            </div>
          </div>
          <div class="container-right container-phones">
            <img
              class="image-highlight"
              src="../../assets/landing/phones.png"
              alt="Phones Image" />
          </div>
        </div>
      </section>
    </div>

    <section class="section-border contact">
      <div class="container-left">
        <div class="container-form">
          <div class="form-group is-100"></div>
          <div class="form-group is-50">
            <CustomInput
              placeholder="Nome"
              v-model.trim="$v.form.name.$model"
              :error="errors.name" />
          </div>
          <div class="form-group is-50">
            <CustomInput
              placeholder="E-mail"
              v-model.trim="$v.form.email.$model"
              :error="errors.email" />
          </div>
          <div class="form-group is-100">
            <CustomSelect placeholder="Eu sou ">
              <option value="Default">Selecione</option>
              <option
                v-for="({ id, label }, key) in options"
                :key="key"
                :value="id">
                {{ label }}
              </option>
            </CustomSelect>
          </div>
          <div class="form-group is-100">
            <CustomInput
              placeholder="Mensagem"
              v-model.trim="$v.form.message.$model"
              :error="errors.message" />
            <!-- <textarea
                        class="textarea  radius-5"
                        rows="5"
                        cols="38"
                    ></textarea> -->
          </div>
          <div class="form-group is-100 cnt-button">
            <button class="btn primary">Enviar</button>
          </div>
        </div>
      </div>
      <div class="container-right">
        <ul class="list-contacts">
          <li class="item-contact">
            <h5 class="title-contact">CONTATO</h5>
            <p>
              Av. Brigadeiro Luís Antônio
              <br />
              contato@lanup.com.br
              <br />
              +55 11 99768-6143
            </p>
          </li>
          <li class="item-contact">
            <h5 class="title-socialmedia">REDES SOCIAIS</h5>
            <a target="_blank" href="https://www.facebook.com/AppLanUP/">
              Facebook
            </a>
            <a target="_blank" href="https://www.instagram.com/applanup/">
              Instagram
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/applanup/">
              LinkedIn
            </a>
            <a
              target="_blank"
              href="https://api.whatsapp.com/send?l=pt-BR&phone=5571993782527">
              Whatsapp
            </a>
            <a
              target="_blank"
              href="https://t.me/joinchat/AAAAAET84B96EqORSrfv-g">
              Telegram
            </a>
          </li>
          <!-- <li class="item-contact">
              <h5 class="title-company">Institucional</h5>
              <a href="">Sobre Nós</a>
              <a href="">FAQ</a>
              <a href="">Termos de uso</a>
            </li> -->
        </ul>
      </div>
    </section>
    <footer class="footer-landing">
      <p>
        © LanUp todos os direitos reservados - {{ currentYear.getFullYear() }}
      </p>
    </footer>
    <!-- Start of Async Drift Code -->

    <!-- End of Async Drift Code -->
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { required, email } from "vuelidate/lib/validators";
import { whenDirty } from "@/helpers/form/validations";

const requiredLabel = "Campo obrigatório";

const errorMessages = {
  email: {
    required: requiredLabel,
    email: "Digite um email válido",
  },
  phone: {
    required: requiredLabel,
  },
  address: {
    required: requiredLabel,
  },
};

const makeGetErrors = (fieldValidationMeta) => (field) =>
  whenDirty(fieldValidationMeta[field], errorMessages[field])[0];

export default {
  name: "Landing",
  components: { VueSlickCarousel },
  mounted() {
    // this.$drift.identify('yxhnfmrfpvuk', {
    //   name: 'LanUp Holder',
    //   email: 'lanup.app@gmail.com',
    // });
  },
  destroyed() {
    // this.$drift.hide();
  },
  computed: {
    errors() {
      const getErrors = makeGetErrors(this.$v.form);

      return {
        name: getErrors("name"),
        email: getErrors("email"),
        subject: getErrors("subject"),
        message: getErrors("message"),
      };
    },
  },
  data: () => ({
    currentYear: new Date(),
    loading: false,
    form: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    options: [
      {
        id: 1,
        label: "Empresa",
      },
      {
        id: 2,
        label: "Contratante",
      },
      {
        id: 3,
        label: "Freelancer",
      },
    ],
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      name: {
        required,
      },
      subject: {
        required,
      },
      message: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.container-landing {
  font-family: "HelveticaNowMicro";
  overflow: hidden;
}
.header-landing {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  .container-logo {
    width: 100px;
  }
  .container-menu-landing input {
    display: none;
  }
  .menu-landing {
    display: flex;
    justify-content: center;
    .menu-item {
      margin-right: 20px;
      a {
        color: #fff;
      }
    }
  }
  .button-login {
    a {
      font-weight: normal;
      color: #fff;
      padding: 6px 20px;
    }
  }
}

// Section Headlines
.container-bg-img {
  background: linear-gradient(0deg, #18142ffc, #1d17388c),
    url("../../assets/landing/header-landing.png") center center no-repeat;
  background-size: cover;
}
.section-headlines {
  padding: 20px;
}
.subheadline {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  li {
    font-size: 30px;
    font-weight: 200;
    margin-right: 40px;
    span {
      font-weight: 400;
    }
    &.create span {
      color: $AzureRadiance;
    }
    &.write span {
      color: $Red;
    }
    &.getit span {
      color: $BrightSun;
    }
  }
}
.title-headline {
  text-align: center;
  font: normal 70px "HelveticaNowMicro";
  line-height: 98px;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 125px 0 25px;
}
.container-options {
  margin-top: 40px;
  padding: 30px 0;
}
.title-options {
  font-size: 30px;
  font-weight: normal;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 73px;
}
.card-options {
  display: flex;
  justify-content: space-evenly;
  .card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 30%;
    min-height: 364px;
    border-width: 2px;
    border-style: solid;
    padding: 24px 36px;
    text-align: center;
    background: $SteelGray;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    &.hirer {
      border-color: $PictonBlue;
      .card-title {
        color: $PictonBlue;
      }
      .card-action {
        background-color: $PictonBlue;
      }
    }
    &.agency {
      border-color: $Pink;
      .card-title {
        color: $Pink;
      }
      .card-action {
        background-color: $Pink;
      }
    }
    &.freelancer {
      border-color: $BrightSun;
      .card-title {
        color: $BrightSun;
      }
      .card-action {
        background-color: $BrightSun;
      }
    }
    .card-title {
      font-size: 30px;
      font-weight: normal;
      line-height: 42px;
      margin: 0;
    }
    .card-description {
      font: normal normal normal 20px/40px "HelveticaNowMicro";
    }
    .card-action {
      font: normal normal bold 20px/29px "HelveticaNowMicro";
      letter-spacing: 0px;
      color: #18142f;
      padding: 8px 40px;
      border-radius: 34px;
      align-self: flex-end;
    }
  }
}

//  Section Slide
.slick-list {
  width: 75%;
  margin: 0 auto !important;
  text-align: center;
}
.custom-arrow {
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
  top: 40%;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;

  &.next {
    border-left: 41px solid #fcd83b;
    right: -90px;
  }
  &.prev {
    border-right: 41px solid #fcd83b;
    left: -90px;
  }
}
.main-circle {
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
  width: 1002px;
  margin: auto;
  background-color: #292045;
  padding: 46px 0;
}
.second-circle {
  width: 90%;
  padding: 90px 30px 70px;
  margin: auto;
  border: 30px solid #44336a;
  border-radius: 50%;
  background: linear-gradient(0deg, #e84988, #875ebf);
}
.slide-title {
  color: #fff;
  font: normal 35px "Quicksand";
  text-align: center;
  letter-spacing: 4.48px;
  text-transform: uppercase;
  margin: 20px auto;
}
.slide-img {
  max-width: 80%;
  margin: auto;
  height: 300px;
  overflow: hidden;
  position: relative;

  img {
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    height: 100%;
    width: auto;
  }
}
.subtitle {
  margin-top: 30px;
  color: #fff;
  font: normal 20px "HelveticaNowMicro";
  text-align: center;
  margin: 20px 0 10px;
}
.slide-description {
  color: #fff;
  font: normal 14px/23px "HelveticaNowMicro";
  text-align: center;
  width: 74%;
  margin: 27px auto 0;
}

// Section For Agencies
.section-border {
  height: 350px;
  margin: 125px 0;
  width: 90%;
  border-radius: 0 272px 273px 0;
  display: flex;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 2000px;
    height: 100%;
    z-index: -1;
  }
  .container-center {
    width: 90%;
    margin: auto;
    display: flex;
  }
  .container-left {
    width: 50%;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;
    justify-content: flex-end;
    position: relative;
    left: 28px;
  }
}
.title-section {
  color: #18142f;
  font: bold 30px "HelveticaNowMicro";
  margin-bottom: 30px;
}
.subtitle-section {
  font: normal 17px "HelveticaNowMicro";
  color: #18142f;
  width: 90%;
  max-width: 330px;
}
.action-section {
  .learn-more {
    font: normal 26px "HelveticaNowMicro";
    color: #ffffff !important;
    background: #18142f;
    padding: 13px 57px;
    border-radius: 36px;
    margin-top: 28px;
    display: block;
  }
}

.for-agencies {
  background: rgba(252, 217, 59, 0.842);
  position: relative;
  .stripe {
    position: absolute;
    width: 53%;
    z-index: -1;
    top: -117px;
  }
  &:before {
    background: rgba(252, 217, 59, 0.04);
    left: -1803px;
  }
  .container-notebook {
    position: relative;
    left: 130px;
    top: -82px;
  }
}
.container-section-reverse {
  display: flex;
  flex-direction: row-reverse;
  background: url("../../assets/landing/handsup.svg") no-repeat;
  margin-bottom: 0 !important;
  height: 831px;
  background-position: center 151px;
  background-size: contain;
}
.for-talents {
  background: rgba(235, 72, 134, 0.76);
  border-radius: 272px 0 0 272px;
  position: relative;
  .stripe {
    position: absolute;
    width: 100%;
    z-index: -1;
    top: -254px;
    left: -165px;
  }
  .container-left {
    width: 55%;
  }
  .subtitle-section {
    width: 100%;
    max-width: 512px;
    margin: 0;
  }
  &:before {
    background: rgba(235, 72, 134, 0.082);
    right: -1803px;
  }
  .image-store {
    width: 160px !important;
  }
  .container-center {
    flex-flow: row-reverse;
    .container-left {
      flex-direction: row-reverse;
      text-align: left;
      left: -163px;
    }
  }
  .container-phones {
    position: relative;
    right: 130px;
    top: -82px;
    img {
      width: 84%;
    }
  }
  .action-section {
    display: flex;
    margin-top: 29px;
    a {
      margin-right: 20px;
    }
  }
}

// Section Contact
.contact {
  background: #18142f;
  margin: 0 0;
  position: relative;
  top: -100px;
  padding: 40px 0;
  .container-right {
    width: 50%;
  }
}
.container-form {
  width: 90%;
  text-align: left;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  .is-50 {
    width: calc(50% - 10px);
  }
  .is-100 {
    width: 100%;
    margin-top: 15px;
  }
  .cnt-button {
    text-align: right;
    padding-right: 5px;
  }
  .btn {
    align-self: flex-end;
    margin-left: auto;
  }
}
.list-contacts {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  margin-left: auto;
  position: relative;
  right: 10px;
  justify-content: space-between;
  .item-contact {
    font: normal 15px/24px "HelveticaNowMicro";
    letter-spacing: 0px;
    color: #dbdbdb;
    width: 49%;
    padding: 0 20px;
    h5 {
      font: normal 17px/20px "HelveticaNowMicro";
      text-transform: uppercase;
    }
    p {
      margin: 0;
      color: #fff;
    }
    a {
      display: block;
      margin-top: 5px;
      color: #fff;
    }
    .title-contact {
      color: $BrightSun;
    }
    .title-socialmedia {
      color: $PictonBlue;
    }
    .title-company {
      color: $Pink;
    }
  }
}

.footer-landing {
  width: 100%;
  padding: 50px 0;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .title-headline {
    font: normal 40px "HelveticaNowMicro";
    line-height: 58px;
    margin: 100px 0 25px;
  }
  .subheadline li {
    font-size: 14px;
  }
  .title-options,
  .card-options .card .card-title {
    font-size: 19px;
  }
  .card-options .card .card-description,
  .card-options .card .card-action {
    font-size: 13px;
    line-height: 24px;
  }
  .card-options .card {
    min-height: 280px;
  }
  .title-section {
    font-size: 17px;
    margin: 35px 0 7px;
  }
  .subtitle-section {
    font-size: 13px;
  }
  .action-section .learn-more {
    font-size: 14px;
    padding: 13px 47px;
    margin-top: 0px;
  }
  .section-border {
    height: 275px;
  }
  .for-talents .container-left {
    width: 91%;
  }
  .container-section-reverse {
    height: 609px;
  }
  .list-contacts {
    width: 86%;
  }
  .list-contacts .item-contact h5 {
    font: normal 14px/13px "HelveticaNowMicro";
  }
  .list-contacts .item-contact p,
  .list-contacts .item-contact a {
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    .container-bg-img {
      background-size: initial;
      background-position: top center;
    }
    .card-options {
      flex-wrap: wrap;
    }
    .card-options .card {
      width: 90%;
      max-width: 500px;
      margin: 0 0 56px;
    }
    .title-headline {
      font: normal 24px "HelveticaNowMicro";
      line-height: 33px;
      margin: 25px 0 25px;
    }
    .subheadline {
      justify-content: center;
      flex-wrap: wrap;
      li {
        width: 90%;
        text-align: center;
        margin-bottom: 14px;
      }
    }
    .container-options {
      margin-top: 0;
      padding: 22px 0 0;
    }
    .card-options .card {
      min-height: auto;
    }
    .main-circle {
      width: 700px;
    }
    .slide-title {
      font-size: 20px;
    }
    .slide-description {
      font: normal 12px/20px "HelveticaNowMicro";
      width: 95%;
      margin: 27px auto 0;
    }
    .custom-arrow {
      border-top: 35px solid transparent;
      border-bottom: 35px solid transparent;

      &.next {
        border-left: 19px solid #fcd83b;
      }
      &.prev {
        border-right: 19px solid #fcd83b;
      }
    }
    .slide-img {
      width: 62%;
    }
    .second-circle {
      width: 90%;
      padding: 57px 30px 40px;
    }
    .section-border .container-center {
      flex-wrap: wrap;
    }
    .section-border .container-left {
      width: 88%;
    }
    .for-agencies .container-notebook {
      position: relative;
      left: 0;
      top: 0;
      width: 63%;
      margin: 31px;
      margin-left: auto;
    }
    .subtitle-section {
      max-width: 90%;
    }
    .section-border {
      height: 380px;
    }
    .for-agencies {
      margin: 125px 0 0;
    }
    .for-talents .container-center .container-left {
      left: 0;
    }
    .for-talents .container-phones {
      right: 0;
      top: 0;
      img {
        width: 300px;
      }
    }
    .for-talents .container-phones {
      left: 70px;
      top: 25px;
      right: 0;
      margin-right: auto;
    }
    .section-border.contact {
      height: auto;
      width: 100%;
      top: 30px;
      flex-wrap: wrap;
    }
    .contact .container-right {
      width: 100%;
    }
    .container-form {
      margin-bottom: 30px;
    }
    .container-form .is-50 {
      width: 100%;
    }
    .list-contacts .item-contact {
      width: 100%;
    }
  }
  @media screen and (max-width: 560px) {
    .container-menu-landing input {
      display: block;
    }

    #menuToggle {
      display: block;
      position: absolute;
      left: 140px;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;
    }
    #menuToggle a {
      text-decoration: none;
      color: #fff;
      font-size: 20px;
      transition: color 0.3s 1.5s ease-in;
      line-height: 31px;
      font-size: 17px;
      opacity: 0;
    }
    #menuToggle input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
    }
    #menuToggle span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: #cdcdcd;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    #menuToggle span:first-child {
      transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
    #menuToggle input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #232323;
    }
    #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }
    #menuToggle input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px);
    }

    #menu {
      opacity: 0.4;
      position: absolute;
      width: 193px;
      height: 193px;
      transform: scale(0.3);
      left: -268px;
      top: -201px;
      padding: 0px;
      text-align: center;
      border-radius: 50%;
      background: #7541bf;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      transition: all 0.3s ease-in-out;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    #menu li {
      padding: 6px 6px 0 3px;
      text-align: right;
    }

    #menuToggle input:checked ~ ul {
      transform: scale(1);
      opacity: 1;
      width: 293px;
      height: 293px;
      left: -187px;
      top: -100px;
      padding: 51px;
      text-align: center;
      border-radius: 50%;
      padding-top: 125px;
      background: #7541bf;
    }
    #menuToggle input:checked ~ ul a {
      opacity: 1;
    }
    footer p {
      font-size: 10px;
    }
    .section-border {
      width: 99%;
    }
    .section-border .container-center {
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      .container-left,
      .container-right {
        width: 100%;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        text-align: center;
        justify-content: center;
        img {
          width: 70%;
        }
      }
    }
    .for-agencies .container-notebook {
      top: 45px;
    }
    .subtitle-section {
      max-width: 80%;
    }
    .image-store {
      width: 40%;
    }
  }
  .section-border.contact .container-left {
    width: 100%;
    left: 0;
  }
  .container-form {
    margin: 0 auto 30px;
  }
  .section-slide {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }

  .main-circle {
    left: 0;
    right: 0;
    margin: auto;
  }
  .second-circle {
    width: 90%;
    padding: 14px 30px 2px;
  }
  .slide-description {
    width: 73%;
  }
  slide-title {
    margin: 4px 0 19px;
  }
  .second-circle {
    position: relative;
  }
  .custom-arrow {
    &.next {
      right: 51px;
    }
    &.prev {
      left: 51px;
    }
  }
}

@media screen and (max-width: 450px) {
  .second-circle {
    width: 90%;
    padding: 32px 30px 42px;
  }
  .slide-img {
    width: 48%;
  }
  .slide-description {
    width: 57%;
  }
  .custom-arrow {
    &.prev {
      left: 87px;
    }
    &.next {
      right: 87px;
    }
  }
  .title-section {
    padding: 0 55px;
  }
  .for-talents .subtitle-section {
    width: 100%;
    max-width: 512px;
    margin: 0;
    padding: 0px 57px;
  }
}
@media screen and (max-width: 370px) {
}
</style>
