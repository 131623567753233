<template>
  <PageLayout class="freelas ml-28 mr-28">
    <template #header>
      <div class="freelas-header d-flex ai-center jc-space-between h-100 w-100">
        <h1 class="header-title">
          <span class="back-arrow" v-if="groupName" @click="backPage()">
            {{ headerLabel }}
          </span>
          <span v-else>
            {{ headerLabel }}
          </span>
          <span v-if="groupName">/ {{ groupName }}</span>
        </h1>

        <div class="d-flex ai-center jc-space-between w-10">
          <div class="cursor-pointer" @click="openCreateGroupModal">
            <i v-if="isOnGroupPage" class="mdi mdi-pencil text-malibu"></i>
            <div v-else class="d-flex ai-center">
              <span class="font-size-08 text-malibu">Criar grupo</span>
              <i class="mdi mdi-plus font-size-1-5 text-malibu"></i>
            </div>
          </div>
          <CustomInput beforeIcon="mdi-magnify" v-model="match" />
        </div>
      </div>
    </template>

    <template #content>
      <section class="freelas-page-content h-100 pb-28">
        <aside class="left-side">
          <CustomCard title="Banco de talentos" class="mt-15 radius-10">
            <div class="w-100 d-flex jc-start ai-center p-15 mb-10">
              <span class="">Total: {{ totalMembers }}</span>
            </div>
          </CustomCard>
          <FilterFreela @filterChange="onFilterChange" />
          <CustomCard title="Código" class="mt-15 radius-10">
            <div class="w-100 d-flex jc-start ai-center p-18">
              <div
                class="cursor-pointer d-flex"
                @click.stop.prevent="copyTestingCode">
                <i
                  class="mdi mdi-content-copy font-size-1-3 text-malibu pr-5"></i>
                <span class="agency-code">@{{ agencyCode }}</span>
              </div>
            </div>
            <!-- <input readonly type="hidden" id="testing-code" :value="`@${agencyCode}`"> -->
          </CustomCard>
        </aside>
        <md-content class="main">
          <MyFreelaList v-if="currentEntityList === 'freelas'" />

          <MyGroupList
            v-else-if="currentEntityList === 'groups'"
            @cardGroupClick="onCardGroupClick" />
        </md-content>
      </section>
    </template>
  </PageLayout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import FilterFreela from "@/components/freelas/FilterFreela.vue";
import MyFreelaList from "@/components/freelas/MyFreelaList.vue";
import MyGroupList from "@/components/freelas/MyGroupList.vue";
import { debounce } from "@/helpers/debounce.js";

export default {
  components: { FilterFreela, MyFreelaList, MyGroupList },
  computed: {
    ...mapGetters({
      agencyCode: "Profile/agencyCode",
      currentFilters: "MyFreelas/currentFilters",
      filterMatch: "MyFreelas/filterMatch",
      group: "MyFreelas/group",
      totalMembers: "MyFreelas/totalMembers",
    }),
    match: {
      get() {
        return this.filterMatch;
      },
      set(val) {
        this.debounceInput(val);
      },
    },
    groupName() {
      return this.group?.name;
    },
    currentPage() {
      return this.$route.name;
    },
    isOnGroupPage() {
      return this.currentPage === "groups.detail";
    },
    currentEntityList() {
      const isGroupsPage = this.currentPage === "groups";

      return isGroupsPage ? "groups" : "freelas";
    },
    isGroupPage() {
      return this.currentPage.includes("groups");
    },
    headerLabel() {
      return this.isGroupPage ? "Meus Grupos" : "Profissionais";
    },
    groupId() {
      return this.$route.params?.groupId;
    },
  },
  watch: {
    currentPage(val) {
      if (["freelas", "groups"].includes(val)) {
        this.resetCurrentGroup();
      }
      this.init();
    },
  },
  mounted() {
    this.debounceInput = debounce(this.debounceInput, 500);
    this.init();
  },
  methods: {
    ...mapActions({
      setFilters: "MyFreelas/setFilters",
      setCurrentGroup: "MyFreelas/setCurrentGroup",
      resetCurrentGroup: "MyFreelas/resetCurrentGroup",
      setFilterMatch: "MyFreelas/setFilterMatch",
      setTotalMembers: "MyFreelas/fetchGetTotalMembers",
      reset: "MyFreelas/reset",
    }),

    debounceInput(val) {
      this.setFilterMatch(val);
    },

    init() {
      const listType = this.isGroupPage ? "groups" : "freelas";

      this.setCurrentGroup({ id: this.groupId });
      this.setFilters({ listType });
      this.reset();
      this.setTotalMembers();
    },

    openCreateGroupModal() {
      this.$eventhub.$emit("modal:open:createGroup", {
        onCreated: this.onCreatedGroup,
        group: this.group,
      });
    },

    validateRedirectRoute({ listType }) {
      if (this.currentFilters.listType !== listType) {
        this.$router.push({ name: listType });
      }
    },

    onCreatedGroup({ id, name, image: preview }) {
      this.$router.push({
        name: "groups.detail",
        params: { groupId: id },
      });
      this.setCurrentGroup({ id: this.groupId, name, preview });
      this.init();
    },

    onFilterChange(filters) {
      this.resetCurrentGroup();
      this.validateRedirectRoute(filters);
      this.setFilters(filters);
      this.init();
    },

    onCardGroupClick({ id, name, imageUrl: preview }) {
      this.$router.push({
        name: "groups.detail",
        params: { groupId: id },
      });
      this.setCurrentGroup({ id: this.groupId, name, preview });
      this.init();
    },
    backPage() {
      this.$router.go(-1);
    },
    copyTestingCode() {
      try {
        navigator.clipboard.writeText(`@${this.agencyCode}`);
        // document.execCommand('copy');
        this.$eventhub.$emit("feedback:show", {
          type: "success",
          msg: "Copiado com sucesso !",
        });
      } catch {
        this.$eventhub.$emit("feedback:show", {
          type: "error",
          msg: "Falha ao copiar !",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.freelas {
  .freelas-header {
    .add-groups {
      border-radius: 50px;
      height: 45px;
      width: 45px;
      color: white;
      font-size: 1.6rem;

      &:hover {
        background: rgba(white, 0.1);
      }

      &:active {
        background: rgba(white, 0.15);
      }
    }
  }

  .freelas-page-content {
    display: grid;
    grid-template-areas: "left main";
    grid-template-columns: 220px 1fr;
    grid-template-rows: 1fr;
    gap: 25px;

    .left-side {
      grid-area: left;
    }

    .main {
      grid-area: main;
    }

    .agency-code {
      font-weight: 700;
      font-size: 0.8rem;
      text-transform: uppercase;
      word-break: break-all;
    }
  }
}
.header-title {
  display: flex;
  flex-grow: 1;
}
.back-arrow {
  display: flex;
  height: 20px;
  width: 250px;

  cursor: pointer;

  background-image: url("../../assets/baseline-keyboard_arrow_down-24px-darkblue.svg");
  background-repeat: no-repeat;
  justify-content: center;
}
.back-arrow:hover {
  text-decoration: underline;
}
</style>
