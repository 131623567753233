<template>
  <form
    action="?"
    @submit.prevent="onSubmit"
    class="p-30 h-100 d-flex direction-column jc-space-between">
    <section>
      <div class="form-group"></div>
      <div class="form-group">
        <CustomInput
          placeholder="E-mail"
          label="seu@email.com"
          v-model.trim="$v.form.email.$model"
          :error="errors.email" />
      </div>
      <div class="form-group">
        <CustomInput
          placeholder="Telefone"
          label="(11) 9999-99999"
          :mask="'(##) ####-#####'"
          v-model.trim="$v.form.phone.$model"
          :error="errors.phone" />
      </div>
      <div class="form-group">
        <AddressAutocomplete
          placeholder="Endereço"
          id="local-address"
          label="ex: Av. Paulista, 970 - São Paulo, SP"
          v-model.trim="$v.form.address.$model"
          :error="errors.address" />
      </div>
    </section>
    <footer class="d-flex jc-end">
      <SpinnerButton
        class="btn primary medium mr-5"
        :disabled="someChange === false || $v.$invalid"
        :loading="loading">
        Salvar Alterações
      </SpinnerButton>
    </footer>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { whenDirty } from "@/helpers/form/validations";
import AddressAutocomplete from "@/components/shared/form/AddressAutocomplete";

const requiredLabel = "Campo obrigatório";

const errorMessages = {
  email: {
    required: requiredLabel,
    email: "Digite um email válido",
  },
  phone: {
    required: requiredLabel,
  },
  address: {
    required: requiredLabel,
  },
};

const makeGetErrors = (fieldValidationMeta) => (field) =>
  whenDirty(fieldValidationMeta[field], errorMessages[field])[0];

export default {
  components: { AddressAutocomplete },
  mounted() {
    this.form = { ...this.contact };
  },
  computed: {
    ...mapGetters({
      someChange: "Profile/someChange",
      contact: "Profile/contact",
      currentState: "Profile/currentState",
    }),
    errors() {
      const getErrors = makeGetErrors(this.$v.form);

      return {
        email: getErrors("email"),
        phone: getErrors("phone"),
        address: getErrors("address"),
      };
    },
  },
  data: () => ({
    loading: false,
    form: {
      email: "",
      phone: "",
      address: "Avenida ",
    },
  }),
  watch: {
    contact: {
      deep: true,
      handler(val) {
        this.form = { ...val };
      },
    },
    form: {
      deep: true,
      immediate: false,
      handler(val) {
        this.setContact(val);
      },
    },
    currentState(state) {
      if (state === "ready") {
        this.$v.$reset();
      }
    },
  },
  validations: {
    form: {
      email: {
        required,
        email: (val) => email(val.toLowerCase()),
      },
      phone: {
        required,
      },
      address: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      setContact: "Profile/setContact",
      save: "Profile/save",
    }),
    onSubmit() {
      this.loading = true;
      const onSuccess = () =>
        this.notify("Suas informações foram salvas!", "success");
      const onError = ({
        response: {
          data: { errorMessage },
        },
      }) => this.notify(errorMessage);

      this.save()
        .then(onSuccess)
        .catch(onError)
        .finally(() => (this.loading = false));
    },
    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", {
        type,
        msg,
      });
    },
  },
};
</script>

<style lang="scss"></style>
