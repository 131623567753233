<template>
  <v-date-picker
    mode="date"
    is-dark
    is-inline
    :popover="{
      placement: $screens({ sm: 'auto' }),
      visibility: 'click',
    }"
    :update-on-input="false"
    v-on="$listeners"
    :value="value"
    :available-dates="{ start: minDate }">
    <template v-slot="{ inputValue, inputEvents }">
      <CustomInput
        :placeholder="label"
        label="00/00/0000"
        icon="mdi-calendar-month-outline font-size-1-3"
        :value="inputValue"
        v-on="inputEvents"
        :readonly="true"
        :pointer="true"></CustomInput>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  props: ["value", "label", "minDate"],
};
</script>

<style scoped></style>
