<template>
  <div class="ph-5">
    <section
      class="service-recruit-budget w-100 pv-10 radius-5 d-flex jc-space-between">
      <div class="d-flex border-right w-50 ph-10">
        <StatusDetails />
      </div>
      <div class="d-flex w-20 mr-50 ai-center jc-space-between ph-10">
        <div class="d-flex direction-column w-70">
          <small>Total a pagar</small>
          <span class="text-malibu font-size-1">
            R$ {{ payment | toCurrency }}
          </span>
        </div>
        <div class="d-flex direction-column">
          <small>Orçamento</small>
          <EmbededInput
            type="currency"
            v-model="budget"
            v-if="enableBudgetEdting"
            ref="payment"
            @keypress.enter="sendNewBudget" />
          <span v-else class="text-malibu font-size-1">
            R$ {{ lastServiceNegotiation | toCurrency }}
          </span>
        </div>
      </div>
      <div class="w-25 d-flex ai-center jc-end mr-10">
        <button
          class="btn primary h-33"
          :disabled="canAddNewBudget"
          @click="onEditBudget">
          {{ editBudgetButtonLabel }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EmbededInput from "@/components/shared/form/EmbededInput.vue";
import { strCurrencyToDecimal } from "@/helpers/form/currencyConvert";
import StatusDetails from "./StatusDetails.vue";

export default {
  components: {
    EmbededInput,
    StatusDetails,
  },
  props: {
    serviceId: String,
  },
  data: () => ({
    enableBudgetEdting: false,
    amount: 0,
  }),
  computed: {
    ...mapGetters({
      slots: "EventDetails/currentSlots",
      detailsInfo: "EventDetails/detailsInfo",
      negotiations: "EventDetails/negotiations",
      currentService: "EventDetails/currentService",
      userId: "User/userId",
      isAgency: "User/isAgency",
    }),

    vacancyStatus() {
      const trimZeros = (str) => str?.toString().padStart(2, "0");

      const totalFilled = this.slots.filter(
        ({ freelaId, status }) => !!freelaId && status !== 8
      ).length;

      return `${trimZeros(totalFilled)}/${
        trimZeros(this.currentService.totalVacancy) || "--"
      }`;
    },
    payment() {
      return this.slots.reduce((total, { payment }) => total + payment, 0);
    },

    budget: {
      get() {
        return this.amount;
      },
      set(value) {
        this.amount = strCurrencyToDecimal(value);
      },
    },

    currentServiceNegotiationToHirer() {
      return (
        this.negotiations &&
        this.negotiations.find((n) => n.serviceId === this.serviceId)
      );
    },

    currentServiceNegotiationToAgency() {
      return (
        this.negotiations &&
        this.negotiations.find(
          (n) => n.serviceId === this.serviceId && n.agencyId === this.userId
        )
      );
    },

    currentServiceNegotiation() {
      return this.isAgency
        ? this.currentServiceNegotiationToAgency
        : this.currentServiceNegotiationToHirer;
    },

    canAddNewBudget() {
      if (
        this.currentServiceNegotiation &&
        this.currentServiceNegotiation.budgets !== null
      )
        return this.currentServiceNegotiation.budgets[0].status === 3;
      else return false;
    },

    lastServiceNegotiation() {
      return this.currentServiceNegotiation &&
        this.currentServiceNegotiation.budgets
        ? this.currentServiceNegotiation.budgets[0].amount
        : this.budget;
    },

    editBudgetButtonLabel() {
      return this.enableBudgetEdting
        ? "Enviar novo orçamento"
        : "Editar orçamento";
    },
  },

  methods: {
    ...mapActions({ updateBudget: "EventDetails/updateBudget" }),

    sendNewBudget() {
      this.enableBudgetEdting = false;
      const data = {
        amount: this.amount,
        serviceId: this.serviceId,
      };

      this.updateBudget(data)
        .then(() => this.feedbackSuccess("Novo orçamento enviado com sucesso!"))
        .catch(this.feedbackError);
    },

    feedbackError({ message }) {
      this.$eventhub.$emit("feedback:show", {
        type: "error",
        msg: `${message}`,
      });
    },

    feedbackSuccess(message) {
      this.$eventhub.$emit("feedback:show", {
        type: "success",
        msg: `${message}`,
      });
    },

    initBudget() {
      const negotiation = this.negotiations.find(
        (n) => n.serviceId === this.serviceId
      );
      if (negotiation && negotiation.budgets)
        this.amount = negotiation.budgets[0].amount;
    },
    onEditBudget() {
      if (!this.enableBudgetEdting) this.enableBudgetEdting = true;
      else {
        this.sendNewBudget();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.service-recruit-budget {
  border: 1.5px solid white;

  .border-right {
    border-right: 1px solid white;
  }
}
</style>
