<template>
  <div class="d-flex direction-column w-100 ph-10 pv-5">
    <div v-if="showText" class="w-100">
      <small class="as-center font-weight-200 font-size-06">
        Membros ({{ qtdOfMembers }} pessoas)
      </small>
    </div>
    <div
      @click="$emit('row-click', membersData)"
      class="d-flex cursor-pointer direction-row ai-center jc-space-between">
      <div class="d-flex ph-10 pv-10">
        <div class="avatars">
          <Avatar
            v-for="(images, index) in showAvatars"
            :key="index"
            :src="images"
            size="xsmall"
            alt="Foto do usuário" />
          <div v-if="hasMoreMembersToShow" class="d-flex jc-end test">
            <span class="d-flex as-center font-size-07">+</span>
            <span class="d-flex as-center font-size-07">
              {{ qtdOfNotShowingAvatars }}
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <span class="font-size-07 text-malibu">Mais detalhes</span>
        <i
          v-if="showText"
          class="mdi mdi-chevron-right font-size-1-9 text-malibu"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { take } from "ramda";

const MAX_AVATAR_SHOW = 6;

export default {
  props: {
    membersData: {
      type: Array,
      default: () => [],
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showAvatars() {
      return take(MAX_AVATAR_SHOW, this.membersData);
    },
    hasMoreMembersToShow() {
      return this.membersData.length > MAX_AVATAR_SHOW;
    },
    qtdOfMembers() {
      return this.membersData.length;
    },
    qtdOfNotShowingAvatars() {
      return this.membersData.length - MAX_AVATAR_SHOW;
    },
  },
};
</script>

<style lang="scss" scoped>
.quantity {
  position: absolute;
  right: 17px;
  top: 7px;
}
.avatars {
  position: relative;
  flex-direction: row-reverse;
  :not(:last-child) {
    margin-left: -3%;
  }
  :not(:nth-child(4)) {
    margin-left: -3%;
  }
}
</style>
