<template>
  <div>
    <div @click="onBackButtonClick" class="d-flex cursor-pointer pt-10 pb-25">
      <i class="mdi mdi-chevron-left font-size-1-9 text-malibu"></i>
      <span class="font-size-08 text-malibu">Voltar para demandas</span>
    </div>
    <header class="d-flex mb-20">
      <div class="h-img">
        <img :src="hasHeaderImage" alt="Imagem do grupo" />
      </div>
      <section
        class="d-flex ai-center jc-space-between w-100 bg-steelGray radius-10 pr-15 pl-15">
        <div class="d-flex direction-column w-70">
          <h1 class="m-0 pb-5 text-white">
            {{ headerInfo.eventLabel }}
          </h1>
          <h4 class="m-0 text-white">{{ topic }}</h4>
        </div>
        <div class="w-50 d-flex jc-end">
          <DropDown
            menu-title="Relatório de Serviços"
            :icon="require('@/assets/file-document-outline.svg')">
            <section @click="reportDownload(null)" class="option">
              <span>Todos dias</span>
            </section>
            <hr class="divider" />
            <section
              class="option"
              v-for="(item, index) in operations"
              :key="index"
              @click="reportDownload(item.day)">
              <span>{{ item.day | formatDate }}</span>
            </section>
          </DropDown>
          <button class="edit-button" @click="onEditEventClick">
            <i class="mdi mdi-pencil text-malibu"></i>
          </button>
        </div>
      </section>
    </header>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { isNil } from "ramda";

export default {
  name: "HeaderDemanda",
  computed: {
    ...mapGetters({
      basicInfo: "EventDetails/basicInfo",
      eventService: "EventDetails/services",
      showNegotiation: "EventDetails/showNegotiation",
      operations: "EventDetails/operations",
    }),

    headerInfo() {
      const { name, type, topic, image, serviceOrderNumber } = this.basicInfo;

      const eventLabel = isNil(serviceOrderNumber)
        ? name
        : `${name} # ${serviceOrderNumber}`;

      return { type, topic, image, eventLabel };
    },

    topic() {
      return `${this.headerInfo.type} - ${this.headerInfo.topic}`;
    },

    hasHeaderImage() {
      return (
        this.headerInfo.image || require("@/assets/default-event-capa.svg")
      );
    },

    eventId() {
      return this.$route.params.id;
    },
  },

  filters: {
    formatDate(e) {
      return `Dia ${new Date(e.replace("Z", "")).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`;
    },
  },

  methods: {
    ...mapActions({
      init: "EventDetails/init",
      getServiceReport: "EventDetails/getServiceReport",
    }),

    reportDownload(day) {
      this.getServiceReport({
        eventId: this.eventId,
        day,
      });
    },

    onEditEventClick() {
      this.$router.push({
        name: "events.edit",
        params: { id: this.eventId, step: 1 },
      });
    },
    onBackButtonClick() {
      this.$router.push({
        name: "events",
      });
    },
  },

  mounted() {
    this.init({ eventId: this.eventId, serviceId: this.job });
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.h-img {
  margin-right: 17px;
  height: 89px;
  width: 89px;
  background-color: $SteelGray;
  border-radius: 8px;
  overflow: hidden;
  img {
    height: 100%;
  }
}
.edit-button {
  border: 2px solid #46c5f3;
  border-radius: 10px;
  padding: 10px;
}
</style>
