<template>
  <img class="avatar-comp radius-50" :class="size" :src="imgSrc" :alt="alt" />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "Foto do Avatar",
    },
    size: {
      type: String,
      default: "small",
    },
  },
  computed: {
    imgSrc() {
      return this.src || require("@/assets/icon_avatar.svg");
    },
  },
};
</script>

<style lang="scss">
.avatar-comp {
  position: relative;
  object-fit: cover;
  border: 2px solid grey;

  &.xxsmall {
    border: none;
    height: 25px;
    min-width: 25px;
    width: 25px;
  }

  &.xsmall {
    height: 35px;
    min-width: 35px;
    width: 35px;
  }

  &.small {
    height: 45px;
    min-width: 45px;
    width: 45px;
  }

  &.larger {
    height: 100px;
    width: 100px;
  }
}
</style>
