<template>
  <div class="filter-events">
    <div class="d-flex ai-center">
      <CustomSelect
        class="w-50"
        placeholder="Tipo"
        id="type"
        v-model="filters.eventType">
        <option :value="null">-</option>
        <option
          v-for="({ value, name }, key) in options"
          :value="value"
          :key="key"
          :selected="value == filters.eventType">
          {{ name }}
        </option>
      </CustomSelect>

      <Divider />

      <div class="d-flex w-100 mh-20">
        <CustomInputDate
          class="w-100"
          placeholder="Definir Período"
          label="Início"
          :value="filters.startDate"
          @input="filters.startDate = $event"
          :max-date="filters.endDate | formatFullDate" />

        <CustomInputDate
          class="w-100"
          placeholder=" "
          label="Final"
          :value="filters.endDate"
          @input="filters.endDate = $event"
          :min-date="filters.startDate | formatFullDate" />
      </div>

      <SearchHirer
        class="w-50"
        @selected="onHirerSelected"
        :value="filters.hirer.name" />
    </div>

    <div class="w-40 ml-5 mr-5">
      <md-checkbox
        :true-value="true"
        :false-value="undefined"
        v-model="filters.payed"
        value="true"
        class="w-50 mr-0">
        Pago
      </md-checkbox>
      <md-checkbox
        :true-value="false"
        :false-value="undefined"
        v-model="filters.payed"
        value="false"
        class="w-50 mr-0">
        A pagar
      </md-checkbox>
    </div>
  </div>
</template>

<script>
import SearchHirer from "@/components/shared/SearchHirer.vue";
// import { stringToDate, isValidDate } from "@/helpers/dateFormat";
import { getAvailableEventTypes } from "@/services/events/event.http";
import { mapActions, mapGetters } from "vuex";

const filterEmptyProps = (filters) =>
  Object.entries(filters).reduce(
    (obj, [key, val]) => (val ? Object.assign(obj, { [key]: val }) : obj),
    {}
  );

export default {
  components: {
    SearchHirer,
  },
  data: () => ({
    options: [],
    filters: {
      eventType: "",
      payed: null,
      startDate: "",
      endDate: "",
      hirer: {
        name: null,
        cnpj: null,
      },
    },
  }),
  mounted() {
    const populateOptions = (availableEventType) =>
      (this.options = availableEventType);

    getAvailableEventTypes().then(populateOptions);
  },
  computed: {
    ...mapGetters({
      filtersTab: "ListEvent/filtersTab",
    }),
  },
  watch: {
    filters: {
      deep: true,
      handler({ eventType, startDate, endDate, payed, hirer: { cnpj } }) {
        const filters = {
          ...filterEmptyProps({
            eventType,
            startDate,
            endDate,
            payed,
          }),
        };

        const clearCnpj = (cnpj) => cnpj?.replace(/[^\d]+/g, "");
        this.setFiltersTab({
          eventType,
          startDate,
          endDate,
          payed,
          hirerCnpj: clearCnpj(cnpj),
        });
        this.$store.commit("ListEvent/RESET_ITEMS");
        this.getEvent({ ...filters, hirerCnpj: clearCnpj(cnpj) });
      },
    },
  },

  methods: {
    ...mapActions({
      setFiltersTab: "ListEvent/setFiltersTab",
      getEvent: "ListEvent/getEvent",
    }),

    onHirerSelected({ name, cnpj }) {
      this.filters.hirer = { name, cnpj };
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.filter-events {
  .input-field .position {
    font-size: 1.2rem;
    top: 12px;
  }

  .floating-calendar.open {
    z-index: 100;
  }

  .md-checkbox-container {
    transform: scale(0.7);
  }

  .md-checkbox .md-checkbox-label {
    font-size: 0.65rem;
    padding-left: 0.5rem;
  }
}
</style>
