<template>
  <div
    v-if="group"
    class="group-card d-flex direction-column"
    @click="$emit('click', group)"
    @keydown.enter="$emit('click', group)">
    <CoverImage
      :src="group.imageUrl"
      :alt="group.name"
      class="card-image cursor-pointer">
      <div class="text-white w-100 p-10 h-100 d-flex direction-column jc-end">
        <h6 class="mb-5">{{ group.name }}</h6>
        <small>Última edição: {{ updatedAt }}</small>
      </div>
    </CoverImage>
    <div class="avatar-members p-8 d-flex direction-column jc-space-between">
      <div class="d-flex pb-2">
        <Avatar
          v-for="(freela, key) in group.freelas"
          :key="key"
          class="freela-avatar"
          size="xxsmall"
          :src="freela" />
      </div>
      <small>{{ group.membersCount }} membros</small>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/helpers/dateFormat";

export default {
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    updatedAt() {
      if (!this.group.date) return "--";
      const day = new Date(this.group.date);
      return `${formatDate(day, "D")} ${formatDate(day, "MMM")} ${formatDate(
        day,
        "YYYY"
      )}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.group-card {
  background: $SteelGray;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0.9;
  transition: all 0.3s ease;
  width: 215px;
  will-change: transition;

  &:hover,
  &:active {
    box-shadow: 0 3px 6px rgba(black, 0.3);
    transform: translateY(-2px);
    opacity: 1;
  }

  h6 {
    font-size: 1rem;
    font-weight: 400;
  }

  small {
    font-size: 0.6rem;
    font-weight: 100;
  }

  .card-image {
    height: 163px;
  }

  .avatar-members {
    height: 63px;
  }

  .freela-avatar {
    margin-right: -10px;
    transition: all 0.3s ease;
    will-change: transform;

    &:hover {
      z-index: 1;
      transform: scale(1.05);
    }
  }
}
</style>
