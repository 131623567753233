<template>
  <TimelineItem
    :activity="occurenceItem"
    class="occurrence-item"
    :class="status.style">
    <div class="mb-5">
      <span class="status mb-10">{{ status.label }}</span>
      <div class="message">{{ occurrence.message }}</div>
      <div v-if="occurrence.imageUrl" class="d-flex jc-start w-100">
        <button
          class="btn outline small mb-10 link"
          @click="showOccurrenceModal">
          Imagem Ocorrência
          <i class="mdi mdi-download-outline"></i>
        </button>
      </div>
      <div class="d-flex jc-end w-100">
        <button
          v-if="occurrence.status === 'Opened'"
          class="btn outline small"
          @click="setStatus(2)">
          Resolver
        </button>
        <button
          v-if="['Opened', 'InProccess'].includes(occurrence.status)"
          class="btn secondary small"
          @click="setStatus(3)">
          Finalizar
        </button>
      </div>
    </div>
  </TimelineItem>
</template>

<script>
import TimelineItem from "@/components/shared/TimelineItem";
import { INCIDENT_STATUS } from "./occurrenceStatus";

const { OPENED, IN_PROCCESS, RESOLVED } = INCIDENT_STATUS;

const OCCURRENCE_STATUS = {
  [OPENED]: {
    style: "opened",
    label: "Em Aberto",
  },

  [IN_PROCCESS]: {
    style: "in-progress",
    label: "Em Progresso",
  },

  [RESOLVED]: {
    style: "closed",
    label: "Finalizado",
  },
};

export default {
  components: { TimelineItem },
  props: {
    occurrence: {
      type: Object,
      default: () => ({
        id: "",
        status: "",
        service: "",
        occurredAt: "",
        message: "",
        avatarUrl: "",
        imageUrl: "",
      }),
    },
  },
  computed: {
    status() {
      return OCCURRENCE_STATUS[this.occurrence.status];
    },
    occurenceItem() {
      return {
        avatarUrl: this.occurrence.avatarUrl,
        description: `<b class="text-fuchsia-blue">${
          this.occurrence.username
        }</b> abriu uma ocorrência em ${this.occurrence.service.toUpperCase()}`,
        occurredAt: this.occurrence.occurredAt,
        type: "",
      };
    },
  },
  methods: {
    setStatus(status) {
      this.$emit("statusButtonClick", { id: this.occurrence.id, status });
    },
    showOccurrenceModal() {
      this.$eventhub.$emit("modal:open:occurrencelink", this.occurrence);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.occurrence-item {
  .status {
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    display: inline-block;
    font-size: 0.6rem;
    min-width: 100px;
    text-align: center;
    width: auto;
  }

  .message {
    font-size: 0.6rem;
    font-weight: 200;
  }

  @mixin status-style($color) {
    .status {
      border-color: $color;
      color: $color;
    }
  }

  &.opened {
    @include status-style($Red);
  }
  &.in-progress {
    @include status-style($Orange);
  }
  &.closed {
    @include status-style($LightGreen);
  }
}
</style>
