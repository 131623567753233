<template>
  <section class="filter-slots w-100 d-flex pt-10">
    <div class="d-flex w-60">
      <CustomSelect
        class="w-40"
        placeholder="Data"
        @input="setFilters({ day: $event })">
        <option
          v-for="({ id, label }, key) in filters.day"
          :key="key"
          :selected="id === currentFilters.day"
          :value="id">
          {{ label }}
        </option>
      </CustomSelect>

      <div class="d-flex w-40">
        <!-- <CustomSelect class="w-50" placeholder="Tipo" @input="setFilters({ type: $event })">
          <option
            v-for="({ id, label }, key) in filters.type"
            :key="key"
            :selected="id === currentFilters.type"
            :value="id"
          >{{ label }}</option>
        </CustomSelect> -->
        <CustomSelect
          class="w-50"
          placeholder="Status"
          @input="setFilters({ status: $event })">
          <option
            v-for="({ id, label }, key) in filters.status"
            :key="key"
            :selected="id === currentFilters.status"
            :value="id">
            {{ label }}
          </option>
        </CustomSelect>
      </div>

      <div class="w-60 d-flex ai-center p-relative checkbox-container">
        <CustomCheckbox
          id="manager-edit__filter"
          type="checkbox"
          :item="service"
          :checked="service.checked"
          @change="onChange" />

        <label for="manager-edit__filter" class="ml-8">
          Mostrar apenas os editados
        </label>
      </div>
    </div>
    <div class="w-50 pb-3 pt-25">
      <div class="d-flex ai-center jc-end h-100 pr-10">
        <button
          v-if="isAgency && canAgencyManager"
          class="btn secondary yellow"
          @click="() => {}">
          <span class="mb-1">Reenviar convites</span>
        </button>

        <ContextMenu
          class="jc-end"
          :position="{ x: -240, y: -36 }"
          :options="contextMenuOptions"
          @itemClick="onContextMenuItemClick">
          <button class="context-menu-button btn outline icon" md-menu-trigger>
            <i class="mdi mdi-dots-horizontal text-malibu font-size-1-3"></i>
          </button>
        </ContextMenu>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { stringToDate } from "@/helpers/dateFormat";

export default {
  computed: {
    ...mapGetters({
      slots: "EventDetails/currentSlots",
      service: "EventDetails/currentService",
      filters: "EventDetails/filters",
      currentFilters: "EventDetails/currentFilters",
      operations: "EventDetails/operations",
      isAgency: "User/isAgency",
      negotiations: "EventDetails/negotiations",
      userId: "User/userId",
    }),
    canAgencyManager() {
      return this.negotiations.some(
        (n) =>
          n?.agencyId === this.userId &&
          n.job === this.service.id &&
          n.budgets &&
          n.budgets.some((b) => b.status === 3)
      );
    },
    eventId() {
      return this.$route.params.id;
    },
    job() {
      return this.$route.params.job;
    },
    emptySlots() {
      return this.slots.filter(({ freelaId }) => !freelaId);
    },
    notPublishedVacancies() {
      return this.slots.filter(
        ({ freelaId, isPublished }) => !freelaId && !isPublished
      );
    },
    filledSlots() {
      return this.slots.filter(({ freelaId }) => freelaId);
    },
    vacanciesLeft() {
      const trimZeros = (str) => str.toString().padStart(2, "0");

      const vacancyQuantity = this.notPublishedVacancies.length;

      return vacancyQuantity > 0
        ? trimZeros(this.notPublishedVacancies.length)
        : vacancyQuantity;
    },
    contextMenuOptions() {
      return [
        {
          label: "Exportar relatório",
          icon: "mdi-file-document",
          action: "exportAll",
          disabled: false,
        },
        {
          label: "Localização de todos",
          icon: "mdi-map-marker-multiple",
          action: "localizeAll",
          disabled: this.currentFilters.day.length === 0,
        },
        {
          label: "Avaliar Todos",
          icon: "mdi-star",
          action: "avaliateAll",
          disabled: false || !this.canAgencyManager,
        },
        {
          label: `Publicar ${this.vacanciesLeft} vagas restantes`,
          icon: "mdi-bullhorn",
          action: "publishAll",
          disabled:
            this.notPublishedVacancies.length == 0 ||
            !this.isAgency ||
            this.canAgencyManager,
        },
      ];
    },
    referenceDate() {
      return stringToDate(this.currentFilters.day);
    },
  },

  methods: {
    ...mapActions({
      setFilters: "EventDetails/setFilters",
      getOperationConnection: "EventDetails/getOperationConnection",
      getReport: "EventDetails/getReport",
    }),
    localizeAll() {
      this.getOperationConnection({
        eventId: this.eventId,
        referenceDate: this.referenceDate.toISOString(),
      });

      this.$eventhub.$emit("modal:open:freela-geolocation", {
        slots: this.filledSlots,
        eventId: this.eventId,
        referenceDate: this.referenceDate,
      });
    },
    exportAll() {
      this.getReport({
        day: this.currentFilters.day,
        status: this.currentFilters.status,
        eventId: this.eventId,
        job: this.job,
      });
    },
    avaliateAll() {
      this.$eventhub.$emit("modal:open:memberAvaliation", {
        members: this.filledSlots,
        operationId: this.operations.map((o) => o.id),
        ratingAll: true,
      });
    },
    openVacancyValueModal() {
      const title = `Publicar ${this.vacanciesLeft} vagas de "${this.service.id}"`;
      this.$eventhub.$emit("modal:open:vacancy-value-modal", {
        title,
        slots: this.emptySlots,
        eventId: this.eventId,
      });
    },
    onContextMenuItemClick({ action }) {
      const actions = {
        avaliateAll: this.avaliateAll,
        publishAll: this.openVacancyValueModal,
        localizeAll: this.localizeAll,
        exportAll: this.exportAll,
      };

      actions[action]();
    },

    onChange(node, checked) {
      this.setFilters({ editedByManager: checked });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.filter-slots {
  .context-menu {
    font-size: 12px;
  }

  .checkbox-container {
    right: 12%;
  }
}

.resend-btn {
  background-color: #fbd900;
}
</style>
