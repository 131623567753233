<template>
  <form
    action="?"
    @submit.prevent="onSubmit"
    class="p-30 h-100 d-flex direction-column jc-space-between">
    <section>
      <div class="form-group">
        <CustomAutocomplete
          placeholder="Banco"
          :tip="bankTip"
          :search="search"
          v-model.trim="$v.form.code.$model"
          @selected="onBankSelected"
          :error="errors.code">
          <template #empty>Nenhum Banco encontrado</template>
        </CustomAutocomplete>
      </div>
      <div class="d-flex w-100">
        <div class="form-group w-100">
          <CustomInput
            placeholder="Agência"
            mask="########"
            v-model.trim="$v.form.branch.$model"
            :error="errors.branch" />
        </div>
        <div class="form-group w-100">
          <CustomInput
            placeholder="Conta Corrente"
            v-model.trim="$v.form.account.$model"
            :error="errors.account" />
        </div>
      </div>
      <div class="form-group">
        <CustomInput
          placeholder="CPF/CNPJ"
          label="111.222.333-44 ou 11.222.333/1000-44"
          :mask="['###.###.###-##', '##.###.###/####-##']"
          v-model.trim="$v.form.doc.$model"
          :error="errors.doc" />
      </div>
      <div class="form-group">
        <CustomInput
          placeholder="Nome"
          label="Titular da conta"
          v-model.trim="$v.form.name.$model"
          :error="errors.name" />
      </div>
    </section>
    <footer class="d-flex jc-end">
      <SpinnerButton
        class="btn primary medium mr-5"
        :disabled="someChange === false || $v.$invalid"
        :loading="loading">
        Salvar Alterações
      </SpinnerButton>
    </footer>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { whenDirty } from "@/helpers/form/validations";
import { validateCNPJ } from "@/helpers/form/validateCNPJ";
import { validateCPF } from "@/helpers/form/validateCPF";

const requiredLabel = "Campo obrigatório";

const errorMessages = {
  code: {
    required: requiredLabel,
  },
  branch: {
    required: requiredLabel,
  },
  account: {
    required: requiredLabel,
  },
  doc: {
    required: requiredLabel,
    validDOC: "Digite um CPF ou CNPJ válido",
  },
  name: {
    required: requiredLabel,
  },
};

const makeGetErrors = (fieldValidationMeta) => (field) =>
  whenDirty(fieldValidationMeta[field], errorMessages[field])[0];

export default {
  mounted() {
    this.form = { ...this.bankAccount };
  },
  data: () => ({
    loading: false,
    bankListJson: require("@/helpers/banks.json"),
    form: {
      code: "",
      branch: "",
      account: "",
      doc: "",
      name: "",
    },
  }),
  watch: {
    bankAccount: {
      deep: true,
      handler(val) {
        this.form = { ...val };
      },
    },
    form: {
      deep: true,
      immediate: false,
      handler(val) {
        this.setBankAccount(val);
      },
    },
  },
  validations: {
    form: {
      code: {
        required,
      },
      branch: {
        required,
      },
      account: {
        required,
      },
      doc: {
        required,
        validDOC: (doc) => validateCPF(doc) || validateCNPJ(doc),
      },
      name: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      someChange: "Profile/someChange",
      bankAccount: "Profile/bankAccount",
    }),
    bankList() {
      const transform = (item) => ({
        ...item,
        label: `${item.code} - ${item.name}`,
        token: `${item.code} - ${item.name}`.toLowerCase(),
      });

      const transformAll = (items) => items.map(transform);

      return transformAll(this.bankListJson);
    },
    bankTip() {
      const empty = "Nenhum banco selecionado";
      return this.form.code ? `Atual: "${this.form.code}"` : empty;
    },
    errors() {
      const getErrors = makeGetErrors(this.$v.form);

      return {
        code: getErrors("code"),
        branch: getErrors("branch"),
        account: getErrors("account"),
        doc: getErrors("doc"),
        name: getErrors("name"),
      };
    },
  },
  methods: {
    ...mapActions({
      setBankAccount: "Profile/setBankAccount",
      save: "Profile/save",
    }),
    search(term) {
      term = term.toLowerCase();
      const match = ({ token }) => token.includes(term);

      const matched = this.bankList.filter(match);

      return Promise.resolve(matched);
    },
    onBankSelected({ code }) {
      this.form.code = code;
    },
    onSubmit() {
      this.loading = true;
      const onSuccess = () =>
        this.notify("Suas informações foram salvas!", "success");
      const onError = ({
        response: {
          data: { errorMessage },
        },
      }) => this.notify(errorMessage);

      this.save()
        .then(onSuccess)
        .catch(onError)
        .finally(() => (this.loading = false));
    },
    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", {
        type,
        msg,
      });
    },
  },
};
</script>
