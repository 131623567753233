<template>
  <Tabs v-if="!isAgency" :tabs="tabs">
    <Tab tab-id="identification">
      <IdentificationFormHirer />
    </Tab>
  </Tabs>
  <Tabs v-else :tabs="tabs">
    <Tab tab-id="identification">
      <IdentificationForm />
    </Tab>
    <Tab tab-id="contact">
      <ContactForm />
    </Tab>
    <Tab tab-id="bank-account">
      <BankAccountForm />
    </Tab>
    <Tab tab-id="services">
      <ServiceList />
    </Tab>
  </Tabs>
</template>

<script>
import { mapGetters } from "vuex";
import IdentificationForm from "./AboutIdentificationForm";
import IdentificationFormHirer from "./AboutIdentificationFormHirer";
import ContactForm from "./AboutContactForm";
import BankAccountForm from "./AboutBankAccountForm";
import ServiceList from "./AboutServices";
import { isAgency } from "@/helpers/auth";

export default {
  components: {
    IdentificationForm,
    IdentificationFormHirer,
    ContactForm,
    BankAccountForm,
    ServiceList,
  },
  computed: {
    ...mapGetters({ tabChanged: "Profile/tabChanged" }),
    tabs() {
      return isAgency() ? this.tabAgency : this.tabHirer;
    },
    tabHirer() {
      return [
        {
          id: "identification",
          label: "Identificação",
          badge: this.tabChanged.identification,
        },
      ];
    },
    tabAgency() {
      return [
        {
          id: "identification",
          label: "Identificação",
          badge: this.tabChanged.identification,
        },
        {
          id: "contact",
          label: "Contato",
          badge: this.tabChanged.contact,
        },
        {
          id: "bank-account",
          label: "Informações Bancárias",
          badge: this.tabChanged.bankAccount,
        },
        {
          id: "services",
          label: "Serviços",
          badge: this.tabChanged.services,
        },
      ];
    },
    isAgency() {
      return isAgency();
    },
  },
};
</script>

<style lang="scss"></style>
