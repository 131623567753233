/* istanbul ignore file */
// dependencies
import Vue from "vue";
import VueTheMask from "vue-the-mask";
import VueMaterial from "vue-material";
import Vuelidate from "vuelidate";
import money from "v-money";
import VueToastr from "vue-toastr";
import * as VueGoogleMaps from "vue2-google-maps";
import VCalendar from "v-calendar";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { ToggleButton } from "vue-js-toggle-button";

// globals
import "@/helpers/axios/";
import "@mdi/font/css/materialdesignicons.css";
import "vue-material/dist/vue-material.min.css";
import "./styles/index.scss";
import StarRating from "vue-star-rating";
// import './registerServiceWorker'

// app structure
import App from "./App.vue";
import store from "./store";
import router from "./router";

// helpers
import storage from "@/mixins/storage";
import scrollTop from "@/mixins/scrollTop";
import eventhub from "./eventhub";
import feedback from "./helpers/feedback";

// filters
import toCurrency from "./filters/toCurrency";
import { formDate } from "./filters/formDate";
import { formatFullDate } from "./filters/formatFullDate";

// Custom
import Badge from "@/components/shared/Badge.vue";
import SpinnerButton from "@/components/shared/SpinnerButton.vue";

import Chip from "@/components/shared/ui/Chip.vue";
import Tab from "@/components/shared/ui/Tab.vue";
import Tabs from "@/components/shared/ui/Tabs.vue";
import Modal from "@/components/shared/ui/Modal.vue";
import ModalRight from "@/components/shared/ui/ModalRight.vue";
import LightBox from "@/components/shared/ui/LightBox.vue";
import Avatar from "@/components/shared/ui/Avatar.vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import Stepper from "@/components/shared/ui/Stepper.vue";
import Divider from "@/components/shared/ui/Divider.vue";
import Loading from "@/components/shared/ui/Loading.vue";
import CustomCard from "@/components/shared/ui/CustomCard.vue";
import CustomTable from "@/components/shared/ui/CustomTable.vue";
import PageLayout from "@/components/shared/ui/PageLayout.vue";
import CoverImage from "@/components/shared/ui/CoverImage.vue";
import ContextMenu from "@/components/shared/ui/ContextMenu.vue";
import StateHandler from "@/components/shared/ui/StateHandler.vue";
import DropDown from "@/components/shared/ui/DropDown.vue";

import ImageField from "@/components/shared/form/ImageField";
import CustomInput from "@/components/shared/form/CustomInput.vue";
import CustomDateRange from "@/components/shared/form/CustomDateRange.vue";
import CustomInputDate from "@/components/shared/form/CustomInputDate.vue";
import CustomSelect from "@/components/shared/form/CustomSelect.vue";
import CustomCheckbox from "@/components/shared/form/CustomCheckbox.vue";
import MultiSelect from "@/components/shared/form/MultiSelect.vue";
import CustomAutocomplete from "@/components/shared/form/CustomAutocomplete.vue";
import AddressAutocomplete from "@/components/shared/form/AddressAutocomplete.vue";

import vClickOutside from "v-click-outside";

// import VueDrift from 'vue-drift';

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBVsSKFLigzkkpRc1L-GTKCN2N0qQHWYOc",
    region: "Brazil",
    language: "pt-br",
    libraries: ["places", "geometry"],
    installComponents: true,
  },
});
Vue.component("GmapCluster", VueGoogleMaps.Cluster);

Vue.use(vClickOutside);

Vue.use(VCalendar, {
  componentPrefix: "vc",
});

Vue.use(money);
Vue.use(VueMaterial);
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(VueToastr);

// Vue.use(VueDrift, {
//   appId: 'yxhnfmrfpvuk',
// });

Vue.filter("toCurrency", toCurrency);
Vue.filter("formDate", formDate);
Vue.filter("formatFullDate", formatFullDate);

Vue.component("DropDown", DropDown);
Vue.component("date-picker", DatePicker);
Vue.component("Chip", Chip);
Vue.component("Tab", Tab);
Vue.component("Tabs", Tabs);
Vue.component("Badge", Badge);
Vue.component("Modal", Modal);
Vue.component("ModalRight", ModalRight);
Vue.component("LightBox", LightBox);
Vue.component("Avatar", Avatar);
Vue.component("Spinner", Spinner);
Vue.component("Stepper", Stepper);
Vue.component("Divider", Divider);
Vue.component("Loading", Loading);
Vue.component("ImageField", ImageField);
Vue.component("CoverImage", CoverImage);
Vue.component("PageLayout", PageLayout);
Vue.component("CustomCard", CustomCard);
Vue.component("CustomTable", CustomTable);
Vue.component("star-rating", StarRating);
Vue.component("CustomInput", CustomInput);
Vue.component("CustomDateRange", CustomDateRange);
Vue.component("CustomInputDate", CustomInputDate);
Vue.component("ContextMenu", ContextMenu);
Vue.component("CustomSelect", CustomSelect);
Vue.component("CustomCheckbox", CustomCheckbox);
Vue.component("MultiSelect", MultiSelect);
Vue.component("StateHandler", StateHandler);
Vue.component("SpinnerButton", SpinnerButton);
Vue.component("CustomAutocomplete", CustomAutocomplete);
Vue.component("AddressAutocomplete", AddressAutocomplete);
Vue.component("ToggleButton", ToggleButton);

Vue.config.productionTip = false;

Vue.prototype.$eventhub = eventhub;
Vue.prototype.$scrollTop = scrollTop;

feedback.register(eventhub);

const vm = new Vue({
  router,
  store,
  mixins: [storage],
  render: (h) => h(App),
}).$mount("#app");

export { vm };
