<template>
  <div class="p-35 h-100">
    <div @click="onBackButtonClick" class="d-flex cursor-pointer mb-20">
      <i class="mdi mdi-chevron-left font-size-1-8 text-malibu"></i>
      <span class="font-size-08 text-malibu">
        Voltar para folha de pagamento
      </span>
    </div>
    <h2 class="text-Periwinkle font-weight-200">Solicitar pagamento</h2>
    <CustomCard class="radius-5 p-20 pr-100 d-flex jc-space-between mb-25">
      <span class="font-size-08">
        Número do lote
        <br />
        <span class="text-Perfume">{{ batchExternalId }}</span>
      </span>
      <span class="font-size-08">
        Data de vencimento
        <br />
        {{ dueDate | formDate }}
      </span>
      <span class="font-size-08">
        Total de profissionais
        <br />
        {{ quantityFreelas }}
      </span>
      <span class="font-size-08">
        Total de turnos
        <br />
        {{ quantityShifts }}
      </span>
      <span class="font-size-08">
        Valor total
        <br />
        R$ {{ totalValue | toCurrency }}
      </span>
      <span class="font-size-08">
        Status
        <br />
        <span class="bg-status" :class="objChargeStatusColor[chargeStatus]">
          {{ objChargeStatus[chargeStatus] }}
        </span>
      </span>
    </CustomCard>
    <div class="d-flex ai-center">
      <h3 class="text-Periwinkle font-weight-300 pr-10">Formas de pagamento</h3>
      <span
        @click="$eventhub.$emit('modal:open:modal-faq')"
        class="text-malibu d-flex ai-center text-underline cursor-pointer">
        <i class="mdi mdi-information font-size-1-3 text-malibu pr-5"></i>
        Porquê isso?
      </span>
    </div>
    <div class="d-flex w-100 pb-20">
      <CustomCard class="radius-5 p-20 mr-10 h-100 w-50">
        <span class="font-size-09">Pague com Pix</span>
        <p class="font-size-07 mt-0 mb-0">
          Agilize seu lote de pagamento pagando com o PIX. É simples, seguro e
          rápido.
        </p>
        <p class="font-size-07 mt-0 mb-10">
          O pagamento via pix pode levar até 1 dia útil para compensar
        </p>
        <div class="d-flex">
          <img
            v-if="qrCode !== ''"
            :src="qrCode"
            alt="QRCode"
            class="img-qrcode" />
          <div class="w-100">
            <CustomInput
              ref="inputPix"
              icon="mdi mdi-content-copy font-size-1-3 text-malibu"
              readonly
              class="input-copy"
              :pointer="true"
              placeholder="PIX Copia e cola"
              :value="keyCopyPaste"
              @click="clipBoard"
              @iconClick="clipBoard" />
            <small v-if="copy" class="font-size-08 text-malibu pl-5">
              Copiado!
            </small>
          </div>
        </div>
      </CustomCard>
      <CustomCard class="radius-5 p-20 ml-10 w-50">
        <span class="font-size-09">Pague com o cartão</span>
        <p class="font-size-07 mt-0 mb-0">
          Use o cartão de crédito, para liberar este novo lote de pagamento.
        </p>
        <p class="font-size-07 mt-0 mb-10">
          O pagamento via cartão de crédito pode levar 1 dia útil para
          compensar.
        </p>
        <button
          @click="goToCheckout"
          class="button-pay-card radius-10 d-flex jc-center">
          Pagar com cartão de crédito
        </button>
      </CustomCard>
    </div>
    <ModalFaq />
  </div>
</template>

<script>
import { getPaymentInformation } from "@/services/payment/payment.http";
import ModalFaq from "./ModalFaq.vue";

export default {
  data: () => ({
    qrCode: "",
    keyCopyPaste: "",
    quantityFreelas: 0,
    quantityShifts: 0,
    dueDate: new Date(),
    totalValue: 0,
    checkoutUrl: "",
    chargeStatus: 0,
    objChargeStatusColor: {
      1: "waiting",
      2: "paid",
      3: "canceled",
    },
    objChargeStatus: {
      1: "Pendente",
      2: "Pago",
      3: "Cancelado",
    },
    batchExternalId: 0,
    copy: false,
  }),
  components: { ModalFaq },
  mounted() {
    this.getPaymentInformation(this.$route.params.batchId);
    this.$eventhub.$emit("modal:open:modal-faq");
  },
  methods: {
    getPaymentInformation(id) {
      getPaymentInformation(id)
        .then((data) => data)
        .then((result) => {
          this.qrCode = `data:image/png;base64,${result.base64Image}`;
          this.keyCopyPaste = result.paymentCode;
          this.quantityFreelas = result.quantityFreelas;
          this.quantityShifts = result.quantityShifts;
          this.dueDate = result.dueDate;
          this.totalValue = result.totalValue;
          this.checkoutUrl = result.checkoutUrl;
          this.chargeStatus = result.chargeStatus;
          this.batchExternalId = result.batchExternalId;
        });
    },
    clipBoard() {
      this.copy = true;
      window.navigator.clipboard.writeText(this.keyCopyPaste);

      setTimeout(() => {
        this.copy = false;
      }, 1000);
    },
    goToCheckout() {
      window.open(this.checkoutUrl, "_blank");
    },
    onBackButtonClick() {
      const { id, isListBatch } = this.$route.params;
      this.$router.push({
        name: "events.info",
        params: { id, isListBatch },
        hash: "#payroll",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/breakpoints.scss";
@import "@/styles/colors.scss";
.button-pay-card {
  align-items: center;
  background: $Malibu;
  color: $SteelGray;
  display: inline-flex;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 2%;
  @media (max-width: $point-sm) {
    min-width: initial;
    width: 100%;
  }

  &:hover,
  &:active {
    background: lighten($Malibu, 10);
  }
}

.input-copy {
  & :focus {
    border-color: $Malibu;
  }
}

.img-qrcode {
  object-fit: contain;
  width: 25%;
  height: 25%;
  border-radius: 5px;
}
</style>
