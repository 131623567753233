<template>
  <CustomCard
    title="Agenda"
    class="agenda-area overflow-hidden w-100 d-flex direction-column">
    <md-content class="operations md-scrollbar p-15" v-if="operations">
      <div
        v-for="(opKey, key) in groupKeys"
        :key="key"
        class="operations-day mb-15">
        <h4>{{ format(opKey) }}</h4>
        <ul>
          <li
            v-for="(operation, k) in groupedOperations[opKey]"
            :key="k"
            class="mb-5"
            :class="{ active: currentOperation === operation.id }"
            @click="setCurrentOperation(operation)"
            @keypress.enter="setCurrentOperation(operation)">
            <div class="operation-item">
              <small>
                {{ operationLabel(operation) }}
              </small>
            </div>
          </li>
        </ul>
      </div>
    </md-content>
  </CustomCard>
</template>

<script>
import { isNil } from "ramda";
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/helpers/dateFormat";

export default {
  mounted() {
    window.addEventListener("wheel", this.handleScroll);
  },
  computed: {
    ...mapGetters({
      operations: "Dashboard/operations",
      currentOperation: "Dashboard/currentOperation",
    }),

    groupedOperations() {
      const groupByDay = (grouped, op) =>
        (grouped[op.day] = [...(grouped[op.day] || []), op]) && grouped;
      return this.operations.reduce(groupByDay, {});
    },

    groupKeys() {
      const orderByDate = (a, b) => new Date(b) - new Date(a);
      const groupedOperationsKey = Object.keys(this.groupedOperations);
      return groupedOperationsKey.sort(orderByDate);
    },
  },
  methods: {
    ...mapActions({
      setOperation: "Dashboard/loadOperation",
      loadEventData: "EventDetails/loadEventData",
    }),
    operationLabel(operation) {
      const { name, serviceOrderNumber } = operation;

      return isNil(serviceOrderNumber)
        ? name
        : `${name} # ${serviceOrderNumber}`;
    },
    handleScroll(ev) {
      const c = this.$el.querySelector(".operations");
      c.scrollBy(-ev.wheelDelta, 0);
    },
    format(day) {
      const date = new Date(day.replace("Z", ""));
      return `${formatDate(date, "D")}/${formatDate(date, "M")}/${formatDate(
        date,
        "YYYY"
      )}`;
    },
    setCurrentOperation(operation) {
      if (operation.id !== this.currentOperation) {
        this.loadEventData(operation.eventId);
        this.setOperation(operation);
        this.$router.push({
          name: "operation",
          params: { operationId: operation.id },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

@media (max-width: 991px) {
  .operations {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .operations-day {
    flex: 0 0 auto;
    width: 90%;
    margin: 1em;
    height: 15em;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background-color: #18142f;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $EastBay;
      border-radius: 5px;
    }
  }
}

@media (min-width: 992px) {
  .agenda-area {
    border-radius: 10px;
  }
}

.operations {
  background: $SteelGray !important;

  .operations-day {
    background: $Ebony;
    border-radius: 15px;
    padding: 10px;

    // flex: 0 0 auto;
    // margin-right: 1em;
    // width: 100%;
    // height: 15em;
    // overflow-y: scroll;

    h4 {
      border-bottom: 5px solid $SteelGray;
    }
  }

  li {
    &:hover {
      cursor: pointer;
      background: transparentize(white, 0.9);
    }
    &.active {
      background: transparentize(white, 0.7);
    }
  }
}
</style>
