<template>
  <div class="d-contents">
    <tr class="header-collapsible">
      <slot name="header" />
      <td>
        <span class="see-details" @click.prevent="show = !show" v-if="!isIcon">
          {{ show ? "Ocultar" : "Ver" }} Detalhes
        </span>
        <span
          class="see-details"
          @click.prevent="show = !show"
          v-else-if="isIcon">
          <img
            class="arrow-collapsible"
            :class="{ 'collapse-open': !show }"
            src="@/assets/baseline-keyboard_arrow_down-24px.svg"
            alt="Seta" />
        </span>
      </td>
    </tr>
    <tr>
      <td colspan="7" class="body-collapsible" :class="{ collapse: !show }">
        <table class="table-container bg-header-ebony-op">
          <thead>
            <tr>
              <slot name="header-body" />
            </tr>
          </thead>
          <tbody>
            <slot name="body" />
          </tbody>
        </table>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  name: "Collapsible",
  props: {
    isIcon: {
      type: Boolean,
      default: false,
    },
    templateColumns: {
      type: String,
      default: "template-col-custom",
    },
  },
  filters: {},
  data: () => ({
    show: false,
  }),
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.header-collapsible {
  td {
    padding: 1.5em 0.6em;
  }
  background-color: $SteelGray;
  border-left: 3px solid $LightGreen;
  font-size: 0.85em;
  & .see-details {
    color: $PictonBlue;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  & .status-wait {
    background-color: rgba($color: $FrenchRose, $alpha: 0.5);
    padding: 5px;
    border-radius: 5px;
  }
  & .status-paid {
    background-color: rgba($color: $RobinsEggBlue, $alpha: 0.5);
    padding: 5px;
    border-radius: 5px;
  }

  .arrow-collapsible {
    transform: rotateZ(-90deg);
    transition: all 0.4s;
  }

  .collapse-open {
    transform: rotateZ(90deg);
  }
}

.body-collapsible {
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s 0.1s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  &::-webkit-scrollbar-track {
    background: $Ebony;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $EastBay;
    border-radius: 10px;
  }
}

.collapse {
  display: none;
}
</style>
