<template>
  <div class="tab-menu mr-28">
    <div class="tab-trigger">
      <ul class="d-flex m-0">
        <li
          class="tab-menu-item empty"
          :class="{ 'tab-selected': tabs == tab.id }"
          v-for="tab in tabsTrigger"
          :key="tab.id"
          @click="tabHandler(tab)">
          <span class="font-size-09">
            <i :class="`${tab.icon}`"></i>
            {{ tab.label }}
          </span>
        </li>
      </ul>
    </div>

    <div class="tab-container-wrap w-100 h-100">
      <div v-if="tabs == 'filters'" class="tab-content-box">
        <FilterEvents />
      </div>

      <div v-if="tabs === 'control'" class="tab-content-box control">
        <p class="font-size-1 text-bold mt-0 ml-16">
          Selecione as informações que deseja visualizar
        </p>
        <div class="d-flex d-wrap">
          <CustomCheckbox
            v-for="(column, i) in columns"
            :key="i"
            :item="column"
            @change="onChange"
            :checked="column.checked"
            value="false"
            class="mr-0">
            {{ column.label }}
          </CustomCheckbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterEvents from "./FilterEvents.vue";
// import Wallet from "./Wallet.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    tabs: "empty",
    tabsTrigger: [
      {
        id: "filters",
        icon: "mdi mdi-filter-outline",
        label: "Filtros",
      },
      {
        id: "control",
        icon: "mdi mdi-tune",
        label: "Controle",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      columns: "ListEvent/menuColumns",
    }),
  },
  components: {
    // Wallet,
    FilterEvents,
  },
  methods: {
    ...mapActions({
      setMenuColumns: "ListEvent/setMenuColumns",
    }),
    tabHandler(tab) {
      if (this.tabs === tab.id) {
        this.tabs = "empty";
        this.$emit("onIsEmpty", true);
      } else {
        switch (tab.id) {
          case "filters":
            this.tabs = "filters";
            break;
          case "control":
            this.tabs = "control";
            break;
        }

        this.$emit("onIsEmpty", false);
      }
    },
    onChange(node, checked) {
      this.setMenuColumns({ node, checked });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.tab-menu {
  .tab-menu-item {
    position: relative;
    min-width: 120px;
    border-radius: 8px 8px 0 0;

    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 1.4rem;
        margin-right: 6px;
      }
    }

    &.tab-selected {
      background: lighten($SteelGray, 5%);
    }
  }

  .tab-container-wrap {
    position: absolute;
    left: 0;
  }

  .tab-content-box {
    height: 100%;
    padding: 20px;
    background: lighten($SteelGray, 5%);

    &.control {
      padding-left: 70px;
      padding-right: 70px;

      label {
        min-width: 160px;
        margin: 0.8rem;
        height: fit-content;
      }
    }
  }
}
</style>
