<template>
  <section>
    <header class="d-flex">
      <h1>
        Termos e condições de uso da plataforma
        <span class="txt-color">Lanup</span>
      </h1>
      <div>
        <p>
          Os termos e condições de uso a seguir regem seu acesso à plataforma.
          Por favor, leia com atenção e entre em contato conosco se tiver alguma
          dúvida. Ao Acessar a Lanup você concorda em cumprir estes termos,
          nossa Política de Privacidade, nossa Política de Cookies e nossas
          Diretrizes da
        </p>
      </div>
    </header>
    <article>
      <div>
        <h3>1. Apresentação e definições</h3>
        <div>
          <p>
            1.1 - Este aplicativo é mantido, operado e de propriedade de LAN UP
            LTDA, com endereço na Rua Coronel José Eusébio, 95, sala 13,
            Higienópolis, São Paulo, SP, CEP: 01.239-030, inscrita no Cadastro
            Nacional de Pessoas Jurídicas do Ministério da Fazenda (CNPJ/MF) sob
            o n. 37.424.705/0001-46, e endereço eletrônico contato@lanup.com.br.
            Este documento relaciona os termos e condições que devem ser
            observados pela Lan Up e pelos Usuários (conforme definição abaixo)
            na utilização do Aplicativo, de suas ferramentas e de suas
            funcionalidades.
          </p>
          <p>
            1.2 - A Lan Up faculta acesso e utilização do Aplicativo a quaisquer
            Usuários que declarem e reconheçam ciência e aceite a estes Termos e
            Condições de Uso e das Políticas de Privacidade antes de ter
            iniciado a utilização do Aplicativo. Caso o Usuário não aceite estes
            Termos e Condições de Uso, não lhe será permitido acesso a áreas
            restritas do Aplicativo.
          </p>
          <p>
            1.3 - A Lan Up poderá modificar os termos a qualquer momento,
            ocasião em que será enviado aos usuários termo aditivo para ciência
            e aceite.
          </p>
          <p>
            1.4 - Para os fins destes Termos e Condições de Uso, bem como das
            Políticas, os termos abaixo terão os seguintes significados:
          </p>
          <p>
            1.5 -Plataforma ou App: Plataforma de automação que realiza o
            gerenciamento das reservas de prestadores de serviços por tomadores
            de serviços. A plataforma de automação é mantida e operada por Lan
            Up, disponível para dispositivos móveis com base nas plataformas da
            Apple Inc., Google e Inc, respectivamente: Apple iOS, Android
            disponível na App Store e Google Play.
          </p>
          <p>
            1.6 - Clientes/Usuários: Usuários que preencheram o cadastro,
            aderiram aos termos e condições de uso e demais termos e detêm uma
            Conta de Acesso, podendo ser: Usuários Contratantes
            (Restaurantes/Bares/Eventos/outros): Pessoas jurídicas ou físicas
            cadastradas na Plataforma de Automação denominada Lan Up que
            contratam os serviços da Plataforma para solicitar uma prestação de
            serviço ou qualquer outro tipo de contrato lícito, com o fim de
            adquirir serviços. Usuários Parceiros: Pessoas físicas, pessoas
            jurídicas ou Microempreendedor Individual cadastrados na Plataforma
            de Automação denominada Lan Up que contratam os serviços da
            Plataforma para aparecer como opção de prestador de serviço, podendo
            angariar clientes que precisam de seus serviços, se comprometendo a
            prestar serviços ao tomador, mediante remuneração deste. Agências:
            Pessoas jurídicas ou banco de talentos, cadastradas na Plataforma de
            Automação denominada Lan Up que contratam os serviços da Plataforma
            para aparecer como opção de prestador de serviço, podendo angariar
            clientes que precisam de seus serviços, se comprometendo a fornecer
            profissionais para prestar serviço ao tomador, mediante remuneração
            deste.
          </p>
          <p>
            1.7 - Conta deAcesso. Credencial definida pelo nome de usuário
            (login) e senha de um visitante cadastrado (Cliente/Usuário),
            pessoal e intransferível, que permite acesso à área restrita e às
            funcionalidades exclusivas no App, tais como acesso e alteração de
            dados pessoais, inscrição e cancelamento de pedidos/reservas de
            Parceiros para prestação de serviços, entre outras;
          </p>
          <p>
            1.8 - Marca. Quaisquer sinais distintivos de titularidade da e/ou
            licenciados à Lan Up, que sejam utilizados na identificação do App,
            de seu conteúdo ou de serviço prestado pela Lan Up.
          </p>
          <p>
            1.9 - Políticas. As políticas que complementam e integram estes
            Termos e Condições de Uso, nominalmente, Política de Segurança e
            Privacidade, todas disponíveis no App;
          </p>
          <p>
            1.10 - Propriedade Intelectual da Lan Up. Todos os bens de
            propriedade intelectual de titularidade da Lan Up, de qualquer
            empresa pertencente a seu grupo econômico, ou, ainda, de terceiro,
            cujo uso seja licenciado e/ou autorizado à Lan Up, incluindo, mas
            não se limitando a Marcas, patentes, invenções ou modelos de
            utilidade, software, desenhos industriais, know-how, segredos de
            negócio, ilustrações, fotografias e/ou conteúdos de qualquer tela do
            App ou quaisquer obras intelectuais ou outros conteúdos que estejam
            inseridos em qualquer obra intelectual ou qualquer bem protegido por
            direitos de propriedade intelectual. Para entender melhor acesse o
            Termo de de Confidencialidade e não Concorrência, disponível no App.
          </p>
          <p>
            1.11 - Operadora da plataforma: Encarregada de administrar
            operativamente e funcionalmente a Plataforma, representada para os
            efeitos destes termos pela Lan Up, ou pela pessoa física ou jurídica
            que ela designar.
          </p>
          <p>
            1.12 - Publicidade: É toda forma de comunicação realizada pela
            OPERADORA, com a finalidade de prestar informações sobre produtos,
            atividades comerciais e comunicar estratégias ou campanhas
            publicitárias ou de marketing, próprias ou de terceiros; realizada
            como mecanismo de referência e não como oferta pública.
          </p>
          <p>1.13 - Serviço: Serviços oferecidos através da plataforma.</p>
          <li>
            Janelas emergentes (Pop-Ups): Janela ou aviso da internet que emerge
            automaticamente em qualquer momento quando a Plataforma é utilizada,
            especialmente utilizado para a formalização do contrato de serviços
            entre Tomadores e Prestadores.
          </li>
          <li>
            Cookies: Arquivos enviados pelo servidor do App para o aparelho
            móvel do cliente, com a finalidade de identificar o aparelho móvel e
            obter dados de acesso, como páginas navegadas ou links clicados,
            permitindo, desta forma, personalizar a utilização do App, de acordo
            com o seu perfil. Também podem ser utilizados para garantir uma
            maior segurança dos usuários do App.
          </li>
          <li>
            Anúncios: Envio de informações de parceiros para divulgação e
            fortalecimento de relações e marcas, afim de dar conhecimento aos
            usuários sobre eventuais benefícios e vantagens disponibilizadas,
            bem como promoções de caráter permanente ou esporádico.
          </li>
        </div>
      </div>
      <div>
        <h3>2. Natureza jurídica</h3>
        <div>
          <p>
            2.1 - Estes termos e condições de uso regulam a relação contratual,
            de caráter comercial, que une os Clientes usuários da Plataforma,
            seja eles Contratantes ou Parceiros que têm acesso à Plataforma de
            automação de aproximação entre tomadores de serviço e prestadores.
          </p>
        </div>
      </div>
      <div>
        <h3>3. Definições gerais de utilização</h3>
        <div>
          <p>
            3.1 - Este termo de condições de uso será regido pela boa fé de
            todas as partes contratantes.
          </p>
          <p>
            3.2 - Aderindo a estes termos e condições de uso a Plataforma Lan Up
            será disponibilizada para que Você possa acessar e solicitar
            serviços à Agências ou profissionais autônomos, dentre as diversas
            categorias (barman, garçom, cozinheiro, auxiliar de cozinha, cumins,
            auxiliar de limpeza, caixa, dentre outros), sem caráter de
            exclusividade e sob demanda utilizando o App da Lan Up .
          </p>
          <p>
            3.3 - Aderindo a estes termos e condições de uso a Plataforma Lan Up
            será disponibilizada para que os Parceiros possam acessar a
            Plataforma, aparecendo como opção de prestador de serviço ou tomador
            de serviço, sem caráter de exclusividade e sob demanda utilizando o
            App da Lan Up.
          </p>
          <p>
            3.4 - Aderindo a estes termos e condições de uso a Plataforma Lan Up
            será disponibilizada para que as Agências possam acessar a
            Plataforma, aparecendo como opção de prestador de serviço, se
            comprometendo a fornecer profissionais para prestar serviço ao
            tomador, sem caráter de exclusividade e sob demanda utilizando o App
            da Lan Up.
          </p>
          <p>
            3.5 - A Lan Up declara e os Usuários reconhecem expressamente que
            não se trata de prestação de serviços de terceirização ou de
            terceirizada, a Lan Up oferece um serviço de intermediação digital
            por meio de seu App, conectando Restaurantes, Bares, empresas de
            eventos, e outros interessados em contratação de profissionais
            Parceiros (barman, garçom, cozinheiro, auxiliar de cozinha, commis,
            auxiliar de limpeza, caixa, dentre outros ) ou a Agências, de forma
            freelancer sem existência de vínculo empregatício e realizando a
            previsão de demanda conforme a necessidade específica, permite que
            os Usuários se conectem com maior facilidade e agilidade.
          </p>
          <p>
            3.6 - Você concorda que para contratar um Parceiro deverá preencher
            campos relativos às especificações dos Atendimentos, tais como
            discriminação dos serviços, local de prestação dos serviços, horário
            do Atendimento, dentre outros. A partir dessas informações serão
            exibidos os Parceiros que se encontram próximos ao local onde os
            serviços serão prestados, cabendo ao Parceiro a aceitação do pedido.
          </p>
          <p>
            3.7 - Os Usuários, Contratantes, parceiros e agências reconhecem que
            a relação com a Lan Up é de independência e de plena autonomia, de
            forma que a presente relação contratual não cria qualquer modalidade
            de vínculo empregatício, societário, similar dentre outros,
            inclusive, sem limitação, mandato, representação, associação e
            formação de grupo econômico.
          </p>
          <p>
            3.8 – Haverá ainda processamento de pagamento, permitindo uma
            integração rápida e funcional entre os usuários Tomadores e
            Parceiros, para que se conectem com maior facilidade e agilidade.
          </p>
        </div>
      </div>
      <div>
        <h3>4. Procedimento:</h3>
        <div>
          <p>
            4.1 – Após o prévio aceite destes termos de utilização e da política
            de privacidade, e mediante o preenchimento de cadastro em que todas
            as informações e qualificações ali inseridas são de inteira
            responsabilidade de quem as preencheu, ou seja dos Usuários
            Contratantes ou tomadores de serviço e dos Usuários Parceiros, tanto
            para o oferecimento de vagas ou trabalhos como para o pedido de
            aceite e habilitação ao trabalho.
          </p>
          <p>
            4.2 - O primeiro Parceiro que aceitar o Atendimento fica obrigado a
            realizá-lo, nas exatas condições pactuadas e oferecidas pelo
            Contratante, por meio da Plataforma Lan Up. Será enviada a
            confirmação da aceitação através do App, podendo ser acompanhado o
            tempo de chegada do Parceiro.
          </p>
          <p>
            4.3 - Após a realização do serviço, deverá haver avaliação do
            serviço.
          </p>
          <p>
            4. 4 - A Plataforma Lan Up com a finalidade de assegurar maior
            segurança para você e para os Parceiros, aconselha que toda a
            relação de contratação, avaliação do serviço e pagamento seja
            executada via o sistema da plataforma de modo a minimizar conflitos
            entre as partes. Ocasião em que a LanUp não possui qualquer
            responsabilidade sobre mudança no escopo do trabalho ou modificação
            da forma de pagamento que seja realizada diretamente e de forma
            pessoal entre o Usuário tomador do serviço e o Usuário Parceiro
            prestador do serviço.
          </p>
          <p>
            4.5 – As partes se comprometem a não demandar ou realizar qualquer
            serviço que não esteja expressamente estipulados na Plataforma Lan
            Up, de modo que qualquer alteração da diretriz deverá ser realizada
            dentro da plataforma e aceita pelo usuário.
          </p>
          <p>
            4.6 - Para cadastro dos usuários são obrigatórios sem prejuízo de
            demais documentos que possam vir a ser solicitados: Razão social ou
            nome, endereço, e-mail, telefone para contato, realização de prova
            de desempenho técnico; documento com foto, comprovante de
            microempreendedor individual (MEI) ou de pessoa jurídica,
            apresentação do cartão de CNPJ, método de pagamento pelos serviços
            da plataforma, etc.
          </p>
          <p>
            4.7 - O Usuário deverá manter o cadastro correto, completo e
            atualizado, sob pena de suspensão ou exclusão da sua Conta de
            Usuário, sem aviso prévio e/ou aplicação de penalidade pela Lan Up.
          </p>
          <p>
            4.8 - O Usuário deverá manter a guarda e o sigilo de sua senha de
            acesso, devendo manter a segurança e o sigilo de seus dados e
            informações de cadastro, de sua exclusiva responsabilidade.
          </p>
          <p>
            4.9 - O Usuário concorda que a Lan Up poderá acessar as contas dos
            usuários a qualquer momento, para garantir o bom e correto
            funcionamento da Plataforma.
          </p>
        </div>
      </div>
      <div>
        <h3>5. Responsabilidades</h3>
        <div>
          <p>
            5.1 - Os Parceiros atuam como agentes prestadores de serviços de
            forma autônoma, sem a existência de relação de emprego entre as
            partes. A Lan Up não se responsabiliza pelos serviços prestados pela
            Parceiro ou pela Agência, bem como não se responsabiliza pelos
            serviços ou diretrizes fornecidos pelos Usuários Contratantes.
          </p>
          <p>
            5.2 - A Lan Up não garante a disponibilidade de Parceiros, nem a
            qualidade, pontualidade, correção da prestação do serviço, bem como
            não responde por quaisquer danos, avarias ou danos causados pelo
            Parceiro, ou por acidentes, ocorrência de sinistros ou danos
            ocasionados pelos Usuários Contratantes e tomadores dos serviços.
          </p>
          <p>
            5.3 - A Lan Up não se responsabiliza pelas obrigações de seguro,
            fiscais, ambientais, ou outras mais que venham a surgir em
            decorrência da prestação dos serviços, sejam elas de ordem fiscal,
            tributária, trabalhistas ou legislativa, sendo de total
            responsabilidades das partes utilizadores do serviço.
          </p>
          <p>
            5.4 - A Lan Up frisa que o Usuário deverá manter licenças,
            autorizações, seguros, capacitação, alvarás do seu estabelecimento
            comercial (aplicável aos Restaurantes, Bares), todos em dia e
            conforme legislação aplicável, não havendo qualquer responsabilidade
            da LanUP sobre a ausência de quaisquer certificados.
          </p>
          <p>
            5.5 - A Lan Up não se responsabiliza por: (i) uso ou incapacidade de
            usar Serviços e/ou a Plataforma Lan Up; (ii) qualidade e integridade
            do Atendimento realizado, que é responsabilidade exclusiva dos
            Usuários; (iii) de qualquer operação ou relacionamento entre
            Usuários; (iv) atos praticados pelos Usuários, inclusive quando da
            realização dos Atendimentos; (v) atos praticados por ou sob a
            responsabilidade de terceiros; (vi) de orientações ou condutas de
            Usuários sobre os Serviços; (vii) de força maior ou caso fortuito;
            (viii) atos de discriminação pessoal, física ou religiosa, injúria
            racial, racismo, homofobia, ou outros que venha a ocorrer antes,
            durante, ou depois da prestação dos serviços, ocasião em que a LanUP
            reforça que é contra qualquer tipo de discriminação e que realiza e
            estimula políticas antidiscriminatórias e inclusivas. (ix) outros
            atos que sejam contrários às políticas da empresa e sua proposta
            comercial, ainda que não especificados anteriormente.
          </p>
          <p>
            5.6 - A Lan Up não será conivente nem fornecerá conteúdo com
            qualquer forma de discriminação, difamatório, calunioso, injurioso,
            violento, obsceno, pornográfico, ilegal ou de qualquer modo
            ofensivo, conforme apuração da Lan Up, a seu critério exclusivo,
            seja ou não esse Conteúdo de Usuário protegido por lei.
          </p>
          <p>
            5.7 - A Lan Up poderá, mas não está obrigada a analisar, monitorar
            ou remover Conteúdo de Usuário, a critério exclusivo da Lan Up, que
            esteja em descumprimento com estes Termos, não se responsabilizando
            por estes haja vista que tais conteúdos são de responsabilidade e
            inseridos pelos próprios Usuários.
          </p>
          <p>
            5.7 – Os Usuários contratantes são quem determinam quaisquer
            critérios que se apliquem aos serviços e o Parceiro será
            exclusivamente responsável por garantir que os serviços atinjam tais
            critérios, devendo o Parceiro e a Agência atender a toda e qualquer
            regulação aplicável a sua atividade e a todas as demais
            responsabilidades e deveres legais, inclusive a urbanidade com os
            demais Usuários e entre eles, sendo única e exclusivamente
            responsáveis pelo seu cumprimento.
          </p>
          <p>
            5.8 - A Lan Up não possui qualquer obrigação de manter o contrato e
            tem o direito de encerrar a relação contratual a qualquer momento na
            forma fixada neste contrato, precipuamente se os Usuários não
            atenderem as exigências legais e contratuais ou contrariarem os
            termos e condições de uso ao qual deram aceite, ou que venham a
            manter qualquer conduta irregular incompatível com o objeto a que a
            Plataforma se presta a realizar.
          </p>
          <p>
            5.9 – Acerca dos tributos decorrentes da relação de serviço, os
            usuários são os responsáveis por incluir no Preço oferecido pelos
            serviços o valor destinado aos tributos obrigatórios. O Usuário
            tomador do serviço para todos os propósitos fiscais será o único e
            exclusivo responsável pelo recolhimento e pagamento dos tributos
            aplicáveis arcando com valor dos tributos que deverão ser pagos em
            seu nome pelos serviços contratados ou prestados pelos Parceiros ou
            mediante as Agências. Cada parte será responsável por suas despesas
            e custos durante a execução deste contrato.
          </p>
          <p>
            5.10 - Taxa de Serviço da Plataforma Lan Up: Para o uso da
            Plataforma Lan Up será cobrado por Usuário uma taxa de serviço
            conforme os planos, pacotes e condições específicos para os Usuários
            Tomadores e Parceiros, os quais serão customizados de acordo com a
            necessidade de cada Usuário, bem como todas as taxas constantes
            deste Contrato deverão ser pagas em moeda corrente e nacional,
            atualmente o Real (BRL).
          </p>
          <p>
            5.11 - É obrigação dos Usuários tanto os parceiros como os
            contratantes e agências promoverem os serviços com total zelo e
            cuidado em relação a sua segurança e de terceiros, devendo: (i)
            Responsabilizar-se pela segurança de seus trabalhos, técnicos e de
            terceiros, cumprindo rigorosamente as normas de Segurança do
            Trabalho, conforme legislação em vigor; (ii) Utilizar equipamentos
            de proteção individual ou coletivo quando for o caso, que serão de
            sua inteira responsabilidade, necessários para a execução das
            atividades. (iii) Os usuários Tomadores e Parceiros assumem a total
            responsabilidade por seus atos e quaisquer acidentes que causarem
            danos ou prejuízos, em decorrência de dolo ou culpa caracterizada
            pela negligência, imprudência ou imperícia;
          </p>
        </div>
      </div>
      <div>
        <h3>6. Informações confidenciais</h3>
        <div>
          <p>
            6.1 - Qualquer informação confidencial, de caráter particular e que
            não seja pública, divulgada por uma parte à outra, seja por qualquer
            meio (verbalmente, por escrito, ou por inspeção de objetos
            tangíveis). A Parte que receber a informação aquiesce que não
            divulgará quaisquer Informações Confidenciais da Parte que revelou a
            informação a quaisquer terceiros, as Informações Confidenciais serão
            utilizadas apenas no que for necessário para a execução deste
            contrato. Cada Parte que receber a informação deve assegurar que as
            Informações Confidenciais serão disponibilizadas apenas aos
            funcionários, colaboradores e agentes que necessitem conhecer destas
            informações, os quais devem ter ciência sobre às obrigações de
            confidencialidade previstas neste Termo, antes de terem acesso a
            qualquer Informação Confidencial da Parte que revelou a informação.
          </p>
          <p>
            6.2 - A Parte que receber a informação não modificará de qualquer
            forma as Informações Confidenciais, notas de direito autoral, marca
            registrada, logotipo, legenda, outros sinais de propriedade da Parte
            que revelou a informação, documentos originais ou cópias da Parte
            que revelou a informação e nem permitirá e/ou autorizará que
            terceiros o façam.
          </p>
          <p>
            6.3 - Apenas membros da Parte que revelou a informação com expressa
            autorização da mesma podem divulgar Informações que esta autorize de
            forma expressa a divulgação.
          </p>
          <p>
            6.4 - A proibição de divulgação de Informações Confidenciais não se
            aplica na hipótese de ordem judicial ou disposição legal, que caso
            ocorra, a Parte que receber a notificação para revelar a informação
            deverá notificar por escrito previamente a Parte que revelou a
            informação sobre tal ordem judicial ou disposição legal.
          </p>
          <p>
            6.5 - No momento do término deste Contrato ou de seu vencimento, e
            conforme seja requerido pela Parte que revelou a informação, cada
            Parte que receber a informação deverá entregar ou destruir (deverá
            ser evidenciado) todo e qualquer material ou documento que contenha
            Informações Confidenciais da Parte que revelou a informação,
            juntamente com qualquer cópia física e/ou digital ou de qualquer
            meio dos documentos.
          </p>
        </div>
      </div>
      <div>
        <h3>7. Propriedade Intelectual</h3>
        <div>
          <p>
            7.1 - O Usuário entende, aceita e concorda que a Plataforma Lan UP,
            bem como todas as invenções, técnicas, metodologias, banco de dados
            e os dados contidos nos mesmos, códigos e linguagens de programação,
            avançadas ou não, desenvolvidas pela Lan Up, são produtos da
            tecnologia e do conhecimento desenvolvidos e pertencentes à Lan Up,
            sendo de propriedade exclusiva da Lan UP.
          </p>
          <p>
            7.2 - Os desenhos, patentes, logotipo, nome, marcas, know-how,
            software, segredos de negócio, processos, fórmulas e designs,
            patenteáveis ou não, planos de negócios (business plan), métodos de
            contabilidade, técnicas e experiências acumuladas, documentos,
            contratos, papéis, estudos, pareceres e pesquisas e quaisquer outros
            dados e informações de propriedade intelectual da Lan Up, de
            qualquer natureza, patenteadas ou não, que sejam utilizados na
            execução dos Serviços e/ou na Plataforma Lan Up, por qualquer meio
            físico, eletrônico ou gráfico, são de exclusiva propriedade da Lan
            Up.
          </p>
          <p>
            7.3 - Estes Termos e o uso dos Serviços não outorgam nem conferem
            aos Usuários qualquer direito de usar, explorar comercialmente ou,
            de qualquer modo, fazer referência a nomes empresariais, logotipos,
            marcas e/ou nomes de produtos, incluindo a Plataforma Lan Up e de
            suas licenciadoras.
          </p>
          <p>
            7.4 - Desde que integralmente cumpridas as condições destes Termos,
            a Lan Up concede ao Usuário uma licença limitada, pessoal e
            intransferível, não exclusiva, não passível de sublicença, e
            revogável para acesso e utilização da Plataforma Lan Up no
            dispositivo pessoal do Usuário, exclusivamente para a utilização dos
            Serviços.
          </p>
          <p>
            7.5 - Quaisquer direitos sobre a Plataforma Lan Up não expressamente
            outorgados em favor do Usuário por estes Termos são reservados à Lan
            UP, em caráter exclusivo.
          </p>
          <p>
            7.6. O Usuário não poderá, exceto da forma expressamente permitida
            pela Lan UP: (i) remover qualquer aviso de direito autoral, direito
            de marca ou outro aviso de direito de propriedade de qualquer parte
            dos Serviços; (ii) reproduzir, preparar obras derivadas, distribuir,
            ceder, sublicenciar, locar, vender, revender, doar, transferir,
            exibir, veicular, revelar, duplicar, copiar, transmitir ou, de
            qualquer outro modo, explorar os Serviços e/ou a Plataforma LanUp;
            (iii) decompilar, modificar, ampliar, alterar, mesclar, incorporar,
            adaptar, realizar engenharia reversa ou desmontar total ou parcial,
            permanente, temporária ou provisória, de forma gratuita ou onerosa,
            sob quaisquer modalidades, formas ou títulos da Plataforma Lan Up;
            (iv) desenvolver, criar ou patrocinar quaisquer programas que possam
            alterar ou copiar a Plataforma Lan Up, ainda que seja para
            introduzir melhores técnicas e/ou procedimentos; (v) conectar,
            espelhar ou recortar qualquer parte dos Serviços; (vi) fazer ou
            lançar quaisquer programas ou scripts com a finalidade de fazer
            scraping, indexação, pesquisa ou qualquer outra forma de obtenção de
            dados de qualquer parte dos Serviços, ou de sobrecarregar ou
            prejudicar indevidamente a operação e/ou funcionalidade de qualquer
            aspecto dos Serviços; ou (vii) tentar obter acesso não autorizado à
            Plataforma Lan UP ou seus sistemas ou redes correlatas.
          </p>
        </div>
      </div>
      <div>
        <h3>8. Declarações e Garantias</h3>
        <div>
          <p>
            8.1 - Por meio deste, cada Parte declara e garante que (i) goza de
            plenos poderes e autoridade para firmar o presente termo e cumprir
            com as obrigações aqui assumidas; (ii) está devidamente constituída,
            validamente existente e em conformidade com a lei aplicável; (iii)
            possui todas as licenças, registros, alvarás e autorizações, cursos,
            necessárias para cumprir com as obrigações fixadas nestes termos de
            uso; (iv) não firmou, e durante a vigência deste Contrato não
            firmará, qualquer acordo que a impeça de cumprir ou executar este
            Contrato, incluindo sem limitação, qualquer acordo com exclusividade
            em favor de terceiros para a contratação de serviços por meio
            plataformas tecnológicas; (v) cumprirá com todas as leis e
            regulações aplicáveis na execução deste Contrato, incluindo, sem
            limitação, a conduta ética e moral e todas as leis aplicáveis de
            proteção de dados, bem como, leis e regulamentos aplicáveis à
            contratação de serviços, seguros, etc; (vi) o conteúdo, mídia e
            outros materiais utilizados ou fornecidos como parte deste Contrato
            não deverão infringir ou de qualquer outra forma violar direitos de
            propriedade intelectual, direitos de publicidade ou outros direitos
            de propriedade da Lan Up e de terceiros.
          </p>
          <p>
            8.2 - Exceto se previsto expressamente neste contrato, A Lan UP não
            presta nenhuma garantia e neste ato recusa todas as demais
            garantias, expressas ou implícitas, associadas aos seus serviços ou
            produtos ou qualquer parte deles, incluindo quaisquer garantias
            implícitas de comercialização ou adequação para uma finalidade
            específica e garantias implícitas decorrentes do curso da negociação
            ou do curso da execução.
          </p>
        </div>
      </div>
      <div>
        <h3>9. Conduta do Usuário</h3>
        <div>
          <p>
            9.1 - Os usuários se comprometem em manter conduta ética e moral, em
            conformidade com a lei, e com as políticas da Lan Up, qualquer
            conduta considerada indevida poderá ensejar suspensão, remoção ou a
            exclusão do usuário, sem prejuízo da responsabilidade civil por
            danos à imagem ou reputação da Lan Up, dentre outros danos.
          </p>
          <p>
            9.2 - Os itens expressos abaixo não abrangem a totalidade de
            condutas, mas exemplificam algumas delas tais como: (i) Cumpra a
            lei, trate todos com respeito, contribua com a sua segurança sua e
            dos demais, compartilhe suas experiências conosco no canal: (ii) O
            App só aceita utilização por maiores de 18 anos; (iii)
            Compartilhamento de contas ou login é proibido, (iv) São
            expressamente proibidos: porte e/ou uso de armas; porte e/ou uso de
            drogas ilícitas; cometimento de fraudes; qualquer tipo de conduta
            discriminatória, especialmente com base em características como
            idade, cor da pele, deficiência, identidade de gênero, estado civil,
            nacionalidade, cor/raça, religião, orientação sexual entre outros;
            comportamento agressivo, conflituoso, assediante, não use palavras
            ou gestos ameaçadores, impróprios, desrespeitosos, evite opiniões
            polêmicas, por exemplo: religião, política, sexualidade.
          </p>
        </div>
      </div>
      <div>
        <h3>10. Emergências públicas e meio ambiente</h3>
        <div>
          <p>
            10.1 - A Lan Up em caso de emergências públicas (catástrofes
            naturais, emergências de saúde pública, crises públicas, entre
            outros) adotará todas as medidas a seu alcance para preservação da
            segurança dos usuários e da plataforma, se submetendo às exigências
            das autoridades públicas competentes. 10.2 – Os Usuários responderão
            de forma direta e ilimitada por toda e qualquer reclamação,
            intimação, multa ou ação proveniente de descumprimento de normas e
            leis ambientais vigentes, que venham a infringir no cumprimento e
            execução dos serviços mencionados neste Termo que a CONTRATADA neste
            ato declara conhecer e obriga-se a cumprir, isentando a CONTRATANTE
            de todo e qualquer vínculo de solidariedade, sendo que responderá
            mediante verificação de culpa e nexo causal com o dano.
          </p>
        </div>
      </div>
      <div>
        <h3>11. Indenização</h3>
        <div>
          <p>
            11.1 - A Parte que der causa a quaisquer reivindicações (incluindo,
            sem estar limitada a, passivos impostos por estatutos, negligência,
            passivos administrativos ou judiciais, regras ou regulamentos),
            processos judiciais, multas, custas (incluindo honorários
            advocatícios) geradas por sentenças transitadas em julgado em favor
            de qualquer pessoa física ou jurídica, resultante de ações ou
            omissões de responsabilidade da mesma ou de seus funcionários,
            agentes, contratados, ou subcontratados relacionados aos Serviços,
            que tenham sido causados por sua culpa ou dolo, irá defender,
            isentar e indenizar a(s) outra(s) Parte(s), suas filiadas, seus
            diretores, executivos, funcionários, representantes, agentes, contra
            todas e quaisquer reclamações, danos, perdas e despesas (incluindo
            despesas processuais, honorários de advogado, dentre outros) em
            relação a qualquer reclamação de terceiros, exceto no caso do dano
            ter sido diretamente causado por negligência ou dolo da Lan Up, a
            qual se responsabiliza exclusivamente pelos seus empregados ou
            representantes, frisando-se que os Usuários não são seus empregados
            ou empregadores e ainda não são representantes ou vinculados a Lan
            Up.
          </p>
          <p>
            11.2 - A Parte que der causa a quaisquer reivindicações, deverá
            imediatamente notificar a(s) outra(s) Parte(s) acerca de toda e
            qualquer reivindicação potencial, sujeita a indenização. A Parte que
            der causa assumirá a defesa da reivindicação, assim como deverá
            constituir advogado para tal ato e que seja razoavelmente aceito
            pela(s) outra(s) Parte(s), não podendo firmar qualquer acordo ou
            transigir qualquer demanda, tampouco consentir o ingresso em
            qualquer ação judicial, sem o consentimento por escrito da(s)
            outra(s) Parte(s), a(s) qual(is) não poderá(ão) recusar sem justo
            motivo, devendo esta cooperar de forma razoável na defesa de uma
            reivindicação.
          </p>
        </div>
      </div>
      <div>
        <h3>12. Limitação de Responsabilidade</h3>
        <div>
          <p>
            12.1 - A Lan Up não será responsável em hipótese alguma por
            reivindicações de quaisquer naturezas de danos direto, indiretos,
            punitivos, incidentais, didáticos, especiais, lucros cessantes ou
            ainda danos decorrentes de perda de negócios dos Usuários ou
            terceiros por força do presente contrato, ou também perda ou
            imprecisão dos dados de qualquer tipo, seja com base no contrato,
            ato ilícito ou qualquer outra teoria legal, ainda que tal parte
            tenha sido avisada sobre a possibilidade de tais danos.
          </p>
          <p>
            12.2 Em caso de responsabilização da Lan Up, por conduta de seus
            usuários, caberá a Lan Up em qualquer tempo ou grau de jurisdição
            pleitear a devida restituição de valores e compensação pelo dano
            sofrido.
          </p>
        </div>
      </div>
      <div>
        <h3>13. Seguro</h3>
        <div>
          <p>
            13.1 - Cada usuário se assim desejar, deverá manter Seguro de
            Responsabilidade Civil, de acidentes, invalidez ou morte, dentre
            outros aplicáveis e, se requerido por lei, seguro contra acidentes
            de trabalho ou cumprir com os requerimentos fixados nos sistemas de
            seguridade social relacionados aos seus empregados.
          </p>
          <p>
            13.2 - Todas as apólices devem ser elaboradas por seguradoras
            conceituadas e já reconhecidas no mercado pela SUSEP, ou equivalente
            agência.
          </p>
          <p>
            13.3 - Em nenhum caso, os limites de nenhuma apólice devem ser
            considerados como limitadores de responsabilidade de qualquer um dos
            Usuários da plataforma.
          </p>
        </div>
      </div>
      <div>
        <h3>14. Vigência e Rescisão</h3>
        <div>
          <p>
            14.1 - Este Termo de uso terá seu início de vigência no momento em
            que o Usuário der seu aceite à ele, e permanecerá em plena vigência
            até que o Usuário requeira o seu descredenciamento da plataforma.
          </p>
          <p>
            14.2 – Mesmo após a solicitação de descredenciamento o Usuário não
            se exime de eventuais pagamentos pendentes até a data de seu
            cancelamento, ocasião em que poderá ser cobrado, de forma
            extrajudicial ou judicial pelos valores inadimplidos.
          </p>
        </div>
      </div>
      <div>
        <h3>15. Notificação</h3>
        <div>
          <p>
            15.1 - Todo e qualquer aviso permitido ou exigido nos termos do
            presente Contrato deverão ser enviados para o endereço descrito na
            cláusula de Apresentação, ou para qualquer outro endereço que venha
            a ser estabelecido. Os avisos para os Usuários devem ser enviados no
            endereço de e-mail e endereço físico, cadastrado pelo próprio
            Usuário na plataforma. Adicionalmente, os Usuários concordam em
            receber ligações automáticas ou mensagens de texto enviadas pela Lan
            Up.
          </p>
        </div>
      </div>
      <div>
        <h3>16. Sanções e Penalidades</h3>
        <div>
          <p>
            16.1 - Prezando pela segurança e transparência na prestação dos
            Serviços, o Usuário reconhece e concorda que: (i) caso utilize a
            Plataforma Lan Up de forma indevida, ilegal ou em desacordo com
            estes Termos, poderá ter como consequência a suspensão ou a exclusão
            da Conta do Usuário e/ou a proibição da utilização dos Serviços de
            forma temporária ou permanentemente, a critério exclusivo da Lan Up,
            sem prejuízo das demais penalidades previstas em lei. (ii) caso o
            Parceiro/Agência cancele 3 (três) serviços consecutivos, sem a
            devida e prévia comunicação com antecedência mínima de 24h, a taxa
            de serviço de cobrança será cobrada de forma integral pela
            Plataforma Lan Up, sem prejuízo das demais sanções cabíveis. (iii)
            caso o Usuário Tomador cancele a solicitação em que o Parceiro
            esteja em Deslocamento, ou que a Agência já tenha enviado o
            prestador, ou sem a observância ao mínimo de 24h de antecedência,
            terá como consequência a taxa de serviço de cobrança cobrada de
            forma integral pela Plataforma Lan Up, sem prejuízo das demais
            sanções cabíveis. (iv) caso haja qualquer pendência financeira
            relativa a serviços anteriores, não poderá solicitar nova
            solicitação na Plataforma Lan Up, até a regularização do débito.
          </p>
          <p>
            16.2 - O Usuário concorda em indenizar a Lan Up, suas filiais,
            empresas controladas ou controladoras, diretores, administradores,
            colaboradores, representantes e empregados por quaisquer perdas,
            danos, responsabilização, reclamação ou demanda, por quaisquer
            prejuízos devido a, ou resultantes direta ou indiretamente de sua
            utilização dos serviços.
          </p>
          <p>
            16.3 - A não-observância de quaisquer das disposições estabelecidas
            nestes Termos sujeitará ao Usuário, como também ao agente causador
            ou facilitador, por ação ou omissão de qualquer daqueles
            relacionados nestes Termos, ao pagamento, ou recomposição, de todas
            as perdas e danos comprovadas pela Lan Up, bem como as de
            responsabilidade civil e criminal respectivas, as quais serão
            apuradas em regular processo judicial ou administrativo.
          </p>
        </div>
      </div>
      <div>
        <h3>17. Disposições Gerais</h3>
        <div>
          <p>
            17.1 - O presente Termo será regido e interpretado de acordo com as
            leis do Brasil, inclusive as Leis Federais n° 8.078/1990 (Código de
            Defesa do Consumidor), 9.609/1998 (Lei do Software), 9.610/1998 (Lei
            de Direitos Autorais), com o Decreto Presidencial no 7.962/2013
            (Regulamentação do Comércio Eletrônico), Lei 12.965/2014 (Marco
            civil da internet), Lei 13.709/2018 (Lei Geral de Proteção de Dados)
            e demais normas técnicas e legais aplicáveis à espécie.
          </p>
          <p>
            17.2 - Sendo competente de forma exclusiva para a resolução de
            qualquer disputa ou conflito o Foro da Comarca de São Paulo/SP.
          </p>
          <p>
            17.3 - A não aplicação, por qualquer uma das partes a qualquer
            momento ou por qualquer período de tempo, das disposições deste
            instrumento, ou o não exercício de qualquer uma das faculdades aqui
            presentes, não será interpretada como renúncia de tal disposição ou
            faculdade e em nada afetará os direitos das partes para exercer tais
            disposições ou faculdades.
          </p>
          <p>
            17.4 - Qualquer modificação ou alteração ao presente Termo de
            utilização será imediatamente válido e encaminhado para o Usuário
            para sua ciência e aceite.
          </p>
          <p>
            17.5 - Caso qualquer disposição deste Contrato venha a ser
            considerada inválida ou inexequível por decisão de um árbitro ou
            tribunal competente, o restante destes Termos (e cada um dos demais
            termos e condições contidos neste documento) permanecerá em pleno
            vigor e efeito.
          </p>
          <p>
            17.6 - As Partes não responderão pelo descumprimento das obrigações
            contratuais em situações de caso fortuito ou força maior, incluindo,
            sem limitação, restrições governamentais, casos de força maior,
            greves ou outras questões trabalhistas, guerra ou sabotagem.
          </p>
          <p>
            17.7 - A Parte impossibilitada de cumprir a sua obrigação deverá dar
            conhecimento à outra Parte, por escrito e imediatamente, da
            ocorrência ou da iminência da ocorrência de um Caso Fortuito ou de
            Força Maior e deverá empregar esforços comercialmente razoáveis para
            minimizar quaisquer atrasos e interferências resultantes na execução
            de suas obrigações fixadas neste Contrato.
          </p>
          <p>
            17.8 - Este Termo não poderá ser cedido, parcialmente ou totalmente,
            por uma parte, sem o prévio consentimento por escrito da outra
            parte. Somente haverá hipótese de cessão pela Lan UP para as suas
            afiliadas, ou em razão de uma venda de todo ou parte substancial,
            dos seus bens ou negócios.
          </p>
          <p>
            17.9 - O presente Termo é vinculante e reverterá em benefício de
            cada uma das partes e seus respectivos sucessores e cessionários.
            Nada deverá ser interpretado de forma a criar uma joint venture,
            sociedade, relação de emprego ou relação de agência entre as partes,
            e nenhuma parte está autorizada a executar qualquer contrato,
            vincular legalmente, incorrer em débito ou assumir responsabilidade
            civil ou obrigação em nome da outra na falta de documento em
            separado, firmado por representante autorizado da outra parte.
          </p>
          <p>
            17.10 - Cada parte será exclusivamente responsável por seus
            serviços, funcionários e contratados utilizados em razão deste Termo
            e para atingir o objetivo dos serviços.
          </p>
          <p>
            17.11 - Estes termos constituem o acordo e entendimento das partes
            na sua totalidade, substituindo e prevalecendo sobre todos os
            entendimentos e compromissos anteriores, se houverem.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

* {
  font-size: 10px;
}

header {
  margin: 0 auto;
  align-items: center;
  background-color: $Minsk;
}

header h1 {
  margin: 0;
  color: white;
}

header h1,
header div,
article > div > div > p,
article > div > div,
h3 {
  flex: 1;
}

.txt-color {
  color: $PictonBlue;
  font-weight: 500;
}

article > div {
  display: flex;
}

article > div > div > p,
h3,
li {
  color: black;
}

article {
  margin: 0 auto;
  box-shadow: 0px 3px 6px rgba(black, 0.2);
}

article,
section {
  background-color: white;
}

@media (min-width: 1140px) {
  header {
    padding: 0 80px;
    width: 70%;
    height: 300px;
  }

  h1,
  h1 span {
    font-size: 30px;
    line-height: 36px;
  }

  header div p {
    font-size: 12px;
  }

  article {
    padding: 2px 80px;
    width: 70%;
  }

  article div h3 {
    font-size: 16px;
    line-height: 20px;
  }

  article div p,
  li {
    font-size: 12px;
  }
}
@media (max-width: 960px), (max-width: 1140px) {
  header {
    padding: 0 30px;
    width: 70%;
    height: 300px;
  }

  h1,
  h1 span {
    font-size: 24px;
    line-height: 26px;
  }

  header div p {
    font-size: 12px;
  }

  article {
    padding: 2px 30px;
    width: 70%;
  }

  article div h3 {
    font-size: 16px;
    line-height: 20px;
  }

  article div p,
  li {
    font-size: 12px;
  }
}
@media (max-width: 720px) {
  header {
    padding: 0 30px;
    display: block;
    width: 82%;
    height: 200px;
  }

  h1 {
    padding: 10px 0;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  h1 span {
    font-size: 16px;
  }

  header div p {
    font-size: 10px;
    padding-bottom: 10px;
  }

  article {
    padding: 2px 30px;
    width: 82%;
  }

  article div {
    display: block;
  }

  article div h3 {
    font-size: 14px;
    line-height: 14px;
  }
}
@media (max-width: 326px), (max-width: 540px) {
  header {
    padding: 0;
  }

  header {
    padding: 0 5px;
    display: block;
    width: 82%;
    height: 200px;
  }

  h1 {
    padding: 10px 0;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

  h1 span {
    font-size: 12px;
  }

  header div p {
    font-size: 7px;
    padding-bottom: 10px;
  }

  article {
    padding: 2px 5px;
    width: 82%;
  }

  article div {
    display: block;
  }

  article div h3 {
    font-size: 10px;
    line-height: 14px;
  }
}
</style>
