<template>
  <md-content class="event-list grid-card" ref="container">
    <template v-if="currentState === states.LIST_ITEMS">
      <EventCard
        :event="event"
        :key="index"
        v-for="(event, index) in events"
        tabindex="0" />
    </template>
    <div
      v-else-if="currentState === states.EMPTY_FILTERED_SEARCH"
      class="empty-state d-flex direction-column-sm jc-center jc-space-evenly-sm ai-center">
      <div class="image-wrap">
        <img class="face-1" :src="require('@/assets/empty_search.png')" />
        <img class="face-2" :src="require('@/assets/empty_search_2.png')" />
      </div>
      <span class="text-cornflower-blue font-size-1-3">
        Não há demandas para essa busca
      </span>
    </div>
    <div
      v-else-if="currentState === states.EMPTY_LIST"
      class="empty-state d-flex direction-column-sm jc-center jc-space-evenly-sm ai-center">
      <div class="image-wrap">
        <img :src="require('@/assets/img_noevent.svg')" />
      </div>
      <div>
        <span class="text-cornflower-blue font-size-1-3">
          Você ainda não criou nenhuma demanda!
        </span>
        <p>
          Clique no botão
          <a
            class="text-cornflower-blue cursor-pointer"
            @click="goToCreateEvent">
            Criar Demandas
          </a>
          acima e comece a planejar e gerenciar sua demanda
        </p>
      </div>
    </div>
    <!-- Loading State -->
    <div
      v-else
      class="empty-state d-flex direction-column jc-space-evenly ai-center">
      <div class="image-wrap">
        <img :src="require('@/assets/img_noevent.svg')" />
      </div>
    </div>
    <!-- end - Loading State -->
  </md-content>
</template>

<script>
import { mapGetters } from "vuex";
import EventCard from "./EventCard";

export default {
  components: {
    EventCard,
  },
  props: {
    states: {
      type: Object,
      default: () => {},
    },
    currentState: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    ...mapGetters({
      events: "ListEvent/events",
      columns: "ListEvent/menuColumns",
    }),
  },
  methods: {
    goToCreateEvent() {
      this.$router.push({ name: "events.new", params: { step: 1 } });
    },
  },
};
</script>

<style lang="scss">
.last-item {
  height: 50px;
  width: calc(90vw - 250px);
}

.event-list {
  flex-wrap: wrap;
  max-height: 100%;
  padding-top: 5px;
  margin-top: -5px;

  .empty-state {
    height: calc(100vh - 300px);
    width: calc(90vw - 300px);

    .image-wrap {
      position: relative;
      max-height: 450px;
      max-width: 450px;
      height: 100%;
      width: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: contain;
      }

      .face-1 {
        opacity: 1;
      }

      .face-2 {
        opacity: 0;
      }

      &:hover {
        .face-1 {
          opacity: 0;
        }
        .face-2 {
          opacity: 1;
        }
      }
    }
  }
}

.grid-card {
  display: grid;
  -webkit-grid-template-columns: repeat(4, auto);
  grid-template-columns: repeat(4, auto);
  -webkit-grid-gap: 30px;
  grid-gap: 30px;
}
.item-button {
  -webkit-grid-column: 1 / span 4;
  grid-column: 1 / span 4;
}
</style>
