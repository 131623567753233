//LOGICA RETIRADA: https://github.com/vuejs-tips/v-money/issues/43
export const strCurrencyToDecimal = (str) => {
  const negative = str.indexOf("-") >= 0 ? -1 : 1;
  const numbers = onlyNumbers(str);
  const currency = numbersToCurrency(numbers, 2);
  return parseFloat(currency) * negative;
};

const toStr = (value) => (value ? value.toString() : "");

const onlyNumbers = (value) => toStr(value).replace(/\D+/g, "") || "0";

const fixed = (precision) => between(0, precision, 20);

const between = (min, n, max) => Math.max(min, Math.min(n, max));

const numbersToCurrency = (numbers, precision) => {
  var exp = Math.pow(10, precision);
  var float = parseFloat(numbers) / exp;
  return float.toFixed(fixed(precision));
};

export default {
  strCurrencyToDecimal,
};
