<template>
  <div class="w-100 event-basic-info">
    <!-- <HeaderImage :headerInfo="headerInfo" /> -->
    <div class="d-flex direction-row jc-space-between p-30 pb-15">
      <DateInfo :eventDates="dateInfo" class="w-50 mr-15" />
      <TimeInfo :eventTime="timeInfo" class="w-50 ml-15" />
    </div>
    <div class="d-flex direction-row jc-space-around p-30 pt-15">
      <LocationInfo :eventLocation="locationInfo" class="w-50 mr-15" />
      <OrganizerInfo :contactInfo="organizerInfo" class="w-50 ml-15" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

// import HeaderImage from './HeaderImage.vue';
import DateInfo from "./DateInfo.vue";
import TimeInfo from "./TimeInfo.vue";
import LocationInfo from "./LocationInfo.vue";
import OrganizerInfo from "./OrganizerInfo.vue";

export default {
  components: {
    // HeaderImage,
    DateInfo,
    TimeInfo,
    LocationInfo,
    OrganizerInfo,
  },
  computed: {
    ...mapGetters({
      basicInfo: "EventDetails/basicInfo",
    }),
    headerInfo() {
      const { name, type, topic, image } = this.basicInfo;
      return { name, type, topic, image };
    },
    dateInfo() {
      const { startDate, endDate } = this.basicInfo;
      return { startDate, endDate };
    },
    timeInfo() {
      return this.basicInfo.schedule;
    },
    locationInfo() {
      const { address, locationName, isHomeOffice } = this.basicInfo;
      return { address, locationName, isHomeOffice };
    },
    organizerInfo() {
      const { contactName, phone, email } = this.basicInfo;
      return { contactName, phone, email };
    },
  },
};
</script>
