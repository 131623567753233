import axios from "axios";

import { setBaseUrl } from "./interceptors/setBaseUrl";
import { setTokenToUrl } from "./interceptors/setTokenToUrl";

const pipe =
  (...fns) =>
  (data) =>
    fns.reduce((arg, fn) => fn(arg), data);

const requestInterceptors = pipe(setBaseUrl, setTokenToUrl);

const onError = (error) => {
  Promise.reject(error);
};

axios.interceptors.request.use(requestInterceptors, onError);
