<template>
  <CustomCard class="profile-resume-card w-100 radius-10">
    <div class="d-flex direction-column ai-center p-20">
      <Avatar :size="'larger'" class="mb-10" />
      <span>{{ profile.name }}</span>
      <!-- <button class="text-azure-radiance mt-10" @click="showProfile">
        Ver meu perfil
      </button> -->
    </div>
    <nav v-if="isAgency">
      <ul>
        <li
          class="menu-item m-0 pb-10 pl-20 pr-20"
          :class="{ active: activeItem === id }"
          :key="key"
          v-for="({ id, title, subtitle }, key) in view.menuItems"
          @click="menuItemClick(id)">
          <h5 class="m-0 mb-2">{{ title }}</h5>
          <small>{{ subtitle }}</small>
        </li>
      </ul>
    </nav>
  </CustomCard>
</template>

<script>
import { mapGetters } from "vuex";
import { getUser } from "@/helpers/auth";
import { isAgency } from "@/helpers/auth";

export default {
  props: {
    activeItem: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      identification: "Profile/identification",
    }),

    profile() {
      return {
        name: this.identification.fantasyName || getUser().username,
      };
    },
    isAgency() {
      return isAgency();
    },
  },
  data: () => ({
    view: {
      menuItems: [
        {
          id: "#about",
          title: "Sobre a Empresa",
          subtitle: "Sua foto de perfil, apresentação e mais",
        },
        // {
        //     id: '#services',
        //     title: 'Serviços',
        //     subtitle: 'Área de operação'
        // },
        // {
        //     id: '#agalery',
        //     title: 'Galeria',
        //     subtitle: 'Fotos e videos de seus trabalhos'
        // }
      ],
    },
  }),
  methods: {
    showProfile() {},
    menuItemClick(id) {
      this.$emit("menuItemClick", id);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.profile-resume-card {
  .menu-item {
    border-top: 2px solid $Ebony;
    cursor: pointer;

    &:hover,
    &:active {
      background: transparentize($FuchsiaBlue, 0.7);
    }
    &.active {
      background: transparentize($FuchsiaBlue, 0.3);
    }

    h5 {
      font-weight: 300;
      text-align: center;
    }

    small {
      color: transparentize(white, 0.5);
      display: inline-block;
      overflow: hidden;
      word-break: break-word;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
