<template>
  <div v-if="!isListBatch">
    <div
      :class="{ 'd-flex': isListBatch }"
      class="pl-16 pr-16 jc-space-between ai-center">
      <div class="container w-100 pt-20 d-flex direction-column h-100">
        <header class="d-flex ph-25 ai-center jc-space-between w-100 mb-20">
          <div class="d-flex ai-center w-50">
            <h2 class="mr-10 font-size-1-3 header-title">Folha de pagamento</h2>
          </div>
          <button
            v-if="isListBatch"
            @click="isListBatch = !isListBatch"
            class="btn secondary d-flex ai-center jc-center">
            <i class="mdi mdi-plus-circle font-size-1-4 button-ic"></i>
            <span class="ml-5">Novo lote</span>
          </button>
          <div class="w-50 d-flex jc-end">
            <button
              @click="openFilterPayrollModal"
              class="filter outline w-35 mt-87 mr-10">
              <i class="mdi mdi-filter-variant font-size-1-4"></i>
              Filtros Avançados
            </button>
            <button
              :class="{ 'coupon-appplied': responseIsValid }"
              class="btn outline w-40 mt-87 mh font-weight-300"
              @click="discountCupom">
              {{ buttonLabel }}
            </button>
          </div>
        </header>
        <div class="d-flex cursor-pointer jc-start pl-25">
          <i class="mdi mdi-chevron-left font-size-1-2 text-malibu"></i>
          <span
            class="font-size-08 text-malibu"
            @click="isListBatch = !isListBatch">
            {{
              isListBatch
                ? "Ir para solicitação de pagamento"
                : "Todos pagamentos solicitados"
            }}
          </span>
        </div>
        <div class="d-flex ph-25 mt-25 ai-center payroll-default-filters">
          <CustomInput
            class="w-100"
            placeholder="Demanda"
            tip="Ex: Festa Junina"
            v-model="eventName" />
          <CustomDateRange
            class="mh-20 w-100"
            label="Período"
            @input="
              setFiltersPayroll({
                startDate: $event.start,
                endDate: $event.end,
              })
            " />
          <SearchHirer
            class="w-100"
            @selected="onHirerSelected"
            :value="filterPayroll.hirer.name" />

          <button class="btn-filter" @click="getMembers">
            <span>Filtrar</span>
          </button>
        </div>
      </div>
    </div>
    <div class="p-15 p-relative">
      <table
        class="table-container bg-header-ebony mt-25"
        :class="{ 'mb-55': sendTobackend.length }">
        <thead>
          <tr>
            <th>
              <custom-checkbox
                type="checkbox"
                :item="root"
                :checked="root.checked"
                @change="onChange"
                :disabled="!root.children.length" />
            </th>
            <th><small>Membros</small></th>
            <th v-if="columns.turn.checked"><small>Total de turnos</small></th>
            <th v-if="columns.chargeByPresence.checked">
              <small>Valor Presença</small>
            </th>
            <th v-if="columns.chargeByPayment.checked">
              <small>Pagamento via Lanup</small>
            </th>
            <th><small>Valor Total</small></th>
            <th><small>Status</small></th>
            <th></th>
          </tr>
        </thead>

        <tbody v-if="!root.empty && !loader">
          <freela-table-row
            :item="item"
            :key="index"
            v-for="(item, index) in root.children" />
        </tbody>

        <tbody v-else>
          <td colspan="7">
            <div class="d-flex ai-center jc-center direction-column pt-30">
              <template v-if="!loader">
                <img
                  class="image-empty"
                  :src="require('@/assets/empty-state-payroll.png')"
                  alt="empty-state" />
                <span>Use o filtro encontrar os freelas que deseja pagar!</span>
              </template>
              <Loading v-else class="p-50" label="Carregando" />
            </div>
          </td>
        </tbody>
      </table>
      <div class="footer" :class="{ 'open-footer': sendTobackend.length }">
        <div class="content d-flex jc-end ai-center">
          <small class="pl-40">
            Selecionados
            <br />
            {{ sendTobackend.length }}
          </small>
          <small class="pl-40">
            Valor Presença
            <br />
            {{ sendTobackend | formatChargeByPresence }}
          </small>
          <small class="pl-40">
            Pagamento via Lanup
            <br />
            {{ sendTobackend | formatChargeByPayment }}
          </small>
          <small class="pl-40">
            Valor adicional
            <br />
            {{ sendTobackend | formatAdditional }}
          </small>
          <small class="pl-40" v-if="coupon != ''">
            Desconto aplicado
            <br />
            {{ sendTobackend | formatDiscount }}
          </small>
          <small class="pl-40">
            Valor Total a pagar
            <br />
            {{ sendTobackend | formatPaymentValue }}
          </small>
          <button @click="openModal" class="btn-payment">
            <span>Solicitar pagamento</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ListBatch v-else />
</template>

<script>
import SearchHirer from "./SearchHirer.vue";
import ListBatch from "@/pages/ListBatch.vue";
import freelaTableRow from "@/components/shared/freelaTableRow.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { sum, pluck } from "ramda";

export default {
  props: {
    eventService: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ListBatch,
    freelaTableRow,
    SearchHirer,
  },

  data: () => ({
    name: "",
    eventid: null,
    services: [],
    dateFilter: null,
    statusFilter: 1,
  }),

  created() {
    this.clearFilterPayroll();
  },

  beforeDestroy() {
    this.setIsListBatch(true);
    this.resetMembersWithJobDone();
  },

  computed: {
    ...mapGetters({
      isBatch: "Payment/isListBatch",
      root: "Payment/membersWithJobDone",
      loader: "Payment/loading",
      coupon: "Payment/coupon",
      paymentCouponDiscount: "Payment/paymentCouponDiscount",
      presenceCouponDiscount: "Payment/presenceCouponDiscount",
      responseIsValid: "Payment/responseIsValid",
      columns: "Payment/payrollColumns",
      filters: "EventDetails/filters",
      filterPayroll: "Payment/filterPayroll",
      currentFilters: "EventDetails/currentFilters",
    }),
    eventName: {
      get() {
        return this.filterPayroll.eventName;
      },
      set(value) {
        this.setFiltersPayroll({ eventName: value });
      },
    },
    isListBatch: {
      get() {
        return this.isBatch;
      },
      set(value) {
        this.setIsListBatch(value);
      },
    },

    buttonLabel() {
      return "Cupom de Desconto";
    },
    eventQuantity() {
      const freelas = this.root.children?.flatMap((x) => {
        return (
          x.children?.filter(
            (s) => s.checked === true && ![2, 3].includes(s.paymentStatus)
          ) || []
        );
      });
      return new Set(freelas.flatMap((x) => x.eventId)).size;
    },
    sendTobackend() {
      const paymentPercent = this.paymentCouponDiscount?.percent
        ? this.paymentCouponDiscount?.percent / 100
        : 0;
      const presencePercent = this.presenceCouponDiscount?.percent
        ? this.presenceCouponDiscount?.percent / 100
        : 0;
      let totalChargeByPresence = 0;
      let totalChargeByPayment = 2 - paymentPercent * 2;

      const freelas = this.root.children?.flatMap((x) => {
        return (
          x.children?.filter(
            (s) => s.checked === true && ![2, 3].includes(s.paymentStatus)
          ) || []
        );
      });

      const group = [];
      if (freelas.length) {
        freelas?.forEach((x) => {
          const freela = group.find((y) => y.freelaId === x.freelaId);
          const { additionalValue, chargeHirer } = x.paymentService;
          if (!freela) {
            totalChargeByPresence =
              x.chargeByPresence - presencePercent * x.chargeByPresence;

            group.push({
              freelaId: x.freelaId,
              paymentServices: [
                {
                  vacancyId: x.vacancyId,
                  additionalValue: additionalValue,
                  chargeHirer: chargeHirer,
                },
              ],
              chargeByPresence: x.chargeByPresence,
              chargeByPayment: 2,
              singleChargeByPayment: totalChargeByPayment,
              discountApplied: presencePercent * x.chargeByPresence,
              payment: x.payment + totalChargeByPresence + additionalValue,
            });
            return true;
          }
          freela.paymentServices.push({
            ...x.paymentService,
            vacancyId: x.vacancyId,
          });
          freela.discountApplied += presencePercent * x.chargeByPresence;
          freela.payment += x.payment + totalChargeByPresence + additionalValue;
          freela.chargeByPresence += x.chargeByPresence;
        });
      }
      return group;
    },
  },
  filters: {
    formatAdditional(val) {
      const getAdditionalValue = pluck("additionalValue");
      const additionalValue = val.flatMap(({ paymentServices }) =>
        getAdditionalValue(paymentServices)
      );
      const totalAdditionalValue = sum(additionalValue);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(totalAdditionalValue);
    },
    formatPaymentValue(val) {
      const payment = val
        .map((p) => p.payment)
        .reduce(
          (acc, currentValue) => acc + currentValue,
          val[0]?.singleChargeByPayment ?? 0
        );

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(payment);
    },
    formatChargeByPresence(val) {
      const charge = val
        .map((v) => v.chargeByPresence)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(charge);
    },
    formatChargeByPayment(val) {
      const charge = val
        .map((v) => v.chargeByPayment)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(charge);
    },

    formatDiscount(val) {
      const discount = val
        .map((v) => v.discountApplied)
        .reduce(
          (acc, currentValue) => acc + currentValue,
          2 - val[0]?.singleChargeByPayment ?? 0
        );

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(discount);
    },
  },

  methods: {
    ...mapMutations({
      resetMembersWithJobDone: "Payment/RESET_MEMBERS_WITH_JOB_DONE",
    }),
    ...mapActions({
      setIsListBatch: "Payment/setIsListBatch",
      udpateVacanciesDone: "Payment/udpateVacanciesDone",
      getVacanciesDone: "Payment/getVacanciesDone",
      setFiltersPayroll: "Payment/setFiltersPayroll",
      clearFilterPayroll: "Payment/clearFilterPayroll",
    }),
    onHirerSelected({ cnpj, name }) {
      this.setFiltersPayroll({ hirer: { cnpj, name } });
    },
    onChange(node, checked) {
      this.udpateVacanciesDone({ node, checked });
    },
    openModal() {
      this.$eventhub.$emit("modal:open:confirmation-payment", {
        freelasToPay: this.sendTobackend,
        eventQuantity: this.eventQuantity,
      });
    },
    advancedFilters() {
      this.$eventhub.$emit("modal:open:AdvancedPayrollFilters", {});
    },
    discountCupom() {
      this.$eventhub.$emit("modal:open:DiscountCupomModal", {});
    },
    openFilterPayrollModal() {
      this.$eventhub.$emit("modal:open:filter-payroll");
    },
    getMembers() {
      this.getVacanciesDone()
        .catch((error) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${error}`,
          });
        })
        .finally(() => {
          this.loaderButton = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.footer {
  position: fixed;
  width: 100%;
  height: 60px;
  margin: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-out;
  -webkit-transform: scalexY(0);
  transform: scaleY(0);

  &.open-footer {
    transition: all 0.3s ease-in;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  & .content {
    background-color: $Ebony;
    height: 100%;
    padding-right: 2.2%;
    & .btn-payment {
      background-color: $LightGreen;
      padding: 10px 30px;
      margin-left: 5%;
      & span {
        color: $Minsk;
        font-size: 1em;
        font-family: "HelveticaNowMicro";
        font-weight: bold;
      }
      &:hover {
        background-color: rgba($color: $LightGreen, $alpha: 0.9);
      }
    }
  }
}
.filter {
  background: $PictonBlue;
  color: $SteelGray;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: lighten($Malibu, 10);
  }
}
.disable {
  visibility: hidden;
}
.image-empty {
  image-rendering: auto;
  width: 25%;
  height: 25%;
  padding-bottom: 5px;
  object-fit: contain;
}
.btn.outline.outline-fuchsiaBlue {
  font-weight: normal;
}

.btn-filter {
  border: 2px solid $FuchsiaBlue;
  height: 50px;
  padding: 0px 30px;
  margin: 0 10px;
  margin-bottom: 4px;
  align-self: end;
  & span {
    color: white;
    font-size: 1em;
    font-family: "HelveticaNowMicro";
  }
  &:hover {
    background-color: $FuchsiaBlue;
  }
}
</style>
