<template>
  <Collapsible :isIcon="true">
    <template #header>
      <td>
        <div class="d-flex ai-center">
          <custom-checkbox
            v-if="item.hasBankData"
            type="checkbox"
            :item="item"
            :checked="item.checked"
            @change="onChange"
            :class="{
              disable: statusPayment(item.children) || !item.hasBankData,
            }" />
          <i v-else class="mdi mdi-account-alert text-red font-size-1-7"></i>
        </div>
      </td>
      <td>
        <div class="d-flex ai-center">
          <div class="d-flex direction-column">
            <span>{{ item.name }}</span>
            <small v-if="item.hasBankData" class="text-Manatee">Titular</small>
            <span v-else class="text-red">Dados bancários incompleto</span>
          </div>
        </div>
      </td>
      <td v-if="columns.turn.checked">{{ item.children.length }}</td>
      <td v-if="columns.chargeByPresence.checked">
        <span>{{ item.children | chargeByPresence }}</span>
      </td>
      <td v-if="columns.chargeByPayment.checked">
        <span>{{ item.children | chargeByPayment }}</span>
      </td>
      <td>
        <span>{{ item.children | chargeByPresence }}</span>
      </td>
      <td>
        <span>{{ item.children | chargeByPayment }}</span>
      </td>
      <td>
        <span>{{ item.children | totalValue }}</span>
      </td>
      <td>
        <div>
          <span v-if="!GeneralStatusPayment(item.children)" class="status-wait">
            Aguardando pagamento
          </span>
          <span v-else class="status-paid">Pagamento efetuado</span>
        </div>
      </td>
    </template>

    <template #header-body>
      <th></th>
      <th></th>
      <th v-if="columns.service.checked"><small>Serviços</small></th>
      <th v-if="columns.event.checked"><small>Demanda</small></th>
      <th v-if="columns.date.checked"><small>Data</small></th>
      <th v-if="columns.checkinAndCheckout.checked"><small>Entrada</small></th>
      <th v-if="columns.checkinAndCheckout.checked"><small>Saída</small></th>
      <th><small>Horas trabalhadas</small></th>
      <th v-if="columns.eventValue.checked"><small>Valor demanda</small></th>
      <th v-if="columns.additionalValue.checked">
        <small>Valor adicional</small>
      </th>
      <th><small>Status</small></th>
      <th></th>
      <th></th>
    </template>

    <template #body>
      <tr
        class="header-body"
        v-for="(shift, index) in item.children"
        :key="index">
        <td></td>
        <td>
          <CustomCheckbox
            v-if="item.hasBankData"
            :class="{
              disable:
                [2, 3].includes(shift.paymentStatus) || !item.hasBankData,
            }"
            type="checkbox"
            :item="shift"
            :checked="shift.checked"
            @change="onChange" />
        </td>
        <td v-if="columns.service.checked">
          <small>{{ shift.job }}</small>
        </td>
        <td v-if="columns.event.checked">
          <small>{{ eventLabel(shift) }}</small>
        </td>
        <td v-if="columns.date.checked">
          <div class="d-flex direction-column">
            <small class="text-white-opacity">
              {{ formatDay(shift.day, "fullDate") }}
            </small>
            <small class="text-white-opacity">
              {{ formatDay(shift.day, "weekday") }}
            </small>
          </div>
        </td>
        <td v-if="columns.checkinAndCheckout.checked">
          <small class="">{{ shift.checkIn | date }}</small>
        </td>
        <td v-if="columns.checkinAndCheckout.checked">
          <small class="">{{ shift.checkOut | date }}</small>
        </td>
        <td>
          <small>{{ shift.hoursWorked | formatHours }}</small>
        </td>
        <td v-if="columns.eventValue.checked">
          <small>R$ {{ shift.payment | toCurrency }}</small>
        </td>
        <td v-if="columns.additionalValue.checked">
          <div class="d-flex direction-column">
            <small class="text-white-opacity d-flex">
              <span class="font-weight-200">Adicional</span>
              <button
                class="additional-button ml-4"
                @click="openAdditionalModal(shift)">
                +
                <span
                  v-if="
                    shift.paymentService !== null &&
                    shift.paymentService.additionalValue !== 0
                  "
                  class="font-size-06 ml-6">
                  {{ shift.paymentService.additionalValue | toCurrency }}
                </span>
              </button>
            </small>
          </div>
        </td>
        <td>
          <small>R$ {{ shift.payment | toCurrency }}</small>
        </td>
        <td>
          <small>R$ {{ shift.payment | toCurrency }}</small>
        </td>
        <td>
          <template>
            <small class="text-FrenchRose" v-if="shift.paymentStatus === 1">
              Aguardando Pagamento
            </small>
            <small class="text-orange" v-else-if="shift.paymentStatus === 2">
              Pagamento Agendado
            </small>
            <small class="text-RobinsEggBlue" v-else>Pagamento efetuado</small>
          </template>
        </td>
      </tr>
    </template>
  </Collapsible>
</template>

<script>
import { isNil } from "ramda";
import Collapsible from "@/components/shared/Collapsible.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Collapsible },
  props: ["item"],
  filters: {
    totalValue(val) {
      const awaitingPayment = val.filter((x) => x.paymentStatus !== 3);
      const value = awaitingPayment.reduce(
        (acc, currentValue) => acc + currentValue.payment || currentValue.value,
        0
      );
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    chargeByPresence(val) {
      const value = val
        .map((v) => v.chargeByPresence)
        .reduce((acc, curr) => acc + curr, 0);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    chargeByPayment(val) {
      const value = val[0].chargeByPayment;

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    date(val) {
      return new Date(val.replace("Z", "")).toLocaleDateString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    formatHours(hoursWorked) {
      const [hour, min] = hoursWorked.split(":");
      return `${hour}h${min}min`;
    },
  },
  computed: {
    ...mapGetters({
      additionalValue: "Payment/additionalValue",
      columns: "Payment/payrollColumns",
    }),
  },
  methods: {
    ...mapActions({
      udpateVacanciesDone: "Payment/udpateVacanciesDone",
    }),
    eventLabel(shift) {
      const { eventName, serviceOrderNumber } = shift;

      return isNil(serviceOrderNumber)
        ? eventName
        : `# ${serviceOrderNumber} ${eventName}`;
    },
    formatDay(day, option) {
      const options = {
        fullDate: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
        weekday: { weekday: "long" },
      };
      if (day) {
        return new Date(day.replace("Z", "")).toLocaleDateString(
          "pt-BR",
          options[option]
        );
      }
    },
    GeneralStatusPayment(val) {
      if (val) {
        return val.every((v) => [3].includes(v.paymentStatus));
      }
    },
    statusPayment(val) {
      if (val) return val.every((v) => [2, 3].includes(v.paymentStatus));
    },
    onChange(node, checked) {
      this.udpateVacanciesDone({ node, checked });
    },
    openAdditionalModal(shift) {
      this.$eventhub.$emit("modal:open:additional-payment", shift.vacancyId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.header-body {
  border-bottom: 2px solid $Ebony;
  &:last-child {
    border-bottom: none;
  }
}

.additional-button {
  border: 2px solid $PictonBlue;
  color: $PictonBlue;
}
</style>
