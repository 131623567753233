<template>
  <div class="p-15 p-relative">
    <filter-payroll :eventService="eventService" />

    <table class="table-container bg-header-ebony mt-25">
      <thead>
        <tr>
          <th>
            <custom-checkbox
              type="checkbox"
              :item="root"
              :checked="root.checked"
              @change="onChange"
              :disabled="!root.children.length" />
          </th>
          <th><small>Membros</small></th>
          <th><small>Total de turnos</small></th>
          <th><small>Valor Presença</small></th>
          <th><small>Pagamento via Lanup</small></th>
          <th><small>Valor Total</small></th>
          <th><small>Status</small></th>
          <th></th>
        </tr>
      </thead>

      <tbody v-if="!root.empty && !loader">
        <freela-table-row
          :item="item"
          :key="index"
          v-for="(item, index) in root.children" />
      </tbody>

      <tbody v-else>
        <td colspan="7">
          <div class="d-flex ai-center jc-center direction-column pt-30">
            <template v-if="!loader">
              <img
                class="image-empty"
                :src="require('@/assets/empty-state-payroll.png')"
                alt="empty-state" />
              <span>Use o filtro encontrar os freelas que deseja pagar!</span>
            </template>
            <Loading v-else class="p-50" label="Carregando" />
          </div>
        </td>
      </tbody>
    </table>
    <div class="footer" :class="{ 'open-footer': sendTobackend.length }">
      <div class="content d-flex jc-end ai-center">
        <small class="pl-40">
          Selecionados
          <br />
          {{ sendTobackend.length }}
        </small>
        <small class="pl-40">
          Valor Presença
          <br />
          {{ sendTobackend | formatChargeByPresence }}
        </small>
        <small class="pl-40">
          Pagamento via Lanup
          <br />
          {{ sendTobackend | formatChargeByPayment }}
        </small>
        <small class="pl-40">
          Valor adicional
          <br />
          {{ sendTobackend | formatAdditional }}
        </small>
        <small class="pl-40" v-if="coupon != ''">
          Desconto aplicado
          <br />
          {{ sendTobackend | formatDiscount }}
        </small>
        <small class="pl-40">
          Valor Total a pagar
          <br />
          {{ sendTobackend | formatPaymentValue }}
        </small>
        <button @click="openModal" class="btn-payment">
          <span>Solicitar pagamento</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import freelaTableRow from "@/components/shared/freelaTableRow.vue";
import FilterPayroll from "./filterPayroll.vue";
import { mapGetters, mapActions } from "vuex";
import { sum, pluck } from "ramda";

export default {
  props: {
    eventService: {
      type: Array,
      default: () => [],
    },
  },
  components: { FilterPayroll, freelaTableRow },

  computed: {
    ...mapGetters({
      root: "Payment/membersWithJobDone",
      loader: "Payment/loading",
      coupon: "Payment/coupon",
      presenceCouponDiscount: "Payment/presenceCouponDiscount",
    }),
    sendTobackend() {
      const paymentPercent = this.paymentCouponDiscount?.percent
        ? this.paymentCouponDiscount?.percent / 100
        : 0;
      const presencePercent = this.presenceCouponDiscount?.percent
        ? this.presenceCouponDiscount?.percent / 100
        : 0;
      let totalChargeByPresence = 0;
      let totalChargeByPayment = 0;

      const freelas = this.root.children?.flatMap((x) => {
        return (
          x.children?.filter(
            (s) => s.checked === true && ![2, 3].includes(s.paymentStatus)
          ) || []
        );
      });

      const group = [];
      if (freelas.length) {
        freelas?.forEach((x) => {
          const freela = group.find((y) => y.freelaId === x.freelaId);
          const { additionalValue, chargeHirer } = x.paymentService;
          if (!freela) {
            totalChargeByPresence =
              x.chargeByPresence - presencePercent * x.chargeByPresence;

            totalChargeByPayment = 2 - paymentPercent * 2;

            group.push({
              freelaId: x.freelaId,
              paymentServices: [
                {
                  vacancyId: x.vacancyId,
                  additionalValue: additionalValue,
                  chargeHirer: chargeHirer,
                },
              ],
              chargeByPresence: x.chargeByPresence,
              chargeByPayment: 2,
              discountApplied:
                presencePercent * x.chargeByPresence + paymentPercent * 2,
              payment:
                x.payment +
                totalChargeByPresence +
                totalChargeByPayment +
                additionalValue,
            });
            return true;
          }
          freela.paymentServices.push({
            ...x.paymentService,
            vacancyId: x.vacancyId,
          });
          freela.discountApplied +=
            presencePercent * x.chargeByPresence + paymentPercent * 2;
          freela.payment += x.payment + totalChargeByPresence + additionalValue;
          freela.chargeByPresence += x.chargeByPresence;
        });
      }

      return group;
    },
  },
  filters: {
    formatAdditional(val) {
      const getAdditionalValue = pluck("additionalValue");
      const additionalValue = val.flatMap(({ paymentServices }) =>
        getAdditionalValue(paymentServices)
      );
      const totalAdditionalValue = sum(additionalValue);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(totalAdditionalValue);
    },
    formatPaymentValue(val) {
      const payment = val
        .map((p) => p.payment)
        .reduce((acc, currentValue) => acc + currentValue, 0);
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(payment);
    },
    formatChargeByPresence(val) {
      const charge = val
        .map((v) => v.chargeByPresence)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(charge);
    },
    formatChargeByPayment(val) {
      const charge = val
        .map((v) => v.chargeByPayment)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(charge);
    },

    formatDiscount(val) {
      const discount = val
        .map((v) => v.discountApplied)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(discount);
    },
  },

  methods: {
    ...mapActions({
      udpateVacanciesDone: "Payment/udpateVacanciesDone",
    }),
    onChange(node, checked) {
      this.udpateVacanciesDone({ node, checked });
    },
    openModal() {
      this.$eventhub.$emit("modal:open:confirmation-payment", {
        freelasToPay: this.sendTobackend,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.footer {
  position: fixed;
  width: 100%;
  height: 60px;
  margin: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-out;
  -webkit-transform: scalexY(0);
  transform: scaleY(0);

  &.open-footer {
    transition: all 0.3s ease-in;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  & .content {
    background-color: $Ebony;
    height: 100%;
    padding-right: 2.2%;
    & .btn-payment {
      background-color: $LightGreen;
      padding: 10px 30px;
      margin-left: 5%;
      & span {
        color: $Minsk;
        font-size: 1em;
        font-family: "HelveticaNowMicro";
        font-weight: bold;
      }
      &:hover {
        background-color: rgba($color: $LightGreen, $alpha: 0.9);
      }
    }
  }
}
.disable {
  visibility: hidden;
}
.image-empty {
  image-rendering: auto;
  width: 25%;
  height: 25%;
  padding-bottom: 5px;
  object-fit: contain;
}
</style>
