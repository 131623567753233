<template>
  <button @click="openClose">
    <section
      class="dropDownMenuWrapper"
      :class="{
        'dropDownMenuWrapper--noTitle': !menuTitle,
      }">
      <div
        :class="{ outline, 'mr-10': !outline }"
        class="button-icon p-10 pl-25 pr-25 d-flex ai-center radius-5">
        <img class="icon" :src="icon" />
        <span class="pl-10 text-white">{{ menuTitle }}</span>
      </div>

      <section
        v-click-outside="hidePopUps"
        :class="{ primary: !outline, ghost: outline }"
        class="dropdownMenu"
        v-if="isOpen">
        <div :class="{ primary: !outline, ghost: outline }" class="menuArrow" />
        <div class="list">
          <slot />
        </div>
      </section>
    </section>
  </button>
</template>

<script>
export default {
  props: ["icon", "menuTitle", "outline"],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    hidePopUps() {
      this.openClose();
    },
    openClose() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
button {
  padding: 0;
}

.dropDownMenuWrapper {
  position: relative;
  width: auto;
  border-radius: 8px;
  background: none;
  outline: none;
  z-index: 3;
  border: none;
  * {
    box-sizing: border-box;
    text-align: left;
  }

  .list {
    overflow: auto;
    max-height: 300px;
    padding: 20px 20px;

    &::-webkit-scrollbar-track {
      background: $Ebony;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $EastBay;
      border-radius: 10px;
    }
  }
  .dropdownMenu {
    position: absolute;
    top: 100%;
    width: 100%;
    right: 10px;
    min-height: 10px;
    border-radius: 8px;
    border: 1px solid #eee;
    box-shadow: 10px 10px 0 0 rgba(black, 0.03);
    animation: menu 0.3s ease forwards;
    .menuArrow {
      width: 20px;
      height: 20px;
      position: absolute;
      top: -10px;
      right: 20px;
      border-left: 1px solid #eee;
      border-top: 1px solid #eee;
      transform: rotate(45deg);
      border-radius: 4px 0 0 0;
    }

    .divider {
      border: none;
      background-color: #eee;
      height: 1px;
    }

    .option {
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 3;

      & :hover {
        color: rgba(white, 0.5);
      }
      &:last-child {
        border-bottom: 0;
      }
      * {
        color: white;
        text-decoration: none;
        background: none;
        border: 0;
        padding: 0;
        outline: none;
        cursor: pointer;
      }
    }
    .desc {
      display: block;
      width: 100%;
      font-size: 14px;
      margin: 3px 0 0 0;
      cursor: default;
    }
  }
  @keyframes menu {
    from {
      transform: translate3d(0, 30px, 0);
    }
    to {
      transform: translate3d(0, 20px, 0);
    }
  }
}
.dropDownMenuWrapper--noTitle {
  padding: 0;
  width: 60px;
  height: 60px;
}

.primary {
  background: $FuchsiaBlue;
}
.ghost {
  background: $SteelGray;
}
</style>
