<template>
  <div class="d-flex ai-center">
    <MultiSelect
      class="w-50"
      placeholder="Serviços"
      v-model="services"
      :options="getServices"></MultiSelect>
    <CustomInput class="w-50" placeholder="Nome" v-model="name" />
    <CustomSelect
      ref="select-date"
      class="w-50"
      placeholder="Data"
      @input="setFiltersPayroll({ shift: $event })">
      <option
        v-for="({ id, label }, key) in getShifts"
        :key="key"
        :selected="id === currentFilters.day"
        :value="id">
        {{ label }}
      </option>
    </CustomSelect>
    <CustomSelect
      ref="select-status"
      class="w-50"
      placeholder="Status"
      @input="setFiltersPayroll({ paymentStatus: Number($event) })">
      <option
        v-for="({ label, value }, key) in status"
        :key="key"
        :value="value">
        {{ label }}
      </option>
    </CustomSelect>
    <div class="w-50 mt-17">
      <button class="btn-filter" @click="getMembers">
        <span>Filtrar</span>
      </button>
    </div>

    <button
      :class="{ 'coupon-appplied': responseIsValid }"
      class="btn outline w-80 mt-17 mh font-weight-300"
      @click="discountCupom">
      {{ buttonLabel }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    eventService: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.register();
  },
  data: () => ({
    status: [
      { label: "Selecione", value: 0 },
      { label: "Aguardando pagamento", value: 1 },
      { label: "Pagamento agendado", value: 2 },
      { label: "Pagamento efetuado", value: 3 },
    ],
  }),
  mounted() {
    this.$refs["select-status"].$refs.select.value = 1;
    this.setFiltersPayroll({
      paymentStatus: 1,
      eventId: this.$route.params.id,
    });
    this.getMembers();
  },
  computed: {
    ...mapGetters({
      filters: "EventDetails/filters",
      filterPayroll: "Payment/filterPayroll",
      currentFilters: "EventDetails/currentFilters",
      coupon: "Payment/coupon",
      responseIsValid: "Payment/responseIsValid",
    }),

    name: {
      get() {
        return this.filterPayroll.term;
      },
      set(value) {
        this.setFiltersPayroll({ term: value });
      },
    },

    services: {
      get() {
        return this.filterPayroll.services;
      },
      set(value) {
        this.setFiltersPayroll({ services: value });
      },
    },

    buttonLabel() {
      return this.responseIsValid
        ? `Cupom Aplicado: ${this.coupon}`
        : "Cupom de Desconto";
    },
    getServices() {
      return this.eventService.map((x) => ({
        label: x.job,
        value: x.job,
      }));
    },
    getShifts() {
      const shift = this.filters.day.slice(1);
      return [{ id: null, label: "Selecione" }, ...shift];
    },
  },
  methods: {
    ...mapActions({
      getVacanciesDone: "Payment/getVacanciesDone",
      setFiltersPayroll: "Payment/setFiltersPayroll",
      clearFilterPayroll: "Payment/clearFilterPayroll",
      setPayrollColumns: "Payment/setPayrollColumns",
    }),
    register() {
      const CLEAR_FILTER_EVENT = `clear:filter-payroll`;
      this.$eventhub.$on(CLEAR_FILTER_EVENT, this.clearFilter);
    },
    clearFilter() {
      this.clearFilterPayroll();
    },

    getMembers() {
      this.getVacanciesDone().catch((error) => {
        this.$eventhub.$emit("feedback:show", {
          type: "error",
          msg: `${error}`,
        });
      });
    },

    discountCupom() {
      this.$eventhub.$emit("modal:open:DiscountCupomModal", {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.btn-filter {
  border: 2px solid $FuchsiaBlue;
  height: 50px;
  padding: 0px 30px;
  margin: 0 10px;
  & span {
    color: white;
    font-size: 1em;
    font-family: "HelveticaNowMicro";
  }
  &:hover {
    background-color: $FuchsiaBlue;
  }
}

.coupon-appplied {
  color: $LightGreen;
}

.mh {
  min-height: 50px;
}
</style>
