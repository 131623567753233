import { getSession } from "@/services/payment/payment.http";

// Reference: https://dev.pagseguro.uol.com.br/reference#transparente-biblioteca-javascript
const URL_SDK =
  "https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js";

// eslint-disable-next-line
export const getPagSeguro = () => PagSeguroDirectPayment;

const initialize = (callback) => () => {
  const pagseguro = getPagSeguro();

  const onSenderHashReady = function ({ status, senderHash: hash }) {
    if (status === "error") return false;

    getSession().then((sessionId) => {
      pagseguro.setSessionId(sessionId);
      callback(pagseguro, hash);
    });
  };

  pagseguro.onSenderHashReady(onSenderHashReady);
};

export const loadLib = (callback) => {
  const script = document.createElement("script");
  script.onload = initialize(callback);
  script.src = URL_SDK;
  document.head.appendChild(script);
};

export default {
  loadLib,
  getPagSeguro,
};
