<template>
  <md-content
    class="service-list md-scrollbar overflow-auto h-100 d-flex direction-column">
    <CustomInput
      label="Buscar funções"
      class="pb-15 pr-15"
      id="search-functions"
      v-model="searchService" />

    <div :class="{ 'text-center': !serviceList.length }" v-if="!loading">
      <ToggleItem :options="serviceList" v-model="service"></ToggleItem>
      <span v-if="!loading && !serviceList.length">
        Nenhuma função encontrada
      </span>
    </div>
    <Loading v-else />
  </md-content>
</template>

<script>
import { not, equals } from "ramda";
import { mapGetters } from "vuex";

import ToggleItem from "@/components/shared/form/ToggleItem.vue";
import { debounce } from "@/helpers/debounce.js";
import { getAvailableServices } from "@/services/events/event.http";

export default {
  components: { ToggleItem },
  props: {
    selectedServices: {
      type: Array,
      default: () => [],
    },

    exclusiveServices: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    serviceList: [],
    availableServices: [],
    searchService: "",
    loading: false,
    service: [],
  }),
  created() {
    getAvailableServices().then(
      (services) => (this.availableServices = services)
    );
    this.debounceInput = debounce(this.debounceInput, 500);
    this.service = this.selectedServices;
  },
  watch: {
    searchService: function (val) {
      this.loading = true;
      this.debounceInput(val);
    },
    availableServices: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        if (not(equals(val, oldVal))) {
          this.refreshServiceList();
        }
      },
    },
    selectedServices: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        if (not(equals(val, oldVal))) {
          this.refreshServiceList();
        }
      },
    },
    exclusiveServices: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        if (not(equals(val, oldVal))) {
          this.refreshServiceList();
        }
      },
    },
    service: function (val) {
      this.$emit("onSelected", val);
    },
    // serviceList: {
    //   deep: true,
    //   immediate: false,
    //   handler(val) {
    //     const selectedList = val
    //       .filter((service) => service.selected)
    //       .map(({ label }) => label);

    //     this.$emit('onSelected', selectedList);
    //   },
    // },
  },

  computed: {
    ...mapGetters({
      isEdit: "EditEvent/isEdit",
      previouslySelected: "EditEvent/previouslySelected",
    }),
  },

  methods: {
    debounceInput(val) {
      getAvailableServices(val)
        .then((services) => (this.availableServices = services))
        .finally(() => (this.loading = false));
    },
    refreshServiceList() {
      this.serviceList = this.availableServices.sort().map((label) => ({
        label,
        selected: this.selectedServices.includes(label.toLowerCase()),
        previouslySelected: this.previouslySelected.includes(
          label.toLowerCase()
        ),
        exclusive: this.exclusiveServices.includes(label.toLowerCase()),
      }));
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.service-list {
  background: $SteelGray !important;
}
</style>
