<template>
  <PageLayout class="dashboard">
    <template #header>
      <div class="d-flex ai-center h-100">
        <h1 class="header-title">Indicadores</h1>
      </div>
    </template>
    <template #content>
      <div class="dashboard-main pb-28">
        <Operations />
        <CustomCard
          :title="eventLabel"
          :button="!!currentOperation && !homeOfficeOperation"
          :secondButton="!!currentOperation"
          :thirdButton="!!currentOperation"
          buttonTitle="QR CODE"
          secondButtonTitle="Relatório de atividades"
          thirdButtonTitle="Gestão de Escala"
          @buttonClick="OpenQrCode"
          @secondButtonClick="DownloadActivitiesReport"
          @thirdButtonClick="openEventServices"
          class="occurrences d-flex direction-column w-100">
          <div
            class="container h-100 w-100 overflow-hidden"
            v-if="currentOperation">
            <div class="freela-part">
              <div class="chart">
                <ChartComponent
                  :activitiesTotal="totalActivities"
                  :operationId="currentOperation" />
              </div>
              <div class="d-flex f-50 jc-center">
                <button
                  class="btn red h-40 btn-dashboard"
                  @click.prevent="onAddEmergencialVacancy">
                  Criar vaga urgente
                </button>
              </div>
              <Divider class="mv-5" />
              <FreelaActivities />
            </div>
            <div class="occurrences-part p-15">
              <EventOccurrences />
            </div>
          </div>
          <div v-else class="d-flex jc-center ai-center h-50 empty">
            selecione uma operação
          </div>
        </CustomCard>
      </div>
    </template>
  </PageLayout>
</template>

<script>
import { isNil } from "ramda";
import { mapActions, mapGetters } from "vuex";
import Operations from "@/components/operation/Operations";
import FreelaActivities from "@/components/operation/FreelaActivities";
import EventOccurrences from "@/components/operation/EventOccurrences";
import ChartComponent from "@/components/shared/chart/ChartComponent";

export default {
  components: {
    Operations,
    FreelaActivities,
    EventOccurrences,
    ChartComponent,
  },
  computed: {
    ...mapGetters({
      currentOperation: "Dashboard/currentOperation",
      homeOfficeOperation: "Dashboard/homeOfficeOperation",
      totalActivities: "Dashboard/chartDataSet",
      operationService: "Dashboard/operationService",
      qrCodeCheckinCheckout: "Dashboard/qrCodeCheckinCheckout",
    }),
    eventLabel() {
      const { eventName, serviceOrderNumber } = this.operationService;

      return isNil(serviceOrderNumber)
        ? eventName
        : `${eventName} # ${serviceOrderNumber}`;
    },
  },
  created() {
    const { operationId } = this.$route.params;
    this.init();
    this.setOperationCurrentOperation({ id: operationId });
  },
  methods: {
    ...mapActions({
      init: "Dashboard/init",
      setOperationCurrentOperation: "Dashboard/loadOperation",
      getActivitiesReport: "EventDetails/getActivitiesReport",
    }),
    onAddEmergencialVacancy() {
      this.$eventhub.$emit("modal:open:emergencialVacancy", {
        operationId: this.currentOperation,
      });
    },
    OpenQrCode() {
      const route = this.$router.resolve({
        name: "QRCode",
        params: { id: this.currentOperation },
      });
      window.open(route.href, "_blank");
    },
    DownloadActivitiesReport() {
      this.getActivitiesReport({
        eventId: this.operationService.eventId,
        operationId: this.currentOperation,
        operationDate: this.operationService.day?.split("T")[0],
      });
    },
    openEventServices() {
      this.$router.push({
        name: "events.info",
        hash: "services",
        params: { id: this.operationService.eventId },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

@media (max-width: 991px) {
  .empty {
    padding: 5em;
  }
  .header-title {
    margin-left: 0.5em;
  }
  .occurrences {
    margin-top: 30px;
  }
  .freela-part {
    padding: 15px;
  }
  .mv-5 {
    margin: 1.5em 0px;
  }
}

@media (min-width: 992px) {
  .dashboard {
    margin: 0px 28px;
  }
  .container {
    display: flex;
  }
  .freela-part {
    width: 44%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .occurrences-part {
    width: 56%;
  }
  .occurrences {
    border-radius: 10px;
  }
  .dashboard-main {
    display: grid;
    height: 100vh;
    grid-template: 1fr / 1fr 3fr;
    column-gap: 20px;
  }
}

.dashboard-main {
  .occurrences {
    overflow: hidden;
  }
}

.btn-dashboard {
  margin-top: 1em;
  width: 60%;
}
</style>
