import {
  getWorkgroups,
  getMembers,
  getTotalMembers,
} from "@/services/agency/agency.http";

const initialState = () => ({
  filters: {
    current: {
      listType: "freelas",
      filterBy: "active",
    },
    options: {
      listType: [
        { label: "Profissionais", id: "freelas" },
        { label: "Grupos", id: "groups" },
      ],
      filterBy: [
        { label: "Ativos", id: "active" },
        { label: "Bloqueados", id: "blocked" },
      ],
    },
  },
  pagination: {
    date: "",
    totalPages: 1,
    size: 10,
    page: 1,
  },
  filterMatch: "",
  currentState: "default",
  currentGroup: {},
  lists: {
    groups: {},
    members: {},
  },
  totalMembers: 0,
});

const state = initialState();

const currentEntity = (state) =>
  state.currentGroup.id ? "freelas" : state.filters.current.listType;

const defaultDate = new Date("2020-01-01").toISOString();

const getters = {
  options: (state) => state.filters.options,
  currentFilters: (state) => state.filters.current,
  filterBy: (state) => state.filters.current.filterBy,
  currentList: (state) => state.filters.current.listType,
  group: (state) => state.currentGroup,
  groupList: (state) => state.lists.groups,
  memberList: (state) => state.lists.members,
  totalMembers: (state) => state.totalMembers,
  currentState: (state) => state.currentState,
  filterMatch: (state) => state.filterMatch,
  isLastPage: (state) =>
    (state.pagination.totalPages || 1) === state.pagination.page,
  currentPage: (state) =>
    Math.ceil(getters.currentEntityList(state).length / state.pagination.size),
  currentEntityList: (state) =>
    Object.values(
      state.lists[currentEntity(state).replace("freelas", "members")]
    ),
  dataParameters: (state) => ({
    entity: currentEntity(state),
    date: state.pagination.date || defaultDate,
    groupId: state.currentGroup.id,
    actives: state.filters.current.filterBy === "active",
    size: state.pagination.size,
    page: state.pagination.page,
    term: state.filterMatch,
  }),
};

const mutations = {
  SET_FILTERS: (state, filters) =>
    (state.filters.current = Object.assign({}, state.filters.current, filters)),

  SET_CURRENT_GROUP: (state, group) =>
    (state.currentGroup = Object.assign({}, state.currentGroup, group)),

  SET_PAGINATION: (state, pagination) =>
    (state.pagination = Object.assign({}, state.pagination, pagination)),

  ADD_GROUP: (state, group) =>
    (state.lists.groups = Object.assign({}, state.lists.groups, {
      [group.id]: group,
    })),

  ADD_MEMBER: (state, member) =>
    (state.lists.members = Object.assign({}, state.lists.members, {
      [member.id]: member,
    })),

  SET_TOTAL_MEMBERS: (state, total) => (state.totalMembers = total),

  RESET_CURRENT_GROUP: (state) => (state.currentGroup = {}),
  RESET_FILTER_MATCH: (state) => (state.filterMatch = ""),
  RESET_GROUPS: (state) => (state.lists.groups = {}),
  RESET_MEMBERS: (state) => (state.lists.members = {}),
  RESET_PAGINATION: (state) => (state.pagination = initialState().pagination),
  RESET_TOTAL_MEMBERS: (state) => (state.totalMembers = 0),

  SET_FILTER_MATCH: (state, filterMatch) => (state.filterMatch = filterMatch),
  SET_STATE_LOADING: (state) => (state.currentState = "loading"),
  SET_STATE_ERROR: (state) => (state.currentState = "error"),
  SET_STATE_EMPTY: (state) => (state.currentState = "empty"),
  SET_STATE_DEFAULT: (state) => (state.currentState = "default"),
};

const initializer = {
  groups: {
    reset({ commit }) {
      return commit("RESET_GROUPS");
    },
    load: getWorkgroups,
    add({ commit }, { content }) {
      const transformGroup = ({
        id,
        name,
        membersCount,
        images,
        createAt,
        thumb,
      }) => ({
        id,
        name,
        membersCount,
        freelas: images || [],
        imageUrl: thumb,
        date: createAt,
      });

      for (let item of content) {
        commit("ADD_GROUP", transformGroup(item));
      }
    },
  },
  freelas: {
    reset({ commit }) {
      return commit("RESET_MEMBERS");
    },
    load: getMembers,
    add({ commit }, { workgroupName: name, content }) {
      if (name) {
        commit("SET_CURRENT_GROUP", { name });
      }

      const transformMember = ({
        memberId,
        freelaId,
        name,
        favorite,
        active,
        imageUrl,
        avgRating,
        totalRelatedGroup,
        createdAt,
      }) => ({
        id: memberId,
        freelaId,
        name,
        favorite,
        active,
        imageUrl,
        avgRating,
        totalRelatedGroup,
        createdAt,
      });

      for (let item of content) {
        commit("ADD_MEMBER", transformMember(item));
      }
    },
  },
};

const actions = {
  reset(context) {
    const { commit } = context;

    commit("SET_STATE_LOADING");
    commit("RESET_PAGINATION");

    const validateState = ({ getters: { currentEntityList } }) =>
      currentEntityList.length === 0
        ? commit("SET_STATE_EMPTY")
        : commit("SET_STATE_DEFAULT");

    return actions
      .fetch(context, { page: 1, resetState: true })
      .then(() => validateState(context))
      .catch(() => commit("SET_STATE_ERROR"));
  },

  fetchGetTotalMembers(context) {
    const { commit } = context;
    commit("RESET_TOTAL_MEMBERS");
    getTotalMembers()
      .then((data) => data)
      .then((result) => commit("SET_TOTAL_MEMBERS", result.value))
      .catch(() => commit("SET_STATE_ERROR"));
  },

  fetch(context, { page, resetState = false }) {
    const { commit, getters } = context;
    const { entity, ...args } = getters.dataParameters;
    const { reset, load, add: addEntity } = initializer[entity];

    const configurePagination = ({
      creationDateOfLastListedEvent: date,
      totalPages,
    }) => commit("SET_PAGINATION", { date, totalPages, page: page });

    const add = (result) => {
      addEntity(context, result);
      return result;
    };

    if (resetState) {
      reset(context);
    }
    return load({ ...args, page })
      .then(add)
      .then(configurePagination);
  },

  resetPagination({ commit }) {
    commit("RESET_PAGINATION");
  },

  nextPage({ commit }) {
    commit("RESET_PAGINATION");
  },

  setFilters({ commit }, filters) {
    commit("RESET_FILTER_MATCH");
    return commit("SET_FILTERS", filters);
  },

  setFilterMatch({ commit, dispatch }, filterMatch) {
    commit("RESET_MEMBERS");
    commit("SET_FILTER_MATCH", filterMatch);
    return dispatch("fetch", { page: 1, resetState: true });
  },

  setCurrentGroup({ commit }, group) {
    return commit("SET_CURRENT_GROUP", group);
  },

  resetCurrentGroup({ commit }) {
    return commit("RESET_CURRENT_GROUP");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
