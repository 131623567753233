<template>
  <CustomCard title="Filtro" class="filter-freela context-fix radius-10">
    <div class="p-10">
      <CustomSelect
        placeholder="Listar:"
        @input="
          $emit('filterChange', {
            ...currentFilters,
            listType: $event,
          })
        ">
        <option
          v-for="({ label, id }, key) in options.listType"
          :key="key"
          :value="id"
          :selected="currentFilters.listType === id">
          {{ label }}
        </option>
      </CustomSelect>

      <CustomSelect
        v-if="showFilterBy"
        placeholder="Filtrar por:"
        @input="
          $emit('filterChange', {
            ...currentFilters,
            filterBy: $event,
          })
        ">
        <option
          v-for="({ label, id }, key) in options.filterBy"
          :key="key"
          :value="id"
          :selected="currentFilters.filterBy === id">
          {{ label }}
        </option>
      </CustomSelect>
    </div>
  </CustomCard>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      options: "MyFreelas/options",
      currentFilters: "MyFreelas/currentFilters",
    }),
    showFilterBy() {
      return this.$route.name !== "groups";
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.filter-freela {
  min-width: 250px;

  .input-field .position {
    font-size: 1.2rem;
    top: 12px;
  }

  &.context-fix {
    .input-field label {
      font-size: 0.6rem !important;
      margin: 3px 0px 0px;
    }
  }
}
</style>
