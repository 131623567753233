<template>
  <CustomCard :title="title" theme="ebony-malibu" class="radius-10">
    <div class="d-flex direction-row ml-5" v-if="eventDates.startDate">
      <div class="left-side">
        <div class="d-flex jc-space-between p-5 ml-10 mt-10 mb-0 mr-0">
          <div class="card-info">
            <span class="days-label">{{ shortWeekDate }}</span>
            <span>{{ shortDayEvent }}</span>
            <span class="days-label">{{ shortMonthDate }}</span>
          </div>
        </div>
      </div>
      <div class="right-side mt-10 ml-20 d-flex ai-center f-100 wrapped">
        <span class="d-flex f-100 jc-start">
          <small>{{ startEventDate }}</small>
        </span>
        <span v-if="!hasTheSameDate" class="d-flex f-100 jc-start payment">
          <small>Até</small>
        </span>
        <span v-if="!hasTheSameDate" class="d-flex f-100 jc-start payment">
          <small>{{ endEventDate }}</small>
        </span>
      </div>
    </div>
  </CustomCard>
</template>

<script>
import { formatDate } from "@/helpers/dateFormat";

const format = (day) =>
  `${formatDate(day, "dddd")}, ${formatDate(day, "D")} ${formatDate(
    day,
    "MMMM"
  )} de ${formatDate(day, "YYYY")}`;

export default {
  props: {
    title: {
      type: String,
      default: "Data da Demanda",
    },
    eventDates: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    date() {
      return {
        start: new Date(this.eventDates?.startDate.replace("Z", "")),
        end: new Date(this.eventDates?.endDate.replace("Z", "")),
      };
    },
    startEventDate() {
      return format(this.date.start);
    },
    endEventDate() {
      return format(this.date.end);
    },
    shortDayEvent() {
      return formatDate(this.date.start, "D");
    },
    shortWeekDate() {
      return formatDate(this.date.start, "ddd");
    },
    shortMonthDate() {
      return formatDate(this.date.start, "MMM");
    },
    hasTheSameDate() {
      return this.startEventDate === this.endEventDate;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.left-side {
  flex: 0 1 15%;
}
.right-side {
  flex: 0 1 70%;

  .right-item {
    margin: 10px 0 0 20px;
    display: flex;
    img {
      margin: 0 auto;
      border-radius: 50%;
      height: 60px;
      width: 60px;
    }
  }
}
.card-info {
  width: 60px;
  color: $Ebony;
  height: 60px;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: $Malibu;

  .days-label {
    font-size: 10px;
    line-height: 1;
  }
}
</style>
