<template>
  <section class="payment-fields md-layout md-gutter">
    <div class="d-flex direction-column-sm w-100 ai-center-sm">
      <aside class="w-100 max-w-350-md">
        <h3 class="title">Valor a pagar</h3>
        <TotalFreelas />
        <br />
        <Divider />
        <br />
        <!-- <img src="" alt="Cartões aceitos: paypal visa e mastercard" /> -->
      </aside>
      <aside
        class="ml-12-sm ml-30 mr-12 w-100"
        :class="{ 'disabled-container': hasPromotionalCode }">
        <CreditCardForm />
      </aside>
    </div>

    <aside
      class="md-layout-item md-size-100"
      :class="{ 'disabled-container': hasPromotionalCode }">
      <Divider class="mv-30" />
      <AdditionalPaymentData />
    </aside>

    <aside
      class="md-layout-item"
      :class="{ 'disabled-container': hasPromotionalCode }">
      <Divider class="mv-30" />
      <BillingAddress />
    </aside>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import { isProduction } from "@/helpers/env";
import { loadLib } from "@/components/events/PagSeguro/";

import TotalFreelas from "@/components/events/StepperApp/Shared/TotalFreelas";

import CreditCardForm from "./CreditCardForm.vue";
import BillingAddress from "./BillingAddress.vue";
import AdditionalPaymentData from "./AdditionalPaymentData.vue";

export default {
  components: {
    CreditCardForm,
    BillingAddress,
    AdditionalPaymentData,
    TotalFreelas,
  },
  mounted() {
    loadLib(this.onLoad);
  },
  computed: {
    ...mapGetters({
      totalFreelasInServices: "NewEvent/totalFreelasInServices",
      hasPromotionalCode: "NewEvent/hasPromotionalCode",
    }),
    label() {
      const PRICE_PER_FREELA = 20;
      const s = this.totalFreelasInServices > 1 ? "is" : "l";
      const totalToPay = this.totalFreelasInServices * PRICE_PER_FREELA;

      return {
        total: `R$ ${totalToPay},00`,
        freelas: `${this.totalFreelasInServices} profissiona${s}`,
      };
    },
  },
  methods: {
    onLoad(pagseguro, hash) {
      this.generateSession(pagseguro, hash);
    },
    generateSession(pagseguro, hash) {
      if (isProduction) this.$store.dispatch("Payment/saveHash", hash);
    },
  },
};
</script>

<style lang="scss">
.payment-fields {
  h3 {
    font-weight: 400;
  }
}
</style>
