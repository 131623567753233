/* istanbul ignore file */
import Vue from "vue";
import Vuex from "vuex";

import createLogger from "vuex/dist/logger";

import modules from "./stores";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules,
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
});
