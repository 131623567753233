<template>
  <md-app md-waterfall md-mode="fixed" class="stepper-app">
    <md-app-drawer md-permanent="full" md-mode="fixed">
      <Stepper
        :currentStep="currentStep.number"
        :totalSteps="totalSteps"
        @stepClick="onStepClick" />
    </md-app-drawer>

    <md-app-content class="stepper-app-content">
      <header class="step-header d-flex md-alignment-center-left">
        <span class="step-circle">{{ currentStep.number }}</span>
        <h3 class="step-title">{{ currentStep.title }}</h3>
      </header>
      <Divider class="step-divider-header" />
      <section class="step-content">
        <Step1 v-if="currentStep.number == 1" />
        <Step2 v-else-if="currentStep.number == 2" />
        <Step3 v-else-if="currentStep.number == 3" />
        <Step4 v-else-if="currentStep.number == 4" />
        <br />
        <Divider />
        <br />
      </section>
      <footer class="d-flex jc-end">
        <button
          v-if="currentStep.number != 1"
          class="btn outline"
          id="btn-back"
          @click="onPreviousClick(currentStep.number - 1)">
          Voltar
        </button>

        <SpinnerButton
          :loading="loading"
          :disabled="disableButton"
          class="btn primary mr-0"
          id="btn-next"
          @click="onNextStepButtonClick">
          {{ nextLabel }}
        </SpinnerButton>
      </footer>
    </md-app-content>
  </md-app>
</template>

<script>
// TODO: verificar viabilidade de quebrar esse componente para isolar algumas lógicas
import { mapGetters, mapActions } from "vuex";
import { pathEq, not } from "ramda";

import { storage } from "@/mixins/storage";
import { getSession } from "@/services/payment/payment.http";
import { getAgencyData } from "@/services/agency/agency.http";

import { isAgency } from "@/helpers/auth";

import { getPagSeguro } from "@/components/events/PagSeguro/";

import Step1 from "./Step1/Index";
import Step2 from "./Step2/Index";
import Step3 from "./Step3/Index";
import Step4 from "./Step4/Index";

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },

  created() {
    this.$eventhub.$on("edit:event:redirect:preview", this.redirectToPreview);
    this.doAgencyVerifications();
  },

  mounted() {
    this.$store.dispatch("EditEvent/resetState");
    this.$store.dispatch("EditEvent/tryEditEvent", this.$route.params.id);
    this.$store.dispatch(
      "EditEvent/getEventDetailToEdit",
      this.$route.params.id
    );

    if (!this.isDuplicated) {
      this.$store.dispatch("NewEvent/resetState");
    }

    this.validateInactiveAgency();
  },
  updated() {
    this.$store.dispatch("EditEvent/tryEditEvent", this.$route.params.id);
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      newEventTotalSteps: "NewEvent/totalSteps",
      servicesAdded: "NewEvent/servicesAdded",
      exceededAmountFreela: "NewEvent/exceededAmountFreela",
      paymentData: "Payment/paymentData",
      formNewEvent: "NewEvent/form",
      isEdit: "EditEvent/isEdit",
      hasPromotionalCode: "NewEvent/hasPromotionalCode",
      isActiveAccount: "Profile/isActiveAccount",
      isDuplicated: "NewEvent/isDuplicated",
    }),
    totalSteps() {
      return this.isEdit || isAgency() ? 3 : this.newEventTotalSteps;
    },
    steps() {
      return [
        "Informações Básicas",
        "Detalhes da Demanda",
        "Serviços",
        "Forma de Pagamento",
      ];
    },
    currentStep() {
      const step = this.$route.params.step;

      return { number: step, title: this.steps[step - 1] };
    },
    isLastStep() {
      return this.currentStep.number == this.totalSteps;
    },
    nextLabel() {
      return this.isLastStep ? "Salvar Demanda" : "Avançar";
    },
    disableButton() {
      return this.isLastStep && this.freelaTotalIsBiggerThanFive;
    },
    freelaTotalIsBiggerThanFive() {
      return this.exceededAmountFreela;
    },
  },
  methods: {
    ...mapActions({
      loadAgencyData: "Profile/loadAgencyData",
    }),
    validateInactiveAgency() {
      if (isAgency()) {
        this.loadAgencyData().then(() => {
          if (not(this.isActiveAccount)) {
            this.$router.push({ name: "profile" });
            this.$eventhub.$emit("modal:open:inactiveAgencyAlert");
          }
        });
      }
    },
    onPreviousClick(step) {
      // TODO: encontrar uma solução melhor para o problema do scrollTop
      this.$scrollTop();

      const params = () => {
        const defaultParams = { step: step < 2 ? 1 : step };
        return this.isEdit
          ? { ...defaultParams, id: this.$route.params.id }
          : defaultParams;
      };

      this.$router.push({
        name: this.getRouteName(),
        params: params(),
      });
    },

    redirectToPreview() {
      this.$router.push({
        name: "events.info",
        params: { eventId: this.$route.params.id },
      });
    },

    onStepClick({ step }) {
      this.$scrollTop();
      this.$route.params.id
        ? this.$router.push({ name: "events.edit", params: { step } })
        : this.$router.push({ name: "events.new", params: { step } });
    },

    openModalInactiveAlert() {
      this.$eventhub.$emit("modal:open:inactiveAgencyAlert");
    },

    onNextStepButtonClick() {
      if (!this.isLastStep) this.$scrollTop();

      if (this.isLastStep && isAgency() && not(this.isActiveAccount)) {
        this.openModalInactiveAlert();
        return;
      }

      if (this.currentStep.number == 3 && this.isEdit) {
        this.editEvent();
      } else if (this.isLastStep) {
        this.hasPromotionalCode
          ? this.saveEventWithPromotionalCode()
          : this.saveEvent();
      } else {
        const params = () => {
          const defaultRouteParams = {
            step: parseInt(this.currentStep.number) + 1,
          };
          return this.isEdit
            ? { ...defaultRouteParams, id: this.$route.params.id }
            : defaultRouteParams;
        };

        this.$router.push({
          name: this.getRouteName(),
          params: params(),
        });
      }
    },

    getRouteName() {
      return this.isEdit ? "events.edit" : "events.new";
    },
    async saveEvent() {
      try {
        this.authValidation();
        this.fieldsValidation();

        if (isAgency()) {
          return this.agencyCreateNewEvent();
        }

        if (this.newEventTotalSteps == 3) {
          return this.createEventWithAgencies();
        }

        await this.cardValidation();
        this.createNewEvent();
      } catch ({ message }) {
        this.showFeedback(message, "warning");
      }
    },
    async saveEventWithPromotionalCode() {
      try {
        this.authValidation();
        this.fieldsValidation();
        this.createNewEventWithPromotionalCode();
      } catch ({ message }) {
        this.showFeedback(message, "warning");
      }
    },
    async editEvent() {
      try {
        this.authValidation();
        this.fieldsValidation();
        await this.trySaveEditedEvent();
      } catch ({ message }) {
        this.showFeedback(message, "warning");
      }
    },

    authValidation() {
      if (!localStorage.getItem("LANUP-TOKEN")) {
        const urlToGoBack = this.$route.fullPath;
        localStorage.setItem("urlToGoBack", urlToGoBack);
        this.$router.push({ name: "access.login" });
      }
    },

    fieldsValidation() {
      const invalidFields = this.eventValidation();
      if (invalidFields.length) throw { message: invalidFields };
    },

    async cardValidation() {
      // Verificar se encontrou brand
      const card = this.$store.getters["Payment/paymentCard"];
      if (!card.brand) {
        throw {
          message: "Cartão inválido, verifique o número do cartão.",
        };
      }

      // Salva token do cartão ou retorna erro
      const [token, error] = await this.getCardToken(card);

      if (error) {
        throw {
          message: "Cartão inválido, verifique os dados de cartão.",
        };
      }

      this.$store.dispatch("Payment/saveToken", token);
    },

    agencyCreateNewEvent() {
      // Salva evento
      const onSave = () => {
        this.$store.dispatch("NewEvent/resetState");
        storage.clean("eventDraft");
        this.showFeedback("Demanda criada!", "success");
        setTimeout(() => this.$router.push({ name: "events" }), 1000);
      };

      this.loading = true;
      this.$store
        .dispatch("NewEvent/agencySave")
        .then(onSave)
        .catch(this.onError)
        .finally(() => (this.loading = false));
    },

    createEventWithAgencies() {
      // Salva evento
      const onSave = () => {
        this.$store.dispatch("NewEvent/resetState");
        storage.clean("eventDraft");
        this.showFeedback("Demanda criada!", "success");
        setTimeout(() => this.$router.push({ name: "events" }), 1000);
      };

      this.loading = true;
      this.$store
        .dispatch("NewEvent/saveEventWithThanFiveFreelas")
        .then(onSave)
        .catch(this.onError)
        .finally(() => (this.loading = false));
    },

    createNewEvent() {
      // Salva evento
      this.loading = true;
      const onSave = () => {
        this.$store.dispatch("NewEvent/resetState");
        storage.clean("eventDraft");
        this.showFeedback("Demanda criada!", "success");
        setTimeout(() => this.$router.push({ name: "events" }), 1000);
      };

      this.$store.dispatch("Payment/hidratePayload", this.formNewEvent);

      this.$store
        .dispatch("NewEvent/save", this.paymentData)
        .then(onSave)
        .catch(this.onError)
        .finally(() => (this.loading = false));
    },

    createNewEventWithPromotionalCode() {
      // Salva evento
      this.loading = true;
      const onSave = () => {
        this.$store.dispatch("NewEvent/resetState");
        storage.clean("eventDraft");
        this.showFeedback("Demanda criada!", "success");
        setTimeout(() => this.$router.push({ name: "events" }), 1000);
      };

      this.$store
        .dispatch("NewEvent/saveWithPromotionalCode", this.paymentData)
        .then(onSave)
        .catch(this.onError)
        .finally(() => (this.loading = false));
    },

    trySaveEditedEvent() {
      this.loading = true;
      const onSave = () => {
        this.$store.dispatch("EditEvent/resetState");
        storage.clean("eventDraft");
        this.showFeedback("Demanda editada com sucesso!", "success");
        setTimeout(() => this.$router.push({ name: "events" }), 1000);
      };
      this.$store
        .dispatch("EditEvent/save")
        .then(onSave)
        .catch(this.onError)
        .finally(() => (this.loading = false));
    },

    onError(error) {
      this.showFeedback(
        error.message.length
          ? error.message
          : "Não foi possivel salvar a demanda, tente novamente!",
        "error"
      );
    },

    showFeedback(message, type = "info") {
      const msgs = Array.isArray(message) ? message : [message];
      this.$eventhub.$emit("feedback:show", {
        type,
        msg: msgs.join("<br>"),
      });
    },

    eventValidation() {
      this.isEdit
        ? this.$store.dispatch("EditEvent/saveDraft")
        : this.$store.dispatch("NewEvent/saveDraft");

      const storeData = this.isEdit
        ? this.$store.getters["EditEvent/draft"]
        : this.$store.getters["NewEvent/draft"];

      const isHomeOffice = this.$store.getters["NewEvent/isHomeOffice"];

      const formData = isHomeOffice
        ? [
            [
              ["dates", "length"],
              0,
              "Necessário informar uma data para a demanda. ",
            ],
            [
              ["event", "title"],
              "",
              "Necessário informar um titulo na demanda. ",
            ],
            [
              ["contact", "fullName"],
              "",
              "Necessário informar nome de contato. ",
            ],
            [
              ["contact", "email"],
              "",
              "Necessário informar um email de contato. ",
            ],
            [
              ["contact", "tel"],
              "",
              "Necessário informar um telefone do contato. ",
            ],
            [
              ["services", "length"],
              0,
              "Necessário informar pelo menos 1 função. ",
            ],
          ]
        : [
            [
              ["dates", "length"],
              0,
              "Necessário informar uma data para a demanda. ",
            ],
            [
              ["event", "title"],
              "",
              "Necessário informar um titulo na demanda. ",
            ],
            [
              ["location", "name"],
              "",
              "Necessário informar o local da demanda. ",
            ],
            [["location", "address"], "", "Necessário informar o endereço. "],
            [
              ["contact", "fullName"],
              "",
              "Necessário informar nome de contato. ",
            ],
            [
              ["contact", "email"],
              "",
              "Necessário informar um email de contato. ",
            ],
            [
              ["contact", "tel"],
              "",
              "Necessário informar um telefone do contato. ",
            ],
            [
              ["services", "length"],
              0,
              "Necessário informar pelo menos 1 função. ",
            ],
          ];

      const validation =
        (storeData) =>
        ([path, data, message]) =>
          pathEq(path, data)(storeData) && message;

      const validationFromStoreData = validation(storeData);

      return formData.map(validationFromStoreData).filter((message) => message);
    },

    getCardToken(card) {
      return new Promise((resolve) => {
        const pagseguro = getPagSeguro();

        getSession()
          .then((sessionId) => pagseguro.setSessionId(sessionId))
          .then(() => {
            pagseguro.createCardToken({
              ...card,
              success: ({ card: { token } }) => resolve([token]),
              error: ({ errors }) => resolve(["", errors]),
            });
          });
      });
    },

    doAgencyVerifications() {
      if (!isAgency()) return;

      getAgencyData().then((agencyData) =>
        this.isEdit
          ? this.$store.dispatch("EditEvent/setAgencyData", agencyData)
          : this.$store.dispatch("NewEvent/setAgencyData", agencyData)
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/breakpoints.scss";

.md-app-drawer {
  margin-right: 20px;
}

.step-divider-header {
  margin-left: 0 !important;
}

.md-snackbar.md-theme-default {
  max-height: inherit;
  background: $Ebony;
  color: white;
}

.stepper-app {
  padding-bottom: 20px;

  .stepper-app-content {
    border-radius: 10px;
    background-color: $SteelGray !important;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    > * {
      margin-left: 16px;
      margin-right: 16px;
    }

    .step-header {
      padding: 16px 0;

      .step-circle {
        align-items: center;
        background: $Malibu;
        border-radius: 20px;
        color: $SteelGray;
        display: inline-flex;
        font-size: 16px;
        font-weight: bold;
        height: 40px;
        justify-content: center;
        margin-right: 10px;
        width: 40px;

        @media (max-width: $mobile) {
          margin-left: 0;
        }
      }

      .step-title {
        align-self: center;
        font-size: 18px;
        font-weight: 400;
        line-height: initial;
        margin: 0;
      }
    }
    .step-content {
      padding: 10px 50px 0;
      .md-snackbar-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .md-snackbar {
        background-color: $Ebony !important;
        border-radius: 10px 10px 0px 0px;
        max-height: inherit;
        small {
          color: white;
        }
      }

      @media (max-width: $mobile) {
        padding: 0;
      }
    }

    footer {
      padding: 0 50px;

      @media (max-width: $mobile) {
        padding: 0;
      }
    }
  }

  .md-app {
    max-height: 100%;

    &:not(.md-overlap).md-theme-default {
      background-color: $SteelGray;
    }
  }

  .md-toolbar {
    align-content: baseline;

    h1 {
      margin: 0;
    }
  }

  .md-drawer {
    display: flex;
    height: 100%;
    justify-content: center;
    max-width: calc(100vw - 80px);
    overflow: hidden;
    padding-top: 33px;
    width: 80px;

    &.md-theme-default {
      background-color: $Ebony;
    }
  }

  .border-solid {
    border: 2px solid white;
  }
}
</style>
