<template>
  <div class="page-layout">
    <header class="header">
      <slot name="header"></slot>
    </header>
    <md-content class="content md-scrollbar h-100">
      <slot name="content"></slot>
    </md-content>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.page-layout {
  display: grid;
  grid-template-rows: 80px 1fr;
  grid-template-columns: auto;
  grid-template-areas:
    "header"
    "content";
  height: 100%;
  position: relative;

  &.no-header {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: "content";
    padding: 28px 0;
  }

  > .header {
    grid-area: header;
    position: relative;
  }

  > .content {
    grid-area: content;
    position: relative;
    overflow: auto;
  }
}
</style>
