var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CustomCard',{staticClass:"filter-freela context-fix radius-10",attrs:{"title":"Filtro"}},[_c('div',{staticClass:"p-10"},[_c('CustomSelect',{attrs:{"placeholder":"Listar:"},on:{"input":function($event){return _vm.$emit('filterChange', {
          ..._vm.currentFilters,
          listType: $event,
        })}}},_vm._l((_vm.options.listType),function({ label, id },key){return _c('option',{key:key,domProps:{"value":id,"selected":_vm.currentFilters.listType === id}},[_vm._v(" "+_vm._s(label)+" ")])}),0),(_vm.showFilterBy)?_c('CustomSelect',{attrs:{"placeholder":"Filtrar por:"},on:{"input":function($event){return _vm.$emit('filterChange', {
          ..._vm.currentFilters,
          filterBy: $event,
        })}}},_vm._l((_vm.options.filterBy),function({ label, id },key){return _c('option',{key:key,domProps:{"value":id,"selected":_vm.currentFilters.filterBy === id}},[_vm._v(" "+_vm._s(label)+" ")])}),0):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }