import { getUser } from "@/helpers/auth/";
import { storage } from "@/mixins/storage";
import { vm } from "@/main";
import eventhub from "@/eventhub";
import { isProduction } from "@/helpers/env";

import {
  paymentBatch,
  validateCoupon,
  getListBatch,
  getAllBatches,
  cancelBatch,
  getListInvoice,
  cancelInvoice,
  markAsPaidInvoice,
} from "@/services/payment/payment.http";

import { getJobDoneMembers } from "@/services/events/event.http";

import creditCardType from "credit-card-type";

const feedback = ({ message }) =>
  eventhub.$emit("feedback:show", { type: "error", msg: message });

const updateChildren = (node, checked) => {
  if (!node.children || (node.name != "" && node.hasBankData == false)) return;
  node.children.forEach((child) => {
    child.checked = checked;
    updateChildren(child, checked);
  });
};

const updateTree = (root) => {
  (function update(node) {
    if (!node.children) return;
    node.children.forEach(update);
    node.checked = node.children.every((child) => child.checked);
  })(root);
};

const initialState = () => ({
  isListBatch: true,
  paymentCard: {
    cardNumber: "",
    cardExpirationDate: "",
    cardSecurityNumber: "",
    cardOwnerName: "",
    cpf: "",
  },
  additionalPaymentData: {
    phone: "",
    birthDate: "",
  },
  paymentSaveData: {
    shippingAddressRequired: false,
    sender: {
      name: "",
      email: "",
      phone: {
        areaCode: "",
        number: "",
      },
      documents: [
        {
          type: "",
          value: "",
        },
      ],
      hash: "",
    },
    items: [],
    shipping: {
      address: {
        street: "",
        number: "",
        district: "",
        complement: "",
        city: "",
        state: "SP",
        country: "BRA",
        postalCode: "",
      },
    },
    creditCard: {
      token: "",
      installment: {
        quantity: 1,
        value: "",
      },
      holder: {
        name: "",
        documents: [],
        phone: {
          areaCode: "",
          number: "",
        },
        birthDate: "",
      },
      billingAddress: {
        street: "",
        number: "",
        district: "",
        complement: "",
        city: "",
        state: "SP",
        country: "BRA",
        postalCode: "",
      },
    },
  },
  batch: {
    listBatch: [],
    totalPages: 0,
  },
  listInvoices: {
    list: [],
    totalPages: 0,
  },
  membersWithJobDone: {
    empty: true,
    checked: false,
    children: [],
  },
  filterPayroll: {
    eventId: null,
    services: [],
    shift: null,
    startDate: null,
    endDate: null,
    paymentStatus: null,
    term: null,
    hirer: { cnpj: null, name: null },
    eventName: null,
  },
  payrollColumns: {
    turn: {
      checked: true,
      label: "Turnos",
    },
    service: {
      checked: true,
      label: "Serviço",
    },
    checkinAndCheckout: {
      checked: true,
      label: "Entrada e Saída",
    },
    chargeByPresence: {
      checked: true,
      label: "Valor Presença",
    },
    event: {
      checked: false,
      label: "Demanda",
    },
    eventValue: {
      checked: true,
      label: "Valor Demanda",
    },
    chargeByPayment: {
      checked: true,
      label: "Pagamento via Lanup",
    },
    date: {
      checked: true,
      label: "Data",
    },
    additionalValue: {
      checked: true,
      label: "Valor adicional",
    },
  },
  filterInvoice: {
    status: ["AwaitPayment", "Expired"],
    cnpj: "",
    creationDate: null,
    expirationDate: null,
    name: "",
  },
  couponsPayment: {
    nameCoupon: "",
    percent: 0,
  },
  couponsPresence: {
    nameCoupon: "",
    percent: 0,
  },
  coupon: "",
  freelasToPay: [],
  responseIsValid: "",
  loading: false,
});

const coupons = {
  couponPresence: {
    A1901LP010: 10,
    B2801LP200: 20,
    C3101LP003: 30,
    D4010LP040: 40,
    E5510LP050: 50,
    F6701LP006: 60,
    G7601LP700: 70,
    H8101LP800: 80,
    I9100LP009: 90,
    J0101LP101: 100,
    "": 0,
  },
  couponPayment: {
    A1901PP010: 10,
    B2801PP200: 20,
    C3101PP003: 30,
    D4010PP040: 40,
    E5510PP050: 50,
    F6701PP006: 60,
    G7601PP700: 70,
    H8101PP800: 80,
    I9100PP009: 90,
    J1010PP101: 100,
    "": 0,
  },
};

const draft = storage.get("eventPayDraft") || {};

const state = { ...initialState(), ...draft };

const getters = {
  isListBatch: (state) => state.isListBatch,
  paymentData: (state) => state.paymentSaveData,
  responseIsValid: (state) => state.responseIsValid,
  payrollColumns: (state) => state.payrollColumns,
  coupon: (state) => state.coupon,
  presenceCouponDiscount: (state) => state.couponsPresence,
  paymentCouponDiscount: (state) => state.couponsPayment,
  freelasToPay: (state) => state.freelasToPay,
  filterPayroll: (state) => state.filterPayroll,
  membersWithJobDone: (state) => state.membersWithJobDone,
  child: (state) => (vacancyId) => {
    let child = {};
    state.membersWithJobDone.children.find((x) => {
      child = x.children.find((y) => y.vacancyId === vacancyId);
      return child !== undefined;
    });
    return child;
  },
  paymentForm: (state) => ({
    name: state.paymentSaveData.creditCard.holder.name,
    cpf: state.paymentSaveData.creditCard.holder.documents[0].value,
    phone: state.paymentSaveData.creditCard.holder.phone,
    birthDate: state.paymentSaveData.creditCard.holder.birthDate,
  }),
  paymentCard: (state) => ({
    cardNumber: state.paymentCard.cardNumber.replace(/ /g, ""), // Número do cartão de crédito
    brand: creditCardType(state.paymentCard.cardNumber.replace(/ /g, ""))[0]
      .type, // Bandeira do cartão
    cvv: state.paymentCard.cardSecurityNumber, // CVV do cartão
    expirationMonth: state.paymentCard.cardExpirationDate.split("/")[0], // Mês da expiração do cartão
    expirationYear: state.paymentCard.cardExpirationDate.split("/")[1], // Ano da expiração do cartão, é necessário os 4 dígitos.
  }),
  billingAddress: (state) => state.paymentSaveData.creditCard.billingAddress,
  additionalPaymentData: (state) => state.additionalPaymentData,
  batch: (state) => state.batch,
  listInvoices: (state) => state.listInvoices,
  filterInvoice: (state) => state.filterInvoice,
  loading: (state) => state.loading,
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  SET_LOADING: (state, loading) => (state.loading = loading),
  SET_CARD_DATA(state, card) {
    Object.assign(state.paymentCard, card);
  },
  SET_HASH(state, hash) {
    state.paymentSaveData.sender.hash = hash;
  },
  SET_CARD_TOKEN(state, cardToken) {
    state.paymentSaveData.creditCard.token = cardToken;
  },
  SET_ITEMS(state, items) {
    state.paymentSaveData.items = items;
  },
  SET_PAYROLL_FILTERS: (state, filters) => {
    state.filterPayroll = Object.assign({}, state.filterPayroll, filters);
  },
  SET_PAYROLL_COLUMNS: (state, { node, checked }) => (node.checked = checked),
  CLEAR_FILTER_PAYROLL: (state) =>
    (state.filterPayroll = {
      eventId: null,
      services: [],
      shift: null,
      startDate: null,
      endDate: null,
      paymentStatus: null,
      term: null,
      hirer: { cnpj: null, name: null },
      eventName: null,
    }),
  SET_COUPON: (state, payload) => {
    state.coupon = payload;
  },

  CLEAR_APPLIED_COUPON: (state) => {
    state.coupon = "";
  },

  CLEAR_APPLIED_COUPON_PRESENCE: (state) => {
    state.couponsPresence = { nameCoupon: "", percent: 0 };
  },

  CLEAR_APPLIED_COUPON_PAYMENT: (state) => {
    state.couponsPayment = { nameCoupon: "", percent: 0 };
  },

  SET_APPLIED_COUPON: (state, payload) => {
    const couponPresence = coupons.couponPresence[payload];
    const couponPayment = coupons.couponPayment[payload];
    if (couponPresence) {
      state.couponsPresence = { nameCoupon: payload, percent: couponPresence };
    }
    if (couponPayment) {
      state.couponsPayment = { nameCoupon: payload, percent: couponPayment };
    }
  },

  SET_COUPON_VALIDATION: (state, payload) => {
    state.responseIsValid = payload;
  },

  SET_FREELAS_TO_PAY: (state, payload) => {
    state.freelasToPay = payload;
  },

  /* eslint-disable no-unused-vars */
  SET_ADDITIONAL_VALUE: (state, { child, additionalValue }) => {
    child.checked = false;
    child.checked = true;
    child.paymentService.additionalValue = additionalValue;
  },

  SET_IS_LIST_BATCH: (state, value) => (state.isListBatch = value),

  /* eslint-disable no-unused-vars */
  SET_CHARGE_HIRER: (state, { child, chargeHirer }) => {
    child.paymentService.chargeHirer = chargeHirer;
  },
  SET_MEMBERS_WITH_JOB_DONE: (state, membersWithJobDone) =>
    (state.membersWithJobDone = membersWithJobDone),
  RESET_MEMBERS_WITH_JOB_DONE: (state) =>
    (state.membersWithJobDone = initialState().membersWithJobDone),
  UPDATE_MEMBERS_WITH_JOB_DONE: (state, { node, checked }) => {
    node.checked = checked;
    updateChildren(node, checked);
    updateTree(state.membersWithJobDone);
  },
  SET_INSTALLMENT(state, total) {
    state.paymentSaveData.creditCard.installment.value = total;
  },
  SET_BILLING(state, billingAddress) {
    Object.assign(
      state.paymentSaveData.creditCard.billingAddress,
      billingAddress
    );
    Object.assign(state.paymentSaveData.shipping.address, billingAddress);
  },
  SET_ADDITIONAL_DATA(state, additionalPaymentData) {
    Object.assign(state.additionalPaymentData, additionalPaymentData);
  },
  SET_HOLDER(state, holder) {
    state.paymentSaveData.creditCard.holder.name = holder.name;
    state.paymentSaveData.creditCard.holder.documents = [
      { type: "CPF", value: holder.cpf },
    ];
    state.paymentSaveData.creditCard.holder.phone = holder.phone;
    state.paymentSaveData.creditCard.holder.birthDate = holder.birthDate;
  },
  SET_SENDER(state, sender) {
    state.paymentSaveData.sender.name = sender.name;
    state.paymentSaveData.sender.email = sender.email;
    state.paymentSaveData.sender.documents = [
      { type: "CPF", value: sender.cpf },
    ];
    state.paymentSaveData.sender.phone = sender.phone;
  },
  SET_LIST_BATCH(state, batch) {
    state.batch = batch;
  },
  SET_CANCEL_BATCH(state, id) {
    state.batch = {
      totalPages: state.batch.totalPages,
      listBatch: state.batch.listBatch.map((obj) => {
        if (obj.id === id) {
          obj.status = 1;
        }
        return obj;
      }),
    };
  },
  SET_CANCEL_OR_MARK_AS_PAID_INVOICE(state, { id, status }) {
    state.listInvoices = {
      totalPages: state.listInvoices.totalPages,
      list: state.listInvoices.list.map((obj) => {
        if (obj.id === id) {
          obj.status = status;
        }
        return obj;
      }),
    };
  },
  SET_LIST_INVOICES(state, invoice) {
    state.listInvoices = invoice;
  },

  SET_FILTERS: (state, filters) => {
    state.filterInvoice = Object.assign({}, state.filterInvoice, filters);
  },

  RESET_LIST_BATCH: (state) =>
    (state.batch = {
      listBatch: [],
      totalPages: 0,
    }),
  RESET_LIST_INVOICES: (state) =>
    (state.listInvoices = {
      list: [],
      totalPages: 0,
    }),
  CLEAR_FILTER_INVOICE: (state) =>
    (state.filterInvoice = {
      status: [],
      cnpj: "",
      creationDate: null,
      expirationDate: null,
      name: "",
    }),
};

const actions = {
  resetState: ({ commit }) =>
    commit("RESET_STATE") && localStorage.removeItem("eventPayDraft"),
  updateCardData: ({ commit }, cardData) => commit("SET_CARD_DATA", cardData),
  saveHash: ({ commit }, hash) => commit("SET_HASH", hash),
  saveToken: ({ commit }, token) => commit("SET_CARD_TOKEN", token),
  setBilling: ({ commit }, billingAddress) =>
    commit("SET_BILLING", billingAddress),
  setAdditionalPaymentData: ({ commit }, data) =>
    commit("SET_ADDITIONAL_DATA", data),
  setIsListBatch: ({ commit }, data) => commit("SET_IS_LIST_BATCH", data),
  updateAdditionalValue(
    { commit, getters },
    { additionalValue, chargeHirer, vacancyId }
  ) {
    let child = {};
    child = getters.child(vacancyId);

    commit("SET_ADDITIONAL_VALUE", { child, additionalValue });
    commit("SET_CHARGE_HIRER", { child, chargeHirer });
  },

  saveDraft: ({ state }) => {
    const data = { ...state, ...{ paymentCard: initialState().paymentCard } };
    storage.set("eventPayDraft", data);
  },

  setItems: ({ commit }, formServices) => {
    const PRICE_PER_FREELA = 20;
    const services = Array.from(formServices).map(([, item]) => item);

    const totalWorkshifts = (workshifts) =>
      workshifts.reduce((total, { quantity }) => total + quantity, 0);

    const newItem = ({ job, workshifts }, idx) => ({
      id: idx + 1,
      description: `Serviço contratado: ${job}`,
      amount: PRICE_PER_FREELA.toFixed(2),
      quantity: totalWorkshifts(workshifts),
    });

    const items = services.map(newItem);
    commit("SET_ITEMS", items);

    commit(
      "SET_INSTALLMENT",
      items
        .reduce(
          (total, { amount, quantity }) =>
            total + parseFloat(amount) * quantity,
          0
        )
        .toFixed(2)
    );
  },

  hidratePayload: ({ state, commit }, event) => {
    const { email } = getUser();

    const [areaCode, number] = state.additionalPaymentData.phone
      .replace(/[(-]/g, "")
      .split(")");

    const sender = {
      name: state.paymentCard.cardOwnerName,
      email: isProduction ? email : "leandro@sandbox.pagseguro.com.br",
      cpf: state.paymentCard.cpf.replace(/[.-]/g, ""),
      phone: { areaCode, number },
    };

    commit("SET_SENDER", sender);

    const holder = {
      name: state.paymentCard.cardOwnerName,
      cpf: state.paymentCard.cpf.replace(/[.-]/g, ""),
      phone: { areaCode, number },
      birthDate: state.additionalPaymentData.birthDate,
    };

    commit("SET_HOLDER", holder);
    actions.setItems({ commit }, event.services);
  },

  getAllBatches({ state, commit }, { page, loader }) {
    commit("SET_LOADING", loader);
    getAllBatches({ page: page })
      .then((data) => data)
      .then(({ content, totalPages }) => {
        commit("SET_LIST_BATCH", {
          totalPages: totalPages,
          listBatch: [...state.batch.listBatch, ...content],
        });
      })
      .finally(() => commit("SET_LOADING", false));
  },

  getListBatch({ state, commit }, { id, page, loader }) {
    commit("SET_LOADING", loader);
    getListBatch({ id, page: page })
      .then((data) => data)
      .then(({ content, totalPages }) => {
        commit("SET_LIST_BATCH", {
          totalPages: totalPages,
          listBatch: [...state.batch.listBatch, ...content],
        });
      })
      .finally(() => commit("SET_LOADING", false));
  },

  cancelBatch({ commit }, { id, batchNumber }) {
    cancelBatch(id)
      .then(() => {
        commit("SET_CANCEL_BATCH", id);
        eventhub.$emit("feedback:show", {
          type: "success",
          msg: `O lote número ${batchNumber} foi cancelado com sucesso`,
        });
      })
      .catch(({ message }) => {
        eventhub.$emit("feedback:show", {
          type: "error",
          msg: `${message}`,
        });
      });
  },

  getVacanciesDone({ commit }) {
    commit("SET_LOADING", true);
    getJobDoneMembers({
      eventId: state.filterPayroll.eventId,
      services: state.filterPayroll.services,
      shift: state.filterPayroll.shift,
      startDate: state.filterPayroll.startDate,
      endDate: state.filterPayroll.endDate,
      paymentStatus: 1,
      term: state.filterPayroll.term,
      eventName: state.filterPayroll.eventName,
      hirer: state.filterPayroll.hirer?.cnpj,
    })
      .then((data) => data)
      .then((result) => {
        const root = {
          checked: false,
          empty: !result.length,
          children: result.map((group) => ({
            name: group.name,
            image: group.image,
            eventId: group.eventId,
            hasBankData: group.hasBankData,
            freelaId: group.freelaId,
            checked: false,
            hasStatus: true,
            children: group.shifts.map((item) => ({
              checked: false,
              ...item,
              paymentService: {
                vacancyId: "",
                additionalValue: 0,
                chargeHirer: false,
              },
            })),
          })),
        };
        commit("SET_MEMBERS_WITH_JOB_DONE", root);
      })
      .finally(() => commit("SET_LOADING", false));
  },

  udpateVacanciesDone({ commit }, { node, checked }) {
    return commit("UPDATE_MEMBERS_WITH_JOB_DONE", {
      node,
      checked,
    });
  },

  setFiltersPayroll({ commit }, filters) {
    return commit("SET_PAYROLL_FILTERS", filters);
  },

  setPayrollColumns({ commit }, { node, checked }) {
    return commit("SET_PAYROLL_COLUMNS", { node, checked });
  },

  clearFilterPayroll({ commit }) {
    commit("CLEAR_FILTER_PAYROLL");
  },

  /* eslint-disable no-unused-vars */
  coupon: ({ commit }, payload) => {
    commit("SET_COUPON", payload);
  },

  validateCoupon: async ({ commit, state }) => {
    await validateCoupon(state.coupon).then((result) => {
      commit("SET_COUPON_VALIDATION", result.value);
      commit("SET_APPLIED_COUPON", state.coupon);
    });
  },

  removeCoupon: async ({ commit, state }) => {
    commit("CLEAR_APPLIED_COUPON", state);
  },

  removeCouponPayment: async ({ commit, state }) => {
    commit("CLEAR_APPLIED_COUPON_PAYMENT", state);
  },

  removeCouponPresence: async ({ commit, state }) => {
    commit("CLEAR_APPLIED_COUPON_PRESENCE", state);
  },

  getListInvoices({ state, commit }, { id, page, loader }) {
    commit("SET_LOADING", loader);
    getListInvoice({
      id,
      page: page,
      document: state.filterInvoice.cnpj,
      status: state.filterInvoice.status,
      creationDateStart: state.filterInvoice.creationDate?.start,
      creationDateEnd: state.filterInvoice.creationDate?.end,
      expireDateStart: state.filterInvoice.expirationDate?.start,
      expireDateEnd: state.filterInvoice.expirationDate?.end,
    })
      .then((data) => data)
      .then(({ content, totalPages }) => {
        commit("SET_LIST_INVOICES", {
          totalPages: totalPages,
          list: [...state.listInvoices.list, ...content],
        });
      })
      .finally(() => commit("SET_LOADING", false));
  },
  paymentBatch: async ({ commit, dispatch, state, rootGetters }) => {
    commit("SET_LOADING", true);
    paymentBatch({
      isHirerAgency: rootGetters["User/isAgency"],
      hirerId: rootGetters["EventDetails/basicInfo"].hirerId,
      paymentBatch: state.freelasToPay,
      coupon: {
        presence: state.couponsPresence.nameCoupon,
        payment: state.couponsPayment.nameCoupon,
      },
    })
      .then((data) => data)
      .then((batchId) => {
        commit("RESET_MEMBERS_WITH_JOB_DONE");
        dispatch("clearFilterPayroll");
        vm.$router.push({
          name: "events.charge",
          params: {
            id: rootGetters["EventDetails/basicInfo"].eventId,
            batchId,
            isListBatch: true,
          },
        });
      })
      .catch((error) => {
        feedback(error);
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },

  freelasToPay: (context, payload) => {
    context.commit("SET_FREELAS_TO_PAY", payload);
  },

  setFiltersInvoice({ commit }, filters) {
    return commit("SET_FILTERS", filters);
  },

  async cancelOrMarkAsPaidInvoice({ commit }, { id, status }) {
    try {
      status === 1 ? await cancelInvoice(id) : await markAsPaidInvoice(id);
      commit("SET_CANCEL_OR_MARK_AS_PAID_INVOICE", { id, status });
      eventhub.$emit("feedback:show", {
        type: "success",
        msg:
          status === 1
            ? `A fatura foi cancelada com sucesso`
            : `O pagamento foi confirmado com sucesso`,
      });
    } catch ({ message }) {
      eventhub.$emit("feedback:show", {
        type: "error",
        msg: `${message}`,
      });
    }
    // cancelInvoice(id)
    //   .then(() => {
    //     commit('SET_CANCEL_OR_MARK_AS_PAID_INVOICE', { id, status });
    //     this._vm.$eventhub.$emit('feedback:show', {
    //       type: 'success',
    //       msg: `A fatura foi cancelada com sucesso`,
    //     });
    //   })
    //   .catch(({ message }) => {
    //     this._vm.$eventhub.$emit('feedback:show', {
    //       type: 'error',
    //       msg: `${message}`,
    //     });
    //   });
  },

  reset({ commit }) {
    commit("RESET_LIST_BATCH");
  },

  clearFilterInvoice({ commit }) {
    commit("CLEAR_FILTER_INVOICE");
  },

  resetListInvoices({ commit }) {
    commit("RESET_LIST_INVOICES");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
