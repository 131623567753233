<template>
  <div
    v-if="event"
    class="event-card d-flex direction-column ml-8 mr-8 mb-8"
    @click="onEventCardClick"
    @keydown.enter="onEventCardClick">
    <ContextMenu
      class="context-menu p-absolute align-menu"
      :options="contextMenuOptions"
      @itemClick="onContextMenuItemClick">
      <button class="context-menu-button btn outline icon" md-menu-trigger>
        <i class="mdi mdi-dots-horizontal text-malibu font-size-1-3"></i>
      </button>
    </ContextMenu>

    <CoverImage
      :src="event.urlImage"
      :alt="event.name"
      class="event-card-image cursor-pointer">
      <div class="text-white w-100 p-10">
        <h6>{{ eventLabel }}</h6>
        <small>Última edição: {{ date }}</small>
      </div>
    </CoverImage>
    <div class="pl-10 pr-10 pt-8 pb-8 d-flex ai-center">
      <CoverDate :date="event.startEventDay" />
      <address v-if="!event.isHomeOffice" class="ml-8">{{ address }}</address>

      <span v-else class="ml-20 mt-5 text-center text-malibu">
        Sem endereço
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import CoverDate from "./CoverDate";

import { formatDate } from "@/helpers/dateFormat";

import { isNil } from "ramda";

export default {
  components: {
    CoverDate,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    date() {
      const data = new Date(this.event.updatedAt || this.event.createdAt);

      return `${formatDate(data, "D")} ${formatDate(data, "MMM")} ${formatDate(
        data,
        "YYYY"
      )}`;
    },

    address() {
      return this.event.address;
    },

    contextMenuOptions() {
      return [
        {
          label: `Editar`,
          icon: "mdi-pencil",
          action: "edit",
          disabled: false,
        },
        {
          label: `Duplicar`,
          icon: "mdi-content-copy",
          action: "duplicate",
          disabled: false,
        },
      ];
    },

    eventLabel() {
      const { name, serviceOrderNumber } = this.event;

      return isNil(serviceOrderNumber)
        ? name
        : `${name} # ${serviceOrderNumber}`;
    },
  },
  methods: {
    ...mapActions({
      getEventDetail: "EditEvent/getEventDetail",
    }),

    onEventCardClick() {
      this.$router.push({ name: "events.info", params: { id: this.event.id } });
    },

    onContextMenuItemClick({ action }) {
      const actions = {
        edit: this.edit,
        duplicate: this.duplicate,
      };

      actions[action]();
    },

    edit() {
      this.$router.push({
        name: "events.edit",
        params: { id: this.event.id, step: 1 },
      });
    },

    duplicate() {
      try {
        this.getEventDetail(this.event.id);
      } catch (err) {
        // console.error(err);
      } finally {
        this.$eventhub.$emit("modal:open:replication", { event: this.event });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.event-card {
  position: relative;
  background: $SteelGray;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0.9;
  transition: all 0.3s ease;
  height: 235px;
  will-change: transition;

  &:hover,
  &:active {
    box-shadow: 0 3px 6px rgba(black, 0.3);
    transform: translateY(-2px);
    opacity: 1;
  }

  h6 {
    font-size: 1.1rem;
    font-weight: 200;
    letter-spacing: -0.5px;
    margin: 0;
  }

  .event-card-image {
    height: 163px;

    small {
      font-size: 0.6rem;
      font-weight: 100;
    }
  }

  address {
    align-self: center;
    font-size: xx-small;
    font-style: inherit;
    font-weight: 100;
    line-height: normal;
    text-size-adjust: auto;
  }
}

.align-menu {
  z-index: 10;
  right: 0px;
  top: 0px;
}
</style>
