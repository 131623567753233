<template>
  <PageLayout class="page-new-event ml-28 mr-28">
    <template #header>
      <div class="d-flex ai-center h-100 w-100">
        <h1 class="header-title">
          {{ isEdit ? "Editar Demanda" : "Nova Demanda" }}
        </h1>
        <div class="md-layout-item d-flex align-right">
          <small class="mr-10">{{ draftLabel }}</small>
          <button class="btn outline" @click="saveDraft">
            Salvar Rascunho
          </button>
        </div>
      </div>
    </template>

    <template #content>
      <StepperApp />
    </template>
  </PageLayout>
</template>

<script>
import { mapGetters } from "vuex";

import StepperApp from "@/components/events/StepperApp/StepperApp";
import { formatDate } from "@/helpers/dateFormat";

export default {
  components: { StepperApp },
  computed: {
    ...mapGetters({
      isEdit: "EditEvent/isEdit",
    }),
    draftLabel() {
      const draftUpdatedAt = this.$store.getters["NewEvent/draftUpdatedAt"];

      const format = (date) => {
        const day = new Date(date);
        const hour = `${formatDate(day, "HH")}:${formatDate(day, "ii")}`;
        return `Rascunho salvo: ${hour}`;
      };

      return draftUpdatedAt ? format(draftUpdatedAt) : "";
    },
  },
  methods: {
    saveDraft() {
      this.$store.dispatch("NewEvent/saveDraft");
      this.$store.dispatch("Payment/saveDraft");
      this.$eventhub.$emit("feedback:show", {
        type: "success",
        msg: "Rascunho salvo!",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.page-new-event {
  height: 90%;

  .sub-header {
    margin-bottom: 10px;
  }

  .border-solid {
    border: 2px solid white;
  }

  .align-right {
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
