/**
 * Validations based on Vuelidate data Schema
 * reference: https://vuelidate.js.org/
 */

export const mapErrors = (requirements, messages) => {
  const notContemplated = (exigency) => requirements[exigency] === false;

  const justNotContemplated = (errors, [requirement, message]) =>
    notContemplated(requirement) ? errors.concat(message) : errors;

  return Object.entries(messages).reduce(justNotContemplated, []);
};

export const whenDirty = (requirements, messages) =>
  requirements.$dirty ? mapErrors(requirements, messages) : [];

export default {
  mapErrors,
  whenDirty,
};
