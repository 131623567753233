<template>
  <v-date-picker
    is-dark
    is-inline
    :popover="{
      placement: $screens({ sm: 'auto' }),
      visibility: 'click',
    }"
    :update-on-input="false"
    v-on="$listeners"
    :value="value"
    is-range>
    <template v-slot="{ inputValue, inputEvents }">
      <label for="siblingsInput">
        <span>{{ label }}</span>
        <div
          :class="{ 'input-focus': siblingsInputOnFocus }"
          class="d-flex ai-center wrapper p-relative"
          style="flex: 1">
          <div
            v-if="value && icon"
            @click="$emit('iconClick')"
            class="before-icon">
            <i class="mdi mdi-close-circle font-size-1-3 text-malibu"></i>
          </div>
          <input
            @focus="siblingsInputFocus"
            @blur="siblingsInputBlur"
            class="w-100 text-align-center input-range"
            :value="inputValue.start"
            v-on="inputEvents.start"
            :readonly="true"
            placeholder="00/00/0000" />
          <label class="" for="siblingsInput">
            <div>Até</div>
          </label>
          <input
            @focus="siblingsInputFocus"
            @blur="siblingsInputBlur"
            class="w-100 text-align-center input-range"
            :value="inputValue.end"
            v-on="inputEvents.end"
            :readonly="true"
            placeholder="00/00/0000" />
        </div>
      </label>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  props: ["label", "value", "icon"],
  data() {
    return {
      siblingsInputOnFocus: false,
      range: {
        start: new Date(),
        end: new Date(),
      },
    };
  },
  methods: {
    siblingsInputFocus() {
      this.siblingsInputOnFocus = true;
    },
    siblingsInputBlur() {
      this.siblingsInputOnFocus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  margin: 0px 0px 2px;
  font-size: 12px;
  font-weight: 300;
}
.wrapper {
  cursor: pointer;
  border: 2px groove white;
  padding: 5px 10px;
  border-radius: 4px;
  height: 50px;
  transition: border 0.25s;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);

  &.input-focus {
    border-color: #7541bf;
  }
}

.input-range {
  border: 0;
  background-color: transparent;
  color: white;
  font-family: inherit;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.before-icon {
  cursor: inherit;
  position: absolute;
  color: white;
  top: 0px;
  left: 0px;
  height: 47px;
  width: 36px;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(white, 0.1);
  }

  &:active {
    background: rgba(white, 0.1);
  }
}
</style>
