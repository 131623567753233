<template>
  <transition name="modal-fade">
    <section
      v-if="show"
      class="lanup-modal"
      @keyup.esc="close"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      tabindex="0">
      <article class="modal-content d-flex direction-column" :class="size">
        <header
          id="modalTitle"
          class="d-flex jc-space-between f-0 w-100 ai-center pv-10 title-wrap">
          <slot name="header">
            <h2 :class="titleColor" class="title">{{ title }}</h2>
          </slot>
          <button
            class="outline d-flex jc-center ai-center js-end close-button"
            @click="close">
            <i class="mdi mdi-close"></i>
          </button>
        </header>

        <md-content id="modalDescription" class="md-scrollbar f-1 h-100 mb-1">
          <slot></slot>
        </md-content>

        <footer>
          <div class="pl-20 pr-20">
            <Divider theme="martinique" />
          </div>
          <slot name="footer"></slot>
        </footer>
      </article>
    </section>
  </transition>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default: "medium",
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    titleColor: {
      type: String,
      default: "color-white",
    },
  },
  data: () => ({
    show: false,
  }),
  created() {
    this.register();
  },
  methods: {
    register() {
      const MODAL_OPEN_EVENT = `modal:open:${this.name}`;
      const MODAL_CLOSE_EVENT = `modal:close:${this.name}`;

      this.$eventhub.$on(MODAL_OPEN_EVENT, this.open);
      this.$eventhub.$on(MODAL_CLOSE_EVENT, this.close);
    },
    open($event) {
      this.$emit("open", $event);
      this.show = true;
    },
    close($event) {
      this.$emit("close", $event);
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/colors.scss";

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
section {
  z-index: 9;
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  background: black 0% 0% no-repeat padding-box;
  background-color: rgba(white, 0.5);
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  grid-column: 0 3;
  grid-row: 0 3;

  header {
    background: $Martinique;
    border-radius: 8px 8px 0 0;
    padding: 0 1rem;
    height: 50px;
    width: 100%;

    h2 {
      font-size: 18px;

      &.color-white {
        color: white;
      }
      &.color-red {
        color: $Red;
      }
    }

    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-grow: 1;
    }

    .close-button {
      border-radius: 50px;
      color: white;
      font-size: 1.2rem;
      height: 30px;
      width: 30px;
      background: rgba(white, 0.1);

      &:hover {
        background: rgba(white, 0.13);
      }

      &:active {
        background: rgba(white, 0.16);
      }
    }
  }

  .modal-content {
    background-color: $SteelGray;
    border-radius: 8px;
    display: flex;
    flex-wrap: nowrap;
    width: auto;

    &.small {
      min-width: 35%;
      max-width: 35%;
      height: 35%;
      min-height: 65%;
      max-height: 65%;
    }

    &.medium {
      min-width: 45%;
      max-width: 45%;
      height: 75%;
      min-height: 75%;
      max-height: 75%;
    }

    &.larger {
      min-width: 55%;
      max-width: 55%;
      height: 85%;
      min-height: 85%;
      max-height: 85%;
    }

    &.full {
      width: 100vw;
      height: 100vh;
    }

    #modalDescription {
      background: $SteelGray;
      overflow: auto;
    }
  }
  .border-solid {
    border: 2px solid white;
  }
}
</style>
