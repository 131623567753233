const vacancyStatus = {
  AVAILABLE: 0,
  WAIT_FREELA_CONFIRMATION: 1,
  CONFIRMED: 2,
  WAIT_AGENCY_OR_HIRER_APPROVAL: 3,
  WORKED: 4,
  WAIT_FREELA_ACCEPT_INVITE: 8,
};

export { vacancyStatus };
