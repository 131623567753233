<template>
  <div class="p-16">
    <div>
      <header class="header-list grid-table p-15">
        <small class="f-1">Número de lote</small>
        <small class="f-1">Data de criação</small>
        <small class="f-1">Valor total</small>
        <small class="f-1">Status</small>
        <small class="f-1">Ações</small>
      </header>
    </div>
    <template v-if="!loader && batch.listBatch.length">
      <div class="list" v-for="(list, index) in batch.listBatch" :key="index">
        <slot-batch :listBatch="list" />
      </div>
    </template>

    <div v-else class="d-flex direction-column ai-center pt-25">
      <template v-if="!loader">
        <img
          class="img-empty"
          :src="require('@/assets/empty_search.png')"
          alt="empty" />
        <h4>Não há nenhum lote para ser exibido.</h4>
      </template>
      <Loading v-else class="p-50" label="Carregando" />
    </div>

    <div
      v-if="!(batch.totalPages === page) && batch.listBatch.length"
      class="d-flex jc-center m-25">
      <SpinnerButton
        :loading="loaderButton"
        class="btn secondary"
        @click="loaderMorePage">
        Carregar mais
      </SpinnerButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import slotBatch from "./BatchSlot.vue";

export default {
  components: { slotBatch },
  data: () => ({
    loaderButton: false,
    page: 1,
  }),
  computed: {
    ...mapGetters({
      batch: "Payment/batch",
      loader: "Payment/loading",
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      getListBatch: "Payment/getListBatch",
      reset: "Payment/reset",
    }),
    init() {
      this.reset();
      this.listBatch(true);
    },
    listBatch(loader) {
      this.getListBatch({ id: this.$route.params.id, page: this.page, loader })
        .catch((error) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: `${error}`,
          });
        })
        .finally(() => {
          this.loaderButton = false;
        });
    },
    loaderMorePage() {
      this.loaderButton = true;
      this.page = this.page + 1;
      this.listBatch(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.header-list {
  background-color: $Mirage;
}
.list {
  border-bottom: 2px solid $Ebony;
}
.grid-table {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.2fr;
}

.img-empty {
  width: 20%;
  height: 20%;
  object-fit: contain;
}
</style>
