<template>
  <section class="total-to-pay">
    <header>
      Valor calculado por contratação
      <button
        :class="{ 'disabled-container': isEdit }"
        class="btn secondary tc-secundary small"
        @click="openModal">
        Cupom
      </button>
    </header>
    <div>
      <small class="align-self-start">Total:</small>
      <strong class="txt-color-malibu">{{ label.total }}</strong>
      <small class="txt-color-malibu">por {{ label.freelas }}</small>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      newTotalFreelasInServices: "NewEvent/totalFreelasInServices",
      editTotalFreelasInServices: "EditEvent/totalFreelasInServices",
      isEdit: "EditEvent/isEdit",
      hasPromotionalCode: "NewEvent/hasPromotionalCode",
    }),
    totalFreelasInServices() {
      return this.isEdit
        ? this.editTotalFreelasInServices
        : this.newTotalFreelasInServices;
    },

    label() {
      const PRICE_PER_FREELA = 20;
      const s = this.totalFreelasInServices > 1 ? "is" : "l";
      const totalToPay = this.hasPromotionalCode
        ? 0
        : this.totalFreelasInServices * PRICE_PER_FREELA;

      return {
        total: `R$ ${totalToPay},00`,
        freelas: `${this.totalFreelasInServices} profissiona${s}`,
      };
    },
  },
  methods: {
    openModal() {
      this.$eventhub.$emit("modal:open:cupom");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.total-to-pay {
  font-size: small;

  .txt-color-malibu {
    color: $Malibu;
  }

  header {
    background: $Ebony;
    border-radius: 10px 10px 0 0;
    font-weight: 400;
    padding: 0.5rem 1rem;
    margin-bottom: 0.2rem;
  }
  div {
    align-items: baseline;
    background: $Ebony;
    border-radius: 0 0 10px 10px;
    display: flex;
    padding: 1rem;

    .align-self-start {
      align-self: flex-start;
    }

    strong {
      font-size: large;
      font-weight: 400;
      margin: 0.2rem 0.6rem;
    }
  }
}
</style>
