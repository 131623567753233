<template>
  <CustomCard
    title="Atividades"
    class="overflow-hidden w-100 d-flex direction-column radius-10">
    <md-content class="operations md-scrollbar p-15">
      <TimelineItem
        v-for="(negociation, key) in negotiations"
        :key="key"
        :activity="createActivityObjectToNegotiation(negociation)"
        class="p-relative">
        <template #header>
          <small
            @click="ratingHirer()"
            v-if="isAgency && key === 0"
            class="text-uppercase pt-10 text-fuchsia-blue font-size-07 cursor-pointer button-rating">
            Avaliar contratante
          </small>
          <small
            @click="ratingAgency(negociation)"
            v-if="!isAgency"
            class="text-uppercase pt-10 text-fuchsia-blue font-size-07 cursor-pointer button-rating">
            Avaliar Empresa
          </small>
        </template>
        <i
          class="mdi mdi-chevron-down icon-collapse cursor-pointer"
          @click="toggleNegociation(key)"></i>
        <div v-if="collapsed(key)">
          <TimelineItem
            v-for="(budget, bkey) in negociation.budgets"
            :key="bkey"
            :showAvatar="false"
            :activity="
              createActivityObjectToBudget({ ...budget }, negociation)
            ">
            <strong>R$ {{ budget.amount | toCurrency }}</strong>

            <div v-if="budget.status == 1 && !isAgency" class="d-flex">
              <spinnerButton
                :loading="loadingRefuse"
                class="btn outline small radius-50 font-size-07"
                @click="onRefuseBudget(negociation)">
                <span class="d-flex ac-center">Negociar</span>
              </spinnerButton>
              <spinnerButton
                :loading="loadingAccept"
                class="btn secondary small radius-50 font-size-07"
                @click="onAcceptBudget(negociation)">
                <span class="d-flex ac-center">Aceitar</span>
              </spinnerButton>
            </div>
          </TimelineItem>
        </div>
      </TimelineItem>
    </md-content>
  </CustomCard>
</template>
<script>
import TimelineItem from "@/components/shared/TimelineItem";
import { mapGetters, mapActions } from "vuex";
import { cond, T } from "ramda";
export default {
  components: {
    TimelineItem,
  },
  data: () => ({
    loadingRefuse: false,
    loadingAccept: false,
    collapseKey: [],
    negociations: [],
  }),

  computed: {
    ...mapGetters({
      allNegotiations: "EventDetails/negotiations",
      userId: "User/userId",
      username: "User/username",
      isAgency: "User/isAgency",
    }),

    currentAgencyNegotiations() {
      return this.allNegotiations.filter((n) => n.agencyId === this.userId);
    },

    negotiations() {
      return this.isAgency
        ? this.currentAgencyNegotiations
        : this.allNegotiations;
    },
  },

  methods: {
    ...mapActions({
      refuse: "EventDetails/refuseNewBudget",
      accept: "EventDetails/acceptNewBudget",
    }),

    ratingAgency(negociation) {
      this.$eventhub.$emit("modal:open:avaliationModal", negociation);
    },
    ratingHirer() {
      this.$eventhub.$emit("modal:open:avaliationModal", {
        agencyId: this.userId,
        name: this.username,
      });
    },

    onRefuseBudget({ serviceId, agencyId }) {
      this.loadingRefuse = true;

      this.refuse({ serviceId, agencyId })
        .then(this.feedbackSuccess("Nova negociação solicitada"))
        .catch(this.feedbackError)
        .finally(() => (this.loadingRefuse = false));
    },

    onAcceptBudget({ serviceId, agencyId }) {
      this.loadingAccept = true;

      this.accept({ serviceId, agencyId })
        .then(this.feedbackSuccess("Negociação aceita!"))
        .catch(this.feedbackError)
        .finally(() => (this.loadingAccept = false));
    },

    createActivityObjectToBudget({ status, createdAt }, { name }) {
      const createTimelineInfoObject = (description, avatarUrl) => ({
        description,
        avatarUrl,
      });
      const getTimelineInfo = ({ status, name }) =>
        cond([
          [
            () => status == 1,
            () =>
              createTimelineInfoObject(
                `A empresa ${name} <strong>mandou um novo orçamento</strong> de`,
                "icon_negociation.svg"
              ),
          ],
          [
            () => status == 2,
            () =>
              createTimelineInfoObject(
                "O contratante <strong>recusou</strong> o orçamento de",
                "icon_denied.svg"
              ),
          ],
          [
            () => status == 4,
            () =>
              createTimelineInfoObject(
                `A empresa <strong>${name}</strong> <strong>cancelou</strong> o orçamento de</strong>`,
                "icon_denied.svg"
              ),
          ],
          [
            T,
            () =>
              createTimelineInfoObject(
                "O contratante aceitou o orçamento",
                "icon_approved.svg"
              ),
          ],
        ])();

      return {
        occurredAt: new Date(createdAt),
        ...getTimelineInfo({ status, name }),
      };
    },

    createActivityObjectToNegotiation({ job, name, createdAt, budgets }) {
      const description = !budgets
        ? `<span><small>A empresa <strong>${name}</strong> ainda não enviou nenhum orçamento para o serviço</small> ${job} </span>`
        : `<span><small>Negociações com a empresa <strong>${name}</strong> do serviço</small> ${job} </span>`;

      return {
        occurredAt: new Date(createdAt),
        description,
      };
    },

    feedbackError({ message }) {
      this.$eventhub.$emit("feedback:show", {
        type: "error",
        msg: `${message}`,
      });
    },

    feedbackSuccess(message) {
      this.$eventhub.$emit("feedback:show", {
        type: "success",
        msg: `${message}`,
      });
    },

    collapsed(key) {
      return this.collapseKey.includes(key);
    },

    toggleNegociation(key) {
      if (this.collapsed(key))
        this.collapseKey = this.collapseKey.filter((value) => value !== key);
      else this.collapseKey.push(key);
    },
  },
};
</script>
<style lang="scss">
.icon-collapse {
  position: absolute;
  right: -10px;
  top: 43px;
}
</style>
