<template>
  <div class="event-service-card" @click.prevent="$emit('showEdit', title)">
    <CustomCard class="service-card mt-20 radius-10" theme="ebony">
      <template #header>
        <h3
          class="d-flex jc-space-between ai-center m-0 w-100 pl-20 pr-5 pt-10 pb-0">
          <span>
            {{ title }}
            <i v-if="isExclusive" class="mdi mdi-star"></i>
          </span>
          <button class="edit-button">
            <i class="js-end mdi mdi-pencil-outline cursor-pointer"></i>
          </button>
        </h3>

        <div class="p-10">
          <Divider theme="steel-gray" />
        </div>
      </template>

      <section class="d-flex pl-20 pr-20 pb-20 pt-0">
        <div
          class="left-side d-flex direction-column jc-space-evenly w-100 pr-10">
          <div class="d-flex jc-space-between">
            <small>Vagas</small>
            <span>{{ vacancyQuantity }}</span>
          </div>

          <div class="d-flex jc-space-between">
            <small>Jornada</small>
            <span>{{ responsabilitiyQuantity }}</span>
          </div>

          <div class="d-flex jc-space-between">
            <small>Check lists</small>
            <span>{{ checkListquantity }}</span>
          </div>
        </div>

        <div class="right-side">
          <div
            class="value-slot d-flex direction-column jc-space-between ai-center"
            v-if="upToFiveFreelas">
            <small>Valor por vaga</small>
            <span class="text-malibu">{{ payment }}</span>
            <small class="calc text-malibu" :class="{ show: vacancyQuantity }">
              {{ `(${vacancyQuantity} X ${payment})` }}
            </small>
          </div>

          <div
            class="agency-slot d-flex direction-column jc-space-between"
            v-else>
            <small>Empresa</small>
            <div class="d-flex direction-column">
              <div class="d-flex flex-100 ai-center">
                <Avatar class="mb-10 mt-10" :src="firstAgencyAdded.image" />
                <span v-if="agencies.length > 1" class="ml-2">
                  +{{ agencies.length }}
                </span>
              </div>
              <span v-if="firstAgencyAdded.name">
                {{ firstAgencyAdded.name }}
              </span>
              <small class="fix-line-height" v-else>
                <i class="text-fuchsia-blue">Nenhuma no momento</i>
              </small>
            </div>
          </div>
        </div>
      </section>
    </CustomCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EventServiceCard",
  props: {
    title: {
      type: String,
      default: "<Nome do serviço>",
    },
    vacancyQuantity: {
      type: Number,
      default: 0,
    },
    responsabilitiyQuantity: {
      type: Number,
      default: 0,
    },
    checkListquantity: {
      type: Number,
      default: 0,
    },
    selectedAgency: {
      type: Boolean,
      default: false,
    },
    agencies: {
      type: Array,
      default: () => [{ name: "", image: "" }],
    },
    payment: {
      type: String,
      default: "0",
    },
  },
  computed: {
    logo() {
      return this.selectedAgency
        ? this.firstAgencyAdded
        : require("@/assets/default-agency.png");
    },
    ...mapGetters({
      upToFiveFreelas: "NewEvent/upToFiveFreelas",
      hirer: "NewEvent/hirer",
    }),
    isExclusive() {
      return this.hirer.services
        .map((s) => s.toLowerCase())
        .includes(this.title.toLowerCase());
    },

    firstAgencyAdded() {
      return this.agencies.length ? this.agencies[0] : { name: "", image: "" };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.event-service-card {
  cursor: pointer;

  .service-card {
    width: 336px;

    .fix-line-height {
      line-height: 0.8rem;
    }

    .edit-button {
      border-radius: 50px;
      color: white;
      height: 40px;
      width: 40px;

      &:hover,
      &.active {
        background: rgba(white, 0.1);
      }
    }

    .right-side {
      min-width: 110px;

      .value-slot {
        height: 110px;

        span {
          font-size: 1.15rem;
        }

        .calc {
          opacity: 0;
          &.show {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
