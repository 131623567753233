<template>
  <div>
    <div
      class="grid-container gt-columns-3 g-gap-10 pb-15 mr-15 ml-15 cursor-pointer">
      <div v-for="(item, key) in thumbnail" :key="key">
        <img
          :src="item.url"
          alt="thumbnail"
          class="thumbnail"
          @click.prevent="show(key)" />
      </div>
    </div>
    <div v-if="visible" class="lightbox">
      <div
        class="cursor-pointer arrow left"
        @click.stop="prev"
        :class="{ invisible: !hasPrev() }">
        <svg
          class="pointer-events-none"
          fill="#fff"
          height="48"
          viewBox="0 0 24 24"
          width="48"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
          <path d="M0-.5h24v24H0z" fill="none" />
        </svg>
      </div>
      <div>
        <button
          class="outline d-flex jc-center ai-center js-end close-button-lightBox"
          @click="hide"
          @click.stop="hide">
          <i class="mdi mdi-close"></i>
        </button>
        <div class="lightbox-image" @click.stop="">
          <img :src="images[index].url" />
        </div>
      </div>

      <div
        class="cursor-pointer arrow right"
        @click.stop="next"
        :class="{ invisible: !hasNext() }">
        <svg
          class="pointer-events-none"
          fill="#fff"
          height="48"
          viewBox="0 0 24 24"
          width="48"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
          <path d="M0-.25h24v24H0z" fill="none" />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    thumbnail: {
      type: Array,
      default: () => [],
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      index: 0,
    };
  },
  methods: {
    show(index) {
      this.index = index;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.index = 0;
    },
    hasNext() {
      return this.index + 1 < this.images.length;
    },
    hasPrev() {
      return this.index - 1 >= 0;
    },
    prev() {
      if (this.hasPrev()) {
        this.index -= 1;
      }
    },
    next() {
      if (this.hasNext()) {
        this.index += 1;
      }
    },
    onKeydown(e) {
      if (this.visible) {
        switch (e.key) {
          case "ArrowRight":
            this.next();
            break;
          case "ArrowLeft":
            this.prev();
            break;
          case "ArrowDown":
          case "ArrowUp":
          case " ":
            e.preventDefault();
            break;
          case "Escape":
            this.hide();
            break;
        }
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeydown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.onKeydown);
  },
};
</script>

<style lang="scss">
.lightbox {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0px;
  margin: 10px;
  background: rgba(#000, 0.5);
}

.thumbnail {
  width: 100%;
  height: 90px !important;
  object-fit: cover;
}

.lightbox-image img {
  width: 100%;
  height: 300px;
  max-width: 100%;
  object-fit: contain;
}

.arrow {
  position: absolute;

  &.left {
    right: 85%;
  }

  &.right {
    left: 85%;
  }
}

.grid-container {
  display: -ms-grid;
  display: grid;
  &.g-gap-10 {
    grid-gap: 10px;
  }
  &.gt-columns-4 {
    grid-template-columns: repeat(4, auto);
    -ms-grid-columns: repeat(4, auto);
  }
  &.gt-columns-3 {
    grid-template-columns: repeat(3, auto);
    -ms-grid-columns: repeat(3, auto);
  }
}

.close-button-lightBox {
  position: absolute;
  left: 89%;
  margin: 5px;
  border-radius: 50px;
  color: white;
  font-size: 1rem;
  height: 25px;
  width: 25px;
  background: rgba(#000, 0.5);

  &:hover {
    background: rgba(#000, 0.9);
  }

  &:active {
    background: rgba(#000, 0.9);
  }
}
</style>
