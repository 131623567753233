<template>
  <div class="p-30" :class="{ 'pb-100': sendTobackend.totalEventSelected }">
    <div class="d-flex cursor-pointer">
      <i class="mdi mdi-chevron-left font-size-1-9 text-malibu"></i>
      <span @click="goBack()" class="font-size-08 text-malibu">
        Voltar para faturas
      </span>
    </div>
    <h2 class="text-Periwinkle">Criar Fatura</h2>
    <FilterNewInvoice />
    <table class="table-container bg-header-steelgray mt-25">
      <thead class="border-header">
        <tr>
          <th>
            <custom-checkbox
              v-if="!root.empty && !serviceAwaitingPayment"
              type="checkbox"
              :item="root"
              :checked="root.checked"
              @change="onChange"
              :disabled="!root.children.length" />
          </th>
          <th><small>Cliente</small></th>
          <th><small>Demanda</small></th>
          <th><small>Período</small></th>
          <th><small>Qtd. profissionais</small></th>
          <th><small>Valor total da demanda</small></th>
          <th></th>
        </tr>
      </thead>

      <tbody v-if="!root.empty && !loader && !serviceAwaitingPayment">
        <template v-for="(item, index) in root.children">
          <event-services-table-row
            v-if="item.children.length"
            :item="item"
            :key="index" />
        </template>
      </tbody>

      <tbody v-else>
        <td colspan="7">
          <div class="d-flex ai-center jc-center direction-column p-10">
            <template v-if="!loader">
              <img
                class="image-empty"
                :src="require('@/assets/empty-state-invoice.svg')"
                alt="empty-state" />
              <span
                v-if="!filterEventService.cnpj"
                class="text-Periwinkle text-center">
                Ei! Use o filtro para encontrar as demandas
                <br />
                e gerar uma fatura.
              </span>
              <span v-else class="text-Periwinkle text-center">
                Nenhum evento foi encontrado
              </span>
            </template>
            <Loading v-else class="p-50" label="Carregando" />
          </div>
        </td>
      </tbody>
    </table>

    <div
      class="footer"
      :class="{ 'open-footer': sendTobackend.totalEventSelected }">
      <div class="content d-flex jc-end ai-center">
        <div class="d-flex w-70 jc-end jc-space-between">
          <small>
            Demandas Selecionadas
            <br />
            {{ sendTobackend.totalEventSelected }}
          </small>
          <small>
            Valor total profissionais
            <br />
            R$ {{ sendTobackend.totalValueByFreela | toCurrency }}
          </small>
          <small>
            Total lucro da agência
            <br />
            R$ {{ sendTobackend.totalAgencyProfit | toCurrency }}
          </small>
          <small class="pl-30">
            Valor Total da fatura
            <br />
            R$ {{ sendTobackend.totalValue | toCurrency }}
          </small>
        </div>
        <div class="h-100 pt-10 pb-10 ml-15 mr-15"><div class="vl"></div></div>
        <div class="d-flex w-30 jc-space-between ai-center">
          <custom-input-date
            v-model="dueDate"
            class="w-60 ml-30 mr-30"
            label="Data de vencimento"
            :minDate="new Date()" />
          <button
            :disabled="!dueDate"
            @click="openModal"
            class="btn-payment mt-15">
            <span>Gerar fatura</span>
          </button>
        </div>
      </div>
    </div>
    <confirmation-create-invoice-modal />
  </div>
</template>

<script>
import FilterNewInvoice from "./Filter/FilterNewInvoice.vue";
import { mapGetters, mapActions } from "vuex";
import EventServicesTableRow from "./EventServicesTableRow.vue";
import ConfirmationCreateInvoiceModal from "./Modal/ConfirmationCreateInvoiceModal.vue";

export default {
  components: {
    FilterNewInvoice,
    EventServicesTableRow,
    ConfirmationCreateInvoiceModal,
  },
  filters: {
    formatPaymentValue(val) {
      const payment = val
        .map((p) => p.totalValue)
        .reduce((acc, currentValue) => acc + currentValue, 0);
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(payment);
    },
  },
  data: () => ({
    dueDate: null,
    total: 0,
  }),
  computed: {
    ...mapGetters({
      root: "EventDetails/eventServiceList",
      loader: "EventDetails/loading",
      filterEventService: "EventDetails/filterEventService",
      agencyProfit: "EventDetails/agencyProfit",
      valueByFreela: "EventDetails/valueByFreela",
    }),
    serviceAwaitingPayment() {
      return !this.root.children?.flatMap((x) =>
        x.children.filter((s) => s.status === 1)
      ).length;
    },
    sendTobackend() {
      const _services = this.root.children?.flatMap((x) => {
        return (
          x.children?.filter(
            (s) => s.checked === true && ![2, 3].includes(s.status)
          ) || []
        );
      });

      const group = {
        eventId: "",
        services: _services,
        totalEventSelected: 0,
        totalValue: 0,
        totalAgencyProfit: 0,
        totalValueByFreela: 0,
      };
      if (_services.length) {
        _services?.forEach((x) => {
          const service = group.eventId === x.eventId;
          if (!service) {
            group.eventId = x.eventId;
            group.totalEventSelected = group.totalEventSelected + 1;
          }
          group.totalValue = group.totalValue + x.totalValue;
          group.totalAgencyProfit =
            group.totalAgencyProfit + x.agencyProfit * x.totalFreelas;
          group.totalValueByFreela =
            group.totalValueByFreela + x.valueByFreela * x.totalFreelas;
        });
      }
      return group;
    },
  },
  methods: {
    ...mapActions({
      udpateEventServiceList: "EventDetails/udpateEventServiceList",
    }),
    onChange(node, checked) {
      this.udpateEventServiceList({ node, checked });
    },
    openModal() {
      this.$eventhub.$emit("modal:open:confirmation-creation-invoice", {
        services: this.sendTobackend.services,
        document: this.filterEventService.cnpj,
        expireDate: new Date(this.dueDate).toISOString(),
      });
    },
    goBack() {
      this.$store.commit("EventDetails/CLEAR_FILTER_SERVICE_EVENT");
      this.$store.commit("EventDetails/RESET_EVENT_SERVICE_LIST");
      this.$router.push({
        name: "invoices",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.border-header {
  border-bottom: 2px solid $Ebony;
}
.footer {
  position: fixed;
  width: 100%;
  height: 90px;
  margin: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-out;
  -webkit-transform: scalexY(0);
  transform: scaleY(0);

  &.open-footer {
    transition: all 0.3s ease-in;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  & .content {
    background-color: $SteelGray;
    height: 100%;
    padding: 0 2.2%;
    & .btn-payment {
      &:disabled {
        cursor: not-allowed;
        background-color: rgba($color: $LightGreen, $alpha: 0.5);
      }
      background-color: $LightGreen;
      padding: 0px 15px;
      width: 45%;
      height: 50px;
      & span {
        color: $Minsk;
        font-size: 1em;
        font-family: "HelveticaNowMicro";
        font-weight: bold;
      }
      &:hover {
        background-color: rgba($color: $LightGreen, $alpha: 0.9);
      }
    }
  }
}
.disable {
  visibility: hidden;
}
.vl {
  width: 0px;
  height: 100%;
  float: left;
  border: 1px inset rgba($color: white, $alpha: 0.5);
}

.image-empty {
  image-rendering: auto;
  width: 20%;
  height: 20%;
  padding-bottom: 10px;
  object-fit: contain;
}
</style>
