<template>
  <div class="stepper">
    <div v-for="(step, index) in totalSteps" :key="`step${index}`">
      <div class="d-flex jc-center" v-if="step !== 1">
        <hr :class="{ active: step <= currentStep }" />
      </div>
      <div class="step">
        <hr class="top" v-if="step <= currentStep && step > 1" />
        <span
          :class="['circle', { active: step <= currentStep }]"
          @click="$emit('stepClick', { step })">
          {{ step }}
        </span>
        <hr class="bottom" v-if="step < currentStep && step < totalSteps" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalSteps: { default: 3 },
    currentStep: { default: 1 },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  width: 20px;
  height: 100%;
  margin: 0px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  hr {
    width: 3px;
    margin: 0px;
    height: 80px;
    bottom: -5px;
    border: none;
    border-radius: 2px;
    background-color: #24203b;

    &.active {
      background-color: #5bc5f3;
    }
  }

  .step {
    display: flex;
    position: relative;
    justify-content: center;

    hr {
      height: 10px;
      z-index: 0;
      position: absolute;
      background-color: #5bc5f3;

      &.top {
        top: -5px;
      }
      &.bottom {
        bottom: -5px;
      }
    }

    .circle {
      align-items: center;
      background-color: #18142f;
      border: 3px solid #24203b;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      font-weight: bold;
      height: 40px;
      justify-content: center;
      width: 40px;

      &.active {
        color: #18142f;
        border: 3px solid #285274;
        background-color: #5bc5f3;
      }
    }
  }
}
</style>
