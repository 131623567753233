<template>
  <section class="verba-fields">
    <h3 class="title">Verba</h3>

    <CustomInput
      type="currency"
      class="max-w-350"
      placeholder="Gostaria de pagar até:"
      example="R$ 1.000,00"
      v-model="verba" />
  </section>
</template>
<script>
export default {
  computed: {
    verba: {
      get() {
        return this.$store.getters["NewEvent/form"].verba;
      },
      set(verba) {
        this.$store.dispatch("NewEvent/setFormData", { verba });
      },
    },
  },
};
</script>
