<template>
  <FormCard class="register-form">
    <template v-slot:title>Cadastrar como {{ typeLabel.label }}:</template>
    <template v-slot:form>
      <form
        action="?"
        @submit="onSubmit(name, email.toLowerCase(), password, document)">
        <div class="d-flex ai-stretch form-wrap jc-space-evenly">
          <!-- <div class="d-flex jc-center ai-center direction-column f-1">
            <h2 class="text-white mb-2">Cadastrar pelo Facebook</h2>
            <FacebookLoginButton @onStatusChange="onFacebookStatusChange" />
          </div>

          <div class="d-flex direction-column">
            <hr class="vertical" />
            <span>Ou</span>
            <hr class="vertical" />
          </div> -->

          <div class="d-flex direction-column ai-center f-1">
            <h2 class="text-white mb-10">Cadastrar com E-mail</h2>
            <div class="fix-width">
              <div class="form-group">
                <CustomInput
                  :isInvalid="$v.name.$error"
                  placeholder="Nome"
                  id="name"
                  v-model.trim="$v.name.$model" />
                <div class="error">
                  <span v-if="$v.name.$dirty && !$v.name.minLength">
                    Nome deve conter um minimo de 2 caracteres
                  </span>
                  <span v-if="$v.name.$dirty && !$v.name.required">
                    Campo Obrigatório
                  </span>
                </div>
              </div>
              <div class="form-group">
                <CustomInput
                  :isInvalid="$v.email.$error"
                  v-model.trim="$v.email.$model"
                  placeholder="E-mail"
                  id="e-mail" />
                <div class="error">
                  <span v-if="!$v.email.email">Entre com um email válido</span>
                  <span v-if="$v.email.$dirty && !$v.email.required">
                    Campo Obrigatório
                  </span>
                </div>
              </div>
              <div v-if="userType === 'hirer'" class="form-group">
                <CustomInput
                  :isInvalid="$v.document.$error"
                  v-model.trim="$v.document.$model"
                  placeholder="CNPJ"
                  id="cnpj"
                  :mask="['##.###.###/####-##']" />
                <div class="error">
                  <span
                    v-if="
                      $v.document.$dirty &&
                      !$v.document.validDOC &&
                      $v.document.required
                    ">
                    Digite um CNPJ válido
                  </span>
                  <span v-if="$v.document.$dirty && !$v.document.required">
                    Campo Obrigatório
                  </span>
                </div>
              </div>
              <div class="form-group">
                <CustomInput
                  :isInvalid="$v.password.$error"
                  v-model.trim="$v.password.$model"
                  type="password"
                  placeholder="Senha"
                  id="password" />
                <div class="error">
                  <span v-if="!$v.password.minLength">
                    Nome deve conter um minimo de 6 caracteres
                  </span>
                  <span v-if="$v.password.$dirty && !$v.password.required">
                    Campo Obrigatório
                  </span>
                </div>
              </div>
              <div class="form-group">
                <CustomInput
                  :isInvalid="$v.confirmPassword.$error"
                  v-model.trim="$v.confirmPassword.$model"
                  type="password"
                  placeholder="Confirme Senha"
                  id="confirm-password" />
                <div class="error d-flex wrapped f-100">
                  <span
                    class="d-flex f-100"
                    v-if="
                      $v.confirmPassword.$dirty &&
                      !$v.confirmPassword.sameAsPassword
                    ">
                    Senhas devem ser iguais
                  </span>
                  <span
                    class="d-flex f-100"
                    v-if="
                      $v.confirmPassword.$dirty && !$v.confirmPassword.required
                    ">
                    Campo Obrigatório
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex jc-center mt-15">
          <SpinnerButton
            :disabled="isUserTypeAgency ? isAgencyInvalid : $v.$invalid"
            :loading="loading"
            class="btn primary submit-button"
            id="btn-submit"
            @click="onSubmit(name, email.toLowerCase(), password, document)">
            Cadastrar
          </SpinnerButton>
        </div>
      </form>
      <footer class="footer d-flex direction-column ai-center">
        <small class="d-flex">
          Ao se cadastrar, você aceita nossos
          <a class="ph-5" href="/termos/termos-de-uso" target="lanup-terms">
            Termos de Uso
          </a>
          e
          <a
            class="ph-5"
            href="/termos/politicas-de-privacidade"
            target="lanup-terms">
            Politicas de privacidade
          </a>
        </small>
      </footer>
    </template>
  </FormCard>
</template>

<script>
import { tryCatch, ifElse, when, equals, pipe, then } from "ramda";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { validateCNPJ } from "@/helpers/form/validateCNPJ";

import FormCard from "./FormCard";
// import FacebookLoginButton from './Facebook/LoginButton';

import {
  register,
  authenticate,
  getFacebookUserData,
} from "@/services/auth/auth.http";

export default {
  props: {
    userType: {
      type: String,
      default: "",
    },
  },
  components: {
    FormCard,
    //  FacebookLoginButton
  },
  data: () => ({
    name: "",
    email: "",
    document: "",
    password: "",
    confirmPassword: "",
    avatarUrl: "",
    facebookToken: "",
    invalidSubmit: false,
    loading: false,
    stepForm: true,
  }),
  validations: {
    name: {
      minLength: minLength(2),
      required,
    },
    email: {
      email: (val) => email(val.toLowerCase()),
      required,
    },
    password: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
    document: {
      required,
      validDOC: (doc) => validateCNPJ(doc),
    },
  },
  computed: {
    isUserTypeAgency() {
      return this.userType === "agency";
    },
    isAgencyInvalid() {
      return (
        this.$v.name.$invalid ||
        this.$v.email.$invalid ||
        this.$v.password.$invalid ||
        this.$v.confirmPassword.$invalid
      );
    },
    typeLabel() {
      const agencyLabel = { label: "Empresa" };
      const hirerLabel = { label: "Contratante" };

      return this.isUserTypeAgency ? agencyLabel : hirerLabel;
    },
  },
  methods: {
    onSubmit(name, email, password, document) {
      this.loading = true;
      const doLogin = () => this.tryLogin(email, password);
      const onError = ({
        response: {
          data: { errorMessage },
        },
      }) => this.onError(errorMessage);

      register(
        {
          name,
          email,
          document,
          password,
          avatar: this.avatarUrl,
          facebookToken: this.facebookToken,
        },
        this.userType
      )
        .then(doLogin)
        .catch(onError)
        .finally(() => (this.loading = false));
      return false;
    },

    tryLogin(login, password) {
      this.loading = true;
      const saveToken = ({ token }) => this.saveUserData(token);
      const onError = ({
        response: {
          data: { errorMessage },
        },
      }) => this.onError(errorMessage);
      const redirect = () => this.redirectFlow();

      authenticate({ login, password })
        .then(saveToken)
        .then(redirect)
        .catch(onError)
        .finally(() => (this.loading = false));
    },

    onFacebookStatusChange(data) {
      const isConnected = ({ status = undefined }) =>
        equals("connected")(status);

      const doFacebookRegisterFlow = this.facebookRegisterFlow;
      const whenConnectedTryRegisterFlow = when(
        isConnected,
        doFacebookRegisterFlow
      );

      whenConnectedTryRegisterFlow(data);
    },

    facebookRegisterFlow(data) {
      const saveFBTokenToRegisterUser = ({ authResponse: { accessToken } }) => {
        this.facebookToken = accessToken;
        return this.facebookToken;
      };

      const getUserInfo = (facebookToken) => getFacebookUserData(facebookToken);

      const isRegistered = ({ isRegistered }) => isRegistered;

      const doLogin = ({ accessToken: { token } }) => this.saveUserData(token);

      const populateForm = ({
        authenticateUser: {
          name,
          email,
          avatar: { url },
        },
      }) => Object.assign(this, { name, email, avatarUrl: url });

      const loginOrPopulateForm = ifElse(isRegistered, doLogin, populateForm);
      const registerFlow = pipe(
        saveFBTokenToRegisterUser,
        getUserInfo,
        then(loginOrPopulateForm)
      );

      const tryRegisterFlow = tryCatch(registerFlow, this.onError);

      tryRegisterFlow(data);
    },

    onError(errorMessage) {
      this.$eventhub.$emit("feedback:show", {
        type: "error",
        msg: errorMessage,
      });
    },

    saveUserData(token) {
      this.$store.dispatch("User/userLogged", token);
    },

    redirectFlow() {
      const urlToGoBack = localStorage.getItem("urlToGoBack");
      localStorage.removeItem("urlToGoBack");

      if (this.isUserTypeAgency) {
        this.$router.push({ name: "profile" });
        this.$eventhub.$emit("feedback:show", {
          timeout: 5000,
          msg: `<span> Seja bem vindo! <br/> Complete seu cadastro e crie sua demanda!</span>`,
        });
        return;
      }

      const redirect = urlToGoBack || { name: "events" };

      this.$router.push(redirect);
    },
  },
};
</script>

<style lang="scss">
.register-form {
  h2 {
    font-size: 1.1rem;
    letter-spacing: 0rem;
  }
  .fix-width {
    width: 100%;
  }

  // .form-wrap {
  // width: 900px;
  // }

  .submit-button {
    max-width: 300px;
  }

  .float-content {
    left: 0;
    position: fixed;
    top: 0;
    z-index: 999;
  }
}
</style>
