<template>
  <section>
    <h3 class="title">Protocolos</h3>

    <div class="d-flex ai-center">
      <div for="hasWorkPictures" class="switch-title mt-6">Biossegurança</div>
      <toggle-button
        class="mt-8 ml-10"
        id="hasWorkPictures"
        v-model="hasSecurityProtocol"
        :height="26"
        :width="56"
        :color="colorSwitch"
        :sync="true" />
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  components: {
    ToggleButton,
  },
  computed: {
    ...mapGetters({ isEdit: "EditEvent/isEdit" }),

    colorSwitch() {
      return {
        checked: "#46C5F3",
        unchecked: "#403A60",
      };
    },
    hasSecurityProtocol: {
      get() {
        return this.isEdit
          ? this.$store.getters["EditEvent/hasSecurityProtocol"]
          : this.$store.getters["NewEvent/hasSecurityProtocol"];
      },
      set(hasSecurityProtocol) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", {
              hasSecurityProtocol,
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              hasSecurityProtocol,
            });
      },
    },
  },
};
</script>

<style scoped>
.switch-title {
  font-size: 12px;
  font-weight: 300;
}
</style>
