<template>
  <PageLayout class="profile ml-28 mr-28">
    <template #header>
      <div class="d-flex ai-center h-100">
        <h1 class="header-title">Perfil</h1>
      </div>
    </template>

    <template #content>
      <div class="d-flex h-100 pb-28">
        <section class="profile-resume d-flex mr-20">
          <ResumeCard
            @menuItemClick="onResumeItemClick"
            :activeItem="activeItem" />
        </section>
        <section class="profile-content-area d-flex">
          <About />
        </section>
      </div>
    </template>
  </PageLayout>
</template>

<script>
import ResumeCard from "@/components/profile/ResumeCard";
import About from "@/components/profile/About";

export default {
  components: { ResumeCard, About },
  methods: {
    onResumeItemClick(hash) {
      if (this.$route.hash !== hash) {
        this.$router.push({ ...this.$route, hash });
      }
    },
  },
  computed: {
    activeItem() {
      return this.$route.hash || "";
    },
  },
};
</script>

<style lang="scss">
.profile {
  .profile-resume {
    width: 300px;
    flex-grow: 1;
  }

  .profile-content-area {
    width: 100%;
  }
}
</style>
