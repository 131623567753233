var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-occurrences d-flex direction-column ph-10 pv-2"},[_c('header',[_c('div',{staticClass:"header-occurrences d-flex w-100 jc-space-between ai-center"},[_c('div',{staticClass:"total d-flex direction-column jc-space-between cursor-pointer",on:{"click":function($event){_vm.view.statusToShow = [...Object.values(_vm.INCIDENT_STATUS)]}}},[_c('span',{staticClass:"text-red font-size-1-2"},[_vm._v(_vm._s(_vm.occurrences.length))]),_c('small',[_vm._v("Ocorrências")])]),_c('i',{staticClass:"separator mdi mdi-chevron-right"}),_c('div',{staticClass:"total-status pv-5 opened d-flex direction-column ai-center",class:{
          disabled: !_vm.view.statusToShow.includes(_vm.INCIDENT_STATUS.OPENED),
        },on:{"click":function($event){return _vm.toggleStatus(_vm.INCIDENT_STATUS.OPENED)}}},[_c('small',[_vm._v("Em aberto")]),_c('hr',{staticClass:"mt-1 border"}),_c('span',[_vm._v(_vm._s(_vm.totalOpened))])]),_c('i',{staticClass:"separator mdi mdi-chevron-right"}),_c('div',{staticClass:"total-status pv-5 in-progress d-flex direction-column ai-center",class:{
          disabled: !_vm.view.statusToShow.includes(_vm.INCIDENT_STATUS.IN_PROCCESS),
        },on:{"click":function($event){return _vm.toggleStatus(_vm.INCIDENT_STATUS.IN_PROCCESS)}}},[_c('small',[_vm._v("Em progresso")]),_c('hr',{staticClass:"mt-1 border"}),_c('span',[_vm._v(_vm._s(_vm.totalInProgress))])]),_c('i',{staticClass:"separator mdi mdi-chevron-right"}),_c('div',{staticClass:"total-status pv-5 resolved d-flex direction-column ai-center",class:{
          disabled: !_vm.view.statusToShow.includes(_vm.INCIDENT_STATUS.RESOLVED),
        },on:{"click":function($event){return _vm.toggleStatus(_vm.INCIDENT_STATUS.RESOLVED)}}},[_c('small',[_vm._v("Resolvido")]),_c('hr',{staticClass:"mt-1 border"}),_c('span',[_vm._v(_vm._s(_vm.totalResolved))])])]),_c('hr')]),_c('md-content',{staticClass:"occurrences-list md-scrollbar"},_vm._l((_vm.occurrencesToDisplay),function(occurrence,key){return _c('OccurrenceItem',{key:key,attrs:{"occurrence":occurrence},on:{"statusButtonClick":_vm.onStatusButtonClick}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }