<template>
  <label class="d-flex ai-center w-max-content">
    <input
      type="checkbox"
      class="custom-regular-checkbox"
      :checked="checked"
      @change="$emit('change', item, $event.target.checked)"
      :disabled="disabled" />
    <slot></slot>
  </label>
</template>

<script>
export default {
  model: {
    prop: "checked",
    event: "change",
  },
  props: ["item", "disabled", "checked"],
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
</style>
