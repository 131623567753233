import axios from "axios";

const URL_REGISTER_HIRER = "/hirers";
const URL_REGISTER_AGENCY = "/auth/users";
const URL_FORGOT_PASSWORD = "/auth/reset";

export const authenticate = (data) =>
  axios.post("/auth", data).then(({ data }) => data.result);

const _registerHirer = (data) =>
  axios.post(URL_REGISTER_HIRER, data).then(({ data }) => data.result);

const _registerAgency = ({ password, email, name, type = 2 }) => {
  const userData = { password, email, name, type };

  return axios
    .post(URL_REGISTER_AGENCY, userData)
    .then(({ data }) => data.result);
};

export const register = (data, type) =>
  type === "agency" ? _registerAgency(data) : _registerHirer(data);

export const resetPassword = (email) =>
  axios
    .post(`${URL_FORGOT_PASSWORD}?email=${email}`)
    .then(({ data }) => data.result);

export const getFacebookUserData = (facebookToken) =>
  axios
    .post("/auth/facebook", { facebookToken, userType: 2 })
    .then(({ data }) => data.result);

export default {
  resetPassword,
  authenticate,
  register,
  getFacebookUserData,
};
