/* istanbul ignore file */
import Vue from "vue";
import Router from "vue-router";

import Dashboard from "@/pages/main/Dashboard.vue";
import Profile from "@/pages/main/Profile.vue";
import Freelas from "@/pages/main/Freelas.vue";
import ViewProfile from "@/components/shared/ui/ModalRight.vue";
import Event from "@/pages/events/MyEvents.vue";
import EventNew from "@/pages/events/New.vue";
import ManageEvent from "@/pages/events/ManageEvent.vue";
import Charge from "@/components/events/EventInfo/EventPayroll/PaymentMethods.vue";
import DetailsBatch from "@/components/events/EventInfo/EventPayroll/Batch/DetailsBatch.vue";

import Login from "@/components/identity/LoginForm.vue";
import Register from "@/components/identity/Register.vue";
import UserTerms from "@/pages/terms/UserTerms.vue";
import PrivacyPolicy from "@/pages/terms/PrivacyPolicy.vue";
import ForgotPassword from "@/components/identity/ForgotPassword.vue";

import Landing from "@/pages/landing/Landing.vue";
import QRCode from "@/pages/QrCode/QRCode.vue";

import beforeEachRoute from "@/helpers/router/beforeEach/";

import Invoices from "@/pages/main/Invoices.vue";
import ListBatch from "@/pages/ListBatch.vue";
import NewInvoice from "@/components/Invoices/NewInvoice.vue";
import InvoiceDetails from "@/components/Invoices/InvoiceDetails.vue";

Vue.use(Router);

const lazy = (page) => () => import(`./pages/${page}.vue`);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      name: "home",
      component: Landing,
      meta: {
        access: "public-only",
      },
    },
    {
      path: "/dashboard/qr-code/:id",
      name: "QRCode",
      component: QRCode,
    },
    {
      path: "/eventos",
      component: lazy("Main"),
      children: [
        {
          path: "",
          name: "events",
          component: Event,
        },
        {
          path: "detalhes/:id",
          name: "events.info",
          component: ManageEvent,
        },
        {
          path: "detalhes/:id/cobranca/:batchId",
          name: "events.charge",
          component: Charge,
        },
        {
          path: "detalhes/:id/lote/:batchId",
          name: "events.details.batch",
          component: DetailsBatch,
        },
        {
          path: "detalhes/:id/servico/:job/:serviceId",
          name: "events.info.services",
          component: ManageEvent,
        },
        {
          path: "novo",
          redirect: { name: "events.new", params: { step: 1 } },
          meta: {
            access: "public",
          },
        },
        {
          path: "novo/:step",
          component: EventNew,
          name: "events.new",
          meta: {
            access: "public",
          },
        },
        {
          path: ":id/:step",
          component: EventNew,
          name: "events.edit",
        },
      ],
    },
    {
      path: "/termos",
      component: lazy("Terms"),
      name: "terms",
      redirect: { name: "access.login" },
      meta: {
        access: "public",
      },
      children: [
        {
          path: "termos-de-uso",
          name: "terms.terms",
          component: UserTerms,
        },
        {
          path: "politicas-de-privacidade",
          name: "terms.policies",
          component: PrivacyPolicy,
        },
      ],
    },
    {
      path: "/acesso",
      component: lazy("Identity"),
      name: "access",
      redirect: { name: "access.login" },
      meta: {
        access: "public-only",
      },
      children: [
        {
          alias: "logout",
          path: "login",
          name: "access.login",
          component: Login,
        },
        {
          path: "criar-conta",
          name: "access.new-account",
          component: Register,
        },
        {
          path: "recuperar-senha",
          name: "access.forgot-password",
          component: ForgotPassword,
        },
      ],
    },
    {
      path: "/",
      component: lazy("Main"),
      children: [
        {
          path: "/",
          name: "dashboard",
          redirect: { name: "operations" },
        },
        {
          path: "profile",
          name: "profile",
          component: Profile,
        },
        {
          path: "operations",
          name: "operations",
          component: Dashboard,
        },
        {
          path: "operations/:operationId",
          name: "operation",
          component: Dashboard,
        },
        {
          path: "freelas",
          name: "freelas",
          component: Freelas,
        },
        {
          path: "groups",
          name: "groups",
          component: Freelas,
        },
        {
          path: "groups/:groupId",
          name: "groups.detail",
          component: Freelas,
        },
        {
          path: "faturas/",
          name: "invoices",
          component: Invoices,
        },
        {
          path: "folha-de-pagamento/",
          name: "payroll",
          component: ListBatch,
        },
        {
          path: "faturas/nova-fatura",
          name: "NewInvoice",
          component: NewInvoice,
        },
        {
          path: "faturas/detalhes/:id",
          name: "InvoiceDetails",
          component: InvoiceDetails,
        },
        {
          path: "freelas/view-profile",
          query: {},
          name: "view-profile",
          component: ViewProfile,
          meta: {
            access: "public",
          },
        },
      ],
    },
  ],
});

router.beforeEach(beforeEachRoute);

export default router;
