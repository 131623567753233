<template>
  <div class="d-flex jc-center w-100 p-5">
    <template v-if="!userType">
      <SelectUserType @input="setUserType" />
    </template>
    <template v-else>
      <RegisterForm :userType="userType" />
    </template>
  </div>
</template>

<script>
import SelectUserType from "./SelectUserType";
import RegisterForm from "./RegisterForm";

export default {
  components: { RegisterForm, SelectUserType },

  computed: {
    userType() {
      return this.$route.query.userType;
    },
  },
  methods: {
    setUserType(type) {
      if (type != this.userType) {
        this.$router.push({
          name: "access.new-account",
          query: { userType: type },
        });
      }
    },
  },
};
</script>
