<template>
  <FormCard class="p-30">
    <template v-slot:title>Entre com sua conta:</template>
    <template v-slot:form>
      <form action="?" @submit="onSubmit(login.toLowerCase(), password)">
        <!-- <FacebookLoginButton @onStatusChange="onFacebookStatusChange" />

        <div class="divider">
          <hr />
          <span>Ou</span>
          <hr />
        </div> -->

        <div class="form-group">
          <CustomInput
            :isInvalid="$v.login.$error"
            id="login-mail"
            v-model.trim="$v.login.$model"
            placeholder="E-mail" />
          <div class="error">
            <span v-if="!$v.login.email">Entre com um email válido</span>
          </div>
        </div>
        <div class="form-group">
          <CustomInput
            :isInvalid="$v.password.$error"
            id="login-password"
            v-model.trim="$v.password.$model"
            placeholder="Senha"
            type="password" />
          <div class="error">
            <span v-if="!$v.password.minLength">
              Senha deve conter o minimo de 6 caracteres
            </span>
          </div>
        </div>
        <div class="d-flex jc-center">
          <SpinnerButton
            :disabled="$v.$invalid"
            :loading="loading"
            :id="'btn-login'"
            class="btn primary submit-button mt-20"
            @click="onSubmit(login.toLowerCase(), password)">
            Acessar
          </SpinnerButton>
        </div>
      </form>

      <small class="footer">
        <router-link class="pr-10" :to="{ name: 'access.forgot-password' }">
          Esqueci minha senha
        </router-link>
        <router-link
          class="pl-10"
          id="sign-up"
          :to="{ name: 'access.new-account' }">
          Cadastrar na LanUP
        </router-link>
      </small>
    </template>
  </FormCard>
</template>

<script>
import { tryCatch, ifElse, when, equals, pipe, then } from "ramda";
import FormCard from "./FormCard";
// import FacebookLoginButton from "./Facebook/LoginButton";
import { authenticate, getFacebookUserData } from "@/services/auth/auth.http";
import { required, minLength, email } from "vuelidate/lib/validators";
import { getUser } from "@/helpers/auth/";

export default {
  data() {
    return {
      login: "",
      password: "",
      formGroup: "",
      loading: false,
    };
  },
  validations: {
    login: {
      email: (val) => email(val.toLowerCase()),
      required,
    },
    password: {
      minLength: minLength(6),
      required,
    },
  },
  components: { FormCard },

  methods: {
    async onSubmit(login, password) {
      this.loading = true;
      const saveToken = ({ token }) =>
        this.$store.dispatch("User/userLogged", token);

      const redirect = () => this.redirectFlow();

      this.loading = true;
      const onError = ({
        response: {
          data: { errorMessage },
        },
      }) => this.onError(errorMessage);

      authenticate({ login, password })
        .then(saveToken)
        .then(redirect)
        .catch(onError)
        .finally(() => (this.loading = false));
    },
    onFacebookStatusChange(data) {
      const isConnected = ({ status = undefined }) =>
        equals("connected")(status);

      const whenConnectedTryFacebookAuthFlow = when(
        isConnected,
        this.facebookAuthFlow
      );

      whenConnectedTryFacebookAuthFlow(data);
    },

    facebookAuthFlow(data) {
      const isRegistered = ({ isRegistered }) => isRegistered;

      const saveToken = ({ accessToken: { token } }) =>
        this.$store.dispatch("User/userLogged", token);

      const goToRegisterPage = () =>
        this.$router.push({ name: "access.new-account" });

      const redirect = () => this.redirectFlow();

      const loginOrRegisterPage = ifElse(
        isRegistered,
        pipe(saveToken, redirect),
        goToRegisterPage
      );

      const getUserInfo = ({ authResponse: { accessToken } = {} }) =>
        getFacebookUserData(accessToken);

      const authFlow = pipe(getUserInfo, then(loginOrRegisterPage));

      const tryAuthFlow = tryCatch(authFlow, this.onError);

      tryAuthFlow(data);
    },

    redirectFlow() {
      if (getUser().userType !== "1") {
        const urlToGoBack = localStorage.getItem("urlToGoBack");
        const redirect = urlToGoBack || { name: "events" };
        this.$router.push(redirect);
        localStorage.removeItem("urlToGoBack");
      } else {
        this.$store.dispatch("User/logout");
        this.$router.push("/acesso/logout");
        this.onError("Você não pode acessar com uma conta de freela");
      }
    },

    onError(errorMessage) {
      this.$eventhub.$emit("feedback:show", {
        type: "error",
        msg: errorMessage,
      });
    },
  },
};
</script>
