<template>
  <CustomCard
    class="service-recruit radius-10 w-100 h-100 d-flex direction-column overflow-hidden ph-20">
    <template #header>
      <header class="p-15 ai-center header jc-space-between">
        <div class="d-flex ai-center">
          <button
            class="btn primary outline icon mr-10"
            @click="onBackButtonClick">
            <i class="mdi mdi-chevron-left font-size-1-5"></i>
          </button>
          <span class="text-uppercase">{{ service.id }}</span>
        </div>
        <div>
          <DropDown
            :outline="true"
            menu-title="Indicadores"
            :icon="require('@/assets/chart-donut.png')">
            <section class="option"></section>
            <hr class="divider" />
            <section
              class="option"
              v-for="(operation, index) in operations"
              :key="index"
              @click="openIndicators(operation)">
              <span>{{ operation.day | formatData }}</span>
            </section>
          </DropDown>
        </div>
      </header>
    </template>
    <section class="d-flex p-relative direction-column h-100 overflow-hidden">
      <Budget v-if="isAgency" :serviceId="serviceId" />
      <FilterSlots class="hover-z-index-2" />
      <VacancySlots />
    </section>
  </CustomCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Budget from "./Budget";
import FilterSlots from "./FilterSlots";
import VacancySlots from "./VacancySlots";

export default {
  components: { Budget, FilterSlots, VacancySlots },
  props: {
    serviceId: String,
  },
  filters: {
    formatData(data) {
      if (data !== "") {
        return new Date(data).toLocaleDateString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return "--";
    },
  },
  computed: {
    ...mapGetters({
      service: "EventDetails/currentService",
      eventId: "EventDetails/eventId",
      isAgency: "User/isAgency",
      operations: "EventDetails/operations",
    }),
  },
  methods: {
    ...mapActions({
      path: "operations/:operationId",
      setOperation: "Dashboard/loadOperation",
      getServiceReport: "EventDetails/getServiceReport",
    }),
    onBackButtonClick() {
      this.$emit("back");
    },
    openIndicators(operation) {
      this.$router.push({
        name: "operation",
        params: { operationId: operation.id },
      });
    },
    reportDownload(day) {
      this.getServiceReport({
        eventId: this.eventId,
        day,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.service-recruit {
  .hover-z-index-2 {
    z-index: 2;
  }
}
</style>
