<template>
  <div>
    <label
      v-for="(option, index) in options"
      :key="index"
      class="toggle-item"
      :id="`item${index}`"
      :class="{
        active: option.selected,
        'previously-selected': option.previouslySelected,
      }">
      {{ option.label }}
      <i v-if="option.exclusive" class="mdi mdi-star"></i>
      <input
        type="checkbox"
        :disabled="option.previouslySelected"
        :value="option.label.toLowerCase()"
        v-model="proxyChecked" />
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: { default: "" },
    checked: {
      type: [Array, Boolean],
      default: false,
    },
  },
  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  methods: {
    // click({ target: { checked } }) {
    //   this.$emit('input', { ...this.value, selected: checked });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.toggle-item {
  background: $Ebony;
  border-radius: 30px;
  color: $DimGray;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin: 2px;
  padding: 8px 15px;

  &.active {
    background: $Malibu;
    color: $Minsk;
  }

  &.previously-selected {
    background: $SilverChalice;
    color: $DimGray;
    cursor: not-allowed;
  }

  input {
    display: none;
  }
}
</style>
