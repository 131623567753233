<template>
  <section class="general-description">
    <h3 class="title">Descrição Geral</h3>

    <div class="md-layout">
      <md-content class="ckeditor md-scrollbar">
        <ckeditor
          id="event-details"
          :editor="editor"
          v-model="generalDescription"
          :config="editorConfig" />
      </md-content>
      <!-- <button
        class="btn primary btn-add-faq"
        @click="openModal"
      >Adicionar Perguntas Frequentes</button> -->
    </div>
  </section>
</template>
<script>
import { pipe, then } from "ramda";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getFaqByEventType } from "@/services/events/event.http";

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      selectedQuestions: [],
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "blockQuote",
        ],
      },
    };
  },

  computed: {
    generalDescription: {
      get() {
        return this.$store.getters["EditEvent/isEdit"]
          ? this.$store.getters["EditEvent/form"].generalDescription
          : this.$store.getters["NewEvent/generalDescription"];
      },
      set(generalDescription) {
        this.$store.getters["EditEvent/isEdit"]
          ? this.$store.dispatch("EditEvent/setFormData", {
              generalDescription,
            })
          : this.$store.dispatch("NewEvent/setFormData", {
              generalDescription,
            });
      },
    },
    eventTypeNew() {
      return this.$store.getters["EditEvent/isEdit"]
        ? this.$store.getters["EditEvent/eventTypeNew"]
        : this.$store.getters["NewEvent/eventTypeNew"];
    },
  },

  mounted() {
    this.generalDescription = this.$store.getters["EditEvent/isEdit"]
      ? this.$store.getters["EditEvent/form"].generalDescription
      : this.$store.getters["NewEvent/generalDescription"];
  },

  methods: {
    openModal() {
      const dispatchOpenModalEvent = (faq) =>
        this.$eventhub.$emit("modal:open:faq", {
          faq,
          selected: this.selectedQuestions,
          onAdd: this.onAddFaqQuestions,
        });

      pipe(getFaqByEventType, then(dispatchOpenModalEvent))(this.eventTypeNew);
    },

    onAddFaqQuestions(selected) {
      this.selectedQuestions = selected;
      const html = "<br/>R: <br/><br/>";

      if (selected.length)
        this.generalDescription = `${selected.join(html)}${html}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
:root {
  --ck-color-table-focused-cell-background: $SteelGray;
  --ck-color-dropdown-panel-background: white;
}
.ck.ck-editor {
  --ck-color-base-background: $SteelGray;
}

.general-description {
  --ck-color-text: #18142f;
  .btn-add-faq {
    margin-top: 15px;
  }

  .ckeditor {
    height: 200px;
    overflow: auto;
    width: 100%;

    .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused,
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      height: 160px;
      border: none;
    }
  }
}
</style>
