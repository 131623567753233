<template>
  <div class="p-25">
    <div @click="onBackButtonClick" class="d-flex cursor-pointer pt-10 pb-10">
      <i class="mdi mdi-chevron-left font-size-1-9 text-malibu"></i>
      <span class="font-size-08 text-malibu">Voltar</span>
    </div>
    <h1 class="text-Periwinkle">Detalhes da folha de pagamento</h1>
    <CustomCard class="radius-5 p-20 pr-100 d-flex jc-space-between mb-25">
      <span class="font-size-08">
        Número do lote
        <br />
        <span class="text-Perfume">
          {{ externalId }}
        </span>
      </span>
      <span class="font-size-08">
        Data de criação
        <br />
        {{ creationDate | formDate }}
      </span>
      <span class="font-size-08">
        Total de profissionais
        <br />
        {{ quantityFreelas }}
      </span>
      <span class="font-size-08">
        Valor total
        <br />
        R$ {{ totalValue | toCurrency }}
      </span>
      <span class="font-size-08">
        Status
        <br />
        <span class="bg-status" :class="objBatchStatusColor[status]">
          {{ objBatchStatus[status] }}
        </span>
      </span>
    </CustomCard>

    <table class="table-container bg-header-steelgray mt-25">
      <thead class="border-header">
        <tr>
          <th><small>Membros</small></th>
          <th><small>Total de turnos</small></th>
          <th><small>Valor Total</small></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <template v-for="(freela, index) in freelaDetail.children">
          <Collapsible :key="index">
            <template #header>
              <td>
                <div class="d-flex ai-center">
                  <div class="d-flex direction-column">
                    <span>{{ freela.name }}</span>
                    <small class="text-Manatee">Titular</small>
                  </div>
                </div>
              </td>

              <td>
                <span>{{ freela.children.length }}</span>
              </td>
              <td>
                <span>{{ freela.children | totalValue }}</span>
              </td>
            </template>

            <template #header-body>
              <th><small>Serviços</small></th>
              <th><small>Data</small></th>
              <th><small>Adicional</small></th>
              <th><small>Valor</small></th>
            </template>

            <template #body>
              <tr v-for="(vacancy, index) in freela.children" :key="index">
                <td>
                  <small>{{ vacancy.jobName }}</small>
                </td>
                <td>
                  <div class="d-flex direction-column">
                    <small class="text-white-opacity">
                      {{ formatDay(vacancy.date, "fullDate") }}
                    </small>
                    <small class="text-white-opacity">
                      {{ formatDay(vacancy.date, "weekday") }}
                    </small>
                  </div>
                </td>
                <td>
                  <small>R$ {{ vacancy.additionalValue | toCurrency }}</small>
                </td>
                <td>
                  <small>R$ {{ vacancy.value | toCurrency }}</small>
                </td>
              </tr>
            </template>
          </Collapsible>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getDetailsBatch } from "@/services/payment/payment.http";
import Collapsible from "@/components/shared/Collapsible.vue";

export default {
  components: { Collapsible },
  data: () => ({
    freelaDetail: [],
    status: 0,
    creationDate: new Date(),
    totalValue: 0,
    quantityFreelas: 0,
    quantityShifts: 0,
    externalId: "",
    objBatchStatusColor: {
      0: "created",
      1: "canceled",
      2: "canceled",
      3: "paid",
    },
    objBatchStatus: {
      0: "Pendente",
      1: "Cancelado",
      2: "Cancelado por problemas",
      3: "Pago",
    },
  }),
  filters: {
    totalValue(val) {
      const awaitingPayment = val.filter((x) => x.paymentStatus !== 3);
      const value = awaitingPayment.reduce(
        (acc, currentValue) =>
          acc +
          (currentValue.payment || currentValue.value) +
          (currentValue.additionalValue || 0),
        0
      );
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
  mounted() {
    this.detailsBatch();
  },
  methods: {
    formatDay(day, option) {
      const options = {
        fullDate: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
        weekday: { weekday: "long" },
      };
      if (day) {
        return new Date(day.replace("Z", "")).toLocaleDateString(
          "pt-BR",
          options[option]
        );
      }
    },

    detailsBatch() {
      getDetailsBatch({ id: this.$route.params.batchId })
        .then((data) => data)
        .then((result) => {
          this.status = result.status;
          this.creationDate = result.creationDate;
          this.totalValue = result.totalValue;
          this.freelaDetail = {
            children: result.freelaDetail.map((group) => ({
              name: group.name,
              freelaId: group.id,
              hasStatus: false,
              hasBankData: true,
              children: group.vacancies.map((item) => ({
                ...item,
              })),
            })),
          };
          this.quantityFreelas = result.freelaDetail.length;
          this.externalId = result.externalId;
        });
    },
    onBackButtonClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.border-header {
  border-bottom: 2px solid $Ebony;
}
</style>
