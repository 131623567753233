export const storage = {
  has: (key) => localStorage.getItem(key),
  get: (key) => JSON.parse(localStorage.getItem(key)),
  set: (key, value) => (localStorage[key] = JSON.stringify(value)),
  clean: (key) => localStorage.removeItem(key),
};

export const lstorage = {
  methods: { ...storage },
};

export default lstorage;
