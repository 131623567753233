<template>
  <CustomCard class="radius-10" :title="title" theme="ebony-malibu">
    <div class="d-flex direction-row">
      <div class="left-side">
        <div class="d-flex jc-space-between p-3 ml-10 mt-10 mb-0 mr-0">
          <span>
            <small class="text-malibu">{{ eventLocation.locationName }}</small>
          </span>
        </div>
        <div class="d-flex jc-space-between p-3 ml-10 mt-10 mb-0 mr-0">
          <span>
            <span v-if="eventLocation.isHomeOffice">Sem endereço</span>
            <small v-else>{{ eventLocation.address }}</small>
          </span>
        </div>
      </div>

      <div
        v-if="!eventLocation.isHomeOffice"
        class="right-side d-flex ai-center">
        <GoogleMapsPreview :location="mapStatic" />
      </div>
    </div>
  </CustomCard>
</template>

<script>
import GoogleMapsPreview from "@/components/events/Google/GoogleMapsPreview.vue";
import { mapGetters } from "vuex";

export default {
  components: { GoogleMapsPreview },
  methods: {
    mapLocation(e) {
      this.$store.dispatch("NewEvent/getMapLocation", e);
    },
  },
  computed: mapGetters({
    mapStatic: "NewEvent/mapLocation",
  }),
  name: "LocationInfo",
  props: {
    title: {
      type: String,
      default: "Localização",
    },
    eventLocation: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    eventLocation: {
      deep: true,
      immediate: false,
      handler: function (newValue) {
        this.mapLocation(newValue.address);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.left-side {
  flex: 0 1 100%;
}
.right-side {
  padding: 10px;
}
</style>
