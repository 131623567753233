import axios from "axios";

const URL_HIRER = "/hirers";

const getInfoBasicHirer = (data) =>
  axios.get(URL_HIRER, data).then(({ data }) => data.result);

const updateInfoBasiHirer = (data) =>
  axios.put(URL_HIRER, data).then(({ data }) => data.result);

export { getInfoBasicHirer, updateInfoBasiHirer };
