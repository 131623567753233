<template>
  <div class="container">
    <div
      @click="enterCode = !enterCode"
      v-if="enterCode"
      class="d-flex cursor-pointer pt-30 arrow-back mb-10">
      <i class="mdi mdi-chevron-left font-size-3"></i>
    </div>
    <div class="row">
      <div class="content-qr-code">
        <div class="title w-100 pl-30 pt-10 pb-5">
          <h5 class="text-start">
            Aponte o QR CODE para a câmera para validar a entrada ou saída do
            profissional
          </h5>
        </div>
        <div v-if="enterCode" class="w-100 header-enter-code">
          <h3 class="m-0">
            Digite o código para validar a entrada ou saída do profissional.
          </h3>
          <small class="font-size-08">O numero esta ao lado do QR code.</small>
        </div>
        <div
          v-if="enterCode"
          class="d-flex w-100 ai-center direction-column mb-10 mt-5">
          <img class="img-ticket mb-30" :src="require('@/assets/ticket.png')" />
          <custom-input class="w-90" v-model="code" />
          <SpinnerButton
            @click="registerCheckinAndCheckoutByCode"
            :loading="loadingCheckin"
            :disabled="!code"
            class="btn primary w-90 mt-15">
            Validar entrada ou saída
          </SpinnerButton>
        </div>
        <div class="wrapper" v-if="!enterCode">
          <qrcode-stream
            @decode="onDecode"
            @init="onInit"
            :track="paintBoundingBox"
            :camera="camera">
            <div class="loading-indicator" v-if="loading">
              <Loading class="p-50" label="Carregando" />
            </div>

            <div v-if="validationCheckin" class="validation-pending">
              <Loading class="p-50" label="Validação em processo" />
            </div>

            <div
              v-if="!loading"
              class="qrCode-container w-100 pl-20 pr-20 mt-15">
              <div class="title-qrcode">
                <h5 class="text-start">
                  Aponte a câmera do celular para o QR CODE
                </h5>
              </div>
            </div>
            <div v-if="!loading" class="typeCodeButton">
              <button @click="enterCode = !enterCode" class="btn primary full">
                Digitar código
              </button>
              <button @click="switchCamera" class="button-rotate p-16 ml-20">
                <img
                  :src="require('@/assets/camera-rotate.png')"
                  :height="30"
                  :width="30" />
              </button>
            </div>
          </qrcode-stream>
        </div>
        <div
          class="Input-type-code d-flex w-90 mt-25 mb-25 ai-center jc-space-between">
          <custom-input
            v-model="code"
            class="input"
            placeholder="Ou digite o código" />
          <SpinnerButton
            @click="registerCheckinAndCheckoutByCode"
            :loading="loadingCheckin"
            :disabled="!code"
            class="btn primary button mt-15">
            Validar entrada ou saída
          </SpinnerButton>
        </div>
        <div class="card">
          <div class="card-item d-flex direction-column">
            <span class="pb-5">Entrada ou saída para:</span>
            <span class="text-malibu font-size-1-3">
              {{ this.operationService.eventName }}
            </span>
          </div>
          <div class="date">{{ this.operationService.day | date }}</div>
        </div>

        <div class="logo">
          <img :height="90" :width="90" :src="require('@/assets/logo.svg')" />
        </div>
      </div>

      <div class="content-img d-flex ai-center jc-center direction-column">
        <img :src="require('@/assets/qr-code.png')" />
        <span>Está na hora! Realize sua entrada ou saída aqui</span>
      </div>
    </div>

    <ForbiddenUser />
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapActions, mapGetters } from "vuex";
import ForbiddenUser from "@/components/events/EventInfo/EventServices/Modal/ForbiddenUser.vue";
import {
  checkin,
  checkout,
  checkinAndCheckoutByCode,
  statusOperation,
} from "@/services/operation/operation.http";

export default {
  components: { QrcodeStream, ForbiddenUser },
  data: () => ({
    enterCode: false,
    loading: false,
    validationCheckin: false,
    loadingCheckin: false,
    code: "",
    camera: "auto",
    noCamera: false,
    date: new Date().toISOString(),
  }),
  mounted() {
    this.getOperationServices(this.$route.params.id);
  },
  filters: {
    date(e) {
      const dateSplit = e?.split("T")[0];

      return dateSplit?.split("-")?.reverse()?.join("/");
    },
  },
  watch: {
    userUnauthorized(value) {
      value && this.$eventhub.$emit("modal:open:forbidden-user");
    },
  },
  computed: {
    ...mapGetters({
      operationService: "Dashboard/operationService",
      userUnauthorized: "Dashboard/userUnauthorized",
    }),
  },
  methods: {
    ...mapActions({
      getOperationServices: "Dashboard/getOperationServices",
    }),

    _validationCheckin() {
      this.validationCheckin = !this.validationCheckin;
    },

    registerCheckinAndCheckoutByCode() {
      this.loadingCheckin = true;
      const id = this.$route.params.id;
      const eventId = this.operationService.eventId;
      this._validationCheckin();
      checkinAndCheckoutByCode({ code: this.code, eventId, id })
        .then(() => {
          this.$eventhub.$emit("feedback:show", {
            type: "success",
            msg: "Entrada/Saída validada com sucesso",
          });
        })
        .catch(({ response }) => {
          const error = response?.data?.errorMessage;
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: error,
          });
        })
        .finally(() => {
          this._validationCheckin();
          this.code = "";
          this.loadingCheckin = false;
        });
    },

    onDecode(result) {
      const id = this.$route.params.id;
      const payload = JSON.parse(result);
      this._validationCheckin();

      statusOperation({ id, freelaId: payload.freelaId, isHomeOffice: true })
        .then((res) => {
          res > 3
            ? this.registerCheckout({ ...payload, id })
            : this.registerCheckin({ ...payload, id });
        })
        .catch(({ response }) => {
          this._validationCheckin();
          const error = response?.data?.errorMessage;
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: error,
          });
        });
    },

    registerCheckin({ id, eventId, vacancyId, freelaId, job }) {
      checkin({ id, eventId, vacancyId, freelaId, job })
        .then(() => {
          this.$eventhub.$emit("feedback:show", {
            type: "success",
            msg: "Entrada validada com sucesso",
          });
        })
        .catch(({ response }) => {
          const error = response?.data?.errorMessage;
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: error,
          });
        })
        .finally(() => this._validationCheckin());
    },

    registerCheckout({ id, eventId, vacancyId, freelaId, job }) {
      checkout({ id, eventId, vacancyId, freelaId, job })
        .then(() => {
          this.$eventhub.$emit("feedback:show", {
            type: "success",
            msg: "Saída validada com sucesso",
          });
        })
        .catch(({ response }) => {
          const error = response?.data?.errorMessage;
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: error,
          });
        })
        .finally(() => this._validationCheckin());
    },

    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },

    async onInit(promise) {
      this.loading = true;
      try {
        await promise;
      } catch (error) {
        const errors = {
          NotAllowedError: "Você precisa de permissão para acessar a câmera",
          NotFoundError: "Nenhuma câmera neste dispositivo",
          NotSupportedError: "Contexto seguro necessário (HTTPS, localhost)",
          NotReadableError: "A câmera já está em uso?",
          OverconstrainedError: "Câmeras instaladas não são adequadas",
          StreamApiNotSupportedError:
            "A API de fluxo não é compatível com este navegador",
          InsecureContextError:
            "O acesso à câmera só é permitido em contexto seguro. Use HTTPS ou localhost em vez de HTTP",
        };
        const cameraMissingError = error.name === "OverconstrainedError";

        if (cameraMissingError && !this.noCamera) {
          this.camera = "auto";
          this.noCamera = true;
        }

        this.$eventhub.$emit("feedback:show", {
          type: "error",
          msg: errors[error.name] || `ERROR: Camera error (${error.name})`,
        });
      } finally {
        this.loading = false;
      }
    },

    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "auto":
          this.camera = "front";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

@media (max-width: 575px) {
  .header-enter-code {
    padding: 1.5em 2em;
    h3 {
      font-size: 1.1em;
      margin-bottom: 0.5em;
    }
  }
  .arrow-back {
    padding-left: 1em;
  }
}

@media (min-width: 575px) {
  .header-enter-code {
    padding: 3em;
    h3 {
      font-size: 1.2em;
      margin-bottom: 0.5em;
    }
  }
  .arrow-back {
    padding-left: 1em;
  }
}

@media (max-width: 991px) {
  .content-img,
  .title {
    display: none;
  }

  .content-qr-code {
    width: 100%;
    height: 100%;
  }
  .wrapper {
    width: 100%;
    height: 70%;
  }
  .card {
    margin: 5% 0;
  }
  .typeCodeButton {
    display: flex;
  }

  .Input-type-code {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .content-img {
    & img {
      object-fit: contain;
      height: 15em;
      width: 20em;
    }
    & span {
      font-size: 1.3em;
      margin-top: 1em;
    }
  }

  .wrapper {
    width: 90%;
    height: 40%;
  }
  .input {
    width: 18em;
  }
  .button {
    width: 12em;
  }
  .content-qr-code {
    width: 50%;
    height: 100%;
    .logo {
      position: absolute;
      bottom: 2%;
    }
  }
  .qrCode-container {
    display: none;
  }

  .typeCodeButton {
    display: none;
  }
}
@media (min-width: 1200px) {
  .content-img {
    & img {
      object-fit: contain;
      height: 20em;
      width: 25em;
    }
    & span {
      font-size: 1.4em;
      margin-top: 1em;
    }
  }
  .wrapper {
    width: 90%;
    height: 40%;
  }
  .input {
    width: 25em;
  }
  .button {
    width: 12em;
  }
  .content-qr-code {
    width: 50%;
    height: 100%;
    .logo {
      position: absolute;
      bottom: 2%;
    }
  }
  .qrCode-container {
    display: none;
  }
  .typeCodeButton {
    display: none;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  background-color: $Minsk;
  height: 100%;
}

.row {
  display: flex;
  width: 100%;
  height: 100%;
}

.content-img {
  width: 50%;
  background-color: $Ebony;
}

.content-qr-code {
  background-color: $Minsk;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 576px) and (orientation: landscape) {
    height: 100vw;
  }
}

.card {
  background-color: $Ebony;
  width: 90%;
  max-height: 10em;
  border-radius: 5px;
  & .card-item {
    padding: 20px;
    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.5);
  }

  & .date {
    padding: 20px;
  }
}

.input {
  margin: 0;
}

.title-qrcode {
  background: $Minsk;
  border-radius: 5px;
  padding: 1px;
  display: flex;
  justify-content: center;
}

.button-rotate {
  background-color: $SteelGray;
}

.typeCodeButton {
  bottom: 0px;
  position: absolute;
  width: 100%;
  justify-content: space-around;
  background-color: rgba($SteelGray, $alpha: 0.5);
  padding: 1rem;
}

.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(25, 24, 24, 0.9);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.img-ticket {
  height: 65%;
  width: 65%;
  object-fit: contain;
}
</style>
