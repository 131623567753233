<template>
  <section>
    <EventSectionFields />
    <Divider class="mv-30" />

    <LocaleSectionFields />
    <Divider class="mv-30" />

    <DatesSectionFields :class="{ 'disabled-container': isEdit }" />

    <HirerSectionFields v-if="isAgency && !isEdit" />
    <ContactSectionFields v-else-if="!isEdit" />
  </section>
</template>

<script>
import EventSectionFields from "./EventSectionFields.vue";
import LocaleSectionFields from "./LocaleSectionFields.vue";
import ContactSectionFields from "./ContactSectionFields.vue";
import DatesSectionFields from "./DatesSectionFields.vue";
import HirerSectionFields from "./HirerSectionFields.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    EventSectionFields,
    LocaleSectionFields,
    ContactSectionFields,
    DatesSectionFields,
    HirerSectionFields,
  },
  computed: mapGetters({
    isEdit: "EditEvent/isEdit",
    isAgency: "User/isAgency",
  }),
};
</script>
