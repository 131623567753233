<template>
  <section>
    <header class="d-flex">
      <h1>
        Termos e condições de uso da plataforma
        <span class="txt-color">Lanup</span>
      </h1>
      <div>
        <p>
          Os termos e condições de uso a seguir regem seu acesso à plataforma.
          Por favor, leia com atenção e entre em contato conosco se tiver alguma
          dúvida. Ao Acessar a Lanup você concorda em cumprir estes termos,
          nossa Política de Privacidade, nossa Política de Cookies e nossas
          Diretrizes da
        </p>
      </div>
    </header>
    <article>
      <div>
        <h3>Política de privacidade</h3>
        <div>
          <p>
            A LAN UP LTDA, em atenção a necessidade de proteção da sua
            privacidade e em respeito a proteção de seus dados. No cumprimento
            da legislação relativa a proteção de dados apresenta-se como uma
            prioridade, o que se reafirma com a presente política da
            privacidade.
          </p>
          <p>
            A LAN UP LTDA realiza todas as medidas necessárias para assegurar a
            integral proteção de dados pessoais de seus colaboradores e que
            sejam tratados de forma segura e de acordo com a legislação
            pertinente.
          </p>
          <p>
            A LAN UP LTDA, oferece para os seus colaboradores serviços, além de
            informações e ofertas. O cadastramento do usuário/colaborador é
            feito através de formulários de website ou inseridos dentro do APP.
            Ao realizar o preenchimento de cadastro e fornecer informações
            cadastrais e preferencias nestes formulários, incluindo dados
            pessoais e, consentindo com o uso destas informações pela LAN UP
            LTDA através de campo check-box, você autoriza a utilização de seus
            dados para a finalidade descrita nesses consentimentos e em
            conformidade com esta Política de Privacidade.
          </p>
          <p>
            Caso você não concorde com os termos deste instrumento, não prossiga
            com o aceite do check-box de consentimento. Saiba também que, a
            qualquer momento após fornecer os dados ou consentido com seu uso,
            você poderá solicitar a retirada dos seus dados de nosso banco de
            dados, ou retirar o seu consentimento para utilização dos seus dados
            ressalvadas hipóteses de proteção legal .
          </p>
          <p>
            Todas as informações solicitadas e fornecidas serão tratadas como
            confidenciais e poderão ser utilizadas exclusivamente pela LAN UP
            LTDA e outras empresas do mesmo grupo econômico e parceiras,
            respeitando as disposições legais aplicáveis, com o objetivo
            principal de melhor adequar os nossos produtos e serviços ao perfil
            de seus usuários.
          </p>
          <p>
            Possuímos sistemas de segurança para proteção contra invasões de
            hackers, e outros, seguindo os melhores padrões de mercado e estará
            constantemente verificando os seus níveis de segurança. As
            informações poderão ser utilizadas para cumprimento de obrigações
            legais ou regulatórias, quando solicitadas pela administração
            pública ou em procedimento judicial/extrajudicial, quando necessário
            para atender aos interesses do titular dos dados ou para sua
            proteção pessoal.
          </p>
        </div>
      </div>

      <div>
        <h3>Informações coletadas</h3>
        <div>
          <p>
            Coletamos apenas informações necessários ao preenchimento do
            cadastro e execução dos serviços por meio de formulários ou e-mails.
            Além dos conteúdos das mensagens também coletamos as informações
            cadastrais as quais sempre possuem tratamento sigiloso dento de
            nossa plataforma.
          </p>
          <p>
            Ao fornecer informações cadastrais, você está autorizando a
            utilização de suas informações em conformidade com esta política de
            privacidade.
          </p>
          <p>
            A veracidade dos dados fornecidos é de vital importância e depende
            única e exclusivamente do preenchimento pelo usuário/colaborador,
            logo, você é o responsável pelas declarações prestadas e que vierem
            a causar prejuízos a si mesmo ou a terceiros.
          </p>
          <p>
            Durante sua visita em nosso site ou app, poderá haver a coleta de
            “cookies” informação sobre você. O “cookie” não permite
            identificá-lo pessoalmente, mas permite gravar informações relativas
            à navegação do seu computador em nosso site a fim de que possamos te
            proporcionar uma melhor experiência, bem como ofertar produtos e
            serviços que sejam do seu interesse.
          </p>
          <p>
            Você pode escolher aceitar ou não os cookies em suas configurações
            de navegador, nas preferências de cookies ou via check-box. Mas sem
            eles a funcionalidade dos serviços oferecidos pode ser afetada.
          </p>
          <p>Para mais informações verifique nossa política de cookies.</p>
          <p>
            As informações que forem coletadas serão tratadas como confidenciais
            e poderão ser utilizadas pela LAN UP LTDA e compartilhadas
            livremente com outras empresas do mesmo grupo econômico ou com
            parceiros, respeitando as disposições legais aplicáveis, com o
            objetivo principal de melhor adequar os nossos produtos e serviços
            ao perfil de seus usuários.
          </p>
          <p>
            A LAN UP LTDA informa que apenas compartilha informações pessoais
            coletadas única e exclusivamente para o fim da prestação de
            serviços, não havendo comunicação ou compartilhamento de informações
            para fins alheios aos serviços realizados, apenas possuímos a
            finalidade de ofertar-lhe produtos e serviços com mais
            assertividade, bem como de atender suas solicitações e necessidades.
          </p>
          <p>
            Desta forma, buscando maior transparência entre a LAN UP LTDA e seus
            usuários.
          </p>
        </div>
      </div>

      <div>
        <h3>Utilização dos dados coletados</h3>
        <div>
          <p>
            As informações coletadas são indispensáveis para fornecer os
            serviços propostos que de forma simplificada, são fornecidas
            previamente pelo usuário/colaborador
          </p>
          <p>
            As informações são indispensáveis para a execução dos serviços e
            também para que possamos cumprir com as nossas obrigações legais.
            Logo, caso não concorde em compartilhar as suas informações e dados
            conosco, não será possível executar os serviços.
          </p>
          <p>
            Poderá ainda haver o envio de informações pela LAN UP LTDA sobre
            seus produtos e serviços. Caso você não queira autorizar o envio de
            e-mail para seu endereço eletrônico com essas informações, deverá
            responder negativamente à pergunta formulada ou ainda cancelar a
            inscrição no recebimento de e-mails promocionais posteriormente.
          </p>
          <p>
            A informações poderão ser utilizadas ainda para contato sobre
            pesquisas de satisfação para verificarmos a qualidade do atendimento
            e serviços prestados.
          </p>
          <p>
            As informações compartilhadas por você, em especial aquelas
            referentes ao cadastro e perfil de usuário, serão utilizadas para
            oferecer produtos e serviços adaptados às suas preferências por meio
            das comunicações de marketing, e somente serão utilizadas para essa
            finalidade mediante seu consentimento prévio.
          </p>
        </div>
      </div>

      <div>
        <h3>Revogação de concentimento</h3>
        <div>
          <p>
            O consentimento fornecido por você pode ser revogado, a qualquer
            momento, mediante solicitação via nossos canais de comunicação.
          </p>
          <p>
            O titular poderá retirar os consentimentos prestados a qualquer
            momento, sem que, no entanto, torne ilegítimo o tratamento de dados
            já realizado com base nesse consentimento até a data em que os
            mesmos sejam retirados.
          </p>
          <p>
            No caso do newsletter, o titular poderá cancelar sua inscrição a
            qualquer momento, bastando clicar no local indicado no fim do
            e-mail.
          </p>
          <p>
            Os dados serão mantidos pelo tempo necessário para cumprimento da
            finalidade para o qual foi coletado, conforme determina a legislação
            aplicável.
          </p>
          <p>
            Para determinar o prazo de conservação dos seus dados pessoais
            utilizamos os seguintes critérios:
          </p>
          <li>O tempo necessário para responder à sua solicitação;</li>
          <li>
            O período de tempo em que a sua conta de usuário estiver ativa;
          </li>
          <li>As nossas obrigações legais;</li>
        </div>
      </div>

      <div>
        <h3>Armazemamento e utilização</h3>
        <div>
          <p>
            Poderá ainda haver compartilhamento de dados com prestadores ou
            parceiros da Lan UP, a fim de ofertar-lhe produtos e serviços que
            sejam de seu interesse, mediante autorização prévia.
          </p>
          <p>
            O armazenamento de dados poderá ocorrer tanto em servidores
            localizados no Brasil como no exterior, a depender da gestora de
            armazenamento em nuvem responsável pela guarda dos respectivos
            dados.
          </p>
          <p>
            A LAN UP LTDA somente disponibilizará os dados às Autoridades
            Públicas competentes mediante requisição formal nos moldes da
            legislação aplicável, e serão apresentados apenas e tão somente os
            dados solicitados. Não efetuamos essa entrega de forma excessiva, ou
            quando não estiver em conformidade com as determinações legais.
          </p>
          <p>
            Todos os direitos relativos ao tratamento de seus dados pessoais
            previstos na legislação, serão criteriosamente obedecidos
            principalmente mas não se limitando aos seguintes:
          </p>
          <li>
            Solicitar a confirmação da existência de tratamento e o acesso aos
            dados que a LAN UP LTDA possui sobre você;
          </li>
          <li>
            Solicitar a retificação ou atualização dos dados pessoais inexatos
            ou desatualizados;
          </li>
          <li>
            Solicitar a exclusão de suas informações pessoais existentes em
            nosso banco de dados e dos nossos parceiros, exceto nas hipóteses em
            que esse direito possa ser limitado conforme determina a lei;
          </li>
          <li>
            Retirar, a qualquer momento, o seu consentimento, para os fins para
            os quais este foi obtido.
          </li>
          <p>
            Caso deseje exercer algum dos seus direitos em relação às suas
            informações pessoais ou caso haja alguma dúvida sobre como usamos
            suas informações pessoais, entre em contato conosco por meio de
            nossos canais de comunicação.
          </p>
        </div>
      </div>

      <div>
        <h3>Atualização da política de privacidade</h3>
        <div>
          <p>
            A presente política de privacidade está sujeita a alterações
            regulares para garantir que esteja atualizada com o uso de suas
            informações pessoais e em conformidade com as leis de proteção de
            dados aplicáveis.
          </p>
          <p>
            Reservamo-nos o direito de revisar esta política de privacidade, a
            qualquer tempo, seja pela utilização de novas tecnologias ou sempre
            que for necessário. A política de privacidade atualizada será
            publicada e imediatamente aplica, motivo pelos qual recomendamos
            periodicamente verificá-la, bem como enviaremos comunicação de
            confirmação via check-box.
          </p>
        </div>
      </div>

      <div>
        <h3>Da confidencialidade e proteção de dados</h3>
        <div>
          <p>
            Para aplicação da legislação sobre proteção e tratamento de dados
            descrita pela Lei 13.709/2018 – Lei Geral de Proteção de Dados
            Pessoais, e para os fins específicos deste termo de uso,
            considera-se:
          </p>
          <span>
            <p>
              (i) “Titular” os Usuários ou qualquer pessoa natural cujos dados
              pessoais estejam sujeitos a tratamento nos termos legais.
            </p>
            <p>
              (ii) “Controlador” qualquer pessoa natural ou jurídica, de direito
              público ou privado, a quem competem as decisões referentes ao
              tratamento de dados pessoais.
            </p>
            <p>
              (iii)“Dados Pessoais” qualquer informação relacionada a pessoa
              natural identificada ou identificável.
            </p>
            <p>
              (iv)“Operador” qualquer pessoa natural ou jurídica, de direito
              público ou privado, que realiza o tratamento de dados pessoais em
              nome do Controlador.
            </p>
            <p>
              (v) “LGPD” a Lei Geral de Proteção de Dados, Lei Federal nº
              13.709, de 14 de agosto de 2018.
            </p>
          </span>
          <p>
            Para efeitos deste Termo, todas as definições relacionadas aos Dados
            Pessoais doravante mencionadas deverão ser expressamente referidas e
            interpretadas em conformidade com a LGPD.
          </p>
          <p>
            As Partes reconhecem que Dados Pessoais, podem ser reciprocamente
            coletados, em decorrência da execução do objeto dos serviços, e
            concordam que tais dados sejam tratados estritamente para garantir a
            execução destes serviços ou para atender obrigações exigidas pelas
            disposições legais de proteção de dados aplicáveis. Os Dados
            Pessoais serão tratados de forma automática ou de forma manual e
            serão armazenados durante a vigência deste Termo e após o seu
            término, por um período não superior aos prazos definidos na
            legislação aplicável.
          </p>
          <p>Fica acordado que:</p>
          <span>
            <p>
              (i) a obtenção de todos os Dados Pessoais necessários para a
              finalidade relacionada a execução dos serviços e deste Termo é um
              pré-requisito essencial para a existência do próprio Termo;
            </p>
            <p>
              (ii) os Dados Pessoais coletados e tratados não deverão ser
              revelados a terceiros que não estejam expressamente permitidos
              pela legislação aplicável e os expressamente indicados neste
              termo. Os Dados Pessoais, obtidos ou tratados poderão, ainda, ser
              comunicados a terceiras indicadas pelo Controlador como Operador;
            </p>
            <p>
              (iii) o Controlador é a própria Lan UP, nas pessoas por ela
              indicadas.
            </p>
          </span>
          <p>
            A contar da ciência e aceite a este Termo e até o seu término,
            haverá indicação de um preposto, como Operador, nos termos do art.
            5.º, inciso VII da LGPD.
          </p>
          <p>
            O Operador garante que executará as operações mencionadas, em
            conformidade com as obrigações impostas pelas leis mencionadas, bem
            como seguirá as instruções emitidas pelo Controlador.
          </p>
          <p>São obrigações do Operador:</p>
          <span>
            <p>
              (i) Tratar Dados Pessoais somente sob às instruções escritas
              expressas do Controlador, especificando o tipo e as categorias dos
              dados;
            </p>
            <p>
              (ii) Assegurar que as pessoas autorizadas a tratar Dados Pessoais
              garantirão a manutenção da confidencialidade das informações e
              dados obtidos em razão ou em conexão com a execução do Contrato e
              não disseminarão ou compartilharão tais informações e dados com
              terceiros exceto os expressamente autorizados a fazê-lo e os casos
              expressamente autorizados por Lei;
            </p>
            <p>
              (iii) Garantir que as pessoas autorizadas tratem os dados em
              conformidade com os requerimentos legais relevantes e com toda e
              qualquer instrução fornecida pelo Controlador. O Controlador
              reserva-se o direito de requerer ao Operador a lista de pessoas
              autorizadas a tratar os dados;
            </p>
            <p>
              (iv) Tomar todas as medidas de segurança relacionadas no art. 6.º,
              inciso VII da LGPD, assim como quaisquer outras medidas
              preventivas que, baseadas na experiência, possam evitar o
              tratamento dos dados sem consentimento ou sem atender quaisquer
              outros requisitos legais estabelecidos na LGPD ou, ainda,
              contrário à finalidade para a qual os dados foram tratados.
              Deverá, ainda, cooperar na implantação das medidas mencionadas
              nesta alínea, notificando e divulgando qualquer violação a Dados
              Pessoais bem como avaliando o impacto na proteção de dados, assim
              como garantindo a confidencialidade e a segurança dos dados,
              minimizando os riscos de perda acidental ou destruição do dado;
            </p>
            <p>
              (v) Não envolver qualquer outro Operador sem a autorização prévia
              e escrita do Controlador;
            </p>
            <p>
              (vi) Fornecer ao Controlador uma lista de locais, atualizado
              anualmente, onde os Dados Pessoais, objeto do Contrato serão
              mantidos;
            </p>
            <p>
              (vii) Não reter ou transferir dados a um país estrangeiro ou
              organização internacional sem a autorização prévia do Controlador,
              exceto se requerido pela legislação. Neste caso, o Operador deverá
              notificar o Controlador sobre a exigência legal, exceto quando a
              própria lei proíba tal notificação em razão de questões de
              interesse público relevante;
            </p>
            <p>
              (viii) Assessorar o Controlador na implantação de medidas técnicas
              e organizacionais adequadas, na medida em que isso seja possível,
              para cumprir as obrigações do Controlador em prestar
              esclarecimentos e responder solicitações recebidas dos titulares
              dos dados ao exercerem seus direitos;
            </p>
            <p>
              (ix) Auxiliar o Controlador a assegurar a conformidade com as
              obrigações previstas nos Artigos 46 a 49 da LGPD, levando em
              consideração a natureza do tratamento e a informação disponível ao
              Operador;
            </p>
            <p>
              (x) Manter um arquivo das atividades de tratamento realizadas em
              benefício do Controlador;/p>
            </p>

            <p>
              (xi) Assim que solicitado pelo Controlador, descartar ou retornar
              todos os Dados Pessoais após o período de prestação de serviços
              relacionados ao tratamento, descartando as cópias existentes,
              exceto nos casos em que, por exigência legal, exija a manutenção
              (arquivo) dos Dados Pessoais;
            </p>
            <p>
              (xii) Auxiliar o Controlador nas obrigações de consultas sob a
              LGPD. O Operador deverá notificar o Controlador a respeito de
              eventual vazamento de dados, tempestivamente e, no máximo, em 24
              (vinte e quatro) horas a contar do conhecimento do evento;
            </p>
            <p>
              (xiii) Garantir aos titulares de dados disponibilizados pelo
              Controlador, quando coletados durante a execução deste Contrato, o
              exercício dos direitos estabelecidos nos artigos 17 a 21 da LGPD,
              sem prejuízo da garantia de outros direitos estabelecidos
              legislação.
            </p>
            <p>
              (xiv) Notificar ao Controlador em até 24 (vinte e quatro) horas
              contadas do recebimento da solicitação dos titulares dos dados
              quanto ao exercício dos direitos estabelecidos nos artigos 17 a 21
              da LGPD;
            </p>
            <p>
              (xv) Notificar ao Controlador, em até 24 (vinte e quatro) horas
              contadas do evento, acerca de qualquer vazamento ou
              comprometimento de suas bases de dados relacionadas com o presente
              Contrato, bem como acerca de qualquer violação da legislação de
              privacidade e de proteção de Dados Pessoais que tiver ciência com
              relação aos dados em sua custódia, inclusive violação acidental ou
              culposa A notificação deverá obrigatoriamente conter, no mínimo,
              as seguintes informações:
            </p>
            <span>
              <p>
                a. Descrição da natureza da violação dos Dados Pessoais,
                incluindo, quando possível, as categorias e o número aproximado
                de titulares dos dados em questão, bem como as categorias e o
                número aproximado de arquivos de Dados Pessoais relacionados;
              </p>
              <p>
                b. Comunicação do nome do responsável que possa fornecer maiores
                informações sobre o incidente;
              </p>
              <p>
                c. Descrição das prováveis consequências da violação dos Dados
                Pessoais;
              </p>
              <p>
                d. Descrição das medidas tomadas ou propostas a serem tomadas
                pelo Controlador para tratar de violações de Dados Pessoais,
                incluindo, quando for apropriado, medidas para mitigar possíveis
                efeitos adversos;
              </p>
            </span>
          </span>
          <p>
            A indicação do Operador será automaticamente revogada ao término da
            vigência ou em qualquer outra hipótese de encerramento, independente
            da causa. Em todos os casos, as obrigações de proteção dos dados que
            foram tratados durante a vigência deste Termo permanecerão em vigor,
            respondendo o Operador em todos os casos de uso inadequado dos dados
            ou vazamento dos mesmos, nos termos do art. 22 da LGPD.
          </p>
          <p>
            A despeito das previsões legais, se o Operador tiver a intenção de
            contratar terceiros para desempenhar atividades específicas de
            tratamento no âmbito do Contrato, estes também serão tratados como
            Operadores, para os fins legais.
          </p>
          <p>
            As Partes acordam que qualquer dano material ou imaterial resultante
            da violação das normas de proteção de Dados Pessoais de Titulares
            será indenizado mediante apuração de conduta dolosa, sendo
            responsabilidade do Operador qualquer dano causado pelo tratamento
            de dados em violação ao estabelecido nestes Termos resguardado ainda
            o direito de regresso previsto no art. 42, § 4º da LGPD.
          </p>
          <p>
            Estes termos constituem o acordo e entendimento das partes na sua
            totalidade,
          </p>
          <p>
            substituindo e prevalecendo sobre todos os entendimentos e
            compromissos
          </p>
          <p>anteriores, se houverem.</p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

* {
  font-size: 10px;
}

header {
  margin: 0 auto;
  align-items: center;
  background-color: $Minsk;
}

header h1 {
  margin: 0;
  color: white;
}

header h1,
header div,
article > div > div > p,
article > div > div,
h3 {
  flex: 1;
}

.txt-color {
  color: $PictonBlue;
  font-weight: 500;
}

article > div {
  display: flex;
}

article > div > div > p,
h3,
li {
  color: black;
}

article {
  margin: 0 auto;
  box-shadow: 0px 3px 6px rgba(black, 0.2);
}

article,
section {
  background-color: white;
}

span p {
  color: black;
  padding-left: 30px;
}

span > span p {
  padding-left: 60px;
}

@media (min-width: 1140px) {
  header {
    padding: 0 80px;
    width: 70%;
    height: 300px;
  }

  h1,
  h1 span {
    font-size: 30px;
    line-height: 36px;
  }

  header div p {
    font-size: 12px;
  }

  article {
    padding: 2px 80px;
    width: 70%;
  }

  article div h3 {
    font-size: 16px;
    line-height: 20px;
  }

  article div p,
  li {
    font-size: 12px;
  }
}
@media (max-width: 960px), (max-width: 1140px) {
  header {
    padding: 0 30px;
    width: 70%;
    height: 300px;
  }

  h1,
  h1 span {
    font-size: 24px;
    line-height: 26px;
  }

  header div p {
    font-size: 12px;
  }

  article {
    padding: 2px 30px;
    width: 70%;
  }

  article div h3 {
    font-size: 16px;
    line-height: 20px;
  }

  article div p,
  li {
    font-size: 12px;
  }
}
@media (max-width: 720px) {
  header {
    padding: 0 30px;
    display: block;
    width: 82%;
    height: 200px;
  }

  h1 {
    padding: 10px 0;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  h1 span {
    font-size: 16px;
  }

  header div p {
    font-size: 10px;
    padding-bottom: 10px;
  }

  article {
    padding: 2px 30px;
    width: 82%;
  }

  article div {
    display: block;
  }

  article div h3 {
    font-size: 14px;
    line-height: 14px;
  }
}
@media (max-width: 326px), (max-width: 540px) {
  header {
    padding: 0;
  }

  header {
    padding: 0 5px;
    display: block;
    width: 82%;
    height: 200px;
  }

  h1 {
    padding: 10px 0;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }

  h1 span {
    font-size: 12px;
  }

  header div p {
    font-size: 7px;
    padding-bottom: 10px;
  }

  article {
    padding: 2px 5px;
    width: 82%;
  }

  article div {
    display: block;
  }

  article div h3 {
    font-size: 10px;
    line-height: 14px;
  }
}
</style>
