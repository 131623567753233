<template>
  <div class="p-20">
    <FormCard class="forgot-password p-30">
      <template v-slot:title>Esqueceu sua senha?</template>
      <template v-slot:form>
        <div class="d-flex direction-column jc-center form-wrap">
          <form action="?" @submit="onSubmit(email)">
            <br />
            <CustomInput
              placeholder="Digite o e-mail cadastrado:"
              :label="'seu@email.com'"
              v-model="email" />
            <br />
            <SpinnerButton
              :loading="loading"
              class="btn primary m-10"
              @click="onSubmit(email)">
              Enviar
            </SpinnerButton>
            <br />
          </form>
          <small class="footer m-10">
            <router-link :to="{ name: 'access.login' }">
              Fazer Login
            </router-link>
            <router-link :to="{ name: 'access.new-account' }">
              Cadastrar na LanUP
            </router-link>
          </small>
        </div>
      </template>
    </FormCard>
  </div>
</template>

<script>
import FormCard from "./FormCard";
import { resetPassword } from "@/services/auth/auth.http";

export default {
  components: { FormCard },
  data: () => ({
    email: "",
    loading: false,
  }),
  methods: {
    onSubmit(email) {
      this.loading = true;
      const onSuccess = () =>
        this.notify("Pronto! Verifique sua caixa de entrada", "success");
      const onError = ({
        response: {
          data: { errorMessage },
        },
      }) => this.notify(errorMessage);

      resetPassword(email)
        .then(onSuccess)
        .catch(onError)
        .finally(() => (this.loading = false));
    },
    notify(msg, type = "error") {
      this.$eventhub.$emit("feedback:show", {
        type,
        msg,
      });
    },
  },
};
</script>

<style lang="scss">
.forgot-password {
  height: 70%;

  .form-wrap {
    min-width: 300px;
  }
  button {
    margin: 0 auto;
    min-width: 280px;
  }
}
</style>
