<template>
  <button @click.stop="openClose">
    <section
      class="contextMenuWrapper media"
      :class="{
        'contextMenuWrapper--noTitle': !menuTitle,
      }">
      <div class="m-10 d-flex ai-center radius-5 mr-10">
        <slot />
      </div>

      <section
        v-click-outside="hidePopUps"
        class="dropdownMenu cursor-default"
        :class="{ right, left, top, middle }"
        v-if="isOpen">
        <div class="list">
          <div
            class="menu-item option cursor-pointer"
            v-for="({ label, icon, disabled }, key) in options"
            :key="key"
            :class="{ 'disabled-container': disabled }"
            @click="onItemClick(options[key])">
            <small class="menu-item-text d-flex ai-center">
              <i v-if="icon" class="mdi mr-10" :class="icon"></i>
              {{ label }}
            </small>
          </div>
        </div>
      </section>
    </section>
  </button>
</template>

<script>
export default {
  props: [
    "icon",
    "menuTitle",
    "options",
    "value",
    "right",
    "left",
    "top",
    "middle",
  ],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    hidePopUps() {
      this.openClose();
    },
    openClose() {
      this.isOpen = !this.isOpen;
    },
    onItemClick(item) {
      this.$emit("itemClick", { ...item, value: this.value });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.contextMenuWrapper {
  width: auto;
  position: relative;
  border-radius: 8px;
  background: none;
  outline: none;
  border: none;
  * {
    box-sizing: border-box;
    text-align: left;
  }

  .list {
    overflow: auto;
    max-height: 300px;
    border-radius: 5px;

    &::-webkit-scrollbar-track {
      background: $Ebony;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: $EastBay;
      border-radius: 10px;
    }
  }
  .dropdownMenu {
    top: 0;
    right: 50px;
    position: absolute;
    width: max-content;
    min-width: 150px;
    min-height: 10px;
    border-radius: 5px;
    box-shadow: 10px 10px 0 0 rgba(black, 0.03);
    background: $Mirage;
    animation: menu 0.3s ease forwards;

    .option {
      width: 100%;
      border-bottom: 1px solid $Ebony;
      padding: 20px 0px;
      position: relative;
      z-index: 2;
      &:hover {
        background: lighten($Mirage, 5%);
      }

      &:last-child {
        border-bottom: 0;
      }
    }
    .desc {
      display: block;
      width: 100%;
      font-size: 14px;
      margin: 3px 0 0 0;
    }
  }
  @keyframes menu {
    from {
      transform: translate3d(0, 30px, 0);
    }
    to {
      transform: translate3d(0, 20px, 0);
    }
  }
}
.contextMenuWrapper--noTitle {
  padding: 0;
  width: 60px;
  height: 60px;
}

.menu-item-text {
  color: white;
  text-decoration: none;
  background: none;
  border: 0;
  padding: 0 20px;
  outline: none;
  font-size: 14px;
  font-family: "HelveticaNowMicro";
  text-align: start;
}

.left {
  right: 50px !important;
}

.right {
  left: 50px !important;
}

.top {
  top: -80px !important;
}

.middle {
  top: 0;
}
</style>
