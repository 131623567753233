<script>
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: reactiveProp,
  data: () => ({
    dashboardChart: {},
    options: {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 70,
      legend: {
        display: true,
        position: "right",
        fullWidth: false,
        align: "start",
        labels: {
          fontColor: "#FFFFFF",
          boxWidth: 20,
          usePointStyle: true,
        },
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function ({ index }, data) {
            return data.labels[index];
          },
        },
      },
    },
  }),
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
