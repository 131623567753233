<template>
  <section>
    <h3>Dados adicionais</h3>
    <div class="md-layout md-gutter">
      <CustomInput
        class="md-layout-item"
        :value="form.phone"
        @input="updateAdditionalPaymentData({ phone: $event })"
        :mask="['(##)#####-####', '(##)####-####']"
        placeholder="Telefone" />
      <CustomInput
        class="md-layout-item"
        :value="form.birthDate"
        @input="updateAdditionalPaymentData({ birthDate: $event })"
        :mask="'##/##/####'"
        placeholder="Data de Nascimento" />
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    form: {
      get() {
        return { ...this.$store.getters["Payment/additionalPaymentData"] };
      },
    },
  },
  methods: {
    updateAdditionalPaymentData(data) {
      this.$store.dispatch("Payment/setAdditionalPaymentData", { ...data });
    },
  },
};
</script>
