<template>
  <div class="input-field">
    <label :for="'input' + placeholder">
      <span>{{ placeholder }}</span>
      <span class="tip">{{ tip }}</span>
    </label>
    <md-field>
      <!-- <i class="mdi mdi-chevron-down position"></i> -->
      <select
        ref="select"
        :id="'input' + placeholder"
        @input="$emit('input', $event.target.value)"
        class="w-100"
        :class="disabled && 'disabled'">
        <slot></slot>
      </select>
    </md-field>
    <label :for="'input' + placeholder" class="pt-2">
      <span class="tip example">{{ example }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    tip: { default: "" },
    value: { default: "" },
    example: { default: "" },
    placeholder: { default: "" },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.input-field {
  display: flex;
  flex-direction: column;
  margin: 0px 5px 0px;

  label {
    margin: 0px 0px 2px;
    font-size: 12px;
    font-weight: 300;

    .tip {
      color: $Manatee;
      font-style: italic;
      font-weight: 200;
      margin-left: 15px;

      &.example {
        margin: 0px;
      }
    }
  }

  select {
    color: white;
    height: 50px;
    border: 2px groove white;
    outline: none;
    padding: 5px 10px;
    box-shadow: none;
    transition: border 0.25s;
    background: transparent;
    border-radius: 4px;

    ::after {
      content: "";
    }

    &:focus {
      border-color: $FuchsiaBlue;
    }

    option {
      background-color: $SteelGray;
    }
  }

  .position {
    align-items: center;
    background: $SteelGray;
    bottom: 7px;
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
    position: absolute;
    right: 2px;
    top: 7px;
    width: 40px;
  }

  .disabled {
    border-color: $DimGray;
    color: $DimGray;
    pointer-events: none;
  }
}
</style>
