<template>
  <section>
    <template v-if="state === 'empty'">
      <slot name="empty">
        <div class="d-flex jc-center ai-center w-100">
          <span>Está vazio aqui...</span>
        </div>
      </slot>
    </template>

    <template v-else-if="state === 'error'">
      <slot name="error">
        <div class="d-flex jc-center ai-center direction-column w-100">
          <span class="mb-10">Ops, alguma coisa aconteceu!</span>
        </div>
      </slot>
    </template>

    <template v-else-if="state === 'loading'">
      <slot name="loading">
        <div class="d-flex jc-center ai-center w-100">
          <Loading label="Carregando" />
        </div>
      </slot>
    </template>

    <template v-else>
      <slot></slot>
    </template>
  </section>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: "",
    },
  },
  data: () => ({}),
};
</script>
