import { getUser, saveToken, isAgency, isFreela } from "@/helpers/auth/";

const initializeState = (user) => ({
  user: {
    id: "",
    username: "",
    avatarUrl: "",
    email: "",
    userType: "",
    activeAccount: false,
    ...user,
  },
});

const state = initializeState(getUser());

const getters = {
  userId: (state) => state.user.id,
  username: (state) => state.user.username,
  avatarUrl: (state) => state.user.avatarUrl,
  isAgency: () => isAgency(),
  isFreela: () => isFreela(),
  activeAccount: (state) => state.user.activeAccount,
};

const mutations = {
  RESET_STATE: (state) => {
    state.user = initializeState(getUser()).user;
  },
};

const actions = {
  userLogged({ commit }, token) {
    saveToken(token);
    commit("RESET_STATE");
  },
  logout({ commit }) {
    localStorage.clear();
    commit("RESET_STATE");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
