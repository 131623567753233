<template>
  <StateHandler class="members d-flex h-100" :state="currentState">
    <template #default>
      <div class="d-flex direction-column jc-space-between w-100">
        <div
          class="d-flex direction-row-reverse jc-end ai-start ac-start w-100 d-wrap">
          <MemberCard
            class="ml-10 mr-10 mb-10"
            v-for="(member, key) in members"
            :key="key"
            :member="member" />
        </div>
        <div class="d-flex jc-center ai-center" v-if="!isLastPage">
          <SpinnerButton
            class="btn primary load-more"
            @click="onLoadMoreClick"
            :loading="loading">
            Carregar Mais
          </SpinnerButton>
        </div>
      </div>
    </template>
    <template #empty>
      <div class="d-flex direction-column jc-center ai-center w-100">
        <p>Nenhum Freela encontrado =(</p>
      </div>
    </template>
    <template #loading>
      <div class="d-flex jc-center ai-center w-100">
        <Loading label="Buscando seus Profissionais" />
      </div>
    </template>
  </StateHandler>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import MemberCard from "./MemberCard";

export default {
  components: { MemberCard },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      memberList: "MyFreelas/memberList",
      isLastPage: "MyFreelas/isLastPage",
      currentState: "MyFreelas/currentState",
      filterMatch: "MyFreelas/filterMatch",
      dataParameters: "MyFreelas/dataParameters",
    }),
    members() {
      return Object.values(this.memberList).reverse();
    },
  },
  methods: {
    ...mapActions({
      fetch: "MyFreelas/fetch",
    }),
    onLoadMoreClick() {
      this.loading = true;
      const setLoadingFalsy = () => (this.loading = false);

      this.fetch({ page: this.dataParameters.page + 1 }).finally(
        setLoadingFalsy
      );
    },
  },
};
</script>
