<template>
  <section v-if="activity" class="timeline-item d-flex f-100">
    <div class="line d-flex ai-center jc-center direction-column">
      <div class="line-bar"></div>
      <Avatar class="m-5" :src="activity.avatarUrl" v-if="showAvatar" />
      <Avatar
        class="m-5"
        :src="require(`@/assets/${activity.avatarUrl}`)"
        v-else />
      <div class="line-bar"></div>
    </div>
    <div class="info f-1 d-flex jc-space-between direction-column ml-10 mr-10">
      <div class="d-flex jc-space-between">
        <small class="text-uppercase pt-13">{{ dayAgo }}</small>
        <slot name="header" />
      </div>
      <p :class="activityType" v-html="activity.description"></p>
      <slot></slot>
      <hr class="w-100 radius-10" />
    </div>
  </section>
</template>

<script>
import { formatDate } from "@/helpers/dateFormat";

const ACTIVITY_TYPES = {
  CHECKIN: "checkin-checkout",
  ONTHEWAY: "onTheWay",
  ARRIVAL: "arrival",
  BREAK: "break-time",
  RETURNBREAK: "break-time",
  CHECKOUT: "checkin-checkout",
};

export default {
  props: {
    activity: {
      type: Object,
      default: () => ({
        avatarUrl: "",
        description: "",
        occurredAt: new Date(),
        type: "",
      }),
    },
    showAvatar: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    dayAgo() {
      const fromNow = formatDate(this.activity.occurredAt, "ago");
      return fromNow + (fromNow.includes("agora") ? "" : " atrás");
    },

    activityType() {
      return ACTIVITY_TYPES[this.activity.type];
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
.timeline-item {
  .line {
    min-width: 50px;

    .line-bar {
      background: $FuchsiaBlue;
      border: 1px solid $FuchsiaBlue;
      border-radius: 10px;
      height: 25px;

      &:last-of-type {
        margin-bottom: -1px;
        height: 100%;
      }
    }
  }

  .info {
    small {
      font-size: 0.55rem;
      font-weight: 300;
    }

    p {
      font-size: 0.6rem;
      // color: $Malibu;

      &.checkin-checkout {
        color: $PictonBlue;
      }

      &.onTheWay {
        color: $LightGreen;
      }

      &.arrival {
        color: $AzureRadiance;
      }

      &.break-time {
        color: $Orange;
      }
    }

    hr {
      border-width: 1px;
      border-style: solid;
    }
  }
}

.button-rating {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
</style>
