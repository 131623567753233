<template>
  <PageLayout class="home" :class="{ empty: emptySelection }">
    <template #header>
      <div class="d-flex ai-start w-100 mt-20">
        <div class="d-flex ai-center jc-space-between w-100">
          <h1 class="header-title w-60 ml-28">Minhas Demandas</h1>

          <CustomInput
            v-model="eventRef"
            label="Pesquisar demanda/OS"
            icon="mdi mdi-magnify font-size-1-3"
            class="d-flex w-100" />

          <TabMenu class="ml-20" @onIsEmpty="emptySelection = $event" />

          <div class="d-flex mr-28 p-8 radius-5 list-view__container">
            <i
              class="mdi mdi-format-list-bulleted font-size-1-6 cursor-pointer"
              @click="isTable = true"></i>
            <i
              class="mdi mdi-grid-large font-size-1-6 ml-10 cursor-pointer"
              @click="isTable = false"></i>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="d-flex direction-column w-100">
        <EventTable
          v-if="isTable"
          :states="states"
          :currentState="currentState" />
        <EventList
          v-else
          :events="events"
          :states="states"
          :currentState="currentState" />
        <div
          class="w-100 mt-5 d-flex jc-center ai-center as-end mb-5 item-button"
          ref="loading">
          <button @click="loadMore" class="btn primary pl-15 pr-15">
            <span>Mais Demandas</span>
          </button>
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<script>
import { cond, isEmpty, T, not, always } from "ramda";
// import { getEvent } from "@/services/events/event.http";
import { stringToDate, isValidDate } from "@/helpers/dateFormat";
import { mapActions, mapGetters } from "vuex";
import EventList from "@/components/events/myevents/EventList";
import TabMenu from "@/components/events/myevents/TabMenu.vue";
import EventTable from "@/components/events/myevents/EventTable";
import { debounce } from "@/helpers/debounce.js";

const filterEmptyProps = (filters) =>
  Object.entries(filters).reduce(
    (obj, [key, val]) => (val ? Object.assign(obj, { [key]: val }) : obj),
    {}
  );

export default {
  components: {
    EventList,
    EventTable,
    TabMenu,
  },
  data: () => ({
    view: {
      loading: false,
    },
    isTable: true,
    states: {
      EMPTY_LIST: "EMPTY_LIST",
      LIST_ITEMS: "LIST_ITEMS",
      EMPTY_FILTERED_SEARCH: "EMPTY_FILTERED_SEARCH",
      LOADING: "LOADING",
    },
    eventRef: "",
    emptySelection: true,
  }),

  mounted() {
    this.searchEvents = debounce(this.searchEvents, 500);
    this.getEvent();
  },

  watch: {
    eventRef: {
      deep: true,
      handler(value) {
        this.searchEvents(value);
      },
    },
  },

  methods: {
    ...mapActions({
      getEvent: "ListEvent/getEvent",
      setFiltersTab: "ListEvent/setFiltersTab",
    }),
    onIsEmpty(value) {
      this.emptySelection = value;
    },
    resetList() {
      this.$store.commit("ListEvent/RESET_ITEMS");
    },
    loadItems(filters) {
      const filter = {
        ...filterEmptyProps({ ...filters }),
      };
      this.view.loading = true;
      this.getEvent({ ...filter }).finally(() => (this.view.loading = false));
    },
    searchEvents(eventRef) {
      this.resetList();
      this.setFiltersTab({ ...this.filtersTab, eventRef });
      const filter = {
        ...filterEmptyProps({ ...this.filtersTab, eventRef }),
      };
      this.getEvent({ ...filter });
    },
    addPageItems({ content }) {
      this.$store.commit("ListEvent/SET_LIST_ITEMS", content);
      this.$store.commit("ListEvent/SET_LIST_EVENT", [
        ...this.events,
        ...content,
      ]);
    },
    loadMore() {
      const pagination = this.lastItemList?.createdAt
        ? { lastEventCreated: this.lastItemList?.createdAt }
        : {};

      const filtersWithPagination = { ...this.filtersTab, ...pagination };

      return this.loadItems(filtersWithPagination);
    },
    setFilters(filters) {
      const { payed, startDate, endDate } = filters;

      Object.assign(this.filters, {
        startDate: isValidDate(stringToDate(startDate)) ? startDate : "",
        endDate: isValidDate(stringToDate(endDate)) ? endDate : "",
        payed,
      });
    },
  },

  computed: {
    ...mapGetters({
      events: "ListEvent/events",
      items: "ListEvent/items",
      filtersTab: "ListEvent/filtersTab",
    }),
    event() {
      return Object.values(this.events);
    },
    lastItemList() {
      return isEmpty(this.event) ? {} : this.event[this.event.length - 1];
    },
    currentState() {
      const isFilterSearchEmpty = ({ filters, events }) =>
        not(isEmpty(filters)) && isEmpty(events);
      const getCurrentState = ((states) =>
        cond([
          [isFilterSearchEmpty, always(states.EMPTY_FILTERED_SEARCH)],
          [({ events }) => isEmpty(events), always(states.EMPTY_LIST)],
          [T, always(states.LIST_ITEMS)],
        ]))(this.states);

      return getCurrentState({
        filters: this.filters,
        events: this.events,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.page-layout {
  grid-template-rows: minmax(90px, 250px) 1fr;

  &.empty {
    grid-template-rows: 90px 1fr;
  }

  .list-view__container {
    background: lighten($SteelGray, 5%);
  }
}
</style>
