<template>
  <form
    action="?"
    @submit.prevent="updateInfo"
    class="d-flex direction-column pt-20 w-100 ai-center">
    <CustomInput
      :isInvalid="$v.name.$error"
      v-model.trim="$v.name.$model"
      placeholder="Nome Completo"
      class="w-70" />
    <div class="error d-flex ai-center w-70">
      <span v-if="$v.name.$dirty && !$v.name.minLength">
        Nome deve conter um minimo de 2 caracteres
      </span>
      <span v-if="$v.name.$dirty && !$v.name.required">Campo Obrigatório</span>
    </div>
    <CustomInput placeholder="Email" class="w-70" disabled v-model="email" />
    <div class="d-flex jc-space-between wrapper">
      <CustomInput
        :isInvalid="$v.cnpj.$error"
        v-model.trim="$v.cnpj.$model"
        placeholder="CNPJ"
        :mask="['##.###.###/####-##']" />
      <div class="d-flex ai-center pt-20">
        <SpinnerButton
          :loading="loading"
          :disabled="$v.cnpj.$error || $v.name.$error"
          class="btn primary">
          Salvar Alterações
        </SpinnerButton>
      </div>
    </div>
    <div class="error d-flex ai-center w-70">
      <span v-if="$v.cnpj.$dirty && !$v.cnpj.validDOC && $v.cnpj.required">
        Digite um CNPJ válido
      </span>
      <span v-if="$v.cnpj.$dirty && !$v.cnpj.required">Campo Obrigatório</span>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { updateInfoBasiHirer } from "@/services/hirer/hirer.http";
import { required, minLength } from "vuelidate/lib/validators";
import { validateCNPJ } from "@/helpers/form/validateCNPJ";

export default {
  data: () => ({
    name: "",
    email: "",
    cnpj: "",
    loading: false,
  }),
  validations: {
    name: {
      minLength: minLength(2),
      required,
    },
    cnpj: {
      required,
      validDOC: (doc) => validateCNPJ(doc),
    },
  },
  mounted() {
    this.name = this.identification.fantasyName;
    this.email = this.identification.email;
    this.cnpj = this.identification.doc;
  },
  computed: {
    ...mapGetters({
      identification: "Profile/identification",
    }),
  },
  watch: {
    identification: {
      deep: true,
      handler(val) {
        this.name = val.fantasyName;
        this.email = val.email;
        this.cnpj = val.doc;
      },
    },
    name: {
      deep: true,
      immediate: false,
      handler(val) {
        this.setIdentification({ ...this.identification, fantasyName: val });
      },
    },
    cnpj: {
      deep: true,
      immediate: false,
      handler(val) {
        this.setIdentification({ ...this.identification, doc: val });
      },
    },
  },
  methods: {
    ...mapActions({
      setIdentification: "Profile/setIdentification",
      save: "Profile/save",
    }),
    updateInfo() {
      this.loading = true;
      updateInfoBasiHirer({ name: this.name, document: this.cnpj })
        .then(() =>
          this.$eventhub.$emit("feedback:show", {
            type: "success",
            msg: "Suas informações foram salvas!",
          })
        )
        .catch((error) => {
          this.$eventhub.$emit("feedback:show", {
            type: "error",
            msg: error,
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 70.9%;
}
</style>
