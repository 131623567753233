<template>
  <div class="badge" :class="badgeClasses">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "red",
    },
    position: {
      type: String,
      default: "",
    },
  },
  computed: {
    badgeClasses() {
      let styles = this.color;

      if (this.position) styles += ` absolute ${this.position}`;

      return styles;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

.badge {
  border-radius: 50px;
  min-height: 5px;
  padding: 4px;
  min-width: 5px;

  &.absolute {
    position: absolute;
  }

  &.top {
    top: 10px;
  }

  &.bottom {
    bottom: 10px;
  }

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }

  &.red {
    background: $Red;
  }

  &.orange {
    background: $Orange;
  }
}
</style>
