<template>
  <section>
    <h3 class="title">Time</h3>

    <div class="profile-requested">
      <MultiSelect
        class="w-100"
        placeholder="Perfil desejado do time"
        :options="options"
        v-model="eventProfileTypes"></MultiSelect>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    options: [
      { label: "Comunicador", value: 1 },
      { label: "Executor", value: 2 },
      { label: "Analista", value: 3 },
      { label: "Planejador", value: 4 },
    ],
  }),
  computed: {
    ...mapGetters({ isEdit: "EditEvent/isEdit" }),

    eventProfileTypes: {
      get() {
        return this.isEdit
          ? this.$store.getters["EditEvent/eventProfileTypes"]
          : this.$store.getters["NewEvent/eventProfileTypes"];
      },
      set(eventProfileTypes) {
        this.isEdit
          ? this.$store.dispatch("EditEvent/setFormData", { eventProfileTypes })
          : this.$store.dispatch("NewEvent/setFormData", { eventProfileTypes });
      },
    },
  },
};
</script>

<style lang="scss">
.profile-requested {
  width: 250px;
}
</style>
