import { pipe, chain, pluck, flatten, sum, last, map } from "ramda";

import eventhub from "@/eventhub";
import { storage } from "@/mixins/storage";
import { getEventToEdit, putEditEvent } from "@/services/events/event.http";
import { strCurrencyToDecimal } from "@/helpers/form/currencyConvert";
import { isAgency, getUser } from "@/helpers/auth";

const initialState = () => ({
  isEdit: false,
  isOwner: false,
  isManager: false,
  form: {
    agencyData: {},
    eventOwner: "",
    hirerCnpj: "",
    id: undefined,
    imageFile: undefined,
    totalFreelas: 5,
    verba: "0",
    expectedAudience: "030 a 100",
    eventProfileTypes: [],
    hasSecurityProtocol: false,
    generalDescription: "",
    isHomeOffice: false,
    hirer: {},
    hasCheckinQrCode: false,
    hasCheckoutQrCode: false,
    requiresFullProfile: false,
    dates: [],
    event: {
      OS: "",
      title: "",
      type: "",
      topic: "",
    },
    location: {
      name: "",
      address: "",
      place_id: "",
    },
    contact: {
      fullName: "",
      email: "",
      tel: "",
    },
    services: new Map(),
  },
  previouslySelected: [],
  draft: {},
});

const state = initialState();

const mapToArray = pipe(Array.from, map(last));
const [getQtd, getWorkshift] = chain(pluck, ["quantity", "workshifts"]);
const totalFreelasComputed = pipe(getWorkshift, flatten, getQtd, sum);
const totalOrZero = (total) => (isNaN(total) ? 0 : total);
const getTotalVacancy = (services) =>
  pipe(totalFreelasComputed, totalOrZero)(services);
const feedback = ({ message }) =>
  eventhub.$emit("feedback:show", { type: "error", msg: message });
const redirect = (id) => eventhub.$emit("edit:event:redirect:preview", id);

const transformService = ([, service]) => ({
  ...service,
  totalVacancy: getTotalVacancy([service]),
  payment: strCurrencyToDecimal(service.payment),
  agencyProfit: strCurrencyToDecimal(service.agencyProfit),
  agencyIds: service.agency.map((a) => a.id),
});

const sortByDate = (a, b) => new Date(a) - new Date(b);

const getters = {
  isEdit: (state) => state.isEdit,
  isOwner: (state) => state.isOwner,
  isManager: (state) => state.isManager,
  servicesAdded: (state) => mapToArray(state.form.services),
  previouslySelected: (state) => state.previouslySelected,
  agencyForServices: (state) => ({
    id: getUser().id,
    cnpj: state.form.agencyData.cnpj,
    name: state.form.agencyData.fantasyName,
    avatarUrl: state.form.agencyData.avatarUrl,
  }),
  form: (state) => state.form,
  dates: (state) =>
    state.form.workshifts
      ? state.form.workshifts
          .map((s) => new Date(s.date.replace("Z", "")))
          .sort(sortByDate)
      : [new Date()],
  currentImage: (state) => state.form.imageFile,
  event: (state) => state.form.event,
  draft: (state) => state.draft,
  eventTypeNew: (state) => state.form.event.type,
  eventTopicNew: (state) => state.form.event.topic,
  location: (state) => state.form.location,
  hasSecurityProtocol: (state) => state.form.hasSecurityProtocol,
  isHomeOffice: (state) => state.form.isHomeOffice,
  qrCodeCheckinCheckout: (state) => {
    return {
      hasCheckinQrCode: state.form.hasCheckinQrCode,
      hasCheckoutQrCode: state.form.hasCheckoutQrCode,
    };
  },
  requiresFullProfile: (state) => state.form.requiresFullProfile,
  totalFreelasInServices: (state) => getTotalVacancy(state.form.services),
  contact: (state) => state.form.contact,
  eventProfileTypes: (state) => state.form.eventProfileTypes,
  expectedAudience: (state) => state.form.expectedAudience,
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },
  SET_IS_EDIT_TRUE(state) {
    state.isEdit = true;
  },

  SET_IS_EDIT_FALSE(state) {
    state.isEdit = false;
  },

  SET_IS_OWNER_TRUE(state) {
    state.isOwner = true;
  },

  SET_IS_OWNER_FALSE(state) {
    state.isOwner = false;
  },

  SET_IS_MANAGER_TRUE(state) {
    state.isManager = true;
  },

  SET_IS_MANAGER_FALSE(state) {
    state.isManager = false;
  },

  SET_AGENCY_DATA(state, agencyData) {
    Object.assign(state.form.agencyData, agencyData);
  },

  SET_SERVICE(state, newMap) {
    state.form.services = newMap;
  },
  SET_EVENT_DETAIL(state, data) {
    state.form = {
      id: data.id,
      eventOwner: data.eventOwner,
      hirerCnpj: data.hirerCnpj,
      agencyData: state.form.agencyData,
      event: {
        title: data.name,
        type: data.eventTypeNew,
        topic: data.eventTopicNew,
      },
      location: {
        name: data.placeName,
        address: data.address,
        place_id: data.addressId,
      },
      contact: {
        fullName: data.contactName,
        email: data.email,
        tel: data.phone,
      },
      expectedAudience: data.expectedAudienceNumber,
      eventProfileTypes: data.eventProfileTypes,
      generalDescription: data.description,
      isHomeOffice: data.isHomeOffice,
      hasCheckinQrCode: data.hasCheckinQrCode,
      hasCheckoutQrCode: data.hasCheckoutQrCode,
      requiresFullProfile: data.requiresFullProfile,
      hasSecurityProtocol: data.hasSecurityProtocol,
      imageFile: data.image,
      services: new Map(
        data.services.map((s) => [
          s.job,
          {
            ...s,
            payment: Number.parseFloat(s.payment).toFixed(2),
            agencyProfit: s.agencyProfit?.toFixed(2) ?? 0,
            workshifts: s.workshifts
              .flatMap((gw) => gw.workshifts)
              .map((w) => {
                return {
                  ...w,
                  date: new Date(w.day.replace("Z", "")),
                  checkinDate: w.checkin,
                  checkinTime: w.checkin,
                  checkoutDate: w.checkout,
                  checkoutTime: w.checkout,
                };
              }),
          },
        ])
      ),
      workshifts: data.services.flatMap((s) => s.workshifts),
    };
  },

  SET_PREV_SELECTED(state, data) {
    state.previouslySelected = data;
  },

  SET_FORM(state, data) {
    Object.assign(state.form, data);
  },

  SET_DRAFT(state, draft) {
    state.draft = draft;
  },
};

const actions = {
  tryEditEvent({ commit }, hasRouteParamId) {
    hasRouteParamId ? commit("SET_IS_EDIT_TRUE") : commit("SET_IS_EDIT_FALSE");
  },

  setPreviouslySelected({ commit }, previouslySelected) {
    commit("SET_PREV_SELECTED", previouslySelected);
  },

  setAgencyData({ commit }, agencyData) {
    commit("SET_AGENCY_DATA", agencyData);
  },

  getEventDetail({ commit }, id) {
    getEventToEdit(id, true)
      .then((result) => commit("SET_EVENT_DETAIL", result))
      .catch((error) => {
        feedback(error);
        redirect(id);
      });
  },

  addNewServices({ commit, state }, services) {
    const newMap = new Map(state.form.services);
    const exclusiveServices = state.form.agencyData.services;

    const getAgencyExclusivity = (service) =>
      isAgency() &&
      exclusiveServices
        .map((s) => s.toLowerCase())
        .includes(service.toLowerCase())
        ? [getters.agencyForServices(state)]
        : [];

    const newServices = services.map((service) => {
      return newMap.has(service)
        ? [service, newMap.get(service)]
        : [
            service,
            {
              totalVacancy: 0,
              job: service,
              payment: "",
              agencyProfit: "",
              responsabilities: [],
              checkListAtCheckin: [],
              checkListAtCheckout: [],
              workshifts: [],
              agency: getAgencyExclusivity(service),
            },
          ];
    });

    const arr = mapToArray(newMap);
    const prepare = arr.map((a) => [a.job, a]);

    commit("SET_SERVICE", new Map([...prepare, ...newServices]));
  },

  setEditPermissions({ state, commit, rootGetters }) {
    const isOwner =
      state.form.eventOwner === rootGetters["Profile/cnpjAccount"];
    const isManager =
      state.form.hirerCnpj === rootGetters["Profile/cnpjAccount"];

    isOwner && commit("SET_IS_OWNER_TRUE");
    isManager && commit("SET_IS_MANAGER_TRUE");
  },

  getEventDetailToEdit({ dispatch, commit }, id) {
    if (state.isEdit)
      getEventToEdit(id)
        .then((result) => commit("SET_EVENT_DETAIL", result))
        .catch((error) => {
          feedback(error);
          redirect(id);
        })
        .then(() => dispatch("setEditPermissions", id))
        .then(() => {
          if (state.isOwner || state.isManager) return;
          redirect(id);
          feedback({
            message: "Você não tem permissão para editar esse evento.",
          });
        });
  },

  updateService({ commit, state }, service) {
    const newMap = new Map(state.form.services);

    const serviceFounded = newMap.get(service.job);
    const updateedService = { ...serviceFounded, ...service };
    newMap.set(service.job, updateedService);

    commit("SET_SERVICE", newMap);
  },

  editService({ commit, state }, editedService) {
    const newMap = new Map(state.form.services);

    const serviceFounded = newMap.get(editedService.job);
    const updatedService = { ...serviceFounded, ...editedService };
    newMap.set(editedService.job, updatedService);

    commit("SET_SERVICE", newMap);
  },
  save({ state: { form }, rootState: { NewEvent } }) {
    const services = Array.from([
      ...Array.from(NewEvent.form.services.values()),
      ...form.services,
    ]);

    form.isHomeOffice && delete form.location;
    const body = {
      ...form,
      eventId: form.event.id,
      name: form.event.title,
      image: form.imageFile && form.imageFile.split("base64,").reverse()[0],
      eventTopicNew: Number(form.event.topic),
      eventTypeNew: Number(form.event.type),
      expectedAudienceNumber: form.expectedAudience,
      eventProfileTypes: form.eventProfileTypes,
      isHomeOffice: form.isHomeOffice,
      hasCheckinQrCode: form.hasCheckinQrCode,
      hasCheckoutQrCode: form.hasCheckoutQrCode,
      requiresFullProfile: form.requiresFullProfile,
      hasSecurityProtocol: form.hasSecurityProtocol,
      contactName: form.contact.fullName,
      email: form.contact.email,
      phone: form.contact.tel,
      description: form.generalDescription,
      services: services.map(transformService),
    };
    return pipe(() => putEditEvent(form.id, body))();
  },

  setFormData: ({ commit }, data) => commit("SET_FORM", { ...data }),

  saveDraft: ({ state: { form }, commit }) => {
    storage.set("eventDraft", { ...form, draftUpdatedAt: new Date() });
    commit("SET_DRAFT", storage.get("eventDraft"));
  },
  resetState: ({ commit }) => commit("RESET_STATE"),
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
