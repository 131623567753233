<template>
  <PageLayout class="manage-event no-header ml-28 mr-28">
    <template #content>
      <article class="d-flex f-100 wrapped h-100">
        <div :class="HandleFullScreenStyle" class="align">
          <template v-if="job">
            <ServiceRecruit @back="onBackButtonClick" :serviceId="serviceId" />
          </template>
          <template v-else>
            <HeaderDemand />
            <Tabs :tabs="tabs">
              <Tab tab-id="basic-info">
                <EventBasicInfo />
              </Tab>

              <Tab tab-id="event-details">
                <EventDetails />
              </Tab>
              <Tab tab-id="payroll">
                <EventPayroll :eventService="eventService" />
              </Tab>

              <Tab tab-id="services">
                <div class="p-15 overflow-hidden w-100 h-100">
                  <ServiceList
                    @serviceSelected="onServiceSelected"
                    :eventService="eventService" />
                </div>
              </Tab>
            </Tabs>
          </template>
        </div>
        <div class="d-flex f-33 h-100 ml-15" v-if="showNegotiation">
          <template>
            <NegotiationTimeLine />
          </template>
        </div>
      </article>
    </template>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import EventBasicInfo from "@/components/events/EventInfo/EventBasicInfo/EventBasicInfo.vue";
import EventDetails from "@/components/events/EventInfo/EventDetails/EventDetails.vue";
import ServiceList from "@/components/events/EventInfo/EventServices/ServiceList.vue";
import ServiceRecruit from "@/components/events/EventInfo/EventServices/ServiceRecruit.vue";
import NegotiationTimeLine from "@/components/events/EventInfo/Negotiation/NegotiationTimeLine.vue";
import EventPayroll from "@/components/events/EventInfo/EventPayroll/index.vue";
import HeaderDemand from "@/components/events/EventInfo/EventBasicInfo/HeaderDemand.vue";

export default {
  components: {
    EventBasicInfo,
    EventDetails,
    ServiceList,
    ServiceRecruit,
    NegotiationTimeLine,
    EventPayroll,
    HeaderDemand,
  },
  computed: {
    ...mapGetters({
      eventService: "EventDetails/services",
      showNegotiation: "EventDetails/showNegotiation",
      operations: "EventDetails/operations",
      basicInfo: "EventDetails/basicInfo",
      isAgency: "User/isAgency",
    }),

    tabs() {
      const tabs = [
        { id: "basic-info", label: "Informações Básicas" },
        { id: "event-details", label: "Detalhes da Demanda" },
        { id: "services", label: "Serviços" },
      ];
      const hash = this.$route.hash.replace("#", "");
      return tabs.map((tab) => ({ ...tab, active: tab.id === hash }));
    },
    isManagementByAgency() {
      return (
        this.isAgency ||
        (!this.basicInfo.isManagementByAgency &&
          !this.basicInfo.hasServiceManagementByAgency)
      );
    },
    eventId() {
      return this.$route.params.id;
    },
    job() {
      return this.$route.params.job;
    },
    serviceId() {
      return this.$route.params.serviceId;
    },

    HandleFullScreenStyle() {
      return this.showNegotiation ? "d-flex f-65" : "d-flex f-100";
    },
  },
  filters: {
    formatDate(e) {
      return `Dia ${new Date(e.replace("Z", "")).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })}`;
    },
  },
  mounted() {
    this.init({ eventId: this.eventId, serviceId: this.job });
  },
  methods: {
    ...mapActions({
      init: "EventDetails/init",
      getServiceReport: "EventDetails/getServiceReport",
    }),
    reportDownload(day) {
      this.getServiceReport({
        eventId: this.eventId,
        day,
      });
    },
    onEditEventClick() {
      this.$router.push({
        name: "events.edit",
        params: { id: this.eventId, step: 1 },
      });
    },
    onServiceSelected({ job, service }) {
      this.$router.push({
        name: "events.info.services",
        params: {
          id: this.eventId,
          job: job,
          serviceId: service.serviceId,
        },
      });
      this.init({ eventId: this.eventId, serviceId: this.job });
    },
    onBackButtonClick() {
      this.$router.push({
        name: "events.info",
        params: { id: this.eventId },
        hash: "#services",
      });
      this.init({ eventId: this.eventId, serviceId: this.serviceId });
    },
  },
};
</script>

<style lang="scss" scoped>
.align {
  display: flex;
  flex-direction: column;
}
</style>
