<template>
  <CustomAutocomplete
    :tip="tip"
    :id="id"
    :label="label"
    :example="example"
    :error="error"
    :placeholder="placeholder"
    :search="search"
    displayField="description"
    :value="input"
    @selected="$emit('selected', $event)"
    @input="input = $event"
    :disabled="disabled"></CustomAutocomplete>
</template>

<script>
import { searchAddress } from "@/services/events/event.http";

export default {
  props: {
    value: { default: "" },
    id: { default: "" },
    tip: { default: "" },
    label: { default: "" },
    example: { default: "" },
    error: { default: "" },
    placeholder: { default: "" },
    disabled: { default: false },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    search(term) {
      return searchAddress(term);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
</style>
