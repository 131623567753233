import axios from "axios";
import { ifElse } from "ramda";
import qs from "qs";

const performError = (e) => {
  const error = e.response ? { message: e.response.data.errorMessage } : e;
  throw error;
};

const isSuccess = ({ data: { isSuccess } }) => isSuccess;
const returnResult = ({ data: { result } }) => result;

const validateReturn = ifElse(isSuccess, returnResult, performError);

const searchAddress = (data) =>
  axios.get(`/events/address?term=${data}`).then(validateReturn);

const getEventTopics = () =>
  axios.get("/events/topics").then(validateReturn).catch(performError);

const getAvailableEventTypes = () =>
  axios.get("/events/types").then(validateReturn).catch(performError);

const getFaqByEventType = (eventTypeNew) =>
  axios
    .get(`/events/faq/${eventTypeNew}`)
    .then(validateReturn)
    .catch(performError);

const saveEvent = (event) =>
  axios.post("/events", event).then(validateReturn).catch(performError);

const saveAgencyEvent = (event) =>
  axios
    .post("/events/agencies", event)
    .then(validateReturn)
    .catch(performError);

const saveEventWithFiveThanFreelas = (event) =>
  axios
    .post("/events/morethanfive", event)
    .then(validateReturn)
    .catch(performError);

const saveEventWithPromotionalCode = (event) =>
  axios
    .post("/events/promotions", event)
    .then(validateReturn)
    .catch(performError);

const getEvent = (event = "", params) =>
  axios
    .get(`/events/${event}`, { params })
    .then(validateReturn)
    .catch(performError);

const getEventDetails = (id) =>
  axios.get(`/events/${id}/preview`).then(validateReturn).catch(performError);

const getEventToEdit = (id, isReplicated) =>
  axios
    .get(`/events/${id}`, {
      params: {
        replicate: isReplicated,
      },
    })
    .then(validateReturn)
    .catch(performError);

const getFreelaInfo = (id) =>
  axios.get(`/freelas/${id}/about`).then(validateReturn).catch(performError);

const getAgencies = (id) =>
  axios.get(`/freelas/${id}/agencies`).then(validateReturn).catch(performError);

const getAvailableServices = (term) =>
  axios
    .get(`/services`, { params: { term } })
    .then(validateReturn)
    .catch(performError);

const getEventAccounts = () =>
  axios.get(`/eventAccounts`).then(validateReturn).catch(performError);

const getStaticMap = (data) =>
  axios
    .get(`/events/staticmap?term=${data}`)
    .then(validateReturn)
    .catch(performError);

const getJobMembers = (eventId, job) =>
  axios
    .get(`/events/${eventId}/vacancies/${job}`)
    .then(validateReturn)
    .catch(performError);

const getJobDoneMembers = ({
  eventId,
  services,
  shift,
  startDate,
  endDate,
  paymentStatus,
  term,
  eventName,
}) =>
  axios
    .get(`/events/vacancies/waitingPayment`, {
      params: {
        eventId,
        services,
        shift,
        startDate,
        endDate,
        paymentStatus,
        term,
        eventName,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
    })
    .then(validateReturn)
    .catch(performError);

const getReport = (eventId, job, day, status) =>
  axios
    .get(
      `/events/${eventId}/vacancies/${job}/export?data=${day}&status=${status}`,
      {
        responseType: "blob",
      }
    )
    .catch(performError);

const getServiceReport = (eventId, day) =>
  axios
    .get(`/events/${eventId}/service/report`, {
      responseType: "blob",
      params: { eventDate: day },
    })
    .catch(performError);

const publishVacancy = (job, body) =>
  axios.post(`vacancies/${job}/publish`, body).then(validateReturn);

const sendInvite = (body) =>
  axios
    .post(`vacancies/invitation`, body)
    .then(validateReturn)
    .catch(performError);

const cancelInvite = (vacancyId, status) =>
  axios
    .delete(`vacancies/invitations`, {
      data: {
        cancelInvitesToFreelas: [{ vacancyId, status }],
      },
    })
    .then(validateReturn)
    .catch(performError);

const approveInvite = (id) =>
  axios
    .put(`vacancies/${id}/invitations/approve`)
    .then(validateReturn)
    .catch(performError);

const getMembersAvatar = (id, services) =>
  axios
    .get(`events/${id}/services/${services}/members`)
    .then(validateReturn)
    .catch(performError);

const postConfirmEventPayment = (id, job, body) =>
  axios
    .post(`events/${id}/services/${job}/payments`, body)
    .then(validateReturn)
    .catch(performError);

const putEditEvent = (id, body) =>
  axios.put(`events/${id}`, body).then(validateReturn).catch(performError);

const getFreelasLocation = (id, date) =>
  axios
    .get(`operations/start/${date}/events/${id}`)
    .then(validateReturn)
    .catch(performError);

const getEventLocation = (id) =>
  axios
    .get(`events/location?placeId=${id}`)
    .then(validateReturn)
    .catch(performError);

const addNewBudget = ({ eventId, serviceId, ...body }) =>
  axios
    .post(`events/${eventId}/services/${serviceId}/budgets`, body)
    .then(validateReturn)
    .catch(performError);

const refuseBudget = ({ eventId, serviceId, agencyId }) =>
  axios
    .post(`events/${eventId}/services/${serviceId}/budgets/${agencyId}/refuse`)
    .then(validateReturn)
    .catch(performError);

const acceptBudget = ({ eventId, serviceId, agencyId }) =>
  axios
    .post(`events/${eventId}/services/${serviceId}/budgets/${agencyId}/accept`)
    .then(validateReturn)
    .catch(performError);

const postEmergencialVacancy = (body) =>
  axios
    .post("vacancies/emergency", body)
    .then(validateReturn)
    .catch(performError);

const getEventServices = ({ cnpj, service, startDate, endDate }) =>
  axios
    .get("/services/event", {
      params: { cnpj, service, startDate, endDate },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
    })
    .then(validateReturn)
    .catch(performError);

const getDistanceBetween = ({ placeId_1, placeId_2 }) =>
  axios
    .get("events/distance/between", {
      params: { placeId_1, placeId_2 },
    })
    .then(validateReturn)
    .catch(performError);

export {
  saveEvent,
  saveAgencyEvent,
  saveEventWithPromotionalCode,
  saveEventWithFiveThanFreelas,
  searchAddress,
  getEventTopics,
  getEventAccounts,
  getFreelaInfo,
  getAgencies,
  getAvailableEventTypes,
  getFaqByEventType,
  getAvailableServices,
  getEvent,
  getEventDetails,
  getEventToEdit,
  getStaticMap,
  getMembersAvatar,
  getFreelasLocation,
  getJobMembers,
  getEventLocation,
  postConfirmEventPayment,
  publishVacancy,
  putEditEvent,
  sendInvite,
  cancelInvite,
  approveInvite,
  addNewBudget,
  refuseBudget,
  acceptBudget,
  postEmergencialVacancy,
  getReport,
  getServiceReport,
  getJobDoneMembers,
  getEventServices,
  getDistanceBetween,
};
