<template>
  <div class="spinner-wrapper" :class="size">
    <svg class="spinner" viewBox="0 0 50 50">
      <circle
        class="path"
        :class="theme"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "small",
    },

    theme: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";

@mixin square-size($size) {
  height: $size;
  width: $size;
}

.spinner-wrapper {
  position: relative;

  &.small {
    @include square-size(20px);
    .spinner {
      @include square-size(20px);
    }
  }

  &.medium {
    @include square-size(40px);
    .spinner {
      @include square-size(40px);
    }
  }

  .spinner {
    animation: rotate 2s linear infinite;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;

    .path {
      stroke: white;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
