<template>
  <section>
    <Divider class="mv-30" />
    <h3 class="title">
      <span>Cliente</span>
    </h3>

    <SelectHirer />
  </section>
</template>
<script>
import SelectHirer from "./SelectHirer";

export default {
  components: { SelectHirer },
};
</script>
