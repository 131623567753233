<template>
  <md-content class="md-scrollbar d-flex h-100 ai-start wrapped">
    <ServiceCard
      class="m-15"
      @members-click="$emit('serviceSelected', { job: $event, service })"
      v-for="(service, index) in allowedServicesToShow"
      :key="index"
      :serviceData="service" />
  </md-content>
</template>

<script>
import ServiceCard from "./ServiceCard.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ServiceCard,
  },
  data: () => ({
    allowedServicesToShow: [],
  }),
  props: {
    eventService: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      userId: "User/userId",
      isAgency: "User/isAgency",
    }),
  },

  watch: {
    eventService: {
      deep: true,
      handler(value) {
        this.allowedServicesToShow = this.isAgency
          ? value.filter((s) =>
              s.negotiations.some((n) => n.agencyId == this.userId)
            )
          : value;
      },
    },
  },
};
</script>
